import React from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import Flag from '@atlaskit/flag';
import { useTranslation } from 'react-i18next';
import { removeFlag } from '../../store/actions/flags';
import Error from '@atlaskit/icon/glyph/error';
import Info from '@atlaskit/icon/glyph/info';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Warning from '@atlaskit/icon/glyph/warning';
import { isString, has } from 'lodash/fp';
import { colors } from '@atlaskit/theme';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

const Flags = styled.div`
  position: fixed;
  max-width: 700px;
  z-index: 214748300;
  
  & > div {
    padding: ${props => (props.isTabletOrMobile ? '12px' : '16px')};    
  }
`;

const getStyle = (isSmallScreen) => isSmallScreen
  ? {
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: "96%",
    bottom: '15px',
  }
  : {
    right: '20px',
    width: "auto",
    bottom: '20px',
  };

// eslint-disable-next-line
export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const { flags } = useMappedState(state => ({ flags: state.flags }));

  const getIcon = ({ appearance }) => {
    return (
      {
        success: <Tick label="Success" secondaryColor={colors.G400} />,
        warning: <Warning label="Warning icon" secondaryColor={colors.Y300} />,
        error: <Error label="Error icon" secondaryColor={colors.R300} />
      }[appearance] || <Info label="Info icon" secondaryColor={colors.N500} />
    );
  };

  return (
    <Flags style={getStyle(isTabletOrMobile)} isTabletOrMobile>
      {flags.list.map(flag => {
        const title = isString(flag.title) ? t(flag.title) : flag.title;

        if (has('timeout', flag)) {
          setTimeout(() => dispatch(removeFlag(flag.id)), flag.timeout);
        }

        return (
          <Flag
            {...flag}
            key={flag.id}
            isDismissAllowed
            icon={getIcon(flag)}
            title={title}
            description={t(flag.description)}
            actions={[
              {
                content: t('common:ok'),
                onClick: () => dispatch(removeFlag(flag.id))
              }
            ]}
          />
        );
      })}
    </Flags>
  );
};
