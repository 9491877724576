import types from '../types';
import UpgradeService from '../../services/UpgradeService';
import ProductsService from '../../services/ProductsService';
import handleError from '../utils/errors';
import { addFlag } from './flags';
import ConfsService from '../../services/ConfsService';
import { delay } from '../../components/utils';
import { findAllConfs, selectConf, unSelectConf } from './confs';

export const checkUpgradable = id => dispatch => {
  dispatch({
    type: types.CHECK_UPGRADABLE_REQUEST
  });

  return UpgradeService.isUpgradable(id)
    .then(flag => {
      return dispatch({
        type: types.CHECK_UPGRADABLE_SUCCESS,
        payload: flag
      });
    })
    .catch(err => handleError(types.CHECK_UPGRADABLE_ERROR, err, dispatch));
};

export const upgradeConf = (confId, items) => dispatch => {
  dispatch({ type: types.UPGRADE_CONF_REQUEST });

  return ProductsService.checkout({ confId, items })
    .then(res => {
      if(res.status === 'paid') {
        return delay(5000)
          .then(() => dispatch(unSelectConf()))
          .then(() => dispatch(findAllConfs()))
          .then(() => dispatch(selectConf(confId)))
          .then(() => dispatch({ type: types.UPGRADE_CONF_SUCCESS }))
          .then(() => {
            dispatch(
              addFlag({
                appearance: 'success',
                title: 'upgrade:actions.update.success',
                timeout: 5000
              })
            );
          });
      }

      dispatch({ type: types.UPGRADE_CONF_NEED_VERIFICATION, payload: res.payment_intent });
    })
    .catch(err => handleError(types.UPGRADE_CONF_ERROR, err, dispatch));
};

export const setUpgradeError = error => dispatch => {
  handleError(types.CHECK_UPGRADABLE_ERROR, { message: error }, dispatch);
};

export const getConfLimits = (id) => dispatch => {
  dispatch({ type: types.CONF_LIMITS_REQUEST });

  return ConfsService.getConfLimits(id)
    .then(data => {
      return dispatch({
        type: types.CONF_LIMITS_SUCCESS,
        payload: data
      });
    })
    .catch(err => handleError(types.CONF_LIMITS_ERROR, err, dispatch));
};

export const cancelVerification = () => dispatch => {
  dispatch({ type: types.UPGRADE_CONF_CANCEL_VERIFICATION });
};
