const links = {
  FIND_ALL_LINKS_REQUEST: 'FIND_ALL_LINKS_REQUEST',
  FIND_ALL_LINKS_SUCCESS: 'FIND_ALL_LINKS_SUCCESS',
  FIND_ALL_LINKS_ERROR: 'FIND_ALL_LINKS_ERROR',

  CREATE_LINK_REQUEST: 'CREATE_LINK_REQUEST',
  CREATE_LINK_SUCCESS: 'CREATE_LINK_SUCCESS',
  CREATE_LINK_ERROR: 'CREATE_LINK_ERROR',

  UPDATE_LINK_REQUEST: 'UPDATE_LINK_REQUEST',
  UPDATE_LINK_SUCCESS: 'UPDATE_LINK_SUCCESS',
  UPDATE_LINK_ERROR: 'UPDATE_LINK_ERROR',

  DELETE_LINK: 'DELETE_LINK'
};

export default links;
