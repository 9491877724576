import types from '../types';
import { requestStatus } from '../request-status';

const initialState = {
  sources: [],
  status: requestStatus.UNDONE,
  deletion: {
    id: null,
    status: requestStatus.UNDONE
  },
  invoices: {
    status: requestStatus.UNDONE
  },
  emailVerification: {
    status: requestStatus.UNDONE,
    errored: false
  },
  consent: {
    status: requestStatus.UNDONE
  },
  is24HourTime: false
};

const account = (state = initialState, action) => {
  switch (action.type) {
    case types.SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        emailVerification: {
          errored: false,
          status: requestStatus.WAITING
        }
      };

    case types.SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        emailVerification: {
          errored: false,
          status: requestStatus.DONE
        }
      };

    case types.SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        emailVerification: {
          errored: true,
          status: requestStatus.DONE
        }
      };

    case types.GET_ACCOUNT_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING
      };

    case types.GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: requestStatus.DONE,
        error: ''
      };

    case types.GET_ACCOUNT_ERROR: {
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload
      };
    }

    case types.UPDATE_ACCOUNT_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING
      };

    case types.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: requestStatus.DONE,
        error: ''
      };

    case types.UPDATE_ACCOUNT_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload
      };

    case types.GET_INVOICES_REQUEST:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          status: requestStatus.WAITING,
          error: ''
        }
      };

    case types.GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          status: requestStatus.DONE,
          error: '',
          ...action.payload
        }
      };

    case types.GET_INVOICES_ERROR:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          status: requestStatus.DONE,
          error: action.payload
        }
      };

    case types.REMOVE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        deletion: {
          ...state.deleteStatus,
          status: requestStatus.WAITING,
          id: action.payload.id
        }
      };

    case types.REMOVE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        deletion: {
          ...state.deleteStatus,
          status: requestStatus.DONE,
          id: null
        }
      };

    case types.REMOVE_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        deletion: {
          ...state.deleteStatus,
          status: requestStatus.DONE,
          id: null
        }
      };

    case types.CONSENT_REQUEST:
      return {
        ...state,
        consent: {
          status: requestStatus.WAITING
        }
      };

    case types.CONSENT_SUCCESS:
      return {
        ...state,
        consent: {
          status: requestStatus.DONE
        }
      };

    case types.CONSENT_ERROR:
      return {
        ...state,
        consent: {
          status: requestStatus.DONE
        }
      };

    case types.SET_TIME_FORMAT:
      return {
        ...state,
        is24HourTime: action.payload
      };

    default:
      return state;
  }
};

export default account;