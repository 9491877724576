export const setIntercomUser = ({ nickname, name, email, created_at }) => {
  if (process.env.REACT_APP_MODE !== 'development') {
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_ID,
      name: nickname || name,
      email,
      created_at,
    });
  }
};

export const shutdownIntercom = () => {
  if (process.env.REACT_APP_MODE !== 'development') {
    window.Intercom('shutdown');
  }
};
