import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { every } from 'lodash/fp';
import FontAwesomeIcon from '../../utils/icons/font-awesome-icon';
import { getSelectedConf } from '../../../store/selectors/confs';
import { findProgress } from '../../../store/actions/progress';
import ConfIsReady from './conf-is-ready';
import ProgressCards from './progress-cards';
import { getSteps } from './steps';
import { MASK } from '../../../constants';
import { getMaskClass } from '../../utils/get-mask-data';

const ProgressWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  
  @media (min-width: 480px) {
    padding: 0 15px;
  }
`;

const ProgressContainer = styled.div`
  margin: 60px 0;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bar = styled.div`
  height: 3px;
`;

const ProgressBarEmpty = styled(Bar)`
  background: #bcd9fb;
  position: relative;
  width: ${props => props.width};
`;

const ProgressBarFull = styled(Bar)`
  background: var(--blue-2);
  width: ${props => (props.isLastCompleted ? '50%' : props.isCompleted ? '100%' : 0)};
  ${props =>
    props.isLastCompleted ? 'transition: all 0.5s cubic-bezier(.18,.88,.34,1.22)' : null};
`;

const Bullet = styled.div`
  position: absolute;
  background: ${props => (props.isCompleted ? 'var(--blue-2)' : '#bcd9fb')};
  height: 25px;
  width: 25px;
  top: -10px;
  ${props => (props.isLast ? 'right: 0' : 'left: -1px')};
  border-radius: 15px;
  text-align: center;
  animation: ${props => (props.isNextToComplete ? 'pulse 2s infinite' : '')};

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(53, 139, 241, 1);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(53, 139, 241, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(53, 139, 241, 0);
    }
  }
`;

const StepTitle = styled.div`
  width: 75px;
  position: absolute;
  top: -20px;
  left: -20px;
  color: ${props => (props.isCompleted ? 'var(--blue-2)' : '#bcd9fb')};
  font-size: 12px;
  
  @media (min-width: 640px) {
    width: 95px;
    font-size: 14px;
    top: -23px;
    left: -30px;
  }
`;

const Icon = ({ icon, name, href, isLast, isCompleted, isNextToComplete }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 480 });

  return (
    <NavLink to={href} exact>
      <Bullet isLast={isLast} isCompleted={isCompleted} isNextToComplete={isNextToComplete}>
        <div
          className={isNextToComplete ? getMaskClass(MASK.NEXT_STEP) : ''}
          style={{ width: '25px', height: '25px' }}
        >
          {!isSmallScreen && (
            <StepTitle isCompleted={isCompleted}>
              {name}{' '}
              {isCompleted && <FontAwesomeIcon icon={'check'} size={'sm'} color={'var(--green-3)'} />}
            </StepTitle>
          )}
          <FontAwesomeIcon icon={icon} size={'xs'} color="#fff" style={{ marginTop: 6 }} />
        </div>
      </Bullet>
    </NavLink>
  );
};

// eslint-disable-next-line
const ConfProgress = ({ showCards = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedConf, progressEntities } = useMappedState(state => ({
    progressEntities: state.progress.entities,
    selectedConf: getSelectedConf(state)
  }));

  useEffect(() => {
    dispatch(findProgress(selectedConf.id));
  }, [dispatch, selectedConf.id]);

  const steps = getSteps(t, selectedConf.id, progressEntities);
  const confIsReady = every(Boolean)(progressEntities);
  return (
    <ProgressWrapper>
      <ProgressContainer>
        <ProgressBarContainer>
          {steps.slice(0, -1).map((step, i) => {
            return (
              <ProgressBarEmpty key={i} width={`${100 / (steps.length - 1)}%`}>
                <ProgressBarFull {...step} />
                <Icon {...step} />
                {i === steps.length - 2 && <Icon {...steps[i + 1]} isLast={true} />}
              </ProgressBarEmpty>
            );
          })}
        </ProgressBarContainer>

        {showCards && (
          <div>
            {confIsReady && <ConfIsReady selectedConf={selectedConf} />}
            <ProgressCards steps={steps} confIsReady={confIsReady} />
          </div>
        )}
      </ProgressContainer>
    </ProgressWrapper>
  );
};

export default ConfProgress;
