import types from '../types';
import { ProductsTypes } from '../../constants';

const initialState = {
  products: []
};

const cart = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_PRODUCT_TO_CART:
      return {
        ...state,
        products: [...state.products, action.payload]
      };

    case types.REMOVE_PRODUCT_FROM_CART:
      return {
        ...state,
        products: state.products.filter(product => {
          return product.id !== action.payload;
        })
      };

    case types.REMOVE_PLANS_FROM_CART:
      return {
        ...state,
        products: state.products.filter(product => {
          return product.type !== ProductsTypes.PLAN;
        })
      };

    case types.REPLACE_PRODUCT_IN_CART:
      return {
        ...state,
        products: state.products.map((p, index) => {
          return action.payload.productIndexInCart === index ? action.payload.product : p;
        })
      };

    case types.EMPTY_CART:
      return {
        ...state,
        products: []
      };

    default:
      return state;
  }
};

export default cart;