import React from 'react';
import styled from 'styled-components';
import Tooltip from 'react-tooltip';
import Banner from '../utils/banner';
import PageWrapper from '../layout/page-wrapper';
import ConfProgress from '../confs/progress/conf-progress';
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel'

const Container = styled(PageWrapper)`
  margin: ${props => (!props.isSub ? '110px' : '50px')} auto 50px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  > h1 {
    margin-right: 15px;
  }
`;

const BannersWrapper = styled.div`
  padding-bottom: 10px;
`;

const CardContent = styled.div`
    position: relative;
    padding: 30px 20px;

    @media (min-width: 640px) {
        padding: 30px;
    }
`;

// eslint-disable-next-line
export default ({
  style,
  afterTitle,
  title,
  titleTooltip,
  children,
  isSub,
  addChildren = '',
  banners = [],
  showProgress = false,
}) => {
  return (
    <Container isSub={isSub} style={style} showProgress={showProgress}>
      {showProgress && <ConfProgress />}
      <Tooltip />
      <TitleWrapper>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h1 style={{ position: 'relative', marginBottom: 20, marginRight: 10 }}>
            {title}
            {titleTooltip && (
              <span
                style={{ position: 'absolute', top: -15, right: -25 }}
                data-tip={titleTooltip}
              >
                <EditorPanelIcon label="Info icon" size='large' primaryColor="grey" />
              </span>
            )}
          </h1>
          {afterTitle}
        </div>
        {addChildren}
      </TitleWrapper>
      <BannersWrapper>
        {banners.map((banner, i) => (
          <Banner isOpen={true} appearance={banner.type} key={i}>
            {banner.message}
          </Banner>
        ))}
      </BannersWrapper>
      <div style={{ backgroundColor: '#fff', borderRadius: 10, boxShadow: '#dfe8f0 0px 1px 7px' }}>
        <CardContent>{children}</CardContent>
      </div>
    </Container>
  );
};
