import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import {
  flow,
  filter,
  groupBy,
  map,
  flatMap,
  uniq,
  uniqBy,
  sortBy,
  identity,
  compact
} from 'lodash/fp';
import { startOfDay, startOfMinute, setMinutes, startOfHour, isEqual } from 'date-fns/esm';
import { requestStatus } from '../request-status';

const sessionList = state => state.sessions.list;
const sessionsStatus = state => state.sessions.status;
const sessionsSelectedDay = state => state.sessions.selectedDay;
const getSelectedRooms = state => state.sessions.selectedRooms;
const getSelectedSpeakers = state => state.sessions.selectedSpeakers;
const getNearestDate = dates => {
  if (isEmpty(dates)) {
    return;
  }
  const today = new Date();
  return startOfDay(dates.find(date => date >= today) || dates[dates.length - 1]);
};

export const getSelectedDay = createSelector(
  sessionList,
  sessionsSelectedDay,
  (sessions, selectedDay) => {
    return !selectedDay
      ? getNearestDate(sessions.map(session => new Date(session.from)))
      : selectedDay;
  }
);

export const sessionsHaveFilters = createSelector(
  sessionsSelectedDay,
  getSelectedRooms,
  getSelectedSpeakers,
  (selectedDay, selectedRooms, selectedSpeakers) => {
    return !(!selectedDay && isEmpty(selectedRooms) && isEmpty(selectedSpeakers));
  }
);

export const getSessionsOfDayGroupedByHalfHour = createSelector(
  sessionList,
  getSelectedDay,
  getSelectedRooms,
  getSelectedSpeakers,
  (sessions, selectedDay, selectedRooms, selectedSpeakers) => {
    return flow(
      // only keep the session of the selected day, rooms, speakers
      filter(session => {
        // Filter sessions by selected rooms
        if (!isEmpty(selectedRooms) && !selectedRooms.includes(session.room_id)) {
          return false;
        }
        // Filter sessions by selected speakers
        const matchSelectedSpeakers = !(
          !isEmpty(selectedSpeakers) &&
          !selectedSpeakers.some(speakerId =>
            session.speakers.some(speaker => speaker.id === speakerId)
          )
        );
        return matchSelectedSpeakers && isEqual(startOfDay(new Date(session.from)), selectedDay);
      }),
      // group them by half hour
      groupBy(session => {
        const date = new Date(session.from);
        if (date.getMinutes() < 30) {
          return startOfHour(date).toString();
        }
        return setMinutes(startOfMinute(date), 30).toString();
      })
    )(sessions);
  }
);

// get all the days that have a session
export const getSessionsDays = createSelector(
  sessionList,
  flow(
    map(session => startOfDay(new Date(session.from))),
    uniqBy(start => start.toString()),
    sortBy(identity)
  )
);

// get all rooms that have a session
export const getSessionsRooms = createSelector(
  sessionList,
  flow(
    map('room'),
    compact,
    uniqBy('id'),
    sortBy(identity)
  )
);

// get all tags of all sessions
export const getAllTags = createSelector(
  sessionList,
  flow(
    flatMap('tags'),
    compact,
    uniq
  )
);

// get sessions speakers of all sessions
export const getSessionsSpeakers = createSelector(
  sessionList,
  sessions =>
    flow(
      flatMap('speakers'),
      uniqBy('id')
    )(sessions)
);

export const areSessionsLoading = createSelector(
  sessionsStatus,
  status => status === requestStatus.WAITING
);

export const areSessionsLoaded = createSelector(
  sessionsStatus,
  status => status === requestStatus.DONE
);

export const getTotalSessions = createSelector(
  sessionList,
  sessions => sessions.length
);
