import HttpService from './HttpService';
import { omit } from 'lodash/fp';

class RoomsService extends HttpService {
  entity = 'rooms';

  create(payload) {
    return this.http
      .post('/room', payload, {
        headers: this.defaultHeaders
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  createFake(t, confId, createdAt) {
    return this.create({
      name: t('rooms:mock:name'),
      conf_id: confId,
      updated_at: createdAt
    });
  }

  findAllOfConf(confId) {
    return this.http
      .get('/room', {
        params: {
          conf_id: `eq.${confId}`,
          order: 'name.asc',
          select: '*,session(*)'
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  update(payload) {
    return this.http
      .patch('/room', omit(['session'])(payload), {
        headers: this.defaultHeaders,
        params: {
          id: `eq.${payload.id}`
        }
      })
      .then(this.onResponse)
      .then(data => ({
        ...data,
        session: payload.session
      }))
      .catch(this.onError);
  }

  delete(id) {
    return this.http
      .delete('/room', {
        headers: this.defaultHeaders,
        params: {
          id: `eq.${id}`
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }
}

const roomsService = new RoomsService();
export default roomsService;
