import { startsWith } from 'lodash/fp';
import types from '../types';
import * as WizardService from '../../services/WizardService';
import ConfsService from '../../services/ConfsService';
import ThemesService from '../../services/ThemesService';
import ImagesService from '../../services/ImagesService';
import SessionsService from '../../services/SessionsService';
import RoomsService from '../../services/RoomsService';
import SpeakersService from '../../services/SpeakersService';
import handleError from '../utils/errors';
import LinksService from '../../services/LinksService';

export const createWizard = (t, { conf, theme }) => dispatch => {
  dispatch({
    type: types.CREATE_WIZARD_REQUEST
  });
  return (
    ConfsService.create(conf)
      .then(createdConf => {
        // if we don't have a base64 image (but an url, probably the default one),
        // we are good to go
        if (!startsWith('data:', theme.logo)) {
          return { createdConf, theme };
        }
        // else, we need to convert the base64 image to a file
        return (
          ImagesService.base64ImageToFile(theme.logo)
            .then(imageFile => {
              // so that we can upload it
              return ImagesService.uploadFile(imageFile);
            })
            // and replace the logo url (base64) by the http image url
            .then(({ url }) => ({
              createdConf,
              theme: {
                ...theme,
                logo: url
              }
            }))
        );
      })
      .then(({ createdConf, theme }) => {
        // if we don't have a base64 image (but an url, probably the default one),
        // we are good to go
        if (!startsWith('data:', theme.background)) {
          return { createdConf, theme };
        }
        // else, we need to convert the base64 image to a file
        return (
          ImagesService.base64ImageToFile(theme.background)
            .then(imageFile => {
              // so that we can upload it
              return ImagesService.uploadFile(imageFile);
            })
            // and replace the background url (base64) by the http image url
            .then(({ url }) => ({
              createdConf,
              theme: {
                ...theme,
                background: url
              }
            }))
        );
      })
      // we need to fetch the newly created theme, and edit it with the wizard values
      .then(({ createdConf, theme }) => {
        return ThemesService.get(createdConf.id).then(created_theme => {
          return { createdConf, theme: { ...created_theme, ...theme } };
        });
      })
      .then(({ createdConf, theme }) => {
        return (
          ThemesService.update({
            ...theme,
            conf_id: createdConf.id
          })
            // we sometime get a 406 error and aren't  able to reproduce it locally,
            // so for now we log the error and continue the promise chain
            .catch(err => {
              console.error({
                err,
                theme,
                createdConf
              });
              return Promise.resolve();
            })
            .then(() => {
              return Promise.all([
                dispatch({ type: types.CREATE_CONF_SUCCESS, payload: createdConf }),
                dispatch({ type: types.UPDATE_THEME_SUCCESS, payload: theme })
              ]);
            })
            .then(([{ payload: { id, updated_at, from } }]) => {
              return Promise.all([
                RoomsService.createFake(t, id, updated_at),
                SpeakersService.createFake(t, id, updated_at),
                LinksService.createFake(t, id, updated_at)
              ]).then(([room, speaker]) => {
                return SessionsService.createFake(t, id, room.id, updated_at, from).then(session => {
                  return SpeakersService.createSessionSpeakers({
                    session_id: session.id,
                    speaker_id: speaker.id
                  });
                });
              });
            })
            .then(() => {
              // and finally clean the wizard inside the localStorage
              WizardService.remove();
              dispatch({ type: types.CREATE_WIZARD_SUCCESS });
              return createdConf;
            })
        );
      })
      .catch(err => {
        // TMP: remove the wizard if an error was thrown
        WizardService.remove();
        return handleError(types.CREATE_WIZARD_ERROR, err, dispatch);
      })
  );
};

export const getWizard = () => dispatch => {
  dispatch({
    type: types.GET_WIZARD_SUCCESS,
    payload: WizardService.get()
  });
  return Promise.resolve();
};

export const saveWizard = payload => dispatch => {
  WizardService.save(payload);
  dispatch({
    type: types.SAVE_WIZARD_SUCCESS,
    payload: payload
  });
  return Promise.resolve();
};
