import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from '@atlaskit/button';
import EditIcon from '@atlaskit/icon/glyph/edit';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import SendIcon from '@atlaskit/icon/glyph/send';
import WatchIcon from '@atlaskit/icon/glyph/watch';
import { format } from 'date-fns/esm';
import Deletable from '../../utils/deletable';
import SendNotificationConfirm from './send-notification-confirm';
import Tooltip from 'react-tooltip';

const DeletableWrapper = styled.div`
  margin-right: 10px;
  display: none;
  flex-shrink: 0;
`;

const NotificationContainer = styled.div`
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  background-color: var(--gray-4);
  position: relative;
  border-radius: 10px;

  &:hover ${DeletableWrapper} {
    display: block;
  }
`;

const NotificationDate = styled.div`
  display: none;

  @media (min-width: 640px) {
      display: block;
      margin-left: auto;
      margin-right: 20px;
      padding-top: 4px;
      color: var(--gray-2);
      font-size: 16px;
  }
`;

const NotificationTitle = styled.div`
  font-size: 16px;
  margin-right: 10px;
  word-break: break-word;
`;

const Notification = ({
  notification,
  receiversCount,
  onEdit,
  onSend,
  onDelete,
  isConfActivated
}) => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const isSent = !!notification.sent_at;

  return (
    <NotificationContainer>
      <Tooltip />
      <DeletableWrapper>
        <Deletable
          onDelete={onDelete}
          modalHeading={t('notifications:confirm_remove.header')}
          modalContent={t('notifications:confirm_remove:warning')}
          noButton
        >
          <div
            {...(isSent
              ? { 'data-tip': t('notifications:sent_notification') }
              : {})
            }
          >
            <Button
              appearance="danger"
              iconBefore={<TrashIcon />}
              isDisabled={isSent}
            />
          </div>
        </Deletable>
      </DeletableWrapper>
      <NotificationTitle>{notification.title}</NotificationTitle>
      <NotificationDate>
        {format(new Date(notification.sent_at || notification.date), 'Pp')}
      </NotificationDate>
      <div style={{
        flexShrink: 0,
        display: 'flex'
      }}>
        <Button
          appearance="subtle"
          iconBefore={isSent ? <WatchIcon /> : <EditIcon  />}
          onClick={onEdit}
        />
        <div
          {...(isSent
            ? { 'data-tip': t('notifications:was_sent') }
            : !isConfActivated
              ? { 'data-tip': t('notifications:create:message') }
              : {}
          )}
        >
          <Button
            appearance="subtle"
            iconBefore={<SendIcon />}
            isDisabled={isSent || !isConfActivated}
            onClick={() => setModalIsOpen(true)}
          />
        </div>
      </div>

      <SendNotificationConfirm
        open={modalIsOpen}
        subscribersCount={receiversCount}
        onConfirm={() => onSend(notification.id)}
        onClose={() => setModalIsOpen(false)}
      />
    </NotificationContainer>
  );
};

export default Notification;
