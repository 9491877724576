import types from '../types';
import { requestStatus } from '../request-status';

const initialState = {
  id: '',
  color: '',
  font: '',
  logo: {
    status: requestStatus.UNDONE,
    url: '',
    error: '',
  },
  background: {
    status: requestStatus.UNDONE,
    url: '',
    error: '',
  },
  status: requestStatus.UNDONE,
  error: '',
};

export var DEFAULT_COLOR = '#F4F5F7';
export var DEFAULT_LOGO = process.env.REACT_APP_LOGO_URL;

const theme = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_THEME_REQUEST:
    case types.UPDATE_THEME_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: '',
      };
    case types.GET_THEME_SUCCESS:
    case types.UPDATE_THEME_SUCCESS:
      const { logo, background, color, font, id } = action.payload;
      return {
        ...state,
        id,
        color: color || DEFAULT_COLOR,
        logo: {
          ...state.logo,
          url: logo || DEFAULT_LOGO,
        },
        background: {
          ...state.background,
          url: background,
        },
        font: font || '',
        status: requestStatus.DONE,
        error: '',
      };
    case types.GET_THEME_ERROR:
    case types.UPDATE_THEME_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload,
      };

    case types.SET_THEME_LOGO:
      return {
        ...state,
        logo: {
          ...state.logo,
          url: action.payload,
        },
      };
    case types.SET_THEME_BACKGROUND:
      return {
        ...state,
        background: {
          ...state.background,
          url: action.payload,
        },
      };
    case types.SET_THEME_FONT:
      return {
        ...state,
        font: action.payload,
      };
    case types.SET_THEME_COLOR:
      return {
        ...state,
        color: action.payload,
      };
    default:
      return state;
  }
};

export default theme;