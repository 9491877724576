import React from 'react';
import Select from '@atlaskit/select';
import { useTranslation } from 'react-i18next';

export function ConfThemeFont({ onChange, value, ...props }) {
  const { t } = useTranslation();

  const options = [
    { value: '', label: t('common:default') },
    { value: 'Arial', label: 'Arial (sans-serif)' },
    { value: 'Verdana', label: 'Verdana (sans-serif)' },
    { value: 'TrebuchetMS', label: 'Trebuchet MS (sans-serif)' },
    { value: 'TimesNewRomanPSMT', label: 'Times New Roman PS (serif)' },
    { value: 'Didot', label: 'Didot (serif)' },
    { value: 'Georgia', label: 'Georgia (serif)' },
    { value: 'AmericanTypewriter', label: 'American Typewriter (serif)' },
    { value: 'Bradley Hand', label: 'Bradley Hand (cursive)' },
  ];
  const optionValue = options.find((option) => option.value === value) || options[0];

  const formatOptionLabel = (item) => {
    return <span style={{ marginLeft: 0, fontFamily: item.value || 'OpenSans' }}>{item.label}</span>;
  };

  return (
    <Select
      options={options}
      value={optionValue}
      placeholder={t('theme:font')}
      formatOptionLabel={formatOptionLabel}
      onChange={(option) => {
        onChange(option.value);
      }}
      styles={{
        container: base => ({ ...base, fontFamily: 'inherit' }),
      }}
      {...props}
    />
  );
}
