const sessions = {
  FIND_ALL_SESSIONS_REQUEST: 'FIND_ALL_SESSIONS_REQUEST',
  FIND_ALL_SESSIONS_SUCCESS: 'FIND_ALL_SESSIONS_SUCCESS',
  FIND_ALL_SESSIONS_ERROR: 'FIND_ALL_SESSIONS_ERROR',

  UPDATE_SESSION_REQUEST: 'UPDATE_SESSION_REQUEST',
  UPDATE_SESSION_SUCCESS: 'UPDATE_SESSION_SUCCESS',
  UPDATE_SESSION_ERROR: 'UPDATE_SESSION_ERROR',

  CREATE_SESSION: 'CREATE_SESSION',
  DELETE_SESSION: 'DELETE_SESSION',

  SELECT_DAY: 'SELECT_DAY',
  SELECT_ROOMS: 'SELECT_ROOMS',
  SELECT_SPEAKERS: 'SELECT_SPEAKERS',
  RESET_FILTERS: 'RESET_FILTERS'
};

export default sessions;
