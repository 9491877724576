import React from 'react';
import { isEmpty } from 'lodash/fp';
import Select from '@atlaskit/select';
import { useTranslation } from 'react-i18next';
import { format } from '../../../services/DatesService';

const formatDay = day => format(day, 'dd MMM');

const DaysSelect = ({ days, value, onSelect }) => {
  const { t } = useTranslation();

  if (isEmpty(days)) {
    return <div />;
  }

  return (
    <Select
      styles={{
        control: base => ({
          ...base,
          backgroundColor: 'var(--gray-4)',
          borderColor: '#f4f5f7',
          borderRadius: 4
        }),
        container: base => ({ ...base, fontFamily: 'inherit' }),
      }}
      noOptionsMessage={() => t('common:no_options')}
      isDisabled={days.length < 2}
      value={value ? {
        label: formatDay(value),
        value
      } : null}
      defaultValue={{
        label: formatDay(days[0]),
        value: days[0]
      }}
      options={days.map(day => {
        return {
          label: formatDay(day),
          value: day
        };
      })}
      onChange={(option, { action }) => {
        if (action === 'select-option') {
          onSelect(option.value);
        }
      }}
    />
  );
};

export default DaysSelect;
