import React from 'react';
import Spinner from '@atlaskit/spinner';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// eslint-disable-next-line
export default () => {
  return (
    <Wrapper>
      <Spinner size={'large'} />
    </Wrapper>
  );
};
