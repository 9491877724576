import styled from 'styled-components';

export default styled.span`
  padding: 3px 6px 4px;
  border-radius: 4px;
  text-align: center;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 1;  
  color: #fff;
  background-color: var(--green-2);
`;


