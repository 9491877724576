import HttpService from './HttpService';

class ThemesService extends HttpService {
  entity = 'theme';

  get(conf_id) {
    return this.http
      .get('/theme', {
        params: {
          conf_id: `eq.${conf_id}`
        },
        headers: {
          Accept: 'application/vnd.pgrst.object+json'
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  update(payload) {
    return this.http
      .patch('/theme', payload, {
        headers: this.defaultHeaders,
        params: {
          id: `eq.${payload.id}`
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }
}

const themesService = new ThemesService();

export default themesService;
