import HttpService from './HttpService';

class UpgradeService extends HttpService {
  isUpgradable(id) {
    return this.http
      .get('/rpc/conf_is_upgradable', {
        params: {
          conf: id
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }
}

const upgradeService = new UpgradeService();

export default upgradeService;
