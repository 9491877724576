import { orderBy } from 'lodash/fp';

export const getSteps = (t, confId, progressEntities) => {
  const steps = [
    {
      icon: 'paint-brush',
      name: t('confs:home:steps:entities:theme:name'),
      title: t('confs:home:steps:entities:theme:title'),
      desc: t('confs:home:steps:entities:theme:desc'),
      isCompleted: progressEntities.theme,
      href: `/conf/${confId}/theme`
    },
    {
      icon: 'door-open',
      name: t('confs:home:steps:entities:rooms:name'),
      title: t('confs:home:steps:entities:rooms:title'),
      desc: t('confs:home:steps:entities:rooms:desc'),
      isCompleted: progressEntities.rooms,
      href: `/conf/${confId}/rooms`
    },
    {
      icon: 'address-card',
      name: t('confs:home:steps:entities:speakers:name'),
      title: t('confs:home:steps:entities:speakers:title'),
      desc: t('confs:home:steps:entities:speakers:desc'),
      isCompleted: progressEntities.speakers,
      href: `/conf/${confId}/speakers`
    },
    {
      icon: 'calendar-alt',
      name: t('confs:home:steps:entities:program:name'),
      title: t('confs:home:steps:entities:program:title'),
      desc: t('confs:home:steps:entities:program:desc'),
      isCompleted: progressEntities.sessions,
      href: `/conf/${confId}/program`
    },
    {
      icon: 'briefcase',
      name: t('confs:home:steps:entities:links:name'),
      title: t('confs:home:steps:entities:links:title'),
      desc: t('confs:home:steps:entities:links:desc'),
      isCompleted: progressEntities.links,
      href: `/conf/${confId}/links`
    }
  ];

  return orderBy('isCompleted')('desc')(steps).map((step, i, steps) => {
    return {
      ...step,
      isNextToComplete: (i === 0 || steps[i - 1].isCompleted) && !step.isCompleted,
      isLastCompleted: (i === steps.length - 1 || !steps[i + 1].isCompleted) && step.isCompleted
    };
  });
};
