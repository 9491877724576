const DEFAULT_TIMES = Array.apply(null, {
  length: 24 * (60 / 30) // half hour
}).map((v, i) => {
  const h = Math.floor(i * (30 / 60));
  const m = i * 30 - h * 60;

  return `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`;
});

export default DEFAULT_TIMES;
