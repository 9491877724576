import { find } from 'lodash/fp';
import types from '../types';
import { ProductsTypes } from '../../constants';

const isPlanInCart = (cart) => find({type: ProductsTypes.PLAN})(cart);

export const addProductToCart = product => (dispatch, getState) => {
  if (product.type === ProductsTypes.PLAN && isPlanInCart(getState().cart.products)) {
    dispatch({ type: types.REMOVE_PLANS_FROM_CART });
  }

  const productIndexInCart = getState().cart.products.findIndex(p => p.id === product.id);

  if (productIndexInCart !== -1) {
    dispatch({
      type: types.REPLACE_PRODUCT_IN_CART,
      payload: {
        productIndexInCart,
        product
      }
    });

    return;
  }

  dispatch({
    type: types.ADD_PRODUCT_TO_CART,
    payload: product
  });
};

export const removeProductFromCart = productId => ({
  type: types.REMOVE_PRODUCT_FROM_CART,
  payload: productId
});

export const emptyCart = {
  type: types.EMPTY_CART,
};
