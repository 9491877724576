import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from '@atlaskit/button';
import { NavLink } from 'react-router-dom';
import FontAwesomeIcon from '../../utils/icons/font-awesome-icon';
import Card from '../../layout/card';

const CardContainer = styled(Card)`
  margin: 0 10px;
  ${props => (props.isFirst ? 'margin-left: 0' : '')};
  ${props => (props.isLast ? 'margin-right: 0' : '')};
  padding: 10px 0;
  background-color: ${props => (props.isCompleted ? 'var(--blue-2)' : '#fff')};
  opacity: ${props => (props.isCompleted || props.isNextToComplete ? 1 : 0.7)};
  color: #000000;
`;

const ContentContainer = styled.div`
  margin: 25px 10px;
  text-align: center;
`;

const Icon = styled.div``;

const Title = styled.div`
  min-height: 40px;
  padding: 10px 20px;
  color: ${props => props.color};
  text-align: center;
  font-size: 16px;
`;

const Desc = styled.div`
  min-height: 110px;
  color: ${props => props.color};
  font-size: 14px;
`;

const getColors = (isCompleted, isNextToComplete) => {
  if (isCompleted) {
    return {
      headerColor: '#fff',
      descColor: '#b8d8ff'
    };
  }
  if (isNextToComplete) {
    return {
      headerColor: '#358BF1',
      descColor: 'var(--black)'
    };
  }
  return {
    headerColor: 'var(--gray-3)',
    descColor: 'var(--gray-3)'
  };
};

const Footer = ({ isCompleted, href, isNextToComplete }) => {
  const { t } = useTranslation();
  if (isCompleted) {
    return (
      <div style={{ minHeight: 36 }}>
        <FontAwesomeIcon icon={'check'} size={'2x'} color={'var(--green-3)'} />
      </div>
    );
  }
  return (
    <NavLink to={href} exact>
      <Button appearance={isNextToComplete ? 'primary' : 'default'}>
        {t('confs:home:steps:button')}
      </Button>
    </NavLink>
  );
};

// eslint-disable-next-line
export default ({
  icon,
  title,
  desc,
  isCompleted,
  href,
  isNextToComplete = false,
  isFirst,
  isLast
}) => {
  const { headerColor, descColor } = getColors(isCompleted, isNextToComplete);
  return (
    <CardContainer
      isCompleted={isCompleted}
      isNextToComplete={isNextToComplete}
      isFirst={isFirst}
      isLast={isLast}
    >
      <ContentContainer>
        <Icon>
          <FontAwesomeIcon icon={icon} size={'lg'} color={headerColor} />
        </Icon>
        <Title color={headerColor}>{title}</Title>
        <Desc color={descColor}>{desc}</Desc>
        <Footer isCompleted={isCompleted} href={href} isNextToComplete={isNextToComplete} />
      </ContentContainer>
    </CardContainer>
  );
};
