import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import TextField from '@atlaskit/textfield';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { useMappedState } from 'redux-react-hook';
import PlayerImg from '../../../../assets/links/player.svg';
import EmbedImg from '../../../../assets/links/embed.svg';
import EventBriteImg from '../../../../assets/links/eventbrite.svg';
import GoogleMapsImg from '../../../../assets/links/googlemaps.svg';
import SlidoImg from '../../../../assets/links/slido.svg';
import GoogleFormsImg from '../../../../assets/links/googleforms.svg';
import KahootImg from '../../../../assets/links/kahoot.svg';
import SurveyMonkeyImg from '../../../../assets/links/surveymonkey.svg';
import WooclapImg from '../../../../assets/links/wooclap.png';
import ExhibitImg from '../../../../assets/links/exhibit.png';
import SponsorsImg from '../../../../assets/links/sponsors.png';
import PdfImg from '../../../../assets/links/pdf.png';
import ImageImg from '../../../../assets/links/image.png';
import EditorImg from '../../../../assets/links/editor.svg';
import TwitterImg from '../../../../assets/links/twitter.svg';
import WebpageImg from '../../../../assets/links/webpage.svg';
import DataLayer from '../../../../services/DataLayer';
import Suggest from './suggest';

const LinkChoicesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const LinkSearchInput = styled(TextField)``;

const LinkWrapper = styled.div`
  display: inline-block;
  background-color: #f5f6f8;
  margin: 7px 15px 7px 0;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #e1e5ed;
  }
`;

const LinkContainer = styled.div`
  margin: 10px 15px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 8px;
  object-fit: contain;
`;

const NotFound = styled.div`
  width: 100%;
  margin: 20px 0 0;
  text-align: center;
`;

// image icons https://www.flaticon.com/
const getAvailableLinks = (t, links = []) => {
  const data = [
    {
      name: t('links:choices:webpage:title'),
      app: 'webpage',
      img: WebpageImg,
      icon: 'plane',
      type: 'url',
    },
    {
      name: t('links:choices:image:title'),
      app: 'image',
      img: ImageImg,
      icon: 'image',
      type: 'url',
    },
    {
      name: t('links:choices:pdf:title'),
      app: 'pdf',
      img: PdfImg,
      icon: 'file-lines',
      type: 'url',
    },
    {
      name: t('links:choices:sponsors:title'),
      app: 'sponsors',
      img: SponsorsImg,
      icon: 'book-reader',
      type: 'url',
    },
    {
      name: t('links:choices:exhibit:title'),
      app: 'exhibit',
      img: ExhibitImg,
      icon: 'object-group',
      type: 'url',
    },
    {
      name: t('links:choices:embedded:title'),
      app: 'embedded',
      img: EmbedImg,
      icon: 'plane',
      type: 'embedded',
    },
    {
      name: t('links:choices:tpl-editor:title'),
      app: 'tpl-editor',
      img: EditorImg,
      icon: 'plane',
      type: 'content',
    },
    {
      name: t('links:choices:googlemaps:title'),
      app: 'googlemaps',
      img: GoogleMapsImg,
      icon: 'map-marked-alt',
      type: 'url',
    },
    {
      name: t('links:choices:slido:title'),
      app: 'slido',
      img: SlidoImg,
      icon: 'poll',
      type: 'url',
    },
    // we removed spotify as it does not work CONF-698
    // {
    //   name: t('links:choices:spotify:title'),
    //   app: 'spotify',
    //   img: SpotifyImg,
    //   icon: 'music',
    //   type: 'url',
    // },
    {
      name: t('links:choices:twitter:title'),
      app: 'twitter',
      img: TwitterImg,
      icon: 'rss-square',
      type: 'embedded',
    },
    {
      name: t('links:choices:surveymonkey:title'),
      app: 'surveymonkey',
      img: SurveyMonkeyImg,
      icon: 'poll',
      type: 'url',
    },
    {
      name: t('links:choices:googleforms:title'),
      app: 'googleforms',
      img: GoogleFormsImg,
      icon: 'file-alt',
      type: 'url',
    },
    {
      name: t('links:choices:player:title'),
      app: 'player',
      img: PlayerImg,
      icon: 'video',
      type: 'embedded',
    },
    {
      name: t('links:choices:eventbrite:title'),
      app: 'eventbrite',
      img: EventBriteImg,
      icon: 'ticket-alt',
      type: 'url',
    },
    {
      name: t('links:choices:kahoot:title'),
      app: 'kahoot',
      img: KahootImg,
      icon: 'poll',
      type: 'url',
    },
    {
      name: t('links:choices:editor:title'),
      app: 'editor',
      img: EditorImg,
      icon: 'plane',
      type: 'content',
    },
    {
      name: t('links:choices:wooclap:title'),
      app: 'wooclap',
      img: WooclapImg,
      icon: 'poll',
      type: 'url',
    },
  ];

  if (!links.length) {
    return data;
  }

  return data.filter((l) => links.includes(l.app));
};

const LinkChoicesList = ({apps, search, onSelected }) => {
  const { t } = useTranslation();

  const links = getAvailableLinks(t, apps).filter((link) => link.name.toLowerCase().includes(search.toLowerCase()));

  if (!links.length) {
    const link = getAvailableLinks(t, ['webpage'])[0];

    return (
      <NotFound>
        <h4 style={{margin: 0}}>{t(`links:empty:header`)}</h4>
        <p style={{marginTop: '5px'}}>{t(`links:empty:alternative`)}</p>
        <LinkChoice
          key={link.name}
          link={link}
          onChoiceSelected={() => onSelected(link)}
        />
      </NotFound>
    );
  }

  return (
    links.map((link) => {
      return (
        <LinkChoice
          key={link.name}
          link={link}
          onChoiceSelected={() => onSelected(link)}
        />
      );
    })
  )
};

const LinkChoice = ({ link, onChoiceSelected }) => {
  return (
    <LinkWrapper onClick={() => onChoiceSelected(link)}>
      <LinkContainer>
        <LinkIcon src={link.img} alt={link.app} />
        <div>{link.name}</div>
      </LinkContainer>
    </LinkWrapper>
  );
};

const LinkChoices = ({ onChoiceSelected, apps }) => {
  const [searchInput, setSearchInput] = useState('');
  const { user } = useMappedState((state) => state.auth);
  const userEmail = user.profile ? user.profile.email : '';

  // eslint-disable-next-line
  const sendEvent = useCallback(
    debounce((name) => {
      if (name.length > 2) {
        DataLayer.emitSearchMiniAppEvent(name, userEmail);
      }

    }, 1000),
    []
  );

  const onSearchChange = (e) => {
    const value = e.target.value;

    setSearchInput(value);
    sendEvent(value);
  };

  return (
    <>
      <LinkSearchInput
        elemBeforeInput={<p style={{ margin: '2px 0 0 8px' }}><SearchIcon label="search" /></p>}
        autoFocus
        value={searchInput}
        onChange={onSearchChange}
      />
      <LinkChoicesContainer>
        <LinkChoicesList onSelected={onChoiceSelected} apps={apps} search={searchInput}/>
      </LinkChoicesContainer>
      <Suggest userEmail={userEmail} />
    </>
  );
};

export default LinkChoices;
