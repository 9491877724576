import React, { useEffect, useRef, useState } from 'react';
import Modal, { ModalTransition, ModalHeader, ModalTitle, ModalBody } from '@atlaskit/modal-dialog';
import AddIcon from '@atlaskit/icon/glyph/add';
import Button from '@atlaskit/button';
import Form from '@atlaskit/form';
import { isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import CloseModalButton from '../modals/close-modal-button';
import { getMaskCreateClass } from '../get-mask-data';
import AdaptiveModalFooter from '../adaptive-modal-footer';

const ModalContent = ({
  DisplayForm,
  entityName,
  entity,
  submitText,
  formSubmit,
  onSubmit,
  rest
}) => {
  const [isDisabled, setDisabled] = useState(true);
  const content = useRef(null);

  useEffect(() => {
    let observer;
    const contentEl = content.current;

    if (contentEl) {
      const onChange = () => {
        setDisabled(!!contentEl.querySelector("[id$='-error']"));
      };

      observer = new MutationObserver(onChange);

      observer.observe(contentEl, {
        characterData: true,
        characterDataOldValue: true,
        childList: true,
        subtree: true
      });
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };

  }, []);

  return (
    <>
      <ModalBody>
        <Form onSubmit={formSubmit}>
          {({ formProps }) => (
            <form id="form-id" {...formProps}>
              <div ref={content}>
                <DisplayForm onSubmit={onSubmit} {...{ ...rest, [entityName]: entity }} />
              </div>
            </form>
          )}
        </Form>
      </ModalBody>
      <AdaptiveModalFooter>
        <Button form="form-id" appearance="primary" type="submit" isDisabled={isDisabled}>
          {submitText}
        </Button>
      </AdaptiveModalFooter>
    </>
  );
};

// eslint-disable-next-line
export default ({
  onOpen,
  onSubmit,
  onCancel,
  DisplayForm,
  open,
  entity = {},
  entityName,
  children,
  title,
  submitText,
  autoFocus = true,
  ...rest
}) => {
  const { t } = useTranslation();
  const isCreating = isEmpty(entity);

  submitText = submitText || t('common:save');
  title = title || t(`${entityName}s:${isCreating ? 'create' : 'edit'}:title`);

  const formSubmit = data => {
    const payload = {
      ...data,
      ...(!isCreating && { id: entity.id })
    };
    onSubmit(payload, isCreating);
  };

  return (
    <>
      <ModalTransition>
        {open && (
          <Modal
            shouldScrollInViewport
            onClose={onCancel}
            shouldCloseOnOverlayClick={false}
            autoFocus={autoFocus}
          >
            <ModalHeader>
              <CloseModalButton onClick={onCancel} />
              <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
            <ModalContent
              DisplayForm={DisplayForm}
              entityName={entityName}
              entity={entity}
              submitText={submitText}
              formSubmit={formSubmit}
              onSubmit={onSubmit}
              rest={rest}
            />
          </Modal>
        )}
      </ModalTransition>

      {children
        ? children
        : onOpen && (
        <Button
          appearance="primary"
          className={getMaskCreateClass(entityName)}
          iconBefore={<AddIcon size="small" label="add icon" />}
          onClick={onOpen}
        >
          {t(`${entityName}s:create:new`)}
        </Button>
      )}
    </>
  );
};
