import auth from './auth';
import confs from './confs';
import sessions from './sessions';
import speakers from './speakers';
import rooms from './rooms';
import theme from './theme';
import links from './links';
import flags from './flags';
import wizard from './wizard';
import plans from './plans';
import account from './account';
import subscription from './subscription';
import sidebar from './sidebar';
import progress from './progress';
import notifications from './notifications';
import subscribers from './subscribers';
import products from './products';
import upgrade from './upgrade';
import dates from './dates';
import cart from './cart';
import settings from './settings';

const types = {
  ...auth,
  ...confs,
  ...sessions,
  ...speakers,
  ...rooms,
  ...theme,
  ...links,
  ...flags,
  ...wizard,
  ...plans,
  ...account,
  ...subscription,
  ...sidebar,
  ...progress,
  ...notifications,
  ...subscribers,
  ...products,
  ...upgrade,
  ...dates,
  ...cart,
  ...settings,
};

export default types;
