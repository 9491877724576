import { merge } from 'lodash/fp';
import { getItem, setItem, removeItem } from './StorageService';

const WIZARD_KEY = 'WIZARD_KEY';

export const save = payload => {
  setItem(WIZARD_KEY, payload);
  return payload;
};

export const get = () => {
  return getItem(WIZARD_KEY) || {};
};

export const patch = payload => {
  const wizard = get();
  const patchedWizard = merge(wizard, payload);
  return save(patchedWizard);
};

export const remove = () => {
  removeItem(WIZARD_KEY);
};
