import types from '../types';
import ProgressService from '../../services/ProgressService';
import handleError from '../utils/errors';

export const findProgress = confId => dispatch => {
  dispatch({ type: types.FIND_PROGRESS_REQUEST });
  return ProgressService.findAll(confId)
    .then(progress => {
      dispatch({
        type: types.FIND_PROGRESS_SUCCESS,
        payload: progress
      });
    })
    .catch(e => handleError(types.FIND_PROGRESS_ERROR, e, dispatch));
};

export const updateEntityProgress = (name, isCompleted) => dispatch => {
  dispatch({
    type: types.UPDATE_ENTITY_PROGRESS,
    payload: {
      [name]: isCompleted
    }
  });
};
