import HttpService from './HttpService';

class ConfTokenService extends HttpService {
  get(confId) {
    return this.http
      .get('/rpc/get_conf_token', {
        params: {
          confid: confId
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  update(confId, token) {
    return this.http
      .patch(
        '/conf_token',
        { token },
        {
          headers: this.defaultHeaders,
          params: {
            conf_id: `eq.${confId}`
          }
        }
      )
      .then(this.onResponse)
      .catch(this.onError);
  }
}

const confTokenService = new ConfTokenService();

export default confTokenService;
