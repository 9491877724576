import types from '../types';
import { requestStatus } from '../request-status';

const initialState = {
  list: [],
  status: requestStatus.UNDONE,
  error: ''
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case types.FIND_ALL_NOTIFICATIONS_REQUEST:
    case types.CREATE_NOTIFICATION_REQUEST:
    case types.DELETE_NOTIFICATION_REQUEST:
    case types.UPDATE_NOTIFICATION_REQUEST:
    case types.SEND_NOTIFICATION_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: ''
      };

    case types.FIND_ALL_NOTIFICATIONS_ERROR:
    case types.CREATE_NOTIFICATION_ERROR:
    case types.DELETE_NOTIFICATION_ERROR:
    case types.UPDATE_NOTIFICATION_ERROR:
    case types.SEND_NOTIFICATION_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload
      };

    case types.FIND_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        status: requestStatus.DONE,
        error: ''
      };

    case types.CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        status: requestStatus.DONE,
        list: state.list.concat([action.payload]),
      };

    case types.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        list: state.list.map(item => {
          return item.id === action.payload.id ? action.payload : item;
        })
      };

    case types.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item.id !== action.payload)
      };

    case types.SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        list: state.list.map(item => {
          return item.id === action.payload.id ? action.payload : item;
        })
      };

    default:
      return state;
  }
};

export default notifications;
