import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@atlaskit/datetime-picker';
import { Field, ErrorMessage } from '@atlaskit/form';
import { isDate } from '../../utils/dates';
import { startOfDay } from 'date-fns/esm';
import { isEmpty } from 'lodash/fp';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { checkDates } from '../../../store/actions/dates';
import { LOCALES_MAP } from '../../../i18n/i18n';
import styled from 'styled-components';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { isSessionsOutOfRange } from '../../../services/SessionsFiltersService';
import { format, getTimeZoneData, TIME_ZONES_DATA } from '../../../services/DatesService';
import Select from '@atlaskit/select';
import Tip from '../../utils/tip';

const WarningWrapper = styled.p`
  display: inline-block;
  vertical-align: center;
  margin-top: 24px;
  margin-bottom: 0;
`;

const Warning = ({ text }) => {
  const { t } = useTranslation();
  return (
    <WarningWrapper>
      <WarningIcon primaryColor="var(--orange-3)" />
      <span style={{ marginLeft: '4px', verticalAlign: 'text-bottom' }}>{t(`confs:home:form:${text}`)}</span>
    </WarningWrapper>
  );
};

const FieldsWrapper = styled.div`
  & > div {
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

// eslint-disable-next-line
export default ({ dates }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [sessionDatesWarning, setSessionDatesWarning] = useState(true);
  const placeholder = t('common:form:date_placeholder', { date: format(new Date(), 'P') });
  const timeZoneData = getTimeZoneData(dates.timezone);

  const { firstDateCanBeChanged, lastDateCanBeChanged, selectedConfId, sessions } = useMappedState(state => ({
    firstDateCanBeChanged: state.dates.firstCanBeChanged,
    lastDateCanBeChanged: state.dates.lastCanBeChanged,
    selectedConfId: state.confs.selectedId,
    sessions: state.sessions.list,
  }));

  useEffect(() => {
    dispatch(checkDates(selectedConfId));
  }, [dispatch, selectedConfId]);

  const isConfEditable = lastDateCanBeChanged || firstDateCanBeChanged;

  return (
    <FieldsWrapper key="conf-dates">
      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 500 }}>
        <div style={{ width: '100%' }}>
          <Field
            label={t('confs:create:from')}
            name="from"
            defaultValue={dates.from || ''}
            validate={(val, form) => {
              setSessionDatesWarning(isSessionsOutOfRange(val, form.to, sessions));
              if (!isDate(val)) return 'sessions:form:errors:date';
              const from = new Date(val);
              if (from < startOfDay(new Date())) return 'sessions:form:errors:past';
              if (form.to && from > new Date(form.to)) {
                return 'sessions:form:errors:start_after';
              }
              if (isEmpty(form.to)) {
                form.to = val;
              }
              return null;
            }}
            isRequired
            isDisabled={!firstDateCanBeChanged}
          >
            {({ fieldProps, error }) => (
              <>
                <DatePicker
                  {...fieldProps}
                  placeholder={placeholder}
                  locale={LOCALES_MAP[i18n.language]}
                />
                {error && <ErrorMessage>{t(error)}</ErrorMessage>}
              </>
            )}
          </Field>
        </div>
        <div style={{ width: '100%', marginLeft: 10 }}>
          <Field
            label={t('confs:create:to')}
            name="to"
            defaultValue={dates.to || ''}
            validate={(val, form) => {
              setSessionDatesWarning(isSessionsOutOfRange(form.from, val, sessions));
              if (!isDate(val)) return 'sessions:form:errors:date';
              if (new Date(val) < new Date(form.from)) return 'sessions:form:errors:end_before';
              return null;
            }}
            isRequired
            isDisabled={!lastDateCanBeChanged}
          >
            {({ fieldProps, error }) => (
              <>
                <DatePicker
                  {...fieldProps}
                  placeholder={placeholder}
                  locale={LOCALES_MAP[i18n.language]}
                />
                {error && <ErrorMessage>{t(error)}</ErrorMessage>}
              </>
            )}
          </Field>
        </div>
      </div>
      <Field
        name="timezone"
        label={t('common:timezone')}
        isRequired
        defaultValue={timeZoneData}
        isDisabled={!isConfEditable}
      >
        {({ fieldProps: { id, ...rest }}) => (
          <Select
            {...rest}
            inputId={id}
            options={TIME_ZONES_DATA}
            menuPortalTarget={document.body}
            styles={{
              container: base => ({ ...base, fontFamily: 'inherit' }),
              menuPortal: base => ({ ...base, zIndex: 9999 })
            }}
          />
        )}
      </Field>
      <Tip>{t('confs:create:timezone_help')}</Tip>
      {!isConfEditable && <Warning text="conf_not_editable" />}
      {sessionDatesWarning && <Warning text="sessions_conflict" />}
    </FieldsWrapper>
  );
};
