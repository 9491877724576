import React, {useMemo} from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CheckIcon from '@atlaskit/icon/glyph/check';
import { COUNTABLE_FEATURE_ID_MAP } from '../../../constants';
import AdaptiveModalFooter from '../../utils/adaptive-modal-footer';

const Subtitle = styled.p`
  margin: 25px 0 10px;
  font-size: 1.25em;
  font-weight: 500;
`;

const Product = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
`;

const ProductName = styled.span`
  margin-left: 5px;
  padding-top: 3px;
`;

const Currency = styled.span`
  color: var(--blue-2);
`;

const getProductsTotal = (items) => {
  return items.reduce((sum, item) => {
    const tax = item.tax_rate_percentage ? item.price * item.tax_rate_percentage / 100 : 0;

    return sum + item.quantity * (item.price + tax);
  }, 0);
};

// eslint-disable-next-line
export default ({ products, onConfirm, onClose }) => {
  const { t } = useTranslation();
  const sum = useMemo(() => getProductsTotal(products), [products]);

  const onConfirmClick = () => {
    onConfirm();
    onClose();
  };

  return (
    <>
      <ModalTransition>
        <Modal
          shouldScrollInViewport
          shouldCloseOnOverlayClick={false}
          onClose={onClose}
        >
          <ModalHeader>
            <ModalTitle>{t(`upgrade:confirm_modal.title`)}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {products.map((product, key) => {
              const quantity = product.quantity * product[COUNTABLE_FEATURE_ID_MAP[product.id]]
              return (
                <Product key={key}>
                  <CheckIcon primaryColor="#58be40" />
                  <ProductName>
                    {t(`upgrade:product.${product.id}`, { quantity })}
                  </ProductName>
                </Product>
              );
            })}
            <Subtitle>{t('upgrade:confirm_modal.subtitle')}</Subtitle>
            {t(`upgrade:confirm_modal.text`)}
            <Currency>
              {t(`upgrade:confirm_modal.sum.${products[0].currency}`, { sum })}
            </Currency>
          </ModalBody>
          <AdaptiveModalFooter>
            <Button appearance="primary" onClick={onConfirmClick} autoFocus>
              {t('upgrade:confirm_modal.confirm')}
            </Button>
            <Button appearance="subtle" onClick={onClose} style={{ marginLeft: '8px' }}>
              {t('common:cancel')}
            </Button>
          </AdaptiveModalFooter>
        </Modal>
      </ModalTransition>
    </>
  );
};
