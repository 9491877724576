const plans = {
  ADD_PLAN_ERROR: 'ADD_PLAN_ERROR',
  REMOVE_PLAN_ERROR: 'REMOVE_PLAN_ERROR',

  FIND_ALL_PLANS_REQUEST: 'FIND_ALL_PLANS_REQUEST',
  FIND_ALL_PLANS_SUCCESS: 'FIND_ALL_PLANS_SUCCESS',
  FIND_ALL_PLANS_ERROR: 'FIND_ALL_PLANS_ERROR'
};

export default plans;
