import React from 'react';
import styled from 'styled-components';
import EditIcon from '@atlaskit/icon/glyph/edit';
import ClearIcon from '@atlaskit/icon/glyph/cross';

const LabelWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Label = styled.div`
  position: absolute;
  top: -6px;
  right: 0;
  padding: 2px;
  font-size: 24px;
  line-height: 1;
  cursor: pointer;
  z-index: 1;
  border-radius: ${(props) => (props.isRound ? '50%' : 'unset')};
  box-shadow: ${(props) =>
    props.isRound ? 'rgb(189 195 199 / 50%) 0 1px 3px, rgb(189 195 199 / 50%) 0 1px 2px' : 'unset'};
`;

// eslint-disable-next-line
export default ({ labelStyle = {}, clearStyle, onLabelClick, onClearClick, isRound = false, children }) => {
  return (
    <LabelWrapper>
      <Label style={labelStyle} onClick={onLabelClick} isRound={isRound} tabIndex="0">
        <EditIcon label="edit-icon" primaryColor="grey" />
      </Label>
      {clearStyle && (
        <Label style={clearStyle} onClick={onClearClick} isRound={isRound} tabIndex="0">
          <ClearIcon label="clear-icon" primaryColor="grey" />
        </Label>
      )}
      {children}
    </LabelWrapper>
  );
};
