import React from 'react';
import { head, sortBy } from 'lodash/fp';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import { useTranslation } from 'react-i18next';
import { formatDistance } from  '../../services/DatesService';

// eslint-disable-next-line
export default ({ list }) => {
  const { t } = useTranslation();
  const today = new Date();
  const nextConf = head(sortBy(['from'], list.filter(conf => new Date(conf.from) >= today)));
  if (!nextConf) return <div/>;
  return (
    <div
      style={{
        maxWidth: 700,
        width: '90%',
        backgroundColor: '#fff',
        borderRadius: 10,
        boxShadow: '#dfe8f0 0px 1px 7px',
        textAlign: 'center'
      }}
    >
      <div style={{ margin: 40 }}>
        <div>
          <div
            style={{
              display: 'inline-block',
              backgroundColor: 'var(--green-1)',
              borderRadius: 60
            }}
          >
            <div style={{ margin: '14px 15px' }}>
              <CalendarIcon label="calendar-icon" primaryColor="var(--green-2)" size="large" />
            </div>
          </div>
        </div>
        <div style={{ fontSize: 34, margin: '15px 10px 50px' }}>
          {t('confs:home:featured:your_next')}{' '}
          <span style={{ fontWeight: 700 }}>"{nextConf.name}"</span>{' '}
          {t('confs:home:featured:starts_in', { days: formatDistance(new Date(nextConf.from), new Date()) })}
        </div>
      </div>
    </div>
  );
};
