import types from '../types';

export const setSidebarVisibility = shouldShow => dispatch => {
  dispatch({
    type: types.SET_SIDEBAR_VISIBILITY,
    payload: {
      shouldShow
    }
  });
};
