import React from 'react';

export function DefaultBackground() {
  return (
    <>
      <path
        className="cls-7"
        d="M756,1618.16c-40,28-91.92,26.21-137.41,11.63s-87.27-40.54-131.59-59a427.24,427.24,0,0,0-115.61-30.13c-56.49-6.47-119.56,1.41-158.91,46.72-43.83,50.44-40.46,141.43,6.92,187.76,24.11,23.55,55.69,35.11,83.22,53.5s53,48.91,50.69,84.38c-2.17,32.88-27.41,58.08-53.53,73.9-20.19,12.23-45,25.58-47.48,50.91-2.35,24.5,18.55,43.7,38.52,54.62,65.11,35.63,145.36,35.28,210.25-.87,23.14-12.93,44.39-30.1,68.66-40.12,63.73-26.41,133.95.18,201.43,9.9a402.57,402.57,0,0,0,171.32-12.47c33.42-9.87,67-25,90.44-53.07,16.93-20.28,27.48-45.92,37.38-71.26q15.84-40.53,30.45-81.62c5.84-16.44,11.59-33.21,13.11-50.81,2.73-31.89-8.79-63.41-24.49-90.32-37.27-63.94-100-108.86-168-120.28s-139.88,10.88-192.6,59.65"
        transform="translate(0 0)"
      />
      <g className="cls-8">
        <path
          d="M649.42,1628.16l-3.3,191.22s119.34,0,220.88,33l2-193.18S706.12,1620.25,649.42,1628.16Z"
          transform="translate(0 0)"
        />
      </g>
      <path
        className="cls-9"
        d="M646.83,1624.7l-3.3,191.22s119.34,0,220.87,33l2-193.18S703.53,1616.78,646.83,1624.7Z"
        transform="translate(0 0)"
      />
      <g className="cls-8">
        <path
          d="M589.51,1633.64l3.3,191.22s-119.34,0-220.87,33l-2-193.18S532.81,1625.77,589.51,1633.64Z"
          transform="translate(0 0)"
        />
      </g>
      <path
        className="cls-9"
        d="M592.11,1629.31l3.29,191.23s-119.33,0-220.86,33l-2-193.18S535.41,1621.44,592.11,1629.31Z"
        transform="translate(0 0)"
      />
      <g className="cls-10">
        <path
          className="cls-11"
          d="M538.5,1651.62l1.78,102.54s-64,0-118.46,17.68l-1.06-103.6S508.1,1647.4,538.5,1651.62Z"
          transform="translate(0 0)"
        />
      </g>
      <g className="cls-8">
        <path
          d="M939.31,1698.5v189.22S978.25,1883.1,1060,1984l7.25-189.22S982.16,1703.11,939.31,1698.5Z"
          transform="translate(0 0)"
        />
      </g>
      <path
        className="cls-9"
        d="M937.58,1695.9v189.23s38.93-4.62,120.66,96.26l7.25-189.23S980.43,1700.52,937.58,1695.9Z"
        transform="translate(0 0)"
      />
      <g className="cls-8">
        <path
          d="M310,1699.36v189.23s-38.9-4.62-120.6,96.26l-7.3-189.23S267.11,1704,310,1699.36Z"
          transform="translate(0 0)"
        />
      </g>
      <path
        className="cls-9"
        d="M312.56,1695.9v189.23s-38.9-4.62-120.61,96.26l-7.29-189.23S269.7,1700.52,312.56,1695.9Z"
        transform="translate(0 0)"
      />
      <g className="cls-10">
        <polygon
          className="cls-11"
          points="678.71 1664.48 759.55 1678.94 759.55 1690.71 678.71 1675.19 678.71 1664.48"
        />
      </g>
      <polygon
        className="cls-2"
        points="522.96 1778.26 442.13 1792.72 442.13 1804.49 522.96 1788.97 522.96 1778.26"
      />
      <polygon
        className="cls-2"
        points="679.78 1686.43 827.53 1716.95 827.53 1728.72 679.78 1697.14 679.78 1686.43"
      />
      <polygon
        className="cls-2"
        points="679.78 1708.38 827.53 1738.89 827.53 1750.67 679.78 1719.08 679.78 1708.38"
      />
      <g className="cls-10">
        <polygon
          className="cls-11"
          points="972.03 1757.01 972.03 1767.4 1003.73 1793.15 1005.22 1784.24 972.03 1757.01"
        />
      </g>
      <polygon
        className="cls-2"
        points="972.03 1857.38 972.03 1867.77 1003.73 1893.52 1005.22 1884.61 972.03 1857.38"
      />
      <polygon
        className="cls-2"
        points="972.53 1774.83 972.03 1785.23 1031.95 1841.67 1030.96 1829.79 972.53 1774.83"
      />
      <polygon
        className="cls-2"
        points="972.03 1794.14 972.03 1804.04 1031.95 1861.49 1031.95 1848.61 972.03 1794.14"
      />
      <g className="cls-10">
        <polygon
          className="cls-11"
          points="277.6 1759.26 278.1 1769.66 218.19 1826.1 219.17 1814.22 277.6 1759.26"
        />
      </g>
      <g className="cls-10">
        <polygon
          className="cls-11"
          points="278.1 1778.57 278.1 1788.47 218.19 1845.91 218.19 1833.04 278.1 1778.57"
        />
      </g>
      <ellipse className="cls-7" cx="358.66" cy="2076.78" rx="49.32" ry="8.88" />
      <path
        className="cls-11"
        d="M241.7,2028s4.75,6.21-2.2,15.58-12.68,17.3-10.39,23.15c0,0,10.48-17.43,19-17.67S251.08,2038.49,241.7,2028Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M241.7,2028a7.5,7.5,0,0,1,1,2c8.33,9.78,12.77,18.9,4.76,19.14-7.45.21-16.44,13.54-18.53,16.91a6.75,6.75,0,0,0,.25.76s10.48-17.43,19-17.67S251.08,2038.49,241.7,2028Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-13"
        d="M250.53,2035.9c0,2.19-.24,4-.55,4s-.55-1.77-.55-4,.31-1.16.61-1.16S250.53,2033.7,250.53,2035.9Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-13"
        d="M253.57,2038.49c-1.92,1-3.6,1.68-3.75,1.42s1.3-1.33,3.22-2.38,1.16-.28,1.31,0S255.49,2037.46,253.57,2038.49Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-11"
        d="M216.59,2028s-4.74,6.2,2.18,15.6,12.68,17.31,10.39,23.16c0,0-10.48-17.43-19-17.67S207.21,2038.49,216.59,2028Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M216.59,2028a7.5,7.5,0,0,0-1,2c-8.33,9.78-12.77,18.9-4.76,19.14,7.46.21,16.44,13.54,18.53,16.91a6.75,6.75,0,0,1-.25.76s-10.48-17.43-19-17.67S207.21,2038.49,216.59,2028Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-13"
        d="M207.75,2035.9c0,2.19.24,4,.55,4s.55-1.77.55-4-.31-1.16-.61-1.16S207.75,2033.7,207.75,2035.9Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-13"
        d="M204.72,2038.49c1.92,1,3.59,1.68,3.74,1.42s-1.3-1.33-3.22-2.38-1.16-.28-1.3,0S202.8,2037.46,204.72,2038.49Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-14"
        d="M209.26,2065.91s13.29-.4,17.3-3.26S247,2056.4,248,2061s20,22.75,5,22.87-34.86-2.33-38.86-4.77S209.26,2065.91,209.26,2065.91Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-15"
        d="M253.23,2082.25c-15,.12-34.86-2.34-38.86-4.78-3.05-1.85-4.26-8.5-4.66-11.57h-.44s.86,10.72,4.83,13.15,23.9,4.95,38.9,4.77c4.33,0,5.83-1.57,5.75-3.85C258.11,2081.36,256.46,2082.22,253.23,2082.25Z"
        transform="translate(0 0)"
      />
      <ellipse className="cls-7" cx="701.21" cy="1945.98" rx="81.33" ry="13.93" />
      <path
        className="cls-16"
        d="M684.8,1758a10.67,10.67,0,0,0-.86,2.65c-.06.24-.11.48-.16.73a74.05,74.05,0,0,0-1,7.92l-.13,1.59s-18.76-1.25-15.51-4.4a6.89,6.89,0,0,0,1.73-3.12c.1-.34.17-.67.24-1a17.42,17.42,0,0,0,.31-2.38v-.6a24.09,24.09,0,0,0-.19-3.89s6.1,0,10.71.49C683,1756.3,685.4,1756.91,684.8,1758Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-16"
        d="M652.27,1847.27c-6.4-.21-1.68-13.52-.95-15.31s-1.09-6.18-1.09-6.18-2.16-12.69-2.16-13.52a33,33,0,0,0-.73-5.14,8.64,8.64,0,0,1,.87-5c.74-1.15.62-5.76.62-5.76v-6l1.43-1.89,3.64,3.46,1,1,1.78,6.68-.63,9.57-.34,9.32,2.21,12.89S658.71,1847.49,652.27,1847.27Z"
        transform="translate(0 0)"
      />
      <polygon
        className="cls-12"
        points="654.29 1793.51 648.84 1791.25 648.84 1790.36 650.26 1788.48 653.91 1791.92 654.29 1793.51"
      />
      <path
        className="cls-17"
        d="M651.78,1779.88a24.82,24.82,0,0,0-1.51,5.34,23.49,23.49,0,0,1-1.43,5.14l6.06,2.51Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-18"
        d="M651.78,1779.88a24.82,24.82,0,0,0-1.51,5.34,23.49,23.49,0,0,1-1.43,5.14l6.06,2.51Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-16"
        d="M700,1912.25l-.5,1.08s-.55,3.55-.55,3.64-1.57,2.46-1.57,2.46l-4,.86-1.67-.21a23.62,23.62,0,0,0-.45-2.9c-.06-.27-.13-.55-.2-.83a22.34,22.34,0,0,0-2.76-6.26s-2.94-7.23-4-8.18-5-10.48-4.62-12.79a65.93,65.93,0,0,0-.16-9c-.17-2.88-.36-5.3-.36-5.3l10-5.89,1.16,1.83h0l.69,1.08a5,5,0,0,1,0,.55v.13a18.27,18.27,0,0,1-.86,4.33s-.75,5.35,0,6.29,3.11,13.55,3.64,19.38v.53c.21,2.68,1.87,5.07,3.54,6.82h0c.1.12.22.23.33.34A20.6,20.6,0,0,0,700,1912.25Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M691.78,1920.07l1.67.2,4-.86L699,1917l.55-3.64.5-1.07.06-.13a20.19,20.19,0,0,1-2.72-2.25h0l-.27-.26c-.09-.1.56,3.46-2.45,5-1.46.75-2.67,1.33-3.51,1.73Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-19"
        d="M700.72,1912.75l-.56,1.21s-.55,3.55-.55,3.64-1.57,2.46-1.57,2.46l-3.95.86-2.28-.27a17.28,17.28,0,0,0-.19-1.89c-.07-.48-.16-1-.29-1.58.86-.38,2.2-1,3.92-1.92a4.92,4.92,0,0,0,2.45-5c.66.65,1.35,1.26,2.07,1.84Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M700.72,1912.75l-.56,1.21s-.55,3.55-.55,3.64-1.57,2.46-1.57,2.46l-3.95.86-2.28-.27a17.28,17.28,0,0,0-.19-1.89,7.68,7.68,0,0,1,1.44.2s5.4,1.15,5.66-3.13c.15-2.42.65-3.37,1-3.74Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-20"
        d="M700.32,1912.47s-1.34-.3-1.6,4-5.66,3.12-5.66,3.12-2.65-.65-2.68.45a10.34,10.34,0,0,0,.71,3s.16,2.55.66,3.15.18,2.86.18,2.86.21,1.68,4.38,0c0,0,3.72-.77,6.81-5.4.1-.16-.36-.63,2.34-1.6s10.38-7.18,9.82-10.22a2.59,2.59,0,0,0-1.83-2.18,3.13,3.13,0,0,0-2.41.33,20.69,20.69,0,0,1-2.26,1,11.56,11.56,0,0,1-3.22.77,12.53,12.53,0,0,0-2.5.47A5.22,5.22,0,0,1,700.32,1912.47Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-16"
        d="M669.76,1883.1a7.84,7.84,0,0,0-1.52,1.73c-1.52,2.33-2.44,5.58-3.46,7-1.33,1.81-1.73,5.34-1.73,5.34-.36,2.32-5.27,14.71-6.6,16.91s-3.19,8.18-3.19,8.18a6.47,6.47,0,0,0-1,4.53,1,1,0,0,0,0,.41.12.12,0,0,1,0,.16v.06l-1.81,3.09-5.78-.8-3-2a9.75,9.75,0,0,0,.65-1.24,54.3,54.3,0,0,0,2.73-9.93s1.61-7.27,1.49-9a66.84,66.84,0,0,1,.48-6.93s4.55-13.88,6-16.13a6.45,6.45,0,0,0,1.09-3,2.36,2.36,0,0,0,0-.65l2-1.94S672.27,1881.1,669.76,1883.1Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M652.42,1926.75v.68l-1.81,3.09-5.78-.81-3-2a9.75,9.75,0,0,0,.65-1.24c1.45-.41,4.78.76,4.78.76s3.59.69,5.1-.41A.09.09,0,0,1,652.42,1926.75Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-19"
        d="M651,1935l-5.86,1.4-5.38-4,.27-2.24a3.93,3.93,0,0,0,1-.86,3.62,3.62,0,0,0,.78-1.61c.31-1.66,5.42.15,5.42.15s3.74.71,5.19-.47a9.26,9.26,0,0,0,.28,3.23,2.36,2.36,0,0,0,.87,1.29C655.06,1932.93,651,1935,651,1935Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M651,1935l-5.86,1.4-5.38-4,.27-2.24a3.93,3.93,0,0,0,1-.86h1.18c.86.08,3,3.93,3,3.93,5.32.36,6.93-1.38,7.47-2.66a2.36,2.36,0,0,0,.87,1.29C655.06,1932.93,651,1935,651,1935Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-20"
        d="M645.23,1933.89s-2.12-3.85-3-3.93a21.87,21.87,0,0,0-2.29,0s-2.35,5.59-3.22,6.06-2.13,3.15-2.13,3.15,6.23,6.06,12.26,4.87a12.49,12.49,0,0,1,5.52,1.38c2.78,1.45,14.42.35,15.84-2.2,1-1.92,1.48-3.31.54-4.33a2.55,2.55,0,0,0-2.19-.74,8.25,8.25,0,0,1-7.63-3.37s-5.31-4.87-5.74-4.88S653.52,1934.45,645.23,1933.89Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M691.07,1872.44H691c-.36.4-2.92,3.21-3.7,3.41a39.61,39.61,0,0,0-3.77,1.67s-1.58,2.11-2.83,2.21a4.78,4.78,0,0,0-1.11.29c-.17-2.88-.36-5.3-.36-5.3l10-5.87,1.16,1.83h0c.25.73.55,1.42.64,1.63Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-19"
        d="M654.86,1832s-3.87,5,0,11.76a39.36,39.36,0,0,0,8,10.06L676,1881.14s3.46-1.89,4.72-2,2.83-2.2,2.83-2.2a36.34,36.34,0,0,1,3.77-1.68c.87-.21,3.75-3.46,3.75-3.46s-1.45-3.14-1.13-4-.94-2.94-.94-2.94a17.29,17.29,0,0,1-.86-3.25c0-.86-1.37-2.83-1.37-2.83a9,9,0,0,1-1.36-3.25c0-1.15-2-5.69-2-5.69l-.53-19.9Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-18"
        d="M691.07,1871.81s-2.91,3.25-3.75,3.46a36.34,36.34,0,0,0-3.77,1.68s-1.58,2.09-2.83,2.2a10.46,10.46,0,0,0-2.6.92c-1.12.51-2.14,1.07-2.14,1.07l-13.21-27.36a29.34,29.34,0,0,1-3.21-3.29,46.08,46.08,0,0,1-4.76-6.77,11.35,11.35,0,0,1-1.5-8.16,9.25,9.25,0,0,1,1.5-3.59l9.4-.67,18.72-1.33.15,5.89.35,14s.3.7.68,1.62a18.19,18.19,0,0,1,1.31,4,8.79,8.79,0,0,0,1.36,3.25,8.92,8.92,0,0,1,1.36,2.83,18.12,18.12,0,0,0,.87,3.25s1.26,2.1,1,2.93S691.07,1871.81,691.07,1871.81Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M669.76,1883.1a7.84,7.84,0,0,0-1.52,1.73c-5.7.42-12.33-2.59-14-3.39a2.36,2.36,0,0,0,0-.65l2-1.94S672.27,1881.1,669.76,1883.1Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-19"
        d="M686.58,1838.16s-.86,2.93-1.15,4.19-.94,7.45-.94,7.45l-.36,1.73-6,28.55-1.31,6.21c-2.3,1.26-8.06-2.11-8.06-2.11-6.72.75-15-3.55-15-3.55s1.36-4.41,1-6.6,1.89-4,1.89-4c-.52-2,1.36-10.7,1.36-10.7s.42-3.24,2-4.08-.2-2.1-.63-2.84a3.16,3.16,0,0,1,.15-1.92,14.09,14.09,0,0,1,.48-1.43c-4-4-4.61-10.24-4.62-13.71a21.49,21.49,0,0,1,.12-2.54l8.65-1.51,18.9-3.31,1.93,5.94h0l.49,1.49Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M684.8,1758a10.67,10.67,0,0,0-.86,2.65c-.06.24-.11.48-.16.73-1.82,1.19-2.95,1.2-5.29,1.2-.8,0-1.25-1.54-2-1.73,0,0-1.14-4.12-1.46-.65-2.2-.45-4.1,1.26-5.65-.24h0v-.6a24.09,24.09,0,0,0-.19-3.89s6.1,0,10.71.5C683,1756.3,685.4,1756.91,684.8,1758Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-16"
        d="M689.13,1750.91a11.7,11.7,0,0,1-11.72,11.68h0a11.91,11.91,0,0,1-2.31-.23h0a11.69,11.69,0,1,1,12.53-17.19A11.55,11.55,0,0,1,689.13,1750.91Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M682.73,1769.27l-.13,1.59s-18.76-1.25-15.51-4.4a6.89,6.89,0,0,0,1.73-3.12l8.14,1.33s2.3,1.79,4.09,3.47A7.61,7.61,0,0,0,682.73,1769.27Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M685.54,1836a6.05,6.05,0,0,1-2.47-.18,5.24,5.24,0,0,1-1-.44c-1.58-.94-6.81.22-6.81.22s-14.26.94-14.89,0c-.36-.55-2.88-.47-4.94-.31-.86.07-1.6.16-2.12.21a9.25,9.25,0,0,1,1.5-3.59l9.4-.67,18.9-3.3,1.93,5.93h0C685.18,1834.61,685.34,1835.32,685.54,1836Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-17"
        d="M676.94,1765.31l-10.23-1.68a3.07,3.07,0,0,1-1.93,1.68c-1.36.31-7.44,3.46-10.17,7.23l-2.72,3.78s-2.21,2.73-1.47,4.92,1.57,9.65,1.57,9.65l1,8.28s2,6,1.37,8.6.31,13.63.31,13.63l-2.1,13.63s7.12-.94,7.79,0,14.89,0,14.89,0,5.19-1.16,6.81-.22a5.7,5.7,0,0,0,3.46.63s-1.25-4.32-.53-6.7a26.45,26.45,0,0,0,.53-6.82s1-6.39.52-7.55,1.16-5.34,1.16-5.34l5.56-29.25s-.68-5.24-3.65-7-6.31-2.31-8.1-4S676.94,1765.31,676.94,1765.31Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-16"
        d="M681.69,1794.14v3.46l3.22,8.7,1.17,8.07s2.1,5.77,2.1,7.79,5.14,11.64,5.14,11.64,1.4,3.25.7,3.88-6.78,14.46.86,14,5.25-14.88,5.25-14.88l-1-6.29s-1.47-15.74-1.47-16.89c0,0-1.89-6.7-2.1-8.17s-1.57-8.91-1.57-8.91l-1.15-4.82Z"
        transform="translate(0 0)"
      />
      <polygon
        className="cls-12"
        points="678.01 1805.04 666.61 1811.56 663.08 1816.91 667.79 1812.12 678.01 1805.04"
      />
      <path
        className="cls-12"
        d="M674.56,1817.53s-5.19,3.39-7.63,3.39-8.1,5.81-8.1,5.81,8-5.19,8.65-5S674.56,1817.53,674.56,1817.53Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M661.27,1819.34s-4.87,4.41-3.62,4.88,7.79-4.88,7.79-4.88Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M682.81,1803.62s-3.77,5.11-2.75,5,3.93-3,3.93-3Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M683.36,1808.66s-4.09,4.63-3.93,5.81S683.36,1808.66,683.36,1808.66Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M684.39,1811s-2.76,4.92-1.58,5.94S684.39,1811,684.39,1811Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M680.82,1798.35s-2.05,3.46-.55,3.69,1.64,0,1.64,0Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M681.39,1785.69a36.15,36.15,0,0,1-2.09,6.17,27,27,0,0,1-.81-3.09A21.12,21.12,0,0,1,681.39,1785.69Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M687.63,1745.17a5.1,5.1,0,0,1-.79,1.22,9.6,9.6,0,0,0-1.42,1.63,8.76,8.76,0,0,0-.51,2.78c-.15.93-.74,2-1.73,2a3.55,3.55,0,0,1-1.38-.41,1.41,1.41,0,0,0-1.38,0,1.56,1.56,0,0,0-.44,1,14.45,14.45,0,0,0-.14,2.46,5.6,5.6,0,0,0,.06,1,5.5,5.5,0,0,1-.19,3.11,3.81,3.81,0,0,1-1.93,1.56,12.21,12.21,0,0,1-2.72.76h0a29.1,29.1,0,0,1-5.36.17,1.13,1.13,0,0,1-.48-.08.57.57,0,0,1-.21-.13,15.92,15.92,0,0,0,.32-3,11.68,11.68,0,1,1,18.3-14.14Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-21"
        d="M682.75,1735.28c-1.07-.11-2.08.46-3.14.69-2.17.48-4.4-.51-6.63-.5a12.3,12.3,0,0,0-5.45,1.63,12.77,12.77,0,0,0-3.63,2.54,6,6,0,0,0-1.73,4,6.69,6.69,0,0,1-.08,2.6,8.17,8.17,0,0,1-.8,1.21,3.6,3.6,0,0,0-.37,3,9.9,9.9,0,0,0,1.79,2.68c1.44,1.84,2.34,4,3.63,6a18.14,18.14,0,0,0,1.88,2.39,1.3,1.3,0,0,0,.41.32,1.12,1.12,0,0,0,.48.07c2.73.08,5.56.14,8.08-.93a3.69,3.69,0,0,0,1.94-1.56,5.59,5.59,0,0,0,.19-3.11,14.39,14.39,0,0,1,.07-3.47,1.5,1.5,0,0,1,.45-1,1.36,1.36,0,0,1,1.38,0,3.75,3.75,0,0,0,1.38.42c.94,0,1.54-1.08,1.73-2a4.66,4.66,0,0,1,1.93-4.4,6.67,6.67,0,0,0,1.18-5.91C686.82,1737.93,684.91,1735.5,682.75,1735.28Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M694,1785a13.67,13.67,0,0,0,0,4.33c-.1,1.16,0,7.55,0,7.55-3.46-1-12.26,1-12.26,1l-1-3.14a7.8,7.8,0,0,1-1.32-2.6,27.52,27.52,0,0,1-.81-3.09,63.91,63.91,0,0,1-1-6.59c-.44-3.33,4.51-6.92,8.27-8.91s7,6.5,7,6.5S694.39,1783.33,694,1785Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-17"
        d="M694,1784.38a13.61,13.61,0,0,0,0,4.32c-.1,1.15,0,7.55,0,7.55-3.46-1-12.26,1-12.26,1l-1-3.14a7.66,7.66,0,0,1-1.32-2.59,27.52,27.52,0,0,1-.81-3.09,63.91,63.91,0,0,1-1-6.59c-.44-3.34,4.51-6.92,8.27-8.92s7,6.5,7,6.5S694.39,1782.7,694,1784.38Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-15"
        d="M687.32,1739.88a7,7,0,0,0-.77-1.69l.15.43a6.65,6.65,0,0,1-1.19,5.91,4.73,4.73,0,0,0-1.93,4.41c-.15.93-.74,2-1.73,2a3.55,3.55,0,0,1-1.38-.41,1.41,1.41,0,0,0-1.38,0,1.56,1.56,0,0,0-.44,1,14.32,14.32,0,0,0-.08,3.46,5.53,5.53,0,0,1-.19,3.12,3.83,3.83,0,0,1-1.94,1.56c-2.51,1.08-5.34,1-8.08.94a1.26,1.26,0,0,1-.47-.08,1.07,1.07,0,0,1-.41-.32,16.92,16.92,0,0,1-1.89-2.4c-1.29-2-2.19-4.15-3.62-6a17.43,17.43,0,0,1-1.19-1.53v.11a9.67,9.67,0,0,0,1.79,2.68c1.43,1.84,2.34,4,3.62,6a17.49,17.49,0,0,0,1.9,2.39,1.34,1.34,0,0,0,.39.32,1.12,1.12,0,0,0,.48.07c2.73.08,5.57.14,8.08-.93a3.69,3.69,0,0,0,1.94-1.56,5.53,5.53,0,0,0,.19-3.12,14.84,14.84,0,0,1,.08-3.46,1.58,1.58,0,0,1,.44-1,1.41,1.41,0,0,1,1.38,0,3.75,3.75,0,0,0,1.38.42c.95,0,1.54-1.08,1.73-2a4.72,4.72,0,0,1,1.93-4.41A6.68,6.68,0,0,0,687.32,1739.88Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-22"
        d="M771.67,1742.15,764.2,1758s-1.1,3-1.81,3.54-3.85,10-3.85,10l-8,12.36h0l-.55.86-3.14-.86-2.2-8.24.13-2.39v-.3s4.94-5.19,6-5.58c0,0,.87.1,2.51-3.92s3.15-4.89,3.15-4.89l.87-2.91,9.28-14.78s7.79-11.16,9.91-6.21S771.67,1742.15,771.67,1742.15Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-22"
        d="M740.63,1855a1.85,1.85,0,0,1-.25.76c-.87,1.26-5.74,27-5.74,27,.39,1.73-.71,5.19-1.42,6.77s-2.28,9.74-2.28,9.74-.39,17.62-1.4,20.37.32,8.65.32,8.65c-.1,2.71,1.53,5.58,2.5,7l.19.28c.31.45.52.71.52.71a4,4,0,0,0,1.09,1.36l.23.17a12.42,12.42,0,0,0,4.4,1.86H739c.62.14,1,.2,1,.2l.39,2.13-4.16.87-8,.54s-9.36.72-7.23-3.46a6.74,6.74,0,0,0,.38-.87h0c.08-.2.15-.41.22-.62a21.62,21.62,0,0,0,.41-8.88l-1.81-14.22c-3-13.21,2.35-27.91,2.35-27.91s-.46-1.18.56-4.17,0-9.51,0-9.51-.55-8.41-1.25-12.58a25.41,25.41,0,0,1-.29-5.3,24.81,24.81,0,0,1,.29-3.1l17.91-2.38A13.58,13.58,0,0,1,740.63,1855Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M740.38,1942l-4.16.86-8,.55s-9.36.72-7.23-3.46a6.74,6.74,0,0,0,.38-.87h0c.08-.2.15-.41.22-.62a14.55,14.55,0,0,1,4.74,1c2.66,1,4.67-1.65,4.67-1.65l1.3-2.48.19-.35c.31.45.52.71.52.71a4.23,4.23,0,0,0,1.32,1.53l-.22.46-1.26,2.65s1.61.16,3.81.51a1.73,1.73,0,0,0,2.08-1.13h.15c.62.14,1,.2,1,.2Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-23"
        d="M744.23,1944c-.31,1.42-9.08,1.85-9.08,1.85s-4.88-.46-6.25.67-5.39.87-5.54.87-2.28-.08-3.11-3.89,1.08-4.33,1.17-4.39h0a14.51,14.51,0,0,1,5,1c2.66,1,4.67-1.65,4.67-1.65l1.49-2.83c.31.45.52.71.52.71a4.15,4.15,0,0,0,1.32,1.53l-1.48,3.11s1.61.16,3.81.51a1.84,1.84,0,0,0,2.19-1.37,2.16,2.16,0,0,0,0-.36.89.89,0,0,0,0-.23l3.06.86A3.15,3.15,0,0,1,744.23,1944Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-22"
        d="M760.75,1873.74c-.21,1.25-.43,4.51-.43,4.51.74,2.72-1.15,9.2-1.15,9.2l-.94,3.69c.41,4.72-2.6,16.25-2.6,16.25s-2,10.69-3.06,15.31a12.64,12.64,0,0,0,.54,7.71l.08.19a1.1,1.1,0,0,0,.06.26,2.29,2.29,0,0,0,.12.37,5.88,5.88,0,0,0,1,1.73,4.46,4.46,0,0,0,.57.58h0a11.64,11.64,0,0,0,3.34,2.06h0l.52.2a14,14,0,0,0,1.67.52h.19l-.42,2.48-5.56-1.43-7.44-2.41-3.6-2a2.29,2.29,0,0,1,.11-.58v-.07a3.71,3.71,0,0,1,.11-.58c.57-3.24,2-12.32,1.28-19-.86-8.28,2.6-19.71,3.4-22.43a13.42,13.42,0,0,0,.63-4.83c-1.36-2-1.68-10-1.68-10s-2.51-5.14-4.59-10.59a27.74,27.74,0,0,1-1.5-5.51,19.16,19.16,0,0,1-.3-4s16.88-15.41,18.73-.5q.09.77.18,1.47C761.69,1870,760.94,1872.52,760.75,1873.74Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M760.75,1936.39l-.43,2.48-5.55-1.43-7.44-2.41-3.66-2.1a2.29,2.29,0,0,1,.11-.58v-.07a3.71,3.71,0,0,1,.11-.58h.52a6.86,6.86,0,0,1,3.11.43c1.22.45,2.81,1,3.13.75a3.88,3.88,0,0,1,1.78-.31l.77-1.73.12-.26a5.36,5.36,0,0,0,1,1.73,6.92,6.92,0,0,0,.6.61v.6l0,.87a10.63,10.63,0,0,0,2.68,1.22,1.33,1.33,0,0,0,.74,0h0l.53.21a14,14,0,0,0,1.67.52h0Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-23"
        d="M758,1941.7c-1.55-.44-5.29-1.16-5.89-.61s-5.79,0-5.79,0a3.18,3.18,0,0,1-2.68-1.56c-1.08-1.68-.2-7.11-.2-7.11h.38l.64-.07h0a6.86,6.86,0,0,1,3.11.42c1.21.46,2.81,1,3.13.76a3.76,3.76,0,0,1,1.78-.32l.86-2a5.75,5.75,0,0,0,1,1.73,6.92,6.92,0,0,0,.6.61l-.08,1.44a11.06,11.06,0,0,0,2.68,1.24,1.07,1.07,0,0,0,1.28-.41,1,1,0,0,0,.09-.21h0a9.45,9.45,0,0,0,2.2.72h0a4,4,0,0,1,4.33,3.08C766.25,1941.88,759.51,1942.14,758,1941.7Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M740.63,1855l-1,.29a14.18,14.18,0,0,1-2.31.49c-5,.7-13.15.27-15.74.11a26.84,26.84,0,0,1,.28-3.1l17.92-2.38A13.82,13.82,0,0,1,740.63,1855Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-23"
        d="M756.26,1850.65s-6.57,1-11.93,2.19c-1.78.38-3.46.8-4.66,1.19a14.18,14.18,0,0,1-2.31.49c-6,.87-16.55.06-16.55.06-2.91-9.43,3.53-21.31,3.53-21.31-.15-.94,1.34-3.84,1.34-3.84v-.09l.91-3.37H751l.3,1.44.62,2.95,3.84,18.23Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M760.05,1856.37l-.14.06L757,1857.7c-3.1,1.43-12.36,1.73-15.57,1.73a18.51,18.51,0,0,1-.31-4s16.89-15.42,18.74-.5C759.93,1855.41,760,1855.92,760.05,1856.37Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-23"
        d="M760.5,1854.9l-.59.27-2.87,1.27c-3.15,1.46-12.72,1.73-15.78,1.73h-.8a3.61,3.61,0,0,1-2.83-1.73c0-.19-.15-1.12-.27-1.92-.11-.64-.2-1.19-.2-1.19a77.19,77.19,0,0,1-9.52-4.92c-2.4-1.64-1.79-13.26-1.33-19.1.16-2,.31-3.34.31-3.34l24.75,1.44,3.46.2,2,.44.53.12s1.42,10.38,2.12,11.25.25,3.77.16,5.43a10.12,10.12,0,0,1-.23,2.12,13,13,0,0,1,.78,4.53A9.29,9.29,0,0,0,760.5,1854.9Z"
        transform="translate(0 0)"
      />
      <circle className="cls-22" cx="734.01" cy="1758.59" r="10.14" />
      <path
        className="cls-22"
        d="M729.06,1762.81s.7,9.61-1.26,11.81a89.07,89.07,0,0,1-7.08,6.37l1.57,6.53,8.89,1,10.38-11.09-2.36-2.86s-1.81-6.13-.32-9.2S729.06,1762.81,729.06,1762.81Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M750.52,1783.92h0l-.55.86-3.14-.86-2.2-8.24.13-2.39.64-.93s3.47.08,3.32,3.23C748.57,1778.34,750.15,1782.87,750.52,1783.92Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M757.35,1829.43s-2.5.55-5.36.93-6.17.57-7.53-.23c-2.27-1.35-14.6-1-18.17-.82h-.6l.91-3.37h24.45l.3,1.45,3.46.2,2,.44C757.15,1828.88,757.35,1829.43,757.35,1829.43Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-24"
        d="M744.78,1773s3.46.08,3.31,3.23,1.88,8.57,1.88,8.57l-.78,1.8s-1.1,4.41-.24,5.51,4,1.1,2.2,10.14c0,0-.39,6.76.55,9s.95,7.47,2.36,9.68a51.59,51.59,0,0,1,3.3,7.31s-10.22,2.27-12.89.71-19.11-.79-19.11-.79.08-2.75,0-3.07a2.57,2.57,0,0,1,.39-2c.56-.75,1.42-2,1-3.11s1.17-2.72.31-4.43-1.91-2-1.73-3a4,4,0,0,0-1-3c-.62-.79-4.87-9.09-4.87-9.09l-.71-3.88s-5.47-4-5.37-5.89,2.07-4.51,2.07-4.51l5.26-5.19s2.92,3.14,5.75,2,8.91-6.37,8.91-6.37,2.17-1.18,2.33-2,1.49-.08,1.49-.08,2-1.08,2.36-.7a6.75,6.75,0,0,0,2.13.7Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-22"
        d="M715.06,1789.35l-1.67,1.32a22.06,22.06,0,0,0-2.1,8.56,114.68,114.68,0,0,1-2.12,13.28l-3.31,5.91s-5.42,11.39-6.13,14.42-3.93,8.14-3.93,8.14-10.93,9.82-3.31,11.6,7.87-7.79,7.87-7.79a11.31,11.31,0,0,0,2.07-3.31c.33-1.34,10-18.44,10-18.44s3.38-4.6,2.67-7c0,0,4.4-10.38,4.4-13a45.4,45.4,0,0,0-.71-6.47l.71-5.42Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M737.7,1811s-7.7,4-9.19,4.24,6.52-.86,6.52-.86Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M744,1814.17s-10.22,3.46-14.71,2.84C729.28,1817,740.46,1819.12,744,1814.17Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M744.63,1815.3s-9.21,5.79-14.63,4.85c0,0,12.66,2.51,14.63,1.17l-5.66-1.56S745.25,1816.43,744.63,1815.3Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M734,1740.74a9.57,9.57,0,0,0-7.79,1.46c-1.58,1.24-2.6,3.14-4.4,4.06a22.14,22.14,0,0,1-2.87,1,8.29,8.29,0,0,0-5.06,8.93c.28,1.56,1,3,1.37,4.54.64,2.74.07,5.61-.63,8.35a87.43,87.43,0,0,1-8.74,21.67,2.39,2.39,0,0,0-.43,1.46,2.2,2.2,0,0,0,.54.92c3.63,4.2,9.52,5.64,15.1,5.83a11.59,11.59,0,0,0,6-1,9,9,0,0,0,2.92-2.68,14.21,14.21,0,0,0,2.72-9.13c-.15-2.64-1-5.31-.37-7.86a2,2,0,0,1,.47-1c.6-.61,1.59-.45,2.44-.55,2.31-.27,3.8-2.7,4.08-5s-.31-4.63-.32-6.93a14.56,14.56,0,0,1,2.74-8.55,9.52,9.52,0,0,0,2-3.42c.33-1.66-.64-2.88-1.47-4.19-1.29-2-1.92-4.42-3.88-6A10.59,10.59,0,0,0,734,1740.74Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-25"
        d="M733.39,1740.11a9.52,9.52,0,0,0-7.73,1.46c-1.58,1.25-2.6,3.14-4.4,4.07a24.52,24.52,0,0,1-2.88,1,8.29,8.29,0,0,0-5.06,8.91c.28,1.56,1,3,1.37,4.54.64,2.74.08,5.61-.63,8.35a87.51,87.51,0,0,1-8.73,21.63,2.22,2.22,0,0,0-.43,1.45,2,2,0,0,0,.53.93c3.63,4.2,9.52,5.64,15.1,5.83a11.79,11.79,0,0,0,6-1,9.1,9.1,0,0,0,2.91-2.68,14.16,14.16,0,0,0,2.71-9.13c-.16-2.6-1-5.31-.37-7.86a2,2,0,0,1,.47-1c.6-.62,1.59-.45,2.44-.55,2.31-.27,3.79-2.71,4.07-5s-.3-4.63-.31-6.92a14.56,14.56,0,0,1,2.74-8.55,9.54,9.54,0,0,0,2-3.46c.32-1.66-.64-2.87-1.47-4.19-1.29-2-1.92-4.42-3.89-6A10.59,10.59,0,0,0,733.39,1740.11Z"
        transform="translate(0 0)"
      />
      <path className="cls-12" d="M730,1838.41s2,9.44,5.38,12Z" transform="translate(0 0)" />
      <g className="cls-8">
        <path d="M713.92,1757.68c-.11-.36-.23-.73-.36-1.1Z" transform="translate(0 0)" />
        <path
          d="M742.6,1749.5a3.1,3.1,0,0,1,0,.86,9.16,9.16,0,0,1-2,3.42,14.59,14.59,0,0,0-2.74,8.56c0,2.33.59,4.64.31,6.92s-1.73,4.74-4.06,5c-.87.11-1.85-.06-2.44.56a2.12,2.12,0,0,0-.48,1c-.67,2.55.22,5.19.37,7.85a14.23,14.23,0,0,1-2.75,9.13,8.89,8.89,0,0,1-2.92,2.67,11.59,11.59,0,0,1-6,1c-5.46-.18-11.25-1.59-14.92-5.63a1.41,1.41,0,0,0-.09.76,1.94,1.94,0,0,0,.53.92c3.63,4.21,9.52,5.65,15.1,5.84a11.79,11.79,0,0,0,6-1,9.21,9.21,0,0,0,2.91-2.68,14.17,14.17,0,0,0,2.71-9.14c-.16-2.6-1-5.3-.37-7.86a2.2,2.2,0,0,1,.47-1c.6-.61,1.59-.45,2.44-.54,2.31-.27,3.79-2.71,4.07-5s-.3-4.63-.31-6.92a14.56,14.56,0,0,1,2.74-8.55,9.68,9.68,0,0,0,2-3.46A3.92,3.92,0,0,0,742.6,1749.5Z"
          transform="translate(0 0)"
        />
      </g>
      <ellipse className="cls-7" cx="293.39" cy="1971.41" rx="18.31" ry="1.85" />
      <path
        className="cls-11"
        d="M299.8,1940.8s-23.88,12.34-11.55,26.82a4.59,4.59,0,0,0,4.52,2.93s-.67-1.22,1.9-2.19a13.37,13.37,0,0,0,2.59-1.37,6,6,0,0,0,2.31-3.09h0s4.89-6.53,1.1-18.62c0,0,.49-3.46.06-4l-2.9,4s3,2,3.35,5c0,0-3.12-5-3.81-4.39-.13.11-1.43,2.17-1.43,2.17s5.19,3.53,5.58,8.11c0,0-2.93-6.41-6.06-7.33l-1.78,3.23s5.68,3.46,6.32,8.65c0,0-3.3-6.59-6.67-7.92l-1.32,3.41s5.47,3.46,6,7.79c0,0-4.58-7.23-6.22-7a18.14,18.14,0,0,0-.87,4.19s3.88,2.59,3.95,5.13c0,0-3.57-4.52-4.13-4.22,0,0-.22,4.54.87,6.93a13.8,13.8,0,0,1-1.59-6.93,7.55,7.55,0,0,0-2.28,3.69s-1-2.5,2.35-4.78c0,0,.32-3.54.71-4,0,0-3.66,1.78-5.19,4,0,0,.57-3.27,5.54-4.89l1.26-3.47s-5,2.11-6.5,4c0,0,1.1-3.25,6.92-4.8l1.73-3.19a27.73,27.73,0,0,0-6.57,3.19s1.67-2.8,7.1-4.08l1.4-2s-3.21,1.15-4.1,1.56-1.08.21-1.08.21a11.45,11.45,0,0,1,5.75-2.6S300,1941,299.8,1940.8Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-26"
        d="M323.32,1962.76c2.5,1.48,6.41,2.44,11.34-.74l-.05,7c.35,4.82,6.3,22.35,6.3,22.35l4.19,14.43a112.62,112.62,0,0,1,4.62,31.38,30.12,30.12,0,0,0,1.34,9.89c2.24,6-.86,13.84-2.23,16.72a66.67,66.67,0,0,1-6.92,2,4.44,4.44,0,0,0-.62.19h0s-6.3,2.51-9.11,2.16c-2.08-.26-3.39,2.34-1.73,4.26a12.92,12.92,0,0,1-4.19.55c-2.81-.35-4.2,4.47,1,5.85s35,0,35,0a4.2,4.2,0,0,0,2.24-4.66l3.71-.16s5.26-2.41,0-8.94l-.27.22-1.13-3.31v-4.47s6.66-32,4.55-35.75-3.15-23-3.15-23l.71-21c1.89-3.92,2.87-10,3.36-14.86a31.49,31.49,0,0,0,1.34-7c.08-1.12.15-2.31.19-3.54a23.66,23.66,0,0,0-1.27-7.91,78.78,78.78,0,0,1,7.93-10,10.22,10.22,0,0,0,3.15-8.94l-1.73-11.69-4.21-19.9c.53-5.54-.62-8.72-1.9-10.52.3-1.79.59-3.58.8-5.37a68.47,68.47,0,0,0-1.23-23.36c-.6-2.67-1.43-5.42-3.3-7.44-2.13-2.29-5.57-3.7-6.36-6.7a21.64,21.64,0,0,0-.49-2.26c-.77-2-3.2-2.68-5.33-2.94-3.79-.46-9.26-.81-12.91.68a15.68,15.68,0,0,0-7.43,7,6,6,0,0,0-.86,4.33,5.62,5.62,0,0,0,.6,1.46,13.18,13.18,0,0,0-.86,4.61,13.41,13.41,0,0,0,8.56,12.44c.08.26.16.52.23.78a13.09,13.09,0,0,1,.34,1.87c-.07,1.22-.14,2.44-.2,3.65a1.85,1.85,0,0,1-.13.5,87.71,87.71,0,0,0-8.8,4.49c2.1,3.08-.35,16.15-.35,16.15-5.25,3.1-10.5,11-4.91,19.25a20.36,20.36,0,0,1,2.45,17.09l-.86,13.45h0l-.18,2.68h.07l-.07,1h.15v1.1a16.13,16.13,0,0,0-8.19,6.06,21.77,21.77,0,0,1-3.67,4.5,2.7,2.7,0,0,0-.41,3.79A2.59,2.59,0,0,0,323.32,1962.76ZM365.59,1927a12.73,12.73,0,0,1,1.25-6.76h2.47c.09.58.18,1.14.3,1.64.71,3.06-2.12,4.51-4,5.12Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-27"
        d="M366.28,2060.3l1.35,4-4.36,4.38L348.79,2072l-10.45-1,.68-3.7h0a4.42,4.42,0,0,1,3.31-3.26c4.33-1,12.48-3.31,14.2-6.51Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M366.28,2060.3l1.35,4-4.36,4.38L348.79,2072l-10.45-1,.68-3.7h0a4.42,4.42,0,0,1,3.31-3.26c4.33-1,12.48-3.31,14.2-6.51Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-28"
        d="M341.69,2070.07l2-2.7-2-3.12s-6.05,2.44-8.75,2.1-4,4.39,1,5.73,33.68,0,33.68,0,5.06-2.35,0-8.75l-4.36,3.36s-7.41,4-11.12,3.38Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-27"
        d="M332.6,2075.79l10.44,1,14.49-3.37,4.38-4.38-.36-1.07-.5-1.5-.49-1.47-9.76-2.7a6,6,0,0,1-2,2c-3.17,2.17-8.87,3.73-12.21,4.52l-.43.12a4.45,4.45,0,0,0-2.93,3.14Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M332.6,2075.79l10.44,1,14.49-3.37,4.38-4.38-.36-1.07-4,3.09s-7.41,4-11.12,3.38H336l2-2.7-1.82-2.81a4.44,4.44,0,0,0-2.93,3.14Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-28"
        d="M336,2074.78l2-2.7-2-3.11s-6.05,2.44-8.76,2.1-4,4.39,1,5.73,33.69,0,33.69,0,5-2.35,0-8.76l-4.38,3.33s-7.41,4.05-11.12,3.38Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-27"
        d="M344.39,1879.41s16.17,1.68,13.85-5.06a12.26,12.26,0,0,1-.51-5.95,16.68,16.68,0,0,1,2.87-6.85l-15.16,2.36a19.6,19.6,0,0,1,2.05,4.86C349.26,1875.92,344.39,1879.41,344.39,1879.41Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-29"
        d="M372,1956.21s-.34,14.48-3.7,21.56l-.71,20.54s1,18.86,3,22.58-4.38,35-4.38,35v5.38s-10.77,4-13.85-.66l3.08-40.09-1.32-55.6Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M372,1956.21s-.34,14.48-3.7,21.56l-.71,20.54s1,18.86,3,22.58-4.38,35-4.38,35v5.38s-10.77,4-13.85-.66l3.08-40.09-1.32-55.6Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-30"
        d="M378.75,1909.72l2,5.39,1.69,11.45a10.15,10.15,0,0,1-3,8.76c-4,4-9.43,12.47-9.43,12.47l-6.41-19.18s6.41-1,5.39-5.39-.67-12.47-.67-12.47Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-18"
        d="M378.75,1909.72l2,5.39,1.69,11.45a10.15,10.15,0,0,1-3,8.76c-4,4-9.43,12.47-9.43,12.47l-6.41-19.18s6.41-1,5.39-5.39-.67-12.47-.67-12.47Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M348.81,2064.3c4.33,6.81,11,2.95,12.24,2.18l-.49-1.47-9.76-2.7A6.1,6.1,0,0,1,348.81,2064.3Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-29"
        d="M335.3,1969.34c.34,4.72,6.06,21.9,6.06,21.9l4,14.14a111.84,111.84,0,0,1,4.43,30.75,30.48,30.48,0,0,0,1.33,9.68c2.7,7.41-2.69,17.52-2.69,17.52,4.71,8.42,12.79,2.7,12.79,2.7v-5.39s6.41-29.31,4.72-35-2.35-21.22-2.35-21.22v-28a31.74,31.74,0,0,0,9.2-20.13c.09-1.09.15-2.25.19-3.46.28-7.6-4.92-16.51-6.92-19.69-.48-.76-.79-1.19-.79-1.19l-29.78,11.8h-.07v3.7Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M345.4,1863.91a19.11,19.11,0,0,1,2.06,4.86,13.06,13.06,0,0,0,10.24-.37,16.53,16.53,0,0,1,2.86-6.85Z"
        transform="translate(0 0)"
      />
      <circle className="cls-27" cx="352.14" cy="1855.83" r="13.13" />
      <path
        className="cls-12"
        d="M335.3,1947.45h.14c14.13,2.9,31.86-11.82,31.86-11.82a16.67,16.67,0,0,1-1.23-2.51c-.49-.76-.79-1.19-.79-1.19l-29.75,11.82-.08,1.17Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-30"
        d="M339.05,1879.07s18.86-11.46,24.92-6.74l8.42,10.78s5.39,1.68,4.38,12.46l4,19.54-12.11,3s-7.16,6.74-1.4,16.52c0,0-17.85,14.82-32,11.8l1-15.84s3-8.75-2.35-16.84-.34-15.83,4.72-18.87C338.66,1894.86,341,1882.1,339.05,1879.07Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-27"
        d="M338,1947.79s-7.07,1.34-10.44,6.73a20.8,20.8,0,0,1-3.53,4.4,2.67,2.67,0,0,0-.18,3.77h0a2.53,2.53,0,0,0,.59.49c2.67,1.6,7,2.6,12.54-1.93C346.41,1953.51,338,1947.79,338,1947.79Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M348.43,1885.46s6.74-.67,8.42,16.85,4.38,21.56,4.38,21.56,1.69,9.1-2.35,14.49-11.8,16.84-11.8,16.84-8.42,2.36-10.1-6.06l10.44-19.54s1.69-6.73-2.35-11.45S338,1882.1,348.43,1885.46Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-30"
        d="M347.76,1884.48s6.74-.68,8.42,16.84,4.38,21.56,4.38,21.56,1.68,9.09-2.36,14.48-11.79,16.84-11.79,16.84-8.42,2.36-10.11-6.06l10.45-19.53s1.68-6.74-2.36-11.46S337.31,1881.09,347.76,1884.48Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M342.2,1897.08s-.67,10.45,2.36,16.18,3,13.13,0,17.18"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M340.52,1884.29s2.7-2.69,10.44-3,11.12-2.7,11.12-2.7"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M359.26,1838.84c2,.26,4.38,1,5.12,2.89.19.73.35,1.46.47,2.21.76,2.94,4.07,4.33,6.11,6.57,1.81,2,2.6,4.67,3.19,7.28a68.27,68.27,0,0,1,1.18,22.89c-.65,5.59-2,11.09-2.38,16.7s.32,11.52,3.39,16.23c1.31,2,3.05,3.81,4,6.06-1.93,1.56-4.6,1.79-7.08,1.88q-4.33.14-8.65,0a9.42,9.42,0,0,1-4.43-.92,6.73,6.73,0,0,1-2.31-2.49,17.76,17.76,0,0,1-2-9.46c.07-3.27.59-6.51.65-9.77a8.8,8.8,0,0,0-1.13-5.12,17,17,0,0,0-2.83-2.6c-3.6-3.19-4.83-8.32-5-13.13s.66-9.64.25-14.44c-.15-1.88-.86-4.14-2.7-4.5a11.23,11.23,0,0,1-1.73-.23c-1.49-.57-1.35-2.66-1.73-4.19-.48-1.73-1.94-3-2.36-4.79-1-4.07,4.22-8.94,7.62-10.38S355.61,1838.39,359.26,1838.84Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-31"
        d="M359.59,1838.17c2.05.26,4.38,1,5.13,2.88a20.81,20.81,0,0,1,.46,2.22c.77,3,4.08,4.33,6.12,6.56,1.81,2,2.6,4.68,3.19,7.29a68.74,68.74,0,0,1,1.18,22.88c-.66,5.59-2,11.1-2.38,16.7s.31,11.52,3.39,16.24c1.31,2,3.05,3.8,4,6-1.93,1.57-4.59,1.79-7.07,1.88q-4.33.14-8.7,0a9.23,9.23,0,0,1-4.42-.92,6.81,6.81,0,0,1-2.32-2.48,17.79,17.79,0,0,1-2-9.47c.07-3.27.59-6.5.65-9.76a8.85,8.85,0,0,0-1.12-5.13,16.56,16.56,0,0,0-2.83-2.59c-3.6-3.2-4.85-8.32-5-13.14s.68-9.64.27-14.44c-.16-1.88-.87-4.13-2.71-4.5a11.21,11.21,0,0,1-1.73-.22c-1.49-.57-1.34-2.67-1.73-4.2-.48-1.73-1.94-3-2.35-4.78-1-4.07,4.21-8.94,7.61-10.39S355.94,1837.75,359.59,1838.17Z"
        transform="translate(0 0)"
      />
      <ellipse className="cls-7" cx="1013.25" cy="2138.03" rx="20.77" ry="2.86" />
      <ellipse className="cls-7" cx="933.7" cy="2146.11" rx="20.77" ry="2.86" />
      <ellipse className="cls-7" cx="840" cy="2135.19" rx="20.77" ry="2.86" />
      <ellipse className="cls-7" cx="965.54" cy="2169.07" rx="14.76" ry="2.02" />
      <ellipse className="cls-7" cx="882.98" cy="2167.04" rx="14.76" ry="2.02" />
      <path
        className="cls-11"
        d="M933.26,2145.46s29.12-95.48-4.56-133.72c-25.2-28.61-53.79-25.19-66.62-21.63a23.67,23.67,0,0,0-15.28,12.7c-4.48,9.66-3.67,25.34,27.94,43.64,52.93,30.64,56.24,72.69,56.24,72.69Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-32"
        d="M866.65,2003.63s85.86,26.83,66.63,141.83"
        transform="translate(0 0)"
      />
      <path className="cls-32" d="M916.8,2013.26s-11.82,8-8.06,19.9" transform="translate(0 0)" />
      <path className="cls-32" d="M875.26,2020.32s7.24-7.35,16-3.29" transform="translate(0 0)" />
      <path className="cls-32" d="M909.25,2057.32s12.68-6.44,16,1.63" transform="translate(0 0)" />
      <path className="cls-32" d="M940.61,2070.75s-9.52-.76-9.7,3.8" transform="translate(0 0)" />
      <path
        className="cls-11"
        d="M934.44,2146.22s-34.11-69.4-43.48-67.87c-4.45.72-6.5,5.09-7.43,9.52a27.89,27.89,0,0,0,1.86,16.88C890.21,2115.84,903.19,2136.93,934.44,2146.22Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-32"
        d="M891.21,2091.52s35.8,51.91,42.73,54.2"
        transform="translate(0 0)"
      />
      <line className="cls-32" x1="887.91" y1="2099.62" x2="896.27" y2="2099.62" />
      <line className="cls-32" x1="897.92" y1="2118.62" x2="911.02" y2="2119.18" />
      <line className="cls-32" x1="905.89" y1="2103.42" x2="904.47" y2="2110.25" />
      <line className="cls-32" x1="919.82" y1="2124.19" x2="919.57" y2="2130.39" />
      <path
        className="cls-11"
        d="M933.42,2146.22s34.11-69.4,43.48-67.87c4.46.72,6.51,5.09,7.44,9.52a27.89,27.89,0,0,1-1.86,16.88C977.6,2115.84,964.63,2136.93,933.42,2146.22Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-32"
        d="M976.65,2091.52s-35.8,51.91-42.71,54.2"
        transform="translate(0 0)"
      />
      <line className="cls-32" x1="979.94" y1="2099.62" x2="971.58" y2="2099.62" />
      <line className="cls-32" x1="969.93" y1="2118.62" x2="956.83" y2="2119.18" />
      <line className="cls-32" x1="961.96" y1="2103.42" x2="963.38" y2="2110.25" />
      <line className="cls-32" x1="948.03" y1="2124.19" x2="948.28" y2="2130.39" />
      <path
        className="cls-11"
        d="M874.86,2162.42a5.84,5.84,0,0,1-1.94-2.93,2.57,2.57,0,0,1,1.36-3c1.24-.48,2.59.39,3.58,1.26s2.16,1.87,3.46,1.69a5.39,5.39,0,0,1-1.65-5,2.18,2.18,0,0,1,.46-1c.7-.75,2-.43,2.78.15,2.6,1.86,3.37,5.44,3.38,8.66a16.39,16.39,0,0,0,0-3.57,3.64,3.64,0,0,1,1.35-3.15,4,4,0,0,1,2-.48,4.22,4.22,0,0,1,3.31.94,3.29,3.29,0,0,1-.13,4.07,12.44,12.44,0,0,1-3.46,2.74,7.71,7.71,0,0,0-2.45,2.34,3,3,0,0,0-.18.41h-7.42A20.3,20.3,0,0,1,874.86,2162.42Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-11"
        d="M1004.83,2134.48a5.9,5.9,0,0,1-1.93-2.94,2.6,2.6,0,0,1,1.35-3c1.24-.49,2.59.38,3.59,1.25s2.16,1.87,3.46,1.73a5.33,5.33,0,0,1-1.65-5,2.09,2.09,0,0,1,.46-1c.69-.74,1.95-.42,2.78.16,2.6,1.85,3.37,5.43,3.38,8.65a16.3,16.3,0,0,0,0-3.56,3.66,3.66,0,0,1,1.34-3.15,4.09,4.09,0,0,1,2-.49,4.18,4.18,0,0,1,3.3,1,3.28,3.28,0,0,1-.13,4.06,12.66,12.66,0,0,1-3.46,2.75,7.58,7.58,0,0,0-2.45,2.33,3.79,3.79,0,0,0-.18.42h-7.41A21,21,0,0,1,1004.83,2134.48Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-11"
        d="M961.52,2162.42a6,6,0,0,1-1.95-2.93,2.6,2.6,0,0,1,1.36-3c1.25-.48,2.6.39,3.59,1.26s2.17,1.87,3.46,1.69a5.33,5.33,0,0,1-1.64-5,2,2,0,0,1,.46-1c.69-.75,1.94-.43,2.77.15,2.59,1.86,3.37,5.44,3.38,8.66a16.39,16.39,0,0,0,0-3.57,3.7,3.7,0,0,1,1.33-3.15,4,4,0,0,1,2-.48,4.22,4.22,0,0,1,3.31.94c1,1.07.74,2.88-.13,4.07a13.13,13.13,0,0,1-3.46,2.74,7.5,7.5,0,0,0-2.45,2.34,3,3,0,0,0-.18.41H966A20.25,20.25,0,0,1,961.52,2162.42Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-11"
        d="M838.66,2105.93s-2.78,3.64,1.28,9.13,7.43,10.13,6.06,13.56c0,0-6.14-10.2-11.13-10.38S833.17,2112,838.66,2105.93Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M838.66,2105.93a4.68,4.68,0,0,0-.57,1.14c-4.87,5.72-7.47,11.07-2.79,11.2,4.37.13,9.61,7.93,10.85,9.9a2.59,2.59,0,0,1-.14.45s-6.14-10.2-11.13-10.38S833.17,2112,838.66,2105.93Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-13"
        d="M833.49,2110.57c0,1.28.14,2.32.32,2.32s.32-1,.32-2.32-.18-.68-.36-.68S833.49,2109.29,833.49,2110.57Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-13"
        d="M831.71,2112.1c1.13.61,2.11,1,2.19.82s-.75-.78-1.87-1.39-.69-.16-.77,0S830.59,2111.49,831.71,2112.1Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-11"
        d="M853.36,2105.93s2.78,3.64-1.29,9.13-7.41,10.13-6.05,13.56c0,0,6.13-10.2,11.13-10.38S858.86,2112,853.36,2105.93Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-12"
        d="M853.36,2105.93a4.47,4.47,0,0,1,.56,1.14c4.88,5.72,7.48,11.07,2.79,11.2-4.33.13-9.59,7.93-10.85,9.9a1.7,1.7,0,0,0,.15.45s6.13-10.2,11.13-10.38S858.86,2112,853.36,2105.93Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-13"
        d="M858.53,2110.57c0,1.28-.14,2.32-.32,2.32s-.32-1-.32-2.32.18-.68.35-.68S858.53,2109.29,858.53,2110.57Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-13"
        d="M860.31,2112.1c-1.12.61-2.11,1-2.19.82s.75-.78,1.88-1.39.68-.16.77,0S861.44,2111.49,860.31,2112.1Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-14"
        d="M857.65,2128.14s-7.79-.24-10.13-1.91-12-3.66-12.54-1-11.7,13.32-2.91,13.39,20.4-1.37,22.75-2.8S857.65,2128.14,857.65,2128.14Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-15"
        d="M831.9,2137.7c8.8.07,20.42-1.36,22.76-2.79,1.77-1.08,2.49-5,2.73-6.78h.26s-.5,6.28-2.83,7.71-14,2.86-22.75,2.79c-2.54,0-3.42-.93-3.37-2.26C829.06,2137.18,830,2137.69,831.9,2137.7Z"
        transform="translate(0 0)"
      />
      <ellipse className="cls-7" cx="326.37" cy="2149.64" rx="30.04" ry="4.44" />
      <ellipse className="cls-7" cx="399.11" cy="2189.49" rx="30.04" ry="4.44" />
      <ellipse className="cls-7" cx="720.04" cy="2085.67" rx="19.89" ry="2.22" />
      <ellipse className="cls-7" cx="627.05" cy="2149.64" rx="19.89" ry="2.22" />
      <ellipse className="cls-7" cx="585.12" cy="2189.49" rx="19.89" ry="2.22" />
    </>
  );
}
