const settings = {
  GET_SETTINGS_REQUEST: 'GET_SETTINGS_REQUEST',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_ERROR: 'GET_SETTINGS_ERROR',

  CREATE_SETTINGS_REQUEST: 'CREATE_SETTINGS_REQUEST',
  CREATE_SETTINGS_SUCCESS: 'CREATE_SETTINGS_SUCCESS',
  CREATE_SETTINGS_ERROR: 'CREATE_SETTINGS_ERROR',

  UPDATE_SETTINGS_REQUEST: 'UPDATE_SETTINGS_REQUEST',
  UPDATE_SETTINGS_SUCCESS: 'UPDATE_SETTINGS_SUCCESS',
  UPDATE_SETTINGS_ERROR: 'UPDATE_SETTINGS_ERROR',
};

export default settings;
