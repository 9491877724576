import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import ImagesService from '../../services/ImagesService';
import { useTranslation } from 'react-i18next';
import EditLabel from '../utils/edit-label';

const Image = styled.img`
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
`;

const ImageOverlay = styled.div`
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  z-index: 0;
`;

const ImageUploaderContainer = styled.div`
  position: relative;
  &:hover ${Image} {
    opacity: 0.3;
  }
  &:hover ${ImageOverlay} {
    opacity: 1;
  }
`;

const Error = styled.p`
  color: red;
`;

const UPLOAD_ERRORS = {
  UNKNOWN: 'UNKNOWN',
};

export const ImageUploader = ({
  value,
  onChange,
  style = {},
  imageStyle = {},
  editLabelStyle = {},
  clearLabelStyle,
  base64 = false,
  maxSize,
}) => {
  const { t } = useTranslation();
  const [uploader, setUploader] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onError = ({ type, message }) => {
    console.error(message);
    setLoading(false);
    const errorMessage =
      type === UPLOAD_ERRORS.UNKNOWN ? t('common:errors:unknown_error') : message;
    setError(errorMessage);
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    setLoading(true);
    (base64 ? ImagesService.fileToBase64Image(file) : ImagesService.uploadFile(file, maxSize))
      .then((data) => {
        setLoading(false);
        onChange(base64 ? data : data.url);
      })
      .catch((error) =>
        onError({
          type: UPLOAD_ERRORS.UNKNOWN,
          message: error,
        })
      );
  };

  return (
    <div style={style}>
      {isLoading ? (
        <Spinner size="large" />
      ) : error ? (
        <>
          <Error>
            {error}{' '}
            <Button
              onClick={() => setError('')}
              style={{ display: 'inline-block' }}
              appearance="subtle-link"
            >
              {t('upload:retry')}
            </Button>
          </Error>
        </>
      ) : (
        <EditLabel
          labelStyle={editLabelStyle}
          clearStyle={clearLabelStyle}
          onLabelClick={() => uploader.click()}
          onClearClick={() => onChange('')}
        >
          <ImageUploaderContainer>
            <Image src={value} alt="Image" style={imageStyle} />
            <ImageOverlay>
              <input
                type="file"
                accept="image/png,image/jpeg"
                ref={(ref) => setUploader(ref)}
                style={{ display: 'none' }}
                onChange={onFileChange}
              />
              <Button appearance="subtle" onClick={() => uploader.click()}>
                {t('upload:edit')}
              </Button>
            </ImageOverlay>
          </ImageUploaderContainer>
        </EditLabel>
      )}
    </div>
  );
};
