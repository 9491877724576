import React from 'react';
import { isEmpty } from 'lodash/fp';
import Select from '@atlaskit/select';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Avatar from '@atlaskit/avatar';

const MultiSelect = ({ data, label, value, onSelect }) => {
  const { t } = useTranslation();

  if (isEmpty(data)) {
    return <div />;
  }

  const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
  `;

  const formatOptionLabel = (node, { context }) => {
    if (context === 'menu' && node.picture) {
      return (
        <SelectWrapper>
          <Avatar alt="picture" src={node.picture} />
          <span style={{ marginLeft: 10 }}>{node.label}</span>
        </SelectWrapper>
      );
    }
    return node.label;
  };

  const options = data.map(f => ({
    picture: f.picture,
    label: f.name,
    value: f.id
  }));

  return (
    <div style={{ flex: isEmpty(value) ? 1.25 : 2, marginLeft: 10 }}>
      <Select
        styles={{
          control: base => ({
            ...base,
            backgroundColor: 'var(--gray-4)',
            borderColor: '#f4f5f7',
            borderRadius: 4
          }),
          container: base => ({ ...base, fontFamily: 'inherit' }),
        }}
        formatOptionLabel={formatOptionLabel}
        noOptionsMessage={() => t('common:no_options')}
        placeholder={label}
        isMulti
        value={options.filter(option => value.includes(option.value))}
        options={options}
        onChange={(option, { action }) => {
          if (action === 'select-option' || action === 'remove-value' || action === 'clear') {
            const values = option.map(f => f.value);
            onSelect(values);
          }
        }}
      />
    </div>
  );
};

export default MultiSelect;
