import HttpService from './HttpService';

class AccountService extends HttpService {
  constructor() {
    super(process.env.REACT_APP_SERVICES_URL);
  }

  getAccount() {
    return this.http
      .get('/account')
      .then(this.onResponse)
      .catch(this.onError);
  }

  updateAccount(data) {
    return this.http
      .put('/account', data)
      .then(this.onResponse)
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data);
        }
        return this.onError(error);
      });
  }

  removePaymentMethod(id) {
    return this.http
      .delete(`/account/payment-method/${id}`)
      .then(this.onResponse)
      .catch(this.onError);
  }

  getInvoices() {
    return this.http
      .get('/account/invoices')
      .then(this.onResponse)
      .catch(this.onError);
  }

  sendEmailVerification() {
    return this.http
      .post('/account/verification-email', {})
      .then(this.onResponse)
      .catch(this.onError);
  }

  setUserLang(lang) {
    return this.http
      .put('/account/preferences', { lang })
      .then(this.onResponse)
      .catch(this.onError);
  }

  updateUserConsent(consentGiven) {
    return this.http
      .put('/account/preferences', { consentGiven })
      .then(this.onResponse)
      .catch(this.onError);
  }
}

// eslint-disable-next-line
export default new AccountService();
