import types from '../types';
import { ProductsTypes } from '../../constants';

export const handleError = (type, error, dispatch) => {
  console.log(error);
  if (error.type === ProductsTypes.PLAN) {
    dispatch({ type: types.ADD_PRODUCTS_ERROR, payload: error });
    // as error as the type key at "plan" it will not show a flag
    dispatch({ type, payload: error });
  } else {
    const message = error.data ? error.data.message : (error.message || 'common:errors.unknown_error');

    dispatch({ type, payload: { message } });
  }

  throw error;
};

export default handleError;
