import { createSelector } from 'reselect';
import { requestStatus } from '../request-status';
import { ProductsTypes } from '../../constants';

const productsListStatus = (state) => state.products.status;
const productsList = (state) => state.products.list;

export const areProductsLoading = createSelector(
  productsListStatus,
  (status) => status === requestStatus.WAITING
);

export const getConfFeatures = createSelector(
  productsList,
  () => ProductsTypes.FEATURE,
  (products, type) => {
    return products.filter((item) => item.type === type);
  }
);

export const getConfPlans = createSelector(
  productsList,
  () => ProductsTypes.PLAN,
  (products, type) => {
    return products.filter((item) => item.type === type);
  }
);
