import types from '../types';
import SpeakersService from '../../services/SpeakersService';
import handleError from '../utils/errors';
import { updateEntityProgress } from './progress';

export const updateSpeaker = speaker => dispatch => {
  dispatch({
    type: types.UPDATE_SPEAKERS_REQUEST
  });

  return SpeakersService.update(speaker)
    .then(() => {
      dispatch(updateEntityProgress('speakers', true));
      dispatch({
        type: types.UPDATE_SPEAKERS_SUCCESS,
        payload: speaker
      });
    })
    .catch(err => handleError(types.UPDATE_SPEAKERS_ERROR, err, dispatch));
};

export const createSpeaker = speaker => dispatch => {
  dispatch({
    type: types.CREATE_SPEAKERS_REQUEST
  });

  return SpeakersService.create(speaker)
    .then(createdSpeakers => {
      dispatch(updateEntityProgress('speakers', true));
      return dispatch({
        type: types.CREATE_SPEAKERS_SUCCESS,
        payload: createdSpeakers
      });
    })
    .catch(err => handleError(types.CREATE_SPEAKERS_ERROR, err, dispatch));
};

export const findSpeakers = confId => dispatch => {
  dispatch({
    type: types.FIND_ALL_SPEAKERS_REQUEST
  });
  return SpeakersService.findAllOfConf(confId)
    .then(confs => {
      return dispatch({
        type: types.FIND_ALL_SPEAKERS_SUCCESS,
        payload: confs
      });
    })
    .catch(err => handleError(types.FIND_ALL_SPEAKERS_ERROR, err, dispatch));
};

export const deleteSpeaker = (speakerId, remainingSpeakers) => dispatch => {
  return SpeakersService.delete(speakerId)
    .then(() =>
      dispatch({
        type: types.DELETE_SPEAKER,
        payload: speakerId
      })
    )
    .then(() => {
      return dispatch(updateEntityProgress('speakers', remainingSpeakers - 1 > 0));
    });
};
