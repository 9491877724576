import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Badge from '../../layout/badge';
import Info from '../../layout/info';

const Wrapper = styled.div`
  width: 100%;
`;

const PlanBadge = styled(Badge)`
  margin: 0 5px;
`;

const Link = styled.a`
  text-decoration: underline;
  margin-left: 5px;
  color: var(--blue-4);
`;

const ProgramInfo = ({planId, confId}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Info>
        <span>{t('plan_info:your')}</span>
        <PlanBadge>{t(`conf_plans:${planId}`)}</PlanBadge>
        <span>{t('plan_info:allows_embed')}</span>
        <Link href={`${process.env.REACT_APP_AGENDA_URL}/${confId}`} target="_blank">
          {`${process.env.REACT_APP_AGENDA_URL}/${confId}`}
        </Link>
      </Info>
    </Wrapper>
  );
};

export default ProgramInfo;
