import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMappedState, useDispatch } from 'redux-react-hook';
import { signIn } from '../../services/AuthService';
import Page from '../layout/page';
import styled from 'styled-components';
import { Checkbox } from '@atlaskit/checkbox';
import Button from '@atlaskit/button';
import { consent } from '../../store/actions/account';
import { Redirect } from 'react-router-dom';
import { isConsentLoading } from '../../store/selectors/account';
import { getUserConsent } from '../../store/selectors/auth';
import TermsImage from '../../assets/undraw_terms.svg';

const ConsentContainer = styled.div`
  padding: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 640px) {
    padding: 0;
  }
`;

const Title = styled.h3`
  padding-bottom: 6px;
  border-bottom: 1px solid #e1e4e8;
`;

const Illustration = styled.img`
  width: 300px;
`;

const TextContentContainer = styled.div`
  margin: 0 40px;
  line-height: 26px;
  max-width: 600px;
  @media (max-width: 640px) {
    margin: 0;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
  text-align: center;
`;

const Consent = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const { loading, consentGiven } = useMappedState(state => ({
    loading: isConsentLoading(state),
    consentGiven: getUserConsent(state)
  }));

  const onConsent = () => {
    dispatch(consent()).then(() => signIn());
  };

  if (consentGiven) {
    return <Redirect to={'/'} />;
  }

  const checkboxLabel = (
    <div>
      {t('consent:checkbox')}
      <a href={`https://conf.app/files/${i18n.language.toUpperCase()}-consent.pdf`}>
        {t('consent:checkbox_end')}
      </a>
    </div>
  );

  return (
    <Page title={t('consent:title')}>
      <ConsentContainer>
        <Illustration src={TermsImage} />

        <TextContentContainer>
          <>
            {t('consent:description')
              .split('\n')
              .map(c => (
                <p> {c} </p>
              ))}
          </>
          <Title>
            {t('consent:terms')} &amp; {t('consent:privacy')}
          </Title>

          <Checkbox
            isChecked={checked}
            onChange={() => setChecked(!checked)}
            label={checkboxLabel}
            name="consent-checkbox"
          />

          <ButtonWrapper>
            <Button
              appearance="primary"
              size
              isDisabled={!checked}
              isLoading={loading}
              onClick={onConsent}
            >
              {t('consent:accept')}
            </Button>
          </ButtonWrapper>
        </TextContentContainer>
      </ConsentContainer>
    </Page>
  );
};

export default Consent;
