import types from '../types';
import ConfsService from '../../services/ConfsService';
import handleError from '../utils/errors';

export const checkDates = id => dispatch => {
  dispatch({
    type: types.CHECK_DATES_REQUEST
  });

  return Promise.all([
    ConfsService.firstDateCanBeChanged(id),
    ConfsService.lastDateCanBeChanged(id)
  ])
    .then(flags => {
      return dispatch({
        type: types.CHECK_DATES_SUCCESS,
        payload: {
          first: flags[0],
          last: flags[1]
        }
      });
    })
    .catch(err => handleError(types.CHECK_DATES_ERROR, err, dispatch));
};
