import React from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { useTranslation } from 'react-i18next';
import Button from '@atlaskit/button';
import AdaptiveModalFooter from '../adaptive-modal-footer';

// eslint-disable-next-line
export default ({ heading, content, isOpen, onClose, onDelete }) => {
  const { t } = useTranslation();
  return (
    <ModalTransition>
      {isOpen && (
        <Modal
          shouldScrollInViewport
          shouldCloseOnOverlayClick={false}
          onClose={onClose}
        >
          <ModalHeader>
            <ModalTitle appearance="danger">{heading}</ModalTitle>
          </ModalHeader>
          <ModalBody>{content}</ModalBody>
          <AdaptiveModalFooter>
            <Button appearance="danger" onClick={onDelete} autoFocus>
              {t('confs:actions:confirm_remove:confirm')}
            </Button>
            <Button appearance="subtle" style={{ marginLeft: '8px' }} onClick={onClose}>
              {t('common:cancel')}
            </Button>
          </AdaptiveModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};
