import React, { useEffect } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Page from '../../layout/page';
import ConfSelected from '../../../containers/conf-selected';
import ConfThemeForm from './conf-theme-form';
import {
  getTheme,
  saveTheme,
  setBackground,
  setColor,
  setFont,
  setLogo,
} from '../../../store/actions/theme';
import Wireframe from './wireframe';
import ConfProgress from '../progress/conf-progress';
import PageWrapper from '../../layout/page-wrapper';
import useMask from '../../../hooks/use-mask';
import { getMaskData } from '../../utils/get-mask-data';
import { MASK } from '../../../constants';
import { isThemeLoaded } from '../../../store/selectors/theme';
import { isLastProgressEntity } from '../../../store/selectors/progress';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 50px;

  @media (min-width: 640px) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`;

const Theme = ({ selectedConf }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showMask, resetMask } = useMask();

  const {
    color,
    logo,
    background,
    font,
    themeProgress,
    themeLoaded,
    isLastInProgress,
  } = useMappedState((state) => ({
    color: state.theme.color,
    font: state.theme.font,
    logo: state.theme.logo.url,
    background: state.theme.background.url,
    themeProgress: state.progress.entities.theme,
    themeLoaded: isThemeLoaded(state),
    isLastInProgress: isLastProgressEntity(state),
  }));

  useEffect(() => {
    if (themeLoaded && !themeProgress) {
      const maskData = getMaskData(MASK.CREATE_THEME, t);
      showMask(maskData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeProgress, themeLoaded]);

  useEffect(() => {
    if (selectedConf) {
      dispatch(getTheme(selectedConf.id));
    }
  }, [selectedConf]); // eslint-disable-line

  const onSave = () => {
    resetMask();
    dispatch(saveTheme()).then(() => {
      if (!themeProgress) {
        const text = isLastInProgress ? MASK.EVENT_READY : MASK.NEXT_STEP;
        const maskData = getMaskData(text, t);
        showMask(maskData);
      }
    });
  };

  return (
    <PageWrapper>
      <ConfProgress />
      <Wrapper>
        <Wireframe color={color} logo={logo} background={background} />
        <Page
          title={t('theme:title')}
          titleTooltip={t('theme:titleTooltip')}
          style={{ margin: '0 0 50px 0', flex: 1, minWidth: 288, width: '100%' }}
          showProgress={false}
        >
          <ConfThemeForm
            logo={logo}
            background={background}
            color={color}
            font={font}
            submitText={t('common:save')}
            helperText={''}
            onSetLogo={(url) => dispatch(setLogo(url))}
            onSetBackground={(url) => dispatch(setBackground(url))}
            onSetColor={(color) => dispatch(setColor(color))}
            onSetFont={(font) => dispatch(setFont(font))}
            onSave={onSave}
           />
        </Page>
      </Wrapper>
    </PageWrapper>
  );
};

export default compose(withRouter, ConfSelected)(Theme);
