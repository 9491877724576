import types from '../types';
import NotificationsService from '../../services/NotificationsService';
import handleError from '../utils/errors';

export const findAllNotifications = confId => dispatch => {
  dispatch({ type: types.FIND_ALL_NOTIFICATIONS_REQUEST });

  return NotificationsService.findAll(confId)
    .then(notifications => {
      return dispatch({
        type: types.FIND_ALL_NOTIFICATIONS_SUCCESS,
        payload: notifications
      });
    })
    .catch(e => handleError(types.FIND_ALL_NOTIFICATIONS_ERROR, e, dispatch));
};

export const sendNotification = (id) => dispatch => {
  dispatch({ type: types.SEND_NOTIFICATION_REQUEST });

  return NotificationsService.sendOne(id)
    .then(notification => {
      return dispatch({
        type: types.SEND_NOTIFICATION_SUCCESS,
        payload: notification
      });
    })
    .catch(e => handleError(types.SEND_NOTIFICATION_ERROR, e, dispatch));
};

export const createNotification = notification => dispatch => {
  dispatch({
    type: types.CREATE_NOTIFICATION_REQUEST
  });

  return NotificationsService.create(notification)
    .then(createdNotification => {

      return dispatch({
        type: types.CREATE_NOTIFICATION_SUCCESS,
        payload: createdNotification
      });
    })
    .catch(err => handleError(types.CREATE_NOTIFICATION_ERROR, err, dispatch));
};

export const updateNotification = notification => dispatch => {
  dispatch({
    type: types.UPDATE_NOTIFICATION_REQUEST
  });

  return NotificationsService.update(notification)
    .then(updatedNotification => {
      return dispatch({
        type: types.UPDATE_NOTIFICATION_SUCCESS,
        payload: updatedNotification
      });
    })
    .catch(err => handleError(types.UPDATE_NOTIFICATION_ERROR, err, dispatch));
};

export const deleteNotification = (id) => dispatch => {
  dispatch({
    type: types.DELETE_NOTIFICATION_REQUEST
  });

  return NotificationsService.delete(id)
    .then(() => {
      return dispatch({
        type: types.DELETE_NOTIFICATION_SUCCESS,
        payload: id
      });
    })
    .catch(err => handleError(types.DELETE_NOTIFICATION_ERROR, err, dispatch));
};
