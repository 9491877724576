import { createSelector } from 'reselect';

const entities = state => state.progress.entities;

export const isLastProgressEntity = createSelector(
  entities,
  entities => {
    const values = Object.values(entities);
    return values.filter(Boolean).length === values.length - 1;
  }
);
