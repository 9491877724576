// import '@atlaskit/css-reset';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import StoreProvider from './store/provider';
import TranslationProvider from './i18n/provider';
import { MaskProvider } from './contexts/mask-context';
import Router from './Router';
import Flags from './components/notifications/flags';
import UnderConstruction from './components/under-construction';
import { initEnvOverrides } from './envOverrides';
import './styles/index.css';
import MobileWarning from './components/utils/mobile-warning';

// polyfills:
import 'url-search-params-polyfill';

initEnvOverrides();

if (process.env.REACT_APP_MODE !== 'development') {
  Sentry.init({
    dsn: 'https://254f06525e93412cbecb8748c21d817b@sentry.io/1763453',
  });
}

const isUnderConstruction = process.env.REACT_APP_UNDER_CONSTRUCTION === 'true';

ReactDOM.render(
  <>
    {isUnderConstruction ? (
      <UnderConstruction />
    ) : (
      <StoreProvider>
        <TranslationProvider>
          <MaskProvider>
            <MobileWarning />
            <Flags />
            <Router />
          </MaskProvider>
        </TranslationProvider>
      </StoreProvider>
    )}
  </>,
  document.getElementById('root')
);
