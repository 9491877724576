import escapeHtml from 'escape-html';
import { Text } from 'slate';

export const serialize = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.code) {
      string = `<code>${string}</code>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    return string;
  }

  const children = node.children.map((n) => serialize(n)).join('');

  switch (node.type) {
    case 'title':
      return serializedNode('h1', children, [stylesLine([textAlignStyle(node.align)])]);
    case 'heading-one':
      return serializedNode('h2', children, [stylesLine([textAlignStyle(node.align)])]);
    case 'heading-two':
      return serializedNode('h3', children, [stylesLine([textAlignStyle(node.align)])]);
    case 'block-quote':
      return serializedNode('blockquote', children, [stylesLine([textAlignStyle(node.align)])]);
    case 'bulleted-list':
      return serializedNode('ul', children, [stylesLine([textAlignStyle(node.align)])]);
    case 'list-item':
      return serializedNode('li', children, [stylesLine([textAlignStyle(node.align)])]);
    case 'numbered-list':
      return serializedNode('ol', children, [stylesLine([textAlignStyle(node.align)])]);
    case 'paragraph':
      return serializedNode('p', children, [stylesLine([textAlignStyle(node.align)])]);
    case 'link':
      return serializedNode('a', children, [
        stylesLine([textAlignStyle(node.align)]),
        `href="${escapeHtml(node.url)}"`,
      ]);
    default:
      return children;
  }
};

const textAlignStyle = (align) => {
  if (!align) return '';
  return `text-align: ${align}`;
};

const stylesLine = (items) => {
  const valuableItems = items.filter(Boolean);
  if (!valuableItems.length) return '';
  return `style="${valuableItems.join('; ')}"`;
};

const propertiesLine = (items) => {
  const valuableItems = items.filter(Boolean);
  if (!valuableItems.length) return '';
  return ` ${valuableItems.join(' ')}`;
};

const serializedNode = (nodeName, children, properties) =>
  `<${nodeName}${propertiesLine(properties)}>${children}</${nodeName}>`;
