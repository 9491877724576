import { Transforms } from 'slate';
import { LIST_TYPES } from './constants';

export const withLayout = (editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    normalize(editor, node, path);
    return normalizeNode([node, path]);
  };

  return editor;
};

/**
 * Make sure the first element is a title
 *
 * TODO optimize
 * - read the documentation better,
 * - check which node is actually normilized,
 * - do not check the whole editor children
 * - etc
 */
const normalize = (editor, node, path) => {
  if (path.length !== 0) return;

  if (editor.children.length < 1) {
    const title = {
      type: 'title',
      children: [{ text: '' }],
    };
    Transforms.insertNodes(editor, title, { at: path.concat(0) });
    return;
  }

  // TODO it means another change is comming - so it will conflict - skip
  // Find out the source of issue? - Probably BlockButton node transform logic?
  if (editor.children[0].type === 'list-item') return;

  // ensure there is only one title
  Transforms.setNodes(
    editor,
    { type: 'paragraph' },
    { match: (n, p) => n.type === 'title' && p.length === 1 && p[0] > 0 }
  );

  // nothing to change - exit
  if (editor.children[0].type === 'title') return;

  // if it is a list
  if (LIST_TYPES.includes(editor.children[0].type)) {
    if (editor.children[0].children.length > 1) {
      Transforms.moveNodes(editor, {
        at: [0, 0],
        to: [0],
      });
    } else {
      Transforms.unwrapNodes(editor, {
        at: [0],
      });
    }
  }

  Transforms.setNodes(editor, { type: 'title' }, { at: [0] });

  return;
};
