import HttpService from './HttpService';

class ProductsService extends HttpService {
  constructor() {
    super(process.env.REACT_APP_SERVICES_URL);
  }

  findAll(confId) {
    return this.http
      .get('/conf-customer-plans', { params: { confId: confId } })
      .then(this.onResponse)
      .catch(this.onError);
  }

  checkout(params) {
    return this.http.post('/conf-checkout', params)
      .then(this.onResponse)
      .catch(this.onError);
  }
}

const productsService = new ProductsService();

export default productsService;
