import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const LogoLink = styled(NavLink)`
  padding-top: 20px;
  text-align: ${props => props.textalign};
  width: ${props => props.width};
`;

// eslint-disable-next-line
export default ({ className = '', textalign = 'center', width = '100%', height = 44 }) => {
  return (
    <LogoLink className={className} width={width} textalign={textalign} to={`/`} exact>
      <img src={process.env.REACT_APP_LOGO_URL} alt="logo" height={height} />
    </LogoLink>
  );
};
