import React from 'react';
import styled from 'styled-components';
import ProgressCard from './progress-card';

const ProgressCardsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => (props.confIsReady ? '30px' : '50px')};
  flex-wrap: wrap;
`;

const ProgressCardContainer = styled.div`
  width: ${props => props.width};
  min-width: 200px;
  margin-bottom: 20px;
`;

// eslint-disable-next-line
export default ({ steps, confIsReady }) => {
  return (
    <ProgressCardsContainer confIsReady={confIsReady}>
      {steps.map((step, i) => {
        return (
          <ProgressCardContainer key={i} width={`${100 / steps.length}%`}>
            <ProgressCard key={i} isFirst={i === 0} isLast={i === steps.length - 1} {...step} />
          </ProgressCardContainer>
        );
      })}
    </ProgressCardsContainer>
  );
};
