import React, {useMemo} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from '@atlaskit/button';
import { find } from 'lodash/fp';
import { FEATURES_LIMITS_MAP } from '../../../constants';
import { isNil } from 'lodash';

const Warning = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 210px;
  margin-bottom: 30px;
  padding: 25px 25px;
  border-radius: 10px;
  background-color: var(--orange-1); 
  border: 2px solid var(--orange-3);
  font-size: 0.9em;
  color: var(--orange-3);
  text-align: center;
  
  @media (min-width: 480px) {
    max-width: 280px;
  }
  
  @media (min-width: 960px) {
    margin-left: 20px;  
  }
`;

const Title = styled.p`
  margin: 0 0 15px;
  font-weight: 500;
`;

const Text = styled.p`
  margin: 0 0 2px;
`;

const TextBold = styled.span`
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  margin-top: auto;
  padding-top: 15px;
`;

// eslint-disable-next-line
export default ({limits, suggestions, plans, onPlanChoose, hasPaymentMethod, isConfUpgradable}) => {
  const { t } = useTranslation();
  const suggestedPlan = useMemo(() => find({id: suggestions.plan})(plans) || {}, [plans, suggestions]);

  return (
    <Warning>
      <Title>{t('limits:warning')}</Title>
      {Object.values(FEATURES_LIMITS_MAP)
        .filter(item => !isNil(suggestions[item]))
        .map(item => {
          return (
            <Text key={item}>
              <TextBold>{t(`limits:${item}`, { count: suggestions[item]})}</TextBold>
              <span>{t('limits:available', { count: limits[item]})}</span>
            </Text>
          );
        })
      }

      <ButtonWrapper
        {...(!hasPaymentMethod
          ? { 'data-tip': t('billing:payment_method.please_add') }
          : {})
        }
      >
        <Button
          appearance="primary"
          onClick={() => onPlanChoose(suggestedPlan)}
          isDisabled={!hasPaymentMethod || !isConfUpgradable}
        >
          {t('conf_plans:choose', { plan: suggestedPlan.name })}
        </Button>
      </ButtonWrapper>
    </Warning>
  );
};
