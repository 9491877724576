import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@atlaskit/button';
import { __RouterContext } from 'react-router';
import styled from 'styled-components';
import NotificationImage from '../../../assets/undraw_notification.svg';

const Illustration = styled.img`
  width: 200px;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
`;

const Desc = styled.div`
  color: var(--gray-2);
  margin-bottom: 20px;
`;

// eslint-disable-next-line
export default ({ selectedConfId }) => {
  const router = useContext(__RouterContext);
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: 'center', marginTop: '30px' }}>
      <Illustration src={NotificationImage} />
      <Title>{t(`notifications:out_of:title`)}</Title>
      <Desc>{t(`notifications:out_of:desc`)}</Desc>
      <Button
        appearance="primary"
        onClick={() => {
          router.history.push(`/conf/${selectedConfId}/upgrade`);
        }}
      >
        {t('plans:modal:accept')}
      </Button>
    </div>
  );
};
