import React from 'react';
import styled from 'styled-components';
import { ModalFooter } from '@atlaskit/modal-dialog';

const ButtonsWrapper = styled.div`
  @media (max-width: 478px) {
    display: flex;
    justify-content: center;
    width: 100%;    
  }
`;

const AdaptiveModalFooter = ({ children }) => {
  return (
    <ModalFooter>
      <ButtonsWrapper>
        {children}
      </ButtonsWrapper>
    </ModalFooter>
  );
};

export default AdaptiveModalFooter;
