import types from '../types';
import { requestStatus } from '../request-status';
import { startOfDay, isEqual } from 'date-fns/esm';
import { getSpeakersData, getZonedSessionData } from '../../services/SessionsFiltersService';

const initialState = {
  list: [],
  selectedDay: null,
  selectedRooms: [],
  selectedSpeakers: [],
  status: requestStatus.UNDONE,
  error: ''
};

const sessions = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_SESSION_REQUEST:
    case types.FIND_ALL_SESSIONS_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: ''
      };

    case types.FIND_ALL_SESSIONS_SUCCESS:
      const data = action.payload;

      return {
        ...state,
        // TODO remove getSpeakersData when api upgrade to 16 is done
        list: Array.isArray(data) ? data.map(getZonedSessionData).map(getSpeakersData) : [],
        status: requestStatus.DONE,
        error: ''
      };

    case types.UPDATE_SESSION_SUCCESS:
      return {
        ...state,
        status: requestStatus.DONE
      };

    case types.UPDATE_SESSION_ERROR:
    case types.FIND_ALL_SESSIONS_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload
      };

    case types.DELETE_SESSION:
      const list = state.list.filter(session => session.id !== action.payload);
      return {
        ...state,
        list,
        // Reselect day if there's no more sessions during selected day
        selectedDay: list.find(session =>
          isEqual(startOfDay(new Date(session.from)), state.selectedDay)
        )
          ? state.selectedDay
          : null
      };

    case types.SELECT_DAY:
      return {
        ...state,
        selectedDay: action.payload
      };

    case types.SELECT_ROOMS:
      return {
        ...state,
        selectedRooms: action.payload
      };

    case types.SELECT_SPEAKERS:
      return {
        ...state,
        selectedSpeakers: action.payload
      };

    case types.RESET_FILTERS:
      return {
        ...state,
        selectedDay: null,
        selectedRooms: [],
        selectedSpeakers: []
      };

    default:
      return state;
  }
};

export default sessions;
