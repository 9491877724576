import React, { useState } from 'react';
import styled from 'styled-components';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import DeleteModal from './modals/delete-modal';

const Container = styled.div`
  position: relative;
`;

const DeleteButton = styled.div`
  display: none;
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: var(--red-1);
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  ${Container}:hover & {
    display: block;
  }
`;

// eslint-disable-next-line
export default ({ children, modalHeading, modalContent, onDelete, noButton }) => {
  const [deleteModalIsOpen, setDeleteModalOpen] = useState(false);

  const handleDelete = () => {
    onDelete();
    setDeleteModalOpen(false);
  }

  return (
    <Container>
      {!noButton && (
        <DeleteButton onClick={() => setDeleteModalOpen(true)}>
          <TrashIcon label="trash-icon" primaryColor="var(--red-2)" />
        </DeleteButton>
      )}
      <DeleteModal
        isOpen={deleteModalIsOpen}
        heading={modalHeading}
        content={modalContent}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={handleDelete}
      />
      <div onClick={noButton ? () => setDeleteModalOpen(true) : null}>{children}</div>
    </Container>
  );
};
