import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Badge from '../layout/badge';
import Info from '../layout/info';

const Wrapper = styled.div`
  width: 100%;
`;

const PlanBadge = styled(Badge)`
  margin: 0 5px;
`;

const Text = styled.span`
  font-weight: 500;
  margin-left: 3px;
`;

// eslint-disable-next-line
export default ({name, planId, limit, used}) => {
  const { t } = useTranslation();
  const left = limit - used;
  return (
    <Wrapper>
      <Info>
        <span>{t('plan_info:your')}</span>
        <PlanBadge>{t(`conf_plans:${planId}`)}</PlanBadge>
        <span>{t('plan_info:provides')}</span>
        {limit !== -1
          ? <>
            <span>{t(`plan_info:${name}`, { count: limit })}</span>
            <Text>{t(`plan_info:left`, { count: left > 0 ? left : 0})}</Text>
          </>
          : <span>{t(`plan_info:${name}_unlimited`)}</span>
        }
      </Info>
    </Wrapper>
  );
};
