import React from 'react';
import { NavLink } from 'react-router-dom';
import LockIcon from '@atlaskit/icon/glyph/lock-filled';
import Tooltip from 'react-tooltip';
import Button from '@atlaskit/button';
import styled from 'styled-components';
import FontAwesomeIcon from '../utils/icons/font-awesome-icon';
import WarningIcon from '@atlaskit/icon/glyph/warning';

const NavLinkContainer = styled.div`
  position: relative;
  margin: 12px 0 12px
    ${props => (props.unattainable ? '73px' : props.isMobileAccessLink ? '68px' : '80px')};
`;

const NavLinkStyle = isUnattainable => ({
  fontWeight: 500,
  pointerEvents: isUnattainable ? 'none' : 'all',
  color: isUnattainable ? 'var(--gray-2)' : 'var(--gray-3)',
  display: 'flex',
  alignItems: 'center',
  height: '26px'
});

const NavLinkBadge = styled('div')`
  margin-left: 4px;
  color:  var(--orange-3);
`;

export const NavItemStyle = { fontWeight: 500, color: 'var(--black)' };
export const NavLinkActiveStyle = { fontWeight: 500, color: 'var(--blue-2)' };

// eslint-disable-next-line
export default ({ t, selectedConf, isVerified, onLinkClick, isProgramWarning }) => {
  const links = [
    { title: 'sidebar:dashboard', to: `/conf/${selectedConf.id}`, className: '' },
    {
      title: 'sidebar:theme',
      to: `/conf/${selectedConf.id}/theme`,
      className: 'onboarding-link-theme'
    },
    {
      title: 'sidebar:rooms',
      to: `/conf/${selectedConf.id}/rooms`,
      className: 'onboarding-link-rooms'
    },
    {
      title: 'sidebar:speakers',
      to: `/conf/${selectedConf.id}/speakers`,
      className: 'onboarding-link-speakers'
    },
    {
      title: 'sidebar:program',
      to: `/conf/${selectedConf.id}/program`,
      className: 'onboarding-link-program',
      badge: <NavLinkBadge><WarningIcon /></NavLinkBadge>
    },
    {
      title: 'sidebar:links',
      to: `/conf/${selectedConf.id}/links`,
      className: 'onboarding-link-links'
    },
    {
      title: 'sidebar:notifications',
      to: `/conf/${selectedConf.id}/notifications`,
      className: 'onboarding-link-notifications'
    },
    {
      title: 'sidebar:upgrade',
      to: `/conf/${selectedConf.id}/upgrade`,
      className: 'onboarding-link-upgrade'
    },
    {
      title: 'sidebar:advanced_settings',
      to: `/conf/${selectedConf.id}/advanced-settings`,
    },
    {
      title: 'sidebar:access',
      to: `/conf/${selectedConf.id}/access`,
      className: 'onboarding-link-access'
    }
  ];

  const isUnattainable = title =>
    title !== 'sidebar:dashboard' && title !== 'sidebar:access' && !isVerified;

  const customProps = title =>
    isUnattainable(title)
      ? {
          'data-tip': t('sidebar:unauthorized'),
          style: {
            cursor: 'not-allowed'
          }
        }
      : {};

  return (
    <div>
      {links.map(({ title, to, className, badge, externalLink }) => {
        const unattainable = isUnattainable(title);
        const isMobileAccessLink = to === `/conf/${selectedConf.id}/access`;
        return (
          <NavLinkContainer
            key={title}
            {...customProps(title)}
            unattainable={unattainable}
            isMobileAccessLink={isMobileAccessLink}
          >
            <NavLink
              to={externalLink ? {pathname: to} : to}
              {...(externalLink && { target: '_blank' })}
              exact
              style={NavLinkStyle(unattainable)}
              activeStyle={NavLinkActiveStyle}
              className={className}
              onClick={onLinkClick}
            >
              {unattainable && <LockIcon />}
              {isMobileAccessLink ? (
                <div style={{ marginTop: 20 }}>
                  <Button appearance="primary">
                    {t(title)}
                    <FontAwesomeIcon icon={'mobile-alt'} size={'1x'} style={{ marginLeft: 10 }} />
                  </Button>
                </div>
              ) : (
                t(title)
              )}
              {isProgramWarning && badge}
            </NavLink>
            <Tooltip />
          </NavLinkContainer>
        );
      })}
    </div>
  );
};
