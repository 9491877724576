import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@atlaskit/button';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import Popover from 'react-popover';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { TimeCmp } from '../../utils/time';

import {
  getOutOfEventDays,
  getOverlappingDate,
  getOverlappingSessions,
  getOverlappingSessionsByRoom,
  getOverlappingSessionsBySpeakers,
  getOverlappingSessionsForSession,
  getOverlappingSpeakers,
  isDayOutOfRange
} from '../../../services/SessionsFiltersService';
import Card from '../../layout/card';
import { format } from '../../../services/DatesService';

const Text = styled.div`
  color: var(--gray-3);
  display: flex;
  align-items: center;
  font-size: 15px;
`;

// eslint-disable-next-line
export default ({ isOpen, setOpen, sessions, session, selectedConf, sessionsDays, timeFormat, render, }) => {
  const { t } = useTranslation();

  const PopoverWrapper = content => (
    <Popover
      isOpen={isOpen}
      preferPlace={'right'}
      body={<Card style={{ margin: 3, padding: 15 }}>{content}</Card>}
      onOuterAction={() => setOpen(false)}
    >
      {render
        ? render(() => setOpen(!isOpen))
        : <div style={{ marginRight: 15 }}>
            <Button
              appearance="warning"
              iconBefore={
                <WarningIcon
                  label="warn-icon"
                  primaryColor="#fff"
                  secondaryColor="var(--orange-3)"
                />
              }
              onClick={() => setOpen(!isOpen)}
            />
          </div>
      }
    </Popover>
  );

  if (!session) {
    const overlappingSessions = getOverlappingSessions(sessions);
    const outOfEventDays = sessionsDays && getOutOfEventDays(sessionsDays, selectedConf.from, selectedConf.to);

    if (isEmpty(overlappingSessions) && isEmpty(outOfEventDays)) {
      return null;
    }

    return PopoverWrapper(
      <>
        {overlappingSessions.map(sessions => (
          <p key={sessions.map(session => session.id).join('')}>
            {t('sessions:conflicts', { name: sessions.map(session => session.name).join(', ') })}
          </p>
        ))}
        {outOfEventDays && (
          outOfEventDays.map(day => (
            <p key={day.getTime()}>
              {t('sessions:form:errors:sessions_is_out_of_event', { date: format(day, 'dd MMM') })}
            </p>
          ))
        )}
      </>
    );
  }
  const overlappingSessions = getOverlappingSessionsForSession(sessions, session);
  const overlappingSessionsByRoom = getOverlappingSessionsByRoom(
    overlappingSessions,
    session.room_id
  );
  const overlappingSessionsBySpeakers = getOverlappingSessionsBySpeakers(
    overlappingSessions,
    session.speakers.map(speaker => speaker.id)
  );

  const isSessionOutOfEvent = isDayOutOfRange(new Date(session.from), selectedConf.from, selectedConf.to);

  if (isEmpty(overlappingSessionsByRoom) && isEmpty(overlappingSessionsBySpeakers) && !isSessionOutOfEvent) {
    return null;
  }
  return PopoverWrapper(
    <>
      {!isEmpty(overlappingSessionsByRoom) && (
        <Text>
          <TimeCmp {...getOverlappingDate(overlappingSessionsByRoom)} timeFormat={timeFormat} />
          {t('sessions:form:errors:room_occupied', {
            room: overlappingSessionsByRoom[0].room.name,
            name: overlappingSessionsByRoom.map(session => session.name).join(', ')
          })}
        </Text>
      )}
      {!isEmpty(overlappingSessionsBySpeakers) && (
        <>
          {!isEmpty(overlappingSessionsByRoom) && <hr />}
          <Text>
            <TimeCmp
              {...getOverlappingDate(overlappingSessionsBySpeakers)}
              timeFormat={timeFormat}
            />
            {t('sessions:form:errors:speaker_occupied', {
              count: getOverlappingSpeakers(overlappingSessionsBySpeakers).length,
              speakers: getOverlappingSpeakers(overlappingSessionsBySpeakers).join(', '),
              name: overlappingSessionsBySpeakers.map(session => session.name).join(', ')
            })}
          </Text>
        </>
      )}
      {isSessionOutOfEvent && (
        <Text>
          <span style={{ padding: '5px 10px' }}>{t('sessions:form:errors:session_is_out_of_event')}</span>
        </Text>
      )}
    </>
  );
};
