import React from 'react';
import styled from 'styled-components';
import FontAwesomeIcon from '../utils/icons/font-awesome-icon';

const Stepper = styled.div`
  position: relative;
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
`;

const StepWrapper = styled.div`
  position: relative;
  display: table-cell;
  text-align: center;
  padding: 0.5rem;

  :after {
    content: ' ';
    position: absolute;
    left: 50%;
    top: 1.75rem;
    width: 100%;
    height: 0.125rem;
    background-color: ${props => (props.complete ? '#58be40' : '#e3e8ec')};
    z-index: 1;
  }

  :last-child:after {
    display: none;
  }
`;
const StepIndicator = styled.div`
  position: relative;
  display: block;
  z-index: 2;
`;

const StepLabel = styled.div`
  position: relative;
  display: block;
  margin: 0.5rem 0;
  color: #cfd7de;
  z-index: 2;
`;

const StepInfo = styled.div`
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid ${props => (props.complete ? '#58be40' : '#e3e8ec')};
  border-radius: 50%;
  background-color: ${props => (props.complete || props.inprogress ? '#58be40' : '#e3e8ec')};
  font-size: 1.25rem;
  line-height: 2.5rem;
  text-align: center;
  color: #fff;
  z-index: 2;
  animation: ${props => (props.animated ? 'pulse 2s infinite' : '')};
  overflow: hidden;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(53, 139, 241, 0.4);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(53, 139, 241, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(53, 139, 241, 0);
    }
  }
`;

const Wave = styled.div`
  width: 8rem;
  height: 8rem;
  position: absolute;
  left: -100%;
  bottom: 50%;
  border-radius: 40%;
  background: rgba(227, 232, 236, 0.7);
  -webkit-animation: wave 15s infinite linear;
  animation: wave 15s infinite linear;

  @keyframes wave {
    from {
      transform: rotate(0deg);
    }
    from {
      transform: rotate(360deg);
    }
  }
`;

const Step = ({ title, icon, inprogress, complete, animated }) => (
  <StepWrapper complete={complete}>
    <StepIndicator>
      <StepInfo inprogress={inprogress} complete={complete} animated={animated}>
        <FontAwesomeIcon icon={icon} size={'1x'} />
        {inprogress && <Wave />}
      </StepInfo>
    </StepIndicator>
    <StepLabel>{title}</StepLabel>
  </StepWrapper>
);

// eslint-disable-next-line
export default () => {
  return (
    <>
      <Stepper>
        <Step icon="check" complete={true} />
        <Step icon="paint-brush" inprogress={true} />
        <Step icon="user" animated={true} />
        <Step icon="mobile" />
      </Stepper>
    </>
  );
};
