import styled from 'styled-components';

const Tip = styled.p`
  margin-top: 3px;
  margin-bottom: 0;
  font-style: italic;
  font-size: 12px;
  font-weight: 300;
  color: var(--gray-3);
`;

export default Tip;
