import { merge } from 'lodash/fp';

import types from '../types';
import { requestStatus } from '../request-status';

const initialState = {
  fetchStatus: requestStatus.UNDONE,
  saveStatus: requestStatus.UNDONE,
  createStatus: requestStatus.UNDONE,
  error: null,
  value: {
    conf: {},
    theme: {
      logo: process.env.REACT_APP_LOGO_URL,
      color: '#358bf1'
    }
  }
};

const wizard = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WIZARD_SUCCESS:
      return {
        ...state,
        fetchStatus: requestStatus.DONE,
        value: merge(state.value, action.payload)
      };

    case types.SAVE_WIZARD_SUCCESS:
      return {
        ...state,
        saveStatus: requestStatus.DONE,
        value: action.payload
      };

    case types.CREATE_WIZARD_REQUEST:
      return {
        ...state,
        createStatus: requestStatus.WAITING
      };

    case types.CREATE_WIZARD_SUCCESS:
      return {
        ...initialState,
        error: null,
        createStatus: requestStatus.DONE
      };

    case types.CREATE_WIZARD_ERROR:
      return {
        ...initialState,
        createStatus: requestStatus.DONE,
        error: action.payload
      };

    default:
      return state;
  }
};

export default wizard;
