import styled from 'styled-components';

export default styled.span`
  padding: 6px;
  text-align: center;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 1;  
  text-transform: uppercase;
  color: #fff;
  background-color: var(--blue-2);
`;


