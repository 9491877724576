import React, { useState } from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { useTranslation } from 'react-i18next';
import Button from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import styled from 'styled-components';
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel';
import Tooltip from 'react-tooltip';
import AdaptiveModalFooter from '../../utils/adaptive-modal-footer';

const Checkboxes = styled.div`
  & label {
    font-family: inherit;
  }
`;

const DuplicateEventConfirm = ({ open, onConfirm, onClose }) => {
  const { t } = useTranslation();
  const [roomsChecked, setRoomsChecked] = useState(true);
  const [speakersChecked, setSpeakersChecked] = useState(true);
  const [miniAppsChecked, setMiniAppsChecked] = useState(true);
  const [sessionsChecked, setSessionsChecked] = useState(true);

  const onConfirmClick = () => {
    onConfirm(roomsChecked, speakersChecked, miniAppsChecked, sessionsChecked);
    onClose();
  };

  return (
    <>
      <ModalTransition>
        {open && (
          <Modal
            shouldScrollInViewport
            shouldCloseOnOverlayClick={false}
            onClose={onClose}
          >
            <ModalHeader>
              <ModalTitle>{t('confs:actions:confirm_duplicate:question')}</ModalTitle>
            </ModalHeader>
            <Tooltip />
            <ModalBody>
              <div style={{ marginBottom: '10px' }}>{t('confs:actions:confirm_duplicate:body')}</div>
              <Checkboxes>
                <Checkbox
                  size="large"
                  isChecked={roomsChecked}
                  label={t('sidebar:rooms')}
                  onChange={() => setRoomsChecked(!roomsChecked)}
                />
                <Checkbox
                  size="large"
                  isChecked={speakersChecked}
                  label={t('sidebar:speakers')}
                  onChange={() => setSpeakersChecked(!speakersChecked)}
                />
                <Checkbox
                  size="large"
                  isChecked={miniAppsChecked}
                  label={t('sidebar:links')}
                  onChange={() => setMiniAppsChecked(!miniAppsChecked)}
                />
                <Checkbox
                  size="large"
                  isChecked={sessionsChecked}
                  label={
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      {t('sidebar:program')}
                      <span
                        data-tip={t('confs:actions:confirm_duplicate:session_warning')}
                        style={{
                          position: 'relative',
                          top: '-3px'
                        }}>
                        <EditorPanelIcon label="Info icon" primaryColor="grey" />
                      </span>
                    </span>
                  }
                  onChange={() => setSessionsChecked(!sessionsChecked)}
                />
              </Checkboxes>
            </ModalBody>
            <AdaptiveModalFooter>
              <Button appearance="primary" onClick={onConfirmClick} autoFocus>
                {t('confs:actions:confirm_duplicate:yes')}
              </Button>
              <Button appearance="subtle" style={{ marginLeft: '8px' }} onClick={onClose}>
                {t('common:cancel')}
              </Button>
            </AdaptiveModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default DuplicateEventConfirm;
