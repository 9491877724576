const notifications = {
  FIND_ALL_NOTIFICATIONS_REQUEST: 'FIND_ALL_NOTIFICATIONS_REQUEST',
  FIND_ALL_NOTIFICATIONS_SUCCESS: 'FIND_ALL_NOTIFICATIONS_SUCCESS',
  FIND_ALL_NOTIFICATIONS_ERROR: 'FIND_ALL_NOTIFICATIONS_ERROR',

  SEND_NOTIFICATION_REQUEST: 'SEND_NOTIFICATION_REQUEST',
  SEND_NOTIFICATION_SUCCESS: 'SEND_NOTIFICATION_SUCCESS',
  SEND_NOTIFICATION_ERROR: 'SEND_NOTIFICATION_ERROR',

  CREATE_NOTIFICATION_REQUEST: 'CREATE_NOTIFICATION_REQUEST',
  CREATE_NOTIFICATION_SUCCESS: 'CREATE_NOTIFICATION_SUCCESS',
  CREATE_NOTIFICATION_ERROR: 'CREATE_NOTIFICATION_ERROR',

  UPDATE_NOTIFICATION_REQUEST: 'UPDATE_NOTIFICATION_REQUEST',
  UPDATE_NOTIFICATION_SUCCESS: 'UPDATE_NOTIFICATION_SUCCESS',
  UPDATE_NOTIFICATION_ERROR: 'UPDATE_NOTIFICATION_ERROR',

  DELETE_NOTIFICATION_REQUEST: 'DELETE_NOTIFICATION_REQUEST',
  DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
  DELETE_NOTIFICATION_ERROR: 'DELETE_NOTIFICATION_ERROR',
};

export default notifications;
