import types from '../types';

const initialState = {
  entities: {
    rooms: false,
    theme: false,
    speakers: false,
    sessions: false,
    links: false
  }
};

const progress = (state = initialState, action) => {
  switch (action.type) {
    case types.FIND_PROGRESS_SUCCESS:
      return {
        ...state,
        entities: action.payload
      };

    case types.UPDATE_ENTITY_PROGRESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload
        }
      };

    default:
      return state;
  }
};

export default progress;