import React, { useState } from 'react';

import { SketchPicker } from 'react-color';
import Button from '@atlaskit/button';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DefaultEventBackground from '../../../assets/default-event-background.png';
import { ImageUploader } from '../../upload/image-uploader';
import EditLabel from '../../utils/edit-label';
import { ConfThemeFont } from './conf-theme-font';
import Tip from '../../utils/tip';
import { getMaskClass } from '../../utils/get-mask-data';
import { MASK } from '../../../constants';

const ThemeFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
`;

const ColorBox = styled.div`
  padding: 6px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const Color = styled.div`
  width: 60px;
  height: 40px;
  border-radius: 3px;
  background: ${(props) => props.color};
`;

const PopOver = styled.div`
  position: absolute;
  z-index: 2;
`;

const Cover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 15px;
  text-align: center;
`;

const ParameterName = styled.h3`
  margin: 8px 0;
  width: 140px;
`;

const ParameterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;
`;

const Helper = styled(Tip)`
  margin-top: 10px;
`;

const ImageUploaderContainer = styled.div`
  max-width: 200px;
  padding: 6px;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
`;

// eslint-disable-next-line
export default ({
  logo,
  background,
  color,
  font,
  helperText,
  submitText,
  onSetLogo,
  onSetBackground,
  onSetColor,
  onSetFont,
  onSave,
  base64 = false,
}) => {
  const { t } = useTranslation();
  const [colorPickerIsShown, showColorPicker] = useState(false);
  return (
    <ThemeFormWrapper>
      <ParameterWrapper>
        <ParameterName>{t('theme:logo')}</ParameterName>
        <ImageUploaderContainer>
          <ImageUploader
            base64={base64}
            value={logo}
            style={{ display: 'flex' }}
            editLabelStyle={{ right: '-36px', top: '-14px' }}
            onChange={(url) => onSetLogo(url)}
          />
        </ImageUploaderContainer>
      </ParameterWrapper>
      <ParameterWrapper>
        <ParameterName>{t('theme:color')}</ParameterName>
        <div style={{ minWidth: 212, marginBottom: '-5px' }}>
          <EditLabel onLabelClick={() => showColorPicker(true)} labelStyle={{ right: '-30px' }}>
            <ColorBox onClick={() => showColorPicker(true)}>
              <Color color={color} />
            </ColorBox>
            {colorPickerIsShown && (
              <PopOver>
                <Cover onClick={() => showColorPicker(false)} />
                <SketchPicker color={color} onChange={(color) => onSetColor(color.hex)} />
              </PopOver>
            )}
          </EditLabel>
        </div>
      </ParameterWrapper>
      <ParameterWrapper>
        <ParameterName>{t('theme:font')}</ParameterName>
        <div style={{ minWidth: 212 }}>
          <ConfThemeFont onChange={(font) => onSetFont(font)} value={font} />
        </div>
      </ParameterWrapper>
      <ParameterWrapper>
        <ParameterName>{t('theme:background')}</ParameterName>
        <ImageUploaderContainer>
          <ImageUploader
            base64={base64}
            value={background || DefaultEventBackground}
            style={{ display: 'flex' }}
            editLabelStyle={{ right: '-36px', top: '-14px' }}
            clearLabelStyle={{ right: '-36px', top: '14px' }}
            onChange={(url) => onSetBackground(url)}
            maxSize={1500} // px
          />
        </ImageUploaderContainer>
      </ParameterWrapper>
      <ButtonWrapper>
        <Button appearance="primary" onClick={onSave} className={getMaskClass(MASK.CREATE_THEME)}>
          {submitText}
        </Button>
        {helperText && <Helper>{helperText}</Helper>}
      </ButtonWrapper>
    </ThemeFormWrapper>
  );
};
