import { chunk, includes } from 'lodash/fp';
import icons from './icons';

const DEFAULT_LANGUAGE = 'en';

export const getChunkedIconListFiltered = (chunkSize, pattern, lang) => {
  // https://stackoverflow.com/a/37511463/4134756
  const patternWithouAccents = pattern.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const filteredIcons = icons.filter(icon => {
    // we want to always match the english icons since those
    // are the real icons names on fontawesome
    return (
      includes(patternWithouAccents)(icon[DEFAULT_LANGUAGE]) ||
      includes(patternWithouAccents)(icon[lang])
    );
  });
  const rawIcons = filteredIcons.map(icon => icon[DEFAULT_LANGUAGE]);
  return chunk(chunkSize, rawIcons);
};
