import React, { useState, useEffect } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { isEmpty } from 'lodash/fp';
import Button from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/glyph/add';
import Spinner from '@atlaskit/spinner';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import Page from '../layout/page';
import { createConf, unSelectConf, findAllConfs } from '../../store/actions/confs';
import { requestStatus } from '../../store/request-status';
import { isWizardFetched, isWizardCreating, isWizardCreated } from '../../store/selectors/wizard';
import { getWizard, createWizard } from '../../store/actions/wizard';
import { resetFilters } from '../../store/actions/sessions';
import ConfCreateForm from './conf-create';
import ConfList from './list/conf-list';
import ConfFeatured from './conf-featured';
import CreateModal from '../utils/entities/entity-form';
import ConfsService from '../../services/ConfsService';
import EmptyConfs from '../layout/empty';
import { getUtcFromZonedTime } from '../../services/DatesService';
import DataLayer from '../../services/DataLayer';

const CreateConfButton = ({ onClick, margin = 0, first = false }) => {
  const { t } = useTranslation();
  return (
    <Button
      style={{ margin: `${margin}px` }}
      iconBefore={<AddIcon size="small" label="add icon" />}
      appearance="primary"
      onClick={() => onClick(true)}
    >
      {first ? t('confs:home:empty:create') : t('confs:create:title')}
    </Button>
  );
};

const ConfListWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
`;

const ConfHome = ({ history }) => {
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    list,
    status,
    wizardIsFetched,
    wizardIsCreating,
    wizardIsCreated,
    wizard
  } = useMappedState(state => ({
    list: state.confs.list,
    status: state.confs.status,
    wizardIsFetched: isWizardFetched(state),
    wizardIsCreating: isWizardCreating(state),
    wizardIsCreated: isWizardCreated(state),
    wizard: state.wizard.value
  }));

  useEffect(() => {
    dispatch(unSelectConf());
    dispatch(resetFilters());
    dispatch(findAllConfs());
    if (wizardIsCreated) {
      return;
    }
    if (!wizardIsFetched) {
      dispatch(getWizard());
      return;
    }
    if (ConfsService.isCreatable(wizard.conf) && !wizardIsCreating) {
      dispatch(createWizard(t, wizard)).then(createdConf => {
        DataLayer.emitWizardCompletedEvent();

        return history.push(`/conf/${createdConf.id}`);
      });
    }
  }, [dispatch, wizardIsFetched, wizardIsCreating, wizardIsCreated, wizard, t, history]);

  const Confs = () => {
    if (status !== requestStatus.DONE || wizardIsCreating) {
      return <Spinner size="large" />;
    }
    if (status === requestStatus.DONE && isEmpty(list)) {
      return (
        <EmptyConfs
          entityProps={{
            primaryAction: <CreateConfButton onClick={() => setOpen(true)} first={true}/>
          }}
          entityName="confs:home"
          showLogo={false}
          showArrow={false}
          withMargin
        />
      );
    }
    return (
      <>
        <ConfFeatured list={list} />
        <Page
          title={t('confs:title')}
          addChildren={!isEmpty(list) && <CreateConfButton onClick={() => setOpen(true)} />}
          isSub
        >
          <ConfList list={list} />
        </Page>
      </>
    );
  };

  return (
    <ConfListWrapper>
      <CreateModal
        DisplayForm={ConfCreateForm}
        open={isOpen}
        onSubmit={payload => {
          setOpen(false);
          const timezone = payload.timezone.value;
          const conf = {
            ...payload,
            name: payload.name.trim(),
            from: getUtcFromZonedTime(payload.from, timezone),
            to: getUtcFromZonedTime(payload.to, timezone),
            timezone: timezone
          };
          return dispatch(createConf(conf)).then(({ payload: { id } }) => {
            history.push(`/conf/${id}`);
          });
        }}
        onCancel={() => setOpen(false)}
        entityName={'conf'}
      />
      <Confs />
    </ConfListWrapper>
  );
};

export default withRouter(ConfHome);
