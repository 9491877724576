import React from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import { useTranslation } from 'react-i18next';
import { useMappedState, useDispatch } from 'redux-react-hook';
import { sendEmailVerification } from '../../store/actions/account';
import { isSendingEmail, isEmailSent } from '../../store/selectors/account';
import EditorDoneIcon from '@atlaskit/icon/glyph/editor/done';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';

const EmailWarningContainer = styled.div`
  font-size: smaller;
  font-style: italic;
  background-color: var(--orange-1);
  color: var(--orange-3);
  border-top: 1px solid var(--orange-2);
  border-bottom: 1px solid var(--orange-2);
`;

const EmailWarning = styled.div`
  padding: 6px 16px;
`;

const SendStatusContainer = styled.span`
  vertical-align: -5px;
`;

const EmailVerification = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading, isDone, isErrored } = useMappedState(state => ({
    isLoading: isSendingEmail(state),
    isDone: isEmailSent(state),
    isErrored: state.account.emailVerification.errored
  }));
  return (
    <EmailWarningContainer>
      <EmailWarning>
        <span>{t('auth:email_not_verified')}</span>
        {isDone ? (
          <SendStatusContainer>
            {isErrored ? (
              <EditorCloseIcon primaryColor="#df4841" />
            ) : (
              <EditorDoneIcon primaryColor="#58be40" />
            )}
          </SendStatusContainer>
        ) : (
          <Button
            appearance="link"
            onClick={() => dispatch(sendEmailVerification())}
            isLoading={isLoading}
            style={{ fontSize: 'smaller' }}
          >
            {t('auth:resend_verification_email')}
          </Button>
        )}
      </EmailWarning>
    </EmailWarningContainer>
  );
};

export default EmailVerification;
