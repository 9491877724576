const products = {
  ADD_PRODUCTS_ERROR: 'ADD_PRODUCTS_ERROR',
  REMOVE_PRODUCT_ERROR: 'REMOVE_PRODUCT_ERROR',

  FIND_ALL_PRODUCTS_REQUEST: 'FIND_ALL_PRODUCTS_REQUEST',
  FIND_ALL_PRODUCTS_SUCCESS: 'FIND_ALL_PRODUCTS_SUCCESS',
  FIND_ALL_PRODUCTS_ERROR: 'FIND_ALL_PRODUCTS_ERROR'
};

export default products;
