import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ReadyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px 0 10px 0;
`;

const ReadyTitle = styled.div`
  font-size: 32px;
  font-weight: 500;
`;

const ReadyDesc = styled.div`
  color: var(--gray-2);
  margin-top: 5px;
`;

// eslint-disable-next-line
export default ({ selectedConf }) => {
  const { t } = useTranslation();
  return (
    <ReadyContainer>
      <ReadyTitle>{t('confs:home:conf_ready:title')}</ReadyTitle>
      <ReadyDesc>
        <span>{t('confs:home:conf_ready:before_link')}</span>
        <NavLink to={`/conf/${selectedConf.id}/access`} style={{ color: 'var(--blue-2)' }} exact>
          {t('confs:home:conf_ready:link')}
        </NavLink>
        <span>{t('confs:home:conf_ready:after_link')}</span>
      </ReadyDesc>
    </ReadyContainer>
  );
};
