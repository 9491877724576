import React, { createContext, useState } from 'react';
import styled from 'styled-components';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Card from '../components/layout/card';

const CustomHelperText = styled.p`
  margin: 0;
  font-size: 0.9em;
  white-space: pre-line;
  text-align: center;

  @media (min-width: 480px) {
    font-size: 1em;
  }  
`;

const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  cursor: pointer;
`;

const MaskContext = createContext();

const MaskProvider = ({ children }) => {
  const [maskData, setMaskData] = useState(null);

  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);

  const CustomHelper = ({ content }) => (
    <Card
      style={{
        padding: '18.5px 50px 18.5px 25px',
        maxWidth: 450,
        borderRadius: 5,
        position: 'relative'
      }}
    >
      <CloseButton onClick={() => setMaskData(null)}>
        <EditorCloseIcon />
      </CloseButton>
      <CustomHelperText>{content}</CustomHelperText>
    </Card>
  );

  return (
    <MaskContext.Provider value={{ setMaskData }}>
      <Tour
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        rounded={5}
        steps={maskData}
        isOpen={!!maskData}
        disableInteraction={false}
        CustomHelper={CustomHelper}
        onRequestClose={() => setMaskData(null)}
      />
      {children}
    </MaskContext.Provider>
  );
};

export { MaskProvider, MaskContext };
