import types from '../types';

/**
 *
 * @param {*} flag : Object { description, appearance, title }
 */
export const addFlag = flag => {
  return {
    type: types.ADD_FLAG,
    payload: { ...flag, created: Date.now() * 1000 }
  };
};

export const removeFlag = flagId => ({
  type: types.REMOVE_FLAG,
  payload: flagId
});
