import React, { useEffect } from 'react';
import { signIn } from '../../services/AuthService';
import ConfsService from '../../services/ConfsService';
import { useMappedState } from 'redux-react-hook';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line
export default () => {
  const {
    i18n: { language }
  } = useTranslation();
  const { wizard } = useMappedState(state => ({
    wizard: state.wizard.value
  }));
  const isComingFromWizard = ConfsService.isCreatable(wizard.conf);

  useEffect(() => {
    const action = isComingFromWizard ? 'signUp' : 'login';
    signIn(action, language);
  }, [isComingFromWizard, language]);

  return <div />;
};
