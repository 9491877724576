import { createSelector } from 'reselect';
import { getNotificationsAllowed } from './upgrade';

const notificationList = state => state.notifications.list;

export const getRemainingNotifications = createSelector(
  notificationList,
  getNotificationsAllowed,
  (notifications, allowed) => {
    return allowed - notifications.length;
  }
);
