import types from '../types';

const initialState = {
  user: null
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_PROFILE_LOADED:
      return { ...state, user: action.payload };

    case types.HANDLE_AUTHENTICATION_CALLBACK:
    default:
      return state;
  }
};

export default auth;