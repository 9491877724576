import { endOfDay, startOfDay } from 'date-fns/esm';

export function isDate(value) {
  const date = Date.parse(value);
  return !Number.isNaN(date) && !!date;
}

export function startOfDayIsPast(date) {
  return new Date() > startOfDay(new Date(date));
}

export function endOfDayIsPast(date) {
  return new Date() > endOfDay(new Date(date));
}
