import types from '../types';
import { requestStatus } from '../request-status';

const initialState = {
  firstCanBeChanged: false,
  lastCanBeChanged: false,
  status: requestStatus.UNDONE,
  error: ''
};

const dates = (state = initialState, action) => {
  switch (action.type) {
    case types.CHECK_DATES_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: ''
      };

    case types.CHECK_DATES_SUCCESS:
      return {
        ...state,
        firstCanBeChanged: action.payload.first,
        lastCanBeChanged: action.payload.last,
        status: requestStatus.DONE
      };

    case types.CHECK_DATES_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload
      };

    default:
      return state;
  }
};

export default dates;