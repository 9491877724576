import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

const middlewares = [thunk];
if (process.env.REACT_APP_MODE === `development`) {
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({ collapsed: true });
  middlewares.push(logger);
}

const store = createStore(reducers, compose(applyMiddleware(...middlewares)));
export default store;
