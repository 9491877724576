import React from 'react';
import styled from 'styled-components';
import ConfappLogo from '../../../assets/confapp-logo-min.png';
import useTime from '../../../hooks/use-time';

const SvgWrapper = styled.div`
  width: 100%;
  min-width: 250px;
  position: relative;
  .prefix__st0 {
    fill:#ebebeb
  }
  .prefix__st1 {
    fill:#fff
  }
  .prefix__st4 {
    fill:none
  }
  .prefix__st6 {
    font-family: &apos;ArialMT&apos;
  }
  .prefix__st7 {
    font-size:37px
  }

`;

const CurrentTime = styled.div`
  position: absolute;
  top: 110px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 56px;
`;

const NotificationWrapper = styled.div`
  position: absolute;
  top: 205px;
  left: 22px;
  width: calc(100% - 44px);
  background-color: #fff;
  border-radius: 10px;
`;

const NotificationContainer = styled.div`
  margin: 10px;
  font-size: 11px;
`;

const NotificationHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const NotificationLogo = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
`;

const NotificationApp = styled.div`
  text-transform: uppercase;
`;

const NotificationTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 3px;
`;

const NotificationBody = styled.div``;

const leftPadZero = str => ('0' + str).slice(-2);

// eslint-disable-next-line
export default ({ title, body }) => {
  const time = useTime();
  const formattedTime = `${leftPadZero(time.getHours())}:${leftPadZero(time.getMinutes())}`;
  return (
    <SvgWrapper>
      <CurrentTime>{formattedTime}</CurrentTime>
      <NotificationWrapper>
        <NotificationContainer>
          <NotificationHeader>
            <NotificationLogo src={ConfappLogo} />
            <NotificationApp>conf.app</NotificationApp>
          </NotificationHeader>
          <NotificationTitle>{title}</NotificationTitle>
          <NotificationBody>{body}</NotificationBody>
        </NotificationContainer>
      </NotificationWrapper>
      <svg id="prefix__Calque_1" x={0} y={0} viewBox="0 0 1237 2548" xmlSpace="preserve">
        <title>{'conf.app Preview'}</title>
        <path
          className="prefix__st0"
          d="M180 0h877c99.4 0 180 80.6 180 180v2188c0 99.4-80.6 180-180 180H180c-99.4 0-180-80.6-180-180V180C0 80.6 80.6 0 180 0z"
        />
        <defs>
          <path
            id="prefix__XMLID_1_"
            d="M192.6 56h851.8c75.5 0 136.6 61.2 136.6 136.6v2162.8c0 75.5-61.2 136.6-136.6 136.6H192.6c-75.5 0-136.6-61.2-136.6-136.6V192.6C56 117.2 117.2 56 192.6 56z"
          />
        </defs>
        <use xlinkHref="#prefix__XMLID_1_" overflow="visible" fill="#358bf1" />
        <clipPath id="prefix__XMLID_3_">
          <use xlinkHref="#prefix__XMLID_1_" overflow="visible" />
        </clipPath>
        <path
          className="prefix__st0"
          d="M316 54h606v20c0 37.6-30.4 68-68 68H384c-37.6 0-68-30.4-68-68V54z"
        />
      </svg>
    </SvgWrapper>
  );
};
