import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from '@atlaskit/button';
import { FEATURES_LIMITS_MAP } from '../../../constants';
import { isNil } from 'lodash';

const Warning = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 210px;
  margin-bottom: 30px;
  padding: 25px 25px;
  border-radius: 10px;
  background-color: var(--red-1); 
  border: 2px solid var(--red-2);
  font-size: 0.9em;
  color: var(--red-2);
  text-align: center;
  
  @media (min-width: 480px) {
    max-width: 280px;
  }
  
  @media (min-width: 960px) {
    margin-left: 25px;  
  }
`;

const Title = styled.p`
  margin: 0 0 15px;
  font-weight: 500;
`;

const Text = styled.p`
  margin: 0 0 2px;
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  margin-top: auto;
  padding-top: 15px;
`;

// eslint-disable-next-line
export default ({ limits, onChoose }) => {
  const { t } = useTranslation();

  return (
    <Warning>
      <Title>{t('limits:missing')}</Title>
      {Object.values(FEATURES_LIMITS_MAP)
        .filter(item => !isNil(limits[item]))
        .map(item => <Text key={item}>{t(`limits:${item}`, {count: limits[item]})}</Text>)
      }

      <ButtonWrapper>
        <Button appearance="primary" onClick={() => onChoose()}>
          {t('limits:choose_plan')}
        </Button>
      </ButtonWrapper>
    </Warning>
  );
};
