import React from 'react';
import Select from '@atlaskit/select';
import { useTranslation } from 'react-i18next';
import { languages } from '../../i18n/i18n';
import styled from 'styled-components';
import AccountService from '../../services/AccountService';
import { useMappedState } from 'redux-react-hook';
import { get } from 'lodash/fp';

const OptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  flex: 1;
  align-items: center;
`;

const Opt = ({ children, icon }) => (
  <OptionWrapper>
    <span>{icon}</span>
    {children}
  </OptionWrapper>
);

// return the country name; used for searching
const controlLabel = ({ icon, name }) => (
  <div>
    <span style={{ paddingRight: 11 }}>{icon}</span>
    {name}
  </div>
);
const optionLabel = ({ icon, name }) => <Opt icon={icon}>{name}</Opt>;
const formatOptionLabel = (opt, { context }) => {
  return context === 'value' ? controlLabel(opt) : optionLabel(opt);
};

// eslint-disable-next-line
export default ({ onChange }) => {
  const { i18n } = useTranslation();
  const { auth } = useMappedState(state => ({
    auth: state.auth
  }));
  const userId = get('user.profile.sub')(auth);
  return (
    <Select
      isClearable={false}
      isMulti={false}
      isSearchable={false}
      formatOptionLabel={formatOptionLabel}
      options={languages}
      getOptionValue={opt => opt.code}
      value={languages.find(lang => lang.code === i18n.language)}
      styles={{
        container: base => ({ ...base, fontFamily: 'inherit' }),
      }}
      onChange={({ code }) => {
        onChange();
        i18n.changeLanguage(code);
        if (userId) {
          AccountService.setUserLang(code).then(() => {});
        }
      }}
    />
  );
};
