import React, { useState } from 'react';
import Toggle from '@atlaskit/toggle';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash/fp';
import TextField from '@atlaskit/textfield';

const UNLIMITED_REGISTRATION_VALUE = -1;

const Registration = ({ limit, onChange }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(limit);
  const isRegistrationEnabled = !isNil(value);

  const onToggleChange = (event) => {
    const newValue = event.target.checked ? UNLIMITED_REGISTRATION_VALUE : null;

    setValue(newValue);
    onChange(newValue);
  };

  const onLimitChange = (event) => {
    const targetValue = event.target.value;

    if (targetValue === '') {
      setValue(targetValue);
      onChange(UNLIMITED_REGISTRATION_VALUE);

      return;
    }

    let targetValueNumber = Number(targetValue);

    if (targetValueNumber < 0) {
      targetValueNumber = 0;
    }

    setValue(targetValueNumber);
    onChange(targetValueNumber);
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Toggle isChecked={isRegistrationEnabled} onChange={onToggleChange}/>
        <p
          style={{
            margin: '0 0 0 5px',
            fontSize: '14px',
            lineHeight: 1,
          }}
        >
          {t('sessions:form:required_registration')}
        </p>
      </div>
      {isRegistrationEnabled && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '7px',
            marginLeft: '5px'
          }}
        >
          <TextField
            isCompact
            width={50}
            type="number"
            value={value === UNLIMITED_REGISTRATION_VALUE ? '' : value}
            onChange={onLimitChange}
          />
          <p
            style={{
              flexGrow: 1,
              margin: '0 0 0 10px',
              fontSize: '14px',
              lineHeight: 1,
            }}
          >
            {t('sessions:form:users_limit')}
          </p>
        </div>
      )}
    </>
  )
};

export default Registration;
