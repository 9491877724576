import HttpService from './HttpService';

class SpeakersService extends HttpService {
  entity = 'speakers';

  create(payload) {
    return this.http
      .post('/speaker', payload, {
        headers: this.defaultHeaders
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  createFake(t, confId, createdAt) {
    return this.create({
      name: 'John Doe',
      desc: t('speakers:mock:desc'),
      position: t('speakers:mock:position'),
      picture: 'https://ui-avatars.com/api/?name=John+Doe',
      socials: [
        { name: 'twitter', value: 'https://twitter.com/john.doe' },
        { name: 'facebook', value: 'https://www.facebook.com/john.doe' }
      ],
      conf_id: confId,
      updated_at: createdAt
    });
  }

  update(payload) {
    return this.http
      .patch('/speaker', payload, {
        headers: this.defaultHeaders,
        params: {
          id: `eq.${payload.id}`
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  findAllOfConf(confId) {
    return this.http
      .get('/speaker', {
        params: {
          conf_id: `eq.${confId}`,
          order: 'name.asc'
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  createSessionSpeakers(payload) {
    return this.http
      .post('/session_speaker', payload)
      .then(this.onResponse)
      .catch(this.onError);
  }

  deleteSessionSpeakers(sessionId) {
    return this.http
      .delete('/session_speaker', {
        params: {
          session_id: `eq.${sessionId}`
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  delete(speakerId) {
    return this.http
      .delete(`/speaker`, {
        headers: this.defaultHeaders,
        params: {
          id: `eq.${speakerId}`
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }
}

const speakersService = new SpeakersService();

export default speakersService;
