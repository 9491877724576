const rooms = {
  FIND_ALL_ROOMS_REQUEST: 'FIND_ALL_ROOMS_REQUEST',
  FIND_ALL_ROOMS_SUCCESS: 'FIND_ALL_ROOMS_SUCCESS',
  FIND_ALL_ROOMS_ERROR: 'FIND_ALL_ROOMS_ERROR',

  CREATE_ROOM_REQUEST: 'CREATE_ROOM_REQUEST',
  CREATE_ROOM_SUCCESS: 'CREATE_ROOM_SUCCESS',
  CREATE_ROOM_ERROR: 'CREATE_ROOM_ERROR',

  UPDATE_ROOM_SUCCESS: 'UPDATE_ROOM_SUCCESS',

  DELETE_ROOM_SUCCESS: 'DELETE_ROOM_SUCCESS'
};

export default rooms;
