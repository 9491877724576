import types from '../types';
import { requestStatus } from '../request-status';

const initialState = {
  isUpgradable: false,
  status: requestStatus.UNDONE,
  error: '',
  needVerification: false,
  paymentIntent: {},
  confLimits: {},
  confLimitsUsage: {},
  upgradeSuggestions: {},
  confLimitsOveruse: {},
  confPlanId: ''
};

const upgrade = (state = initialState, action) => {
  switch (action.type) {
    case types.CHECK_UPGRADABLE_REQUEST:
    case types.UPGRADE_CONF_REQUEST:
    case types.CONF_LIMITS_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: ''
      };

    case types.CHECK_UPGRADABLE_SUCCESS:
      return {
        ...state,
        isUpgradable: action.payload,
        status: requestStatus.DONE
      };

    case types.CHECK_UPGRADABLE_ERROR:
    case types.UPGRADE_CONF_ERROR:
    case types.CONF_LIMITS_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload
      };

    case types.UPGRADE_CONF_SUCCESS:
      return {
        ...state,
        status: requestStatus.DONE
      };

    case types.UPGRADE_CONF_NEED_VERIFICATION:
      return {
        ...state,
        needVerification: true,
        paymentIntent: action.payload
      };

    case types.UPGRADE_CONF_CANCEL_VERIFICATION:
      return {
        ...state,
        needVerification: false,
      };

    case types.CONF_LIMITS_SUCCESS:
      return {
        ...state,
        status: requestStatus.DONE,
        confLimits: action.payload.total,
        confLimitsUsage: action.payload.used,
        confLimitsOveruse: action.payload.overused,
        confPlanId: action.payload.current_plan,
        upgradeSuggestions: action.payload.upgrade_suggestion
      };

    default:
      return state;
  }
};

export default upgrade;