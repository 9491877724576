const confs = {
  FIND_ALL_CONFS_REQUEST: 'FIND_ALL_CONFS_REQUEST',
  FIND_ALL_CONFS_SUCCESS: 'FIND_ALL_CONFS_SUCCESS',
  FIND_ALL_CONFS_ERROR: 'FIND_ALL_CONFS_ERROR',

  CREATE_CONF_SUCCESS: 'CREATE_CONF_SUCCESS',
  CREATE_CONF_REQUEST: 'CREATE_CONF_REQUEST',
  CREATE_CONF_ERROR: 'CREATE_CONF_ERROR',

  UNSELECT_CONF: 'UNSELECT_CONF',
  SELECT_CONF: 'SELECT_CONF',

  UPDATE_CONF_REQUEST: 'UPDATE_CONF_REQUEST',
  UPDATE_CONF_SUCCESS: 'UPDATE_CONF_SUCCESS',
  UPDATE_CONF_ERROR: 'UPDATE_CONF_ERROR',

  DELETE_CONF_REQUEST: 'DELETE_CONF_REQUEST',
  DELETE_CONF_SUCCESS: 'DELETE_CONF_SUCCESS',
  DELETE_CONF_ERROR: 'DELETE_CONF_ERROR',

  DUPLICATE_CONF_REQUEST: 'DUPLICATE_CONF_REQUEST',
  DUPLICATE_CONF_SUCCESS: 'DUPLICATE_CONF_SUCCESS',
  DUPLICATE_CONF_ERROR: 'DUPLICATE_CONF_ERROR',

  SET_CONF_TOKEN: 'SET_CONF_TOKEN',
  UPDATE_CONF_TOKEN: 'UPDATE_CONF_TOKEN',

  SET_SHOW_ONBOARDING: 'SET_SHOW_ONBOARDING'
};

export default confs;
