import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Toggle from '@atlaskit/toggle';
import EmojiFrequentIcon from '@atlaskit/icon/glyph/emoji/frequent'
import { useDispatch, useMappedState } from 'redux-react-hook';
import { set24HourTime } from '../../store/actions/account';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 4px 6px 11px;
`;

const Label = styled.span`
  padding-right: 8px;
  margin-left: 11px;
  margin-right: auto;
`;

const TimeFormatToggle = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const is24HourTime = useMappedState(state => state.account.is24HourTime);

  return (
    <Wrapper>
      <EmojiFrequentIcon size="small" primaryColor="#bcd0e1" />
      <Label>{t('common:24_hour_time')}</Label>
      <Toggle
        onChange={() => dispatch(set24HourTime(!is24HourTime))}
        isChecked={is24HourTime}
      />
    </Wrapper>
  );
};

export default TimeFormatToggle;
