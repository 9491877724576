const ENV_OVERRIDES_STORAGE_KEY = 'confApp.bo.env';

export function initEnvOverrides() {
  window['clearEnvs'] = () => {
    clearEnvs();
    return 'Done!';
  };
  window['setEnv'] = (key, value) => {
    setEnv(key, value);
    return 'Done!';
  };
}

export function getEnv(key) {
  const flags = readEnvOverrides();
  return key in flags ? flags[key] : getProcessEnv(key);
}

function getProcessEnv(key) {
  try {
    return process.env[key] || '';
  } catch (e) {
    console.error(e);
    return '';
  }
}

function setEnv(key, value) {
  const flags = readEnvOverrides();
  flags[key] = value;
  writeEnvOverrides(flags);
}

function clearEnvs() {
  writeEnvOverrides({});
}

function readEnvOverrides() {
  try {
    return JSON.parse(localStorage.getItem(ENV_OVERRIDES_STORAGE_KEY)) || {};
  } catch (e) {
    console.error(e);
    return {};
  }
}

function writeEnvOverrides(flags) {
  try {
    localStorage.setItem(ENV_OVERRIDES_STORAGE_KEY, JSON.stringify(flags));
  } catch (e) {
    console.error(e);
  }
}
