import types from '../types';
import { requestStatus } from '../request-status';

const initialState = {
  list: [],
  status: requestStatus.UNDONE,
  listError: '',
  errors: []
};

const plans = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_PLAN_ERROR:
      return {
        ...state,
        errors: state.errors.concat([
          {
            ...action.payload,
            id: Math.random()
              .toString(36)
              .substr(2, 9)
          }
        ])
      };
    case types.REMOVE_PLAN_ERROR:
      return {
        ...state,
        errors: state.errors.filter(error => error.id !== action.payload.id)
      };
    case types.FIND_ALL_PLANS_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING
      };
    case types.FIND_ALL_PLANS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        status: requestStatus.DONE
      };
    case types.FIND_ALL_PLANS_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        listError: action.payload
      };

    default:
      return state;
  }
};

export default plans;