import React, { useState } from 'react';
import Button from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/glyph/add';
import { useTranslation } from 'react-i18next';
import EntityTile from '../../utils/entities/entity-tile';
import FontAwesomeIcon from '../../utils/icons/font-awesome-icon';
import LinkFormModal from '../links/link-edit-modal/link-edit-modal';
import styled from 'styled-components';
import { uniqueId } from 'lodash/fp';
import { SESSION_APPS } from '../../../constants';

const Apps = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const AddButtonWrapper = styled.div`
  margin-top: 5px;
`;

const EntityHash = styled.span`
  display: none;
`;

const MiniApps = ({ apps, onChange }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState({});
  const [sessionLinks, setSessionLinks] = useState(apps);

  const addLink = () => {
    setSelectedLink({});
    setOpen(true);
  };

  const editLink = (link) => {
    setSelectedLink(link);
    setOpen(true);
  };

  const removeLink = (link) => {
    const newSessionLinks = sessionLinks.filter((l) => l.id !== link.id);
    setSessionLinks(newSessionLinks);
    onChange(newSessionLinks);
  };

  const onClose = (link) => {
    if (link.app) {
      let newSessionLinks = [...sessionLinks];
      const linkIndex = newSessionLinks.findIndex((l) => l.id === link.id);

      if (linkIndex === -1) {
        newSessionLinks.push({ ...link, id: uniqueId('link_') });
      } else {
        newSessionLinks[linkIndex] = { ...link };
      }

      setSessionLinks(newSessionLinks);
      onChange(newSessionLinks);
    }

    setOpen(false);
  };

  const hash = (obj) => {
    const str = JSON.stringify(obj);
    let hash = 0,
      i,
      chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };

  return (
    <>
      <LinkFormModal
        isOpen={open}
        link={selectedLink}
        onClose={(link) => onClose(link)}
        apps={SESSION_APPS}
      />
      <Apps>
        {sessionLinks.map((link) => {
          return (
            <React.Fragment key={link.id}>
              <EntityTile
                title={link.name}
                desc={t(`links:form.types.${link.type}`)}
                picture={<FontAwesomeIcon icon={link.icon} size={'2x'} />}
                deleteModalConfig={{
                  modalHeading: t('links:actions:confirm_remove:header'),
                  modalContent: t('links:actions:confirm_remove:warning', { name: link.name }),
                }}
                onDelete={() => removeLink(link)}
                onEdit={() => editLink(link)}
              />
              <EntityHash>{hash(link)}</EntityHash>
            </React.Fragment>
          );
        })}
      </Apps>
      <AddButtonWrapper>
        <Button
          appearance="primary"
          spacing="compact"
          iconBefore={<AddIcon size="small" />}
          onClick={addLink}
        >
          {t('sessions:add')}
        </Button>
      </AddButtonWrapper>
    </>
  );
};

export default MiniApps;
