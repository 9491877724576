import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { useTranslation } from 'react-i18next';
import Toggle from '@atlaskit/toggle';
import InlineEdit from '@atlaskit/inline-edit';
import styled from 'styled-components';
import { compose, isEmpty } from 'lodash/fp';
import LockIcon from '@atlaskit/icon/glyph/lock';
import UnlockIcon from '@atlaskit/icon/glyph/unlock';
import Tooltip from 'react-tooltip';

import { getToken, updateConf, updateToken } from '../../../store/actions/confs';
import { getSelectedConf } from '../../../store/selectors/confs';
import ConfSelected from '../../../containers/conf-selected';
import ConfProgress from '../progress/conf-progress';
import Card from '../../layout/card';
import DateCmp from '../../utils/date';
import DateFormModal from './date-form-modal';
import EntityForm from '../../utils/entities/entity-form';
import OnboardingModal from '../../onboarding/onboarding-modal';
import { SizedTextarea, SizedTextfield } from '../../utils/sized-textfield';
import EditLabel from '../../utils/edit-label';
import Code from './code';
import PageWrapper from '../../layout/page-wrapper';
import Badge from '../../layout/badge';
import { checkUpgradable } from '../../../store/actions/upgrade';
import { getUtcFromZonedTime } from '../../../services/DatesService';
import ActivateButton from '../activate-button';
import ConfButtons from '../conf-buttons';

const MainContainer = styled(PageWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translateY(-100px);
  margin: auto;
`;

const MainWrapper = styled(Card)`
  width: calc(100% - 20px);
  position: relative;
`;

const DateWrapper = styled.div`
  position: absolute;
  transform: translate(50%, -55%);  
  right: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (min-width: 640px) {
    transform: translateY(-50%);
    right: 22px;
  }
`;

const StatusBadge = styled(Badge)`
  position: absolute;
  top: 10px;
  left: 30px;
`;

const EditPrivacy = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 16px;
      
  @media (min-width: 640px) {
    justify-content: flex-start;
  }
`;

const PrivacyCaption = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
      
  @media (min-width: 640px) {
    width: auto;
    align-items: center;
    justify-content: flex-start;
    margin-right: 4px;
  }
`;

const PlanBadge = styled(Badge)`
  margin-top: 4px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (min-width: 640px) {
    flex-direction: row;
    justify-content: space-between;
  }  
`;

const PrivateWrapper = styled.div`
  margin-bottom: 10px;  
  @media (min-width: 640px) {
    margin-bottom: 0;  
  } 
`;

const ConfHome = props => {
  const { id } = props.match.params;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedConf, selectedConfId, isConfUpgradable } = useMappedState(state => ({
    selectedConf: getSelectedConf(state),
    selectedConfId: state.confs.selectedId,
    isConfUpgradable: state.upgrade.isUpgradable,
  }));

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (selectedConfId === id) {
      dispatch(getToken(id));
    }
  }, [dispatch, selectedConfId, id]);

  useEffect(() => {
    if (selectedConf) {
      dispatch(checkUpgradable(selectedConf.id));
    }
  }, [selectedConf, dispatch]);

  const onEdit = (key, trim = false) => value => {
    // do not edit if the value didn't change
    if (selectedConf[key] === (trim ? value.trim() : value)) {
      return;
    }
    dispatch(
      updateConf({
        id: selectedConf.id,
        [key]: trim ? value.trim() : value
      })
    ).catch(() => {
      // do nothing since we've already displayed the flag
      // or the plan upgrade modal
    });
  };

  const onTokenEdit = token => {
    dispatch(updateToken(selectedConf.id, token));
  };

  return (
    <>
      <OnboardingModal useComponent={true} />
      <EntityForm
        DisplayForm={DateFormModal}
        open={isOpen}
        onSubmit={data => {
          const timezone = data.timezone.value;
          dispatch(
            updateConf({
              id: selectedConf.id,
              from: getUtcFromZonedTime(data.from, timezone),
              to: getUtcFromZonedTime(data.to, timezone),
              timezone
            })
          ).then(() => {
            setOpen(false);
          });
        }}
        title={t('confs:home.form.edit_dates')}
        submitText={t('common:save')}
        entity={selectedConf}
        entityName="dates"
        autoFocus={false}
        onCancel={() => setOpen(false)}
      />
      <div style={{ height: '220px', width: '100%', backgroundColor: 'var(--blue-2)' }} />
      <MainContainer>
        <Tooltip />
        <MainWrapper>
          <StatusBadge>
            {t(`common:${selectedConf.activated ? 'active' : 'inactive'}`)}
          </StatusBadge>
          <DateWrapper>
            <EditLabel
              labelStyle={{backgroundColor: "#fff", top: "-16px", right: "-16px"}}
              onLabelClick={() => setOpen(true)}
              isRound={true}
            >
              <DateCmp
                from={selectedConf.from}
                to={selectedConf.to}
                onClick={() => setOpen(true)}
              />
            </EditLabel>
            <PlanBadge>{t(`conf_plans:${selectedConf.conf_limits.current_plan}`)}</PlanBadge>
          </DateWrapper>
          <section style={{ margin: '40px 30px 30px' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ fontSize: 45, fontWeight: 700 }} className='onboarding-conf-title'>
                <InlineEdit
                  defaultValue={selectedConf.name || ''}
                  editView={fieldProps => (
                    <SizedTextfield
                      style={{ fontSize: 45, fontWeight: 700 }}
                      {...fieldProps}
                      maxLength={50}
                      autoFocus
                      isRequired
                    />
                  )}
                  validate={val => (isEmpty(val.trim()) ? 'common:form.empty' : null)}
                  readView={() => (
                    <EditLabel>
                      <span style={{ paddingRight: '32px' }}>{selectedConf.name}</span>
                    </EditLabel>
                  )}
                  onConfirm={onEdit('name', true)}
                />
              </div>
              <ConfButtons
                event={selectedConf}
                history={props.history}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                  flexShrink: 0
                }}
              />
            </div>
            <div style={{ fontSize: 22 }}>
              <InlineEdit
                defaultValue={selectedConf.desc || ''}
                editView={(fieldProps, ref) => (
                  <SizedTextarea
                    style={{ fontWeight: 300, fontSize: 22, lineHeight: 1.1 }}
                    {...fieldProps}
                    maxLength={500}
                    ref={ref}
                    autoFocus
                  />
                )}
                readView={() => (
                  <div style={isEmpty(selectedConf.desc) ? { color: 'gray' } : null}>
                    {!isEmpty(selectedConf.desc)
                      ? <EditLabel><span style={{ paddingRight: '30px' }}>{selectedConf.desc}</span></EditLabel>
                      : t('confs:home.form.empty_desc')}
                  </div>
                )}
                onConfirm={onEdit('desc', true)}
              />
            </div>
            <Wrapper>
              <PrivateWrapper className="onboarding-conf-private">
                <EditPrivacy>
                  <PrivacyCaption>
                    {selectedConf.private ? (
                      <LockIcon primaryColor="var(--blue-2)" />
                    ) : (
                      <UnlockIcon primaryColor="var(--blue-2)" />
                    )}
                    {t(`confs:home.private.is_${!selectedConf.private ? 'not_' : ''}private`)}
                  </PrivacyCaption>
                  <Toggle
                    size="large"
                    onChange={() => onEdit('private')(!selectedConf.private)}
                    isChecked={selectedConf.private}
                  />
                </EditPrivacy>
                {selectedConf.private && (
                  <Code onCodeConfirm={token => onTokenEdit(token)} code={selectedConf.token}/>
                )}
              </PrivateWrapper>
              <ActivateButton
                isUpgradable={isConfUpgradable}
                isActivated={selectedConf.activated}
                conf={selectedConf}
              />
            </Wrapper>
          </section>
        </MainWrapper>
        <ConfProgress showCards={true} />
      </MainContainer>
    </>
  );
};

export default compose(
  withRouter,
  ConfSelected
)(ConfHome);
