import { createSelector } from 'reselect';
import { requestStatus } from '../request-status';

const roomList = state => state.rooms.list;
const roomsStatus = state => state.rooms.status;

export const areRoomsLoading = createSelector(
  roomsStatus,
  status => status === requestStatus.WAITING
);

export const areRoomsLoaded = createSelector(
  roomsStatus,
  status => status === requestStatus.DONE
);

export const getTotalRooms = createSelector(
  roomList,
  rooms => rooms.length
);
