const account = {
  SEND_VERIFICATION_EMAIL_REQUEST: 'SEND_VERIFICATION_EMAIL_REQUEST',
  SEND_VERIFICATION_EMAIL_SUCCESS: 'SEND_VERIFICATION_EMAIL_SUCCESS',
  SEND_VERIFICATION_EMAIL_ERROR: 'SEND_VERIFICATION_EMAIL_ERROR',

  CONSENT_REQUEST: 'CONSENT_REQUEST',
  CONSENT_SUCCESS: 'CONSENT_SUCCESS',
  CONSENT_ERROR: 'CONSENT_ERROR',

  GET_ACCOUNT_REQUEST: 'GET_ACCOUNT_REQUEST',
  GET_ACCOUNT_SUCCESS: 'GET_ACCOUNT_SUCCESS',
  GET_ACCOUNT_ERROR: 'GET_ACCOUNT_ERROR',

  UPDATE_ACCOUNT_REQUEST: 'UPDATE_ACCOUNT_REQUEST',
  UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_ERROR: 'UPDATE_ACCOUNT_ERROR',

  GET_INVOICES_REQUEST: 'GET_INVOICES_REQUEST',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
  GET_INVOICES_ERROR: 'GET_INVOICES_ERROR',

  REMOVE_PAYMENT_METHOD_REQUEST: 'REMOVE_PAYMENT_METHOD_REQUEST',
  REMOVE_PAYMENT_METHOD_SUCCESS: 'REMOVE_PAYMENT_METHOD_SUCCESS',
  REMOVE_PAYMENT_METHOD_ERROR: 'REMOVE_PAYMENT_METHOD_ERROR',

  SET_TIME_FORMAT: 'SET_TIME_FORMAT',
};

export default account;
