import React, { useContext } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import Modal, { ModalBody, ModalHeader, ModalTransition } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import PlanReachedImage from '../../assets/undraw_plan_limit_reached.png';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { __RouterContext } from 'react-router';
import { removeConfPlanError } from '../../store/actions/products';
import { getSelectedConf } from '../../store/selectors/confs';
import AdaptiveModalFooter from '../utils/adaptive-modal-footer';

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  height: auto;
  width: 100%;
  margin-top: 10px;
  max-width: 400px;
`;

// eslint-disable-next-line
export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const router = useContext(__RouterContext);

  const { errors, selectedConf } = useMappedState((state) => ({
    errors: state.products.errors,
    selectedConf: getSelectedConf(state),
  }));

  const onAccept = (id) => () => {
    router.history.push(`/conf/${selectedConf.id}/upgrade`);
    dispatch(removeConfPlanError(id));
  };

  const onDecline = (id) => () => {
    dispatch(removeConfPlanError(id));
  };

  const errorMessage = (error) =>
    (error.hint && t(`conf_plans:errors.${error.hint}`)) || error.message || '';

  return (
    <>
      {errors.map((error, id) => {
        return (
          <ModalTransition key={id}>
            <Modal shouldScrollInViewport onClose={onDecline(error.id)}>
              <ModalHeader>
                <ImageWrapper>
                  <Image src={PlanReachedImage} />
                </ImageWrapper>
              </ModalHeader>
              <ModalBody>
                <div style={{ textAlign: 'center' }}>
                  <h2>{t('conf_plans:modal.title')}</h2>
                  <p>{errorMessage(error)}</p>
                </div>
              </ModalBody>
              <AdaptiveModalFooter>
                <Button appearance="primary" onClick={onAccept(error.id)} autoFocus>
                  {t('conf_plans:modal.accept')}
                </Button>
                <Button appearance="subtle" style={{ marginLeft: '8px' }} onClick={onDecline(error.id)}>
                  {t('conf_plans:modal.decline')}
                </Button>
              </AdaptiveModalFooter>
            </Modal>
          </ModalTransition>
        );
      })}
    </>
  );
};
