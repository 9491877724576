// eslint-disable-next-line
export default t => [
  {
    selector: '.onboarding-conf-title',
    content: t('onboarding:steps.home.title')
  },
  {
    selector: '.onboarding-conf-date',
    content: t('onboarding:steps.home.date')
  },
  {
    selector: '.onboarding-conf-private',
    content: t('onboarding:steps.home.private')
  },
  {
    selector: '.onboarding-link-theme',
    content: t('onboarding:steps.home.sidebar.theme')
  },
  {
    selector: '.onboarding-link-rooms',
    content: t('onboarding:steps.home.sidebar.rooms')
  },
  {
    selector: '.onboarding-link-speakers',
    content: t('onboarding:steps.home.sidebar.speakers')
  },
  {
    selector: '.onboarding-link-program',
    content: t('onboarding:steps.home.sidebar.program')
  },
  {
    selector: '.onboarding-link-links',
    content: t('onboarding:steps.home.sidebar.links')
  },
  {
    selector: '.onboarding-link-notifications',
    content: t('onboarding:steps.home.sidebar.notifications')
  },
  {
    selector: '.onboarding-link-upgrade',
    content: t('onboarding:steps.home.sidebar.upgrade')
  },
  {
    selector: '.onboarding-link-access',
    content: t('onboarding:steps.home.sidebar.access')
  }
];
