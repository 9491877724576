import { createSelector } from 'reselect';
import { requestStatus } from '../request-status';

const linksStatus = state => state.links.status;

export const areLinksLoading = createSelector(
  linksStatus,
  status => status === requestStatus.WAITING
);

export const areLinksLoaded = createSelector(
  linksStatus,
  status => status === requestStatus.DONE
);