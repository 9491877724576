import { createSelector } from 'reselect';
import { find } from 'lodash/fp';
import { requestStatus } from '../request-status';

const confList = state => state.confs.list;
const confListStatus = state => state.confs.status;
const selectedConfId = state => state.confs.selectedId;

export const getSelectedConf = createSelector(
  confList,
  selectedConfId,
  (list, selectedId) => find({ id: selectedId })(list)
);

export const isConfLoading = createSelector(
  confListStatus,
  status => status === requestStatus.WAITING
);

export const getConfsMap = createSelector(
  confList,
  (list) => list.reduce((res, conf) => ({...res, [conf.id]: conf}), {})
);
