import types from '../types';
import ProductsService from '../../services/ProductsService';
import handleError from '../utils/errors';
import { ProductsTypes } from '../../constants';

export const removeConfPlanError = errorId => ({
  type: types.REMOVE_PRODUCT_ERROR,
  payload: { type: ProductsTypes.PLAN, id: errorId }
});

export const findProducts = (confId) => dispatch => {
  dispatch({ type: types.FIND_ALL_PRODUCTS_REQUEST });

  return ProductsService.findAll(confId)
    .then(products => {
      dispatch({ type: types.FIND_ALL_PRODUCTS_SUCCESS, payload: products });
    })
    .catch(e => handleError(types.FIND_ALL_PRODUCTS_ERROR, e, dispatch));
};
