import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { useMediaQuery } from 'react-responsive';
import { addFlag } from '../../store/actions/flags';

const MobileWarning = () => {
  const dispatch = useDispatch();
  const isVertTabletOrMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    if (!isVertTabletOrMobile) {
      return;
    }

    dispatch(addFlag({
      appearance: 'warning',
      title: 'mobile_view:title',
      description: 'mobile_view:description',
    }));
  }, [dispatch, isVertTabletOrMobile]);

  return null;
}

export default MobileWarning;
