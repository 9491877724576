import React from 'react';
import { cn } from '../../../../../utils/css';
import FontAwesomeIcon from '../../../../../utils/icons/font-awesome-icon';
import styles from './component.module.css';

export const Button = React.forwardRef(({ className, active, reversed, ...props }, ref) => {
  const style = {
    color: `${reversed ? (active ? 'white' : '#aaa') : active ? 'black' : '#ccc'}`,
  };
  // TODO style is overriden
  return <span {...props} style={style} ref={ref} className={cn(className, styles.button)} />;
});

export const Icon = React.forwardRef(({ className, ...props }, ref) => (
  <FontAwesomeIcon {...props} ref={ref} className={cn(className, styles.icon)} />
));

export const Menu = React.forwardRef(({ className, ...props }, ref) => (
  <div {...props} ref={ref} className={cn(className, styles.menu)} />
));

export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
  <Menu {...props} ref={ref} className={cn(className, styles.toolbar)} />
));
