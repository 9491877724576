import React, { useState, useRef } from 'react';
import Textfield from '@atlaskit/textfield';
import styled from 'styled-components';
import Textarea from '@atlaskit/textarea';
import { omit } from 'lodash/fp';
const WordCounter = styled.div`
  color: ${props => (props.invalid ? 'var(--red-2)' : 'var(--gray-2)')};
  font-size: small;
  font-weight: bold;
  margin-right: 5px;
  transition: color 0.2s ease-in-out;
`;
const Counter = ({ value, setInvalid, maxLength }) => {
  const invalid = value.length >= maxLength;
  setInvalid(invalid);
  return (
    <WordCounter invalid={invalid}>
      {value.length}/{maxLength}
    </WordCounter>
  );
};

const TextareaWrapper = styled.div`
  position: relative;
`;
const AfterInputWrapper = styled.div`
  position: absolute;
  padding: 5px;
  right: 0;
  bottom: 0;
`;

const TextareaWithPlaceholder = props => {
  const fieldProps = omit(['elemAfterInput'])(props);
  const [height, setHeight] = useState(0);
  const areaRef = useRef(null);
  if (areaRef && areaRef.current) {
    if (height !== areaRef.current.scrollHeight) {
      setHeight(areaRef.current.scrollHeight);
    }
  }
  return (
    <TextareaWrapper>
      <Textarea
        ref={areaRef}
        {...fieldProps}
        style={{
          [height > 0 ? 'height' : null]: height,
          padding: 0,
          marginBottom: 20,
          ...props.style
        }}
      />
      <AfterInputWrapper>{props.elemAfterInput}</AfterInputWrapper>
    </TextareaWrapper>
  );
};

const SizeableInput = (props, Element) => {
  const maxLength = props.maxLength;
  const [internalValue, setInternalValue] = useState(props.value || '');
  const [invalid, setInvalid] = useState(false);
  const onChange = event => {
    event.persist();
    props.onChange(event);
    setInternalValue(event.target.value);
  };
  return (
    <Element
      {...props}
      onChange={onChange}
      isInvalid={props.isInvalid || invalid}
      elemAfterInput={
        <Counter value={internalValue} setInvalid={setInvalid} maxLength={maxLength} />
      }
    />
  );
};

export const SizedTextfield = props => {
  return SizeableInput(props, Textfield);
};

export const SizedTextarea = props => {
  return SizeableInput(props, TextareaWithPlaceholder);
};

export default SizedTextfield;
