import React from 'react';
import { Field } from '@atlaskit/form';
import { useTranslation } from 'react-i18next';
import SizedTextfield from '../../utils/sized-textfield';

// eslint-disable-next-line
export default ({ room = {} }) => {
  const { t } = useTranslation();
  return (
    <>
      <Field
        label={t('rooms:form:name')}
        name="name"
        defaultValue={room.name || ''}
      >
        {({ fieldProps }) => <SizedTextfield {...fieldProps} maxLength={35} autoFocus />}
      </Field>
    </>
  );
};
