import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import Avatar from '@atlaskit/avatar';
import { useMappedState, useDispatch } from 'redux-react-hook';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { get } from 'lodash/fp';
import ClickOutside from 'react-click-outside';
import { __RouterContext } from 'react-router';
import VidPlayIcon from '@atlaskit/icon/glyph/vid-play';
import SignOutIcon from '@atlaskit/icon/glyph/sign-out';
import CreditcardIcon from '@atlaskit/icon/glyph/creditcard';
import { signOut } from '../../services/AuthService';
import LangSelect from '../i18n/lang-select';
import { setShowOnboarding } from '../../store/actions/confs';
import EmailVerification from './email-verification';
import TimeFormatToggle from './time-format-toggle';

const AuthWidget = styled.div`
  position: absolute;
  display: flex;
  right: 15px;
  top: 15px;
  font-size: 14px;
  align-items: center;
  flex-direction: column;
  width: ${props => (props.expanded ? 'auto' : '44px')};
  height: ${props => (props.expanded ? 'auto' : '44px')};
  background-color: white;
  border-radius: ${props => (props.expanded ? '5px' : '22px')};
  ${props =>
    props.expanded
      ? 'box-shadow: 0 3px 3px rgba(189,195,199,0.12),0 2px 7px rgba(189,195,199,0.54);'
      : null}
`;

const ExpandedContent = styled.div`
  display: ${props => (props.expanded ? 'flex' : 'none')};
  flex-grow: 1;
  flex-direction: column;
`;

const AvatarContainer = styled.div`
  margin: ${props => (props.expanded ? '10px 10px 0 10px' : '0')};
`;

const MenuEntry = styled.div`
  padding: 11px;
  &:hover {
    background-color: rgb(245, 245, 245);
    cursor: pointer;
  }
`;

const EntryIconContainer = styled.span`
  margin-right: 11px;
`;

const AccountName = styled.div`
  word-break: break-word;
  text-align: center;
  margin: 0 15px 15px 15px;
  font-size: 13px;
`;

const Spacer = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  height: 1px;
  width: 100%;
  overflow: hidden;
`;

const ShowOnboarding = ({ selectedId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const router = useContext(__RouterContext);

  const showDemo = () => {
    dispatch(setShowOnboarding(true));
    const confHome = `/conf/${selectedId}`;
    // if we are not in the conf-home page, lets navigate to it
    if (router.location.pathname !== confHome) {
      router.history.push(confHome);
    }
  };

  return (
    <div onClick={showDemo}>
      <EntryIconContainer>
        <VidPlayIcon size="small" label="" primaryColor="#bcd0e1" />
      </EntryIconContainer>
      {t('onboarding:button')}
    </div>
  );
};

const mapState = state => ({
  auth: state.auth,
  selectedId: state.confs.selectedId,
  sidebarIsShown: state.sidebar.isShown
});
const presences = {
  offline: 'offline',
  online: 'online'
};

// eslint-disable-next-line
export default () => {
  const [presence, setPresence] = useState(presences.offline);
  const [expanded, setExpanded] = useState(false);
  const [verified, setVerified] = useState(true);
  const router = useContext(__RouterContext);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  const { t } = useTranslation();
  const { auth, selectedId, sidebarIsShown } = useMappedState(mapState);

  useEffect(() => {
    if (auth.user) {
      setPresence(auth.user.authenticated ? presences.online : presences.offline);
      setVerified(auth.user.profile.email_verified);
    }
  }, [auth]);

  if (isTabletOrMobile && sidebarIsShown) {
    return <></>;
  }

  return (
    <ClickOutside onClickOutside={() => setExpanded(false)}>
      <AuthWidget expanded={expanded} verified={verified}>
        <AvatarContainer expanded={expanded}>
          <Avatar
            size="large"
            presence={verified ? presence : 'busy'}
            src={get('user.profile.picture')(auth)}
            onClick={() => setExpanded(!expanded)}
          />
        </AvatarContainer>
        {auth.user ? (
          <ExpandedContent expanded={expanded}>
            <AccountName>{auth.user.profile.name}</AccountName>
            <TimeFormatToggle />
            {!verified && <EmailVerification />}
            <LangSelect onChange={() => setExpanded(false)} />
            <Spacer />
            {selectedId !== 0 && (
              <>
                <MenuEntry>
                  <ShowOnboarding selectedId={selectedId} />
                </MenuEntry>
                <Spacer />
              </>
            )}
            <MenuEntry onClick={() => router.history.push('/settings/billing')}>
              <EntryIconContainer>
                <CreditcardIcon size="small" label="" primaryColor="#bcd0e1" />
              </EntryIconContainer>
              {t('billing:widget_title')}
            </MenuEntry>
            <Spacer />
            <MenuEntry onClick={signOut}>
              <EntryIconContainer>
                <SignOutIcon size="small" label="" primaryColor="#bcd0e1" />
              </EntryIconContainer>
              {t('auth:logout')}
            </MenuEntry>
          </ExpandedContent>
        ) : (
          <ExpandedContent expanded={expanded} style={{ width: 150 }}>
            <LangSelect onChange={() => setExpanded(false)} />
          </ExpandedContent>
        )}
      </AuthWidget>
    </ClickOutside>
  );
};
