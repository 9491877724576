import React from 'react';
import styled from 'styled-components';
import FontAwesomeIcon from '../utils/icons/font-awesome-icon';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';

const Wrapper = styled.section`
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  text-align: center;    
`;

const Logo = styled.div`
  margin: 100px 0 70px;
  text-align: center;

  @media (min-width: 960px) {
    margin-top: 150px;
  }
`;

const Title = styled.h1`
  margin: 0 0 5px;
  font-size: 38px;
  color: var(--black);
  text-transform: uppercase;

  @media (min-width: 960px) {
    font-size: 80px;    
  }
`;

const Subtitle = styled.h2`
  margin: 0 0 200px;
  font-size: 28px;
  color: var(--blue-2);

  @media (min-width: 960px) {
    font-size: 34px;
    margin-bottom: 300px
  }
`;

const Social = styled.div`
  margin-bottom: 20px;
  color: var(--gray-3);
  font-size: 13px;
    
  & a {
    display: inline-block;
    margin: 0 5px;
  }
`;

const Copyright = styled.p`
  margin: 0;
  color: var(--black);
  font-size: 14px;
`;

// eslint-disable-next-line
export default () => {
  return (
    <Wrapper>
      <Logo>
        <img src={process.env.REACT_APP_LOGO_URL} alt="logo" height="55" />
      </Logo>
      <Title>Under construction</Title>
      <Subtitle>We are making lots of improvements and will be back soon</Subtitle>
      <Social>
        <a href="https://twitter.com/ConfappHQ">
          <FontAwesomeIcon icon={faTwitter} size={'2x'} color={'var(--blue-2)'} />
        </a>
        <a href="mailto:contact@conf.app">
          <FontAwesomeIcon icon={'envelope'} size={'2x'} color={'var(--blue-2)'} />
        </a>
      </Social>
      <Copyright>© 2019 Conf.app - All Rights Reserved</Copyright>
    </Wrapper>
  );
}
