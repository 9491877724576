import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import { isEmpty } from 'lodash/fp';
import { format } from '../../services/DatesService';

const Invoices = ({ invoices, confsMap }) => {
  const { t } = useTranslation();
  const { passed = [] } = invoices;

  const head = {
    cells: ['number', 'conf', 'dates', 'status', 'actions'].map(key => ({
      key,
      content: t(`billing:invoices.table.${key}`)
    }))
  };

  const getConfName = (confsMap, invoice) => {
    const confId = invoice.metadata && invoice.metadata.confId;
    const conf = confId && confsMap[confId];
    return (conf && conf.name) || '';
  };

  const rows = passed.map(invoice => ({
    key: invoice.id,
    cells: [
      { key: 'number', content: invoice.number },
      { key: 'conf', content: getConfName(confsMap, invoice) },
      {
        key: 'dates',
        content: t('billing:invoices.dates', {
          from: format(new Date(invoice.period_start), 'P'),
          to: format(new Date(invoice.period_end), 'P')
        })
      },
      {
        key: 'status',
        content: (
          <>
            {t(`billing:invoices.${invoice.paid ? 'paid' : 'unpaid'}`)}
            {!invoice.paid && invoice.hosted_invoice_url && (
              <Button target="_blank" href={invoice.hosted_invoice_url} appearance="link">
                {t(`billing:invoices.pay_now`)}
              </Button>
            )}
          </>
        )
      },
      {
        key: 'actions',
        content: (
          <Button target="_blank" href={invoice.invoice_pdf}>
            {t(`billing:invoices.download`)}
          </Button>
        )
      }
    ]
  }));
  return (
    <>
      {isEmpty(passed)
        ? <h4 style={{ textAlign: 'center' }}>{t(`billing:invoices.empty`)}</h4>
        : (
          <DynamicTable
            head={head}
            rows={rows}
            rowsPerPage={10}
            defaultPage={1}
            loadingSpinnerSize="large"
            isLoading={false}
            isFixedSize
          />
      )}
    </>
  );
};

export default Invoices;
