import React, { useState } from 'react';
import styled from 'styled-components';
import FontAwesomeIcon from '../../utils/icons/font-awesome-icon';
import Button from '@atlaskit/button';

const Counter = styled.div`
  display: flex;
  align-items: center;
`;

const CounterValue = styled.span`
  width: 20px;
  margin: 0 4px;
  text-align: center;
  font-size: 0.9em;
  line-height: 1;
  
  @media (min-width: 480px) {
    margin: 0 6px;
    font-size: 1.25em;
  }
`;

// eslint-disable-next-line
export default ({item, onChange}) => {
  const [counterItem, setCounterItem] = useState(item);

  const increaseQuantity = () => {
    const modifiedItem = { ...counterItem, quantity: counterItem.quantity + 1 };
    setCounterItem(modifiedItem);
    onChange(modifiedItem);
  };

  const decreaseQuantity = () => {
    if (counterItem.quantity === 1) {
      return;
    }

    const modifiedItem = { ...counterItem, quantity: counterItem.quantity - 1 };
    setCounterItem(modifiedItem);
    onChange(modifiedItem);
  };

  return <Counter>
    <Button
      appearance="primary"
      onClick={() => decreaseQuantity()}
      isDisabled={counterItem.isUncountable || counterItem.quantity === 1}
    >
      <FontAwesomeIcon icon={"minus"} size={'1x'} />
    </Button>
    <CounterValue>{counterItem.quantity}</CounterValue>
    <Button
      appearance="primary"
      onClick={() => increaseQuantity()}
      isDisabled={counterItem.isUncountable}
    >
      <FontAwesomeIcon icon={"plus"} size={'1x'} />
    </Button>
  </Counter>
};
