import types from '../types';
import { requestStatus } from '../request-status';

const initialState = {
  id: '',
  endDate: null,
  plan: {},
  needVerification: false,
  paymentIntent: {},
  status: requestStatus.UNDONE,
  updateStatus: requestStatus.UNDONE,
  cancelStatus: requestStatus.UNDONE,
  error: ''
};

const subscription = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: ''
      };
    case types.GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: requestStatus.DONE
      };
    case types.GET_SUBSCRIPTION_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload
      };

    case types.UPDATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        updateStatus: requestStatus.WAITING,
        error: ''
      };
    case types.UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        updateStatus: requestStatus.DONE
      };
    case types.UPDATE_SUBSCRIPTION_NEED_VERIFICATION:
      return {
        ...state,
        updateStatus: requestStatus.DONE,
        needVerification: true,
        paymentIntent: action.payload
      };
    case types.UPDATE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        updateStatus: requestStatus.DONE,
        error: action.payload
      };

    case types.CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        cancelStatus: requestStatus.WAITING,
        error: ''
      };
    case types.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        cancelStatus: requestStatus.DONE,
        error: ''
      };
    case types.CANCEL_SUBSCRIPTION_ERROR:
      return {
        ...state,
        updateStatus: requestStatus.DONE,
        error: action.payload
      };
    default:
      return state;
  }
};

export default subscription;