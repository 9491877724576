import types from '../types';
import ThemesService from '../../services/ThemesService';
import { getSelectedConf } from '../selectors/confs';
import handleError from '../utils/errors';
import { addFlag } from './flags';
import { updateEntityProgress } from './progress';

export const getTheme = (conf_id) => (dispatch) => {
  dispatch({ type: types.GET_THEME_REQUEST });
  ThemesService.get(conf_id)
    .then((theme) => {
      dispatch({ type: types.GET_THEME_SUCCESS, payload: theme });
    })
    .catch((err) => handleError(types.GET_THEME_ERROR, err, dispatch));
};

export const saveTheme = () => (dispatch, getState) => {
  const conf_id = getSelectedConf(getState()).id;
  const {
    id,
    color,
    font,
    logo: { url },
    background: { url: backgroundUrl },
  } = getState().theme;

  dispatch({ type: types.UPDATE_THEME_REQUEST });
  return ThemesService.update({ id, color, conf_id, logo: url, background: backgroundUrl, font: font })
    .then((theme) => {
      dispatch({ type: types.UPDATE_THEME_SUCCESS, payload: theme });
      dispatch(updateEntityProgress('theme', true));
      return dispatch(
        addFlag({
          appearance: 'success',
          title: 'theme:actions.update.success',
          timeout: 5000,
        })
      );
    })
    .catch((err) => handleError(types.UPDATE_THEME_ERROR, err, dispatch));
};

export const setColor = (color) => (dispatch) => {
  dispatch({
    type: types.SET_THEME_COLOR,
    payload: color,
  });
};

export const setLogo = (url) => (dispatch) => {
  dispatch({ type: types.SET_THEME_LOGO, payload: url });
};

export const setBackground = (url) => (dispatch) => {
  dispatch({ type: types.SET_THEME_BACKGROUND, payload: url });
};

export const setFont = (font) => (dispatch) => {
  dispatch({ type: types.SET_THEME_FONT, payload: font });
};
