import types from '../types';
import ConfsService from '../../services/ConfsService';
import handleError from '../utils/errors';
import ConfTokenService from '../../services/ConfTokenService';
import { omit } from 'lodash/fp';
import { setDone, setUndone } from '../../services/OnboardingService';

export const setShowOnboarding = showOnboarding => {
  if (showOnboarding === true) {
    setUndone();
  } else {
    setDone();
  }
  return {
    type: types.SET_SHOW_ONBOARDING,
    payload: showOnboarding
  };
};

export const deleteConf = conf => dispatch => {
  dispatch({
    type: types.DELETE_CONF_REQUEST
  });

  return ConfsService.delete(conf)
    .then(() => {
      return dispatch({
        type: types.DELETE_CONF_SUCCESS,
        payload: conf
      });
    })
    .catch(err => {
      // we got 409 error when try to delete paid event
      const message = err.status = 409 ? 'confs:actions:remove_error_paid' : 'confs:actions:remove_error';

      throw new Error(message);
    })
};

export const duplicateConf = (confId, rooms, speakers, links, sessions) => dispatch => {
  dispatch({
    type: types.DUPLICATE_CONF_REQUEST
  });

  return ConfsService.duplicate(confId, rooms, speakers, links, sessions)
    .then(() => {
      return dispatch({
        type: types.DUPLICATE_CONF_SUCCESS,
        payload: confId
      });
    })
    .catch(err => handleError(types.DUPLICATE_CONF_ERROR, err, dispatch));
};

export const findAllConfs = () => (dispatch, getState) => {
  dispatch({
    type: types.FIND_ALL_CONFS_REQUEST
  });
  const userId = getState().auth.user.profile.sub;
  return ConfsService.findAll(userId)
    .then(confs => {
      const transformedConfs = (confs || []).map(conf => ({
        ...conf,
        // TODO remove when api upgrade to 16 is done
        conf_limits: Array.isArray(conf.conf_limits) ? conf.conf_limits[0] : conf.conf_limits,
        theme: conf.theme[0]
      }));

      return dispatch({
        type: types.FIND_ALL_CONFS_SUCCESS,
        payload: transformedConfs
      });
    })
    .catch(err => handleError(types.FIND_ALL_CONFS_ERROR, err, dispatch));
};

export const createConf = conf => dispatch => {
  dispatch({
    type: types.CREATE_CONF_REQUEST
  });

  return ConfsService.create(conf)
    .then(createdConf => {
      return dispatch({
        type: types.CREATE_CONF_SUCCESS,
        payload: createdConf
      });
    })
    .catch(err => handleError(types.CREATE_CONF_ERROR, err, dispatch));
};

export const unSelectConf = () => dispatch => {
  dispatch({ type: types.UNSELECT_CONF });
};

export const selectConf = confId => dispatch => {
  dispatch({
    type: types.SELECT_CONF,
    payload: confId
  });
};

export const updateConf = conf => dispatch => {
  dispatch({
    type: types.UPDATE_CONF_REQUEST
  });
  return ConfsService.update(omit(['token', 'speakers', 'theme', 'conf_limits', 'showcased'], conf))
    .then(updatedConf => {
      return dispatch({
        type: types.UPDATE_CONF_SUCCESS,
        payload: updatedConf
      });
    })
    .catch(err => handleError(types.UPDATE_CONF_ERROR, err, dispatch));
};

export const getToken = confId => dispatch => {
  return ConfTokenService.get(confId).then(data => {
    dispatch({
      type: types.SET_CONF_TOKEN,
      payload: { conf_id: confId, token: (data || {}).token || '' }
    });
  });
};

export const updateToken = (confId, token) => dispatch => {
  return ConfTokenService.update(confId, token).then(({ conf_id }) => {
    dispatch({
      type: types.UPDATE_CONF_TOKEN,
      payload: { conf_id, token }
    });
  });
};
