import React from 'react';

const cardBrand = {
  Visa: 'pf-visa',
  MasterCard: 'pf-mastercard',
  'American Express': 'pf-american-express'
};

const Card = ({ card }) => {
  return (
    <>
      <i title={card.brand} className={`pf ${cardBrand[card.brand || 'pf-credit-card']}`}/>
      <div style={{ marginLeft: 12 }}>•••• {card.last4}</div>
      <div style={{ marginLeft: 12 }}>
        {card.exp_month < 9 ? `0${card.exp_month}` : card.exp_month} / {card.exp_year}
      </div>
    </>
  );
};

export default Card;
