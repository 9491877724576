import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TextField from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import Tooltip from 'react-tooltip';
import DataLayer from '../../../../services/DataLayer';
import { FAQ_MINI_APPS_LINK } from '../../../../constants';

const SuggestWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px 0 30px 0;
`;

const SuggestLink = styled.p`
  margin: 10px 15px 10px 0;
  white-space: nowrap;
  text-decoration: underline;
  font-size: 0.9em;
  line-height: 1;
  color: var(--blue-2);
  cursor: pointer;
`;

const HelpLink = styled.a`
  margin: 10px 0 10px auto;
  text-decoration: underline;
  font-size: 0.9em;
  line-height: 1;
  color: var(--blue-2);
  cursor: pointer;
`;

const SuggestInput = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;  
  min-width: 100px;

  & input {
    padding-bottom: 3px;
    padding-top: 3px;
  }
`;

const SuggestTextField = styled(TextField)`
  flex-grow: 1;
`;

const SuggestButton = styled(Button)`
  margin-left: 10px
`;

const SuggestMessage = styled.p`
  margin: 0;
  white-space: nowrap;
  font-size: 0.9em;
`;

const Suggest = ({ userEmail }) => {
  const { t } = useTranslation();
  const [input, setInput] = useState('');
  const [isInputShown, setIsInputShown] = useState(false);
  const [isSuccessShown, setIsSuccessShown] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (isSuccessShown) {
      timeoutId = setTimeout(() => setIsSuccessShown(false), 2500);
    }

    return () => timeoutId && clearInterval(timeoutId);
  }, [isSuccessShown]);

  const handleSuggestLinkClick = () => {
    if (!isSuccessShown) {
      setIsInputShown(true);
    }
  }

  const closeInput = () => {
    setInput('');
    setIsInputShown(false);
  };

  const sendSuggestion = () => {
    DataLayer.emitSuggestMiniAppEvent(input, userEmail);
    closeInput();
    setIsSuccessShown(true);
  };

  return (
    <SuggestWrapper>
      <Tooltip />
      <SuggestLink
        data-tip={t('links:missed_mini_apps')}
        onClick={handleSuggestLinkClick}
      >
        {t('links:suggest')}
      </SuggestLink>
      {!isInputShown && !isSuccessShown && (
        <HelpLink href={FAQ_MINI_APPS_LINK} target='_blank'>{t('common:help')}</HelpLink>
      )}
      {isInputShown && (
        <SuggestInput>
          <SuggestTextField
            value={input}
            onChange={e => setInput(e.target.value)}
          />
          <SuggestButton
            appearance="primary"
            spacing="compact"
            isDisabled={!input}
            onClick={sendSuggestion}
          >
            {t('links:send')}
          </SuggestButton>
          <SuggestButton
            spacing="compact"
            onClick={closeInput}
          >
            {t('links:close')}
          </SuggestButton>
        </SuggestInput>
      )}
      {isSuccessShown && <SuggestMessage>{t('links:thank_you')}</SuggestMessage>}
    </SuggestWrapper>
  );
};

export default Suggest;