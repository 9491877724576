import types from '../types';
import { requestStatus } from '../request-status';

const initialState = {
  list: [],
  status: requestStatus.UNDONE,
  error: ''
};

const links = (state = initialState, action) => {
  switch (action.type) {
    case types.FIND_ALL_LINKS_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: ''
      };

    case types.FIND_ALL_LINKS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        status: requestStatus.DONE,
        error: ''
      };

    case types.FIND_ALL_LINKS_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload
      };

    case types.CREATE_LINK_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: ''
      };
    case types.CREATE_LINK_SUCCESS:
      return {
        ...state,
        status: requestStatus.DONE,
        list: state.list.concat([action.payload]),
        error: ''
      };
    case types.CREATE_LINK_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload
      };

    case types.UPDATE_LINK_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: ''
      };
    case types.UPDATE_LINK_SUCCESS:
      return {
        ...state,
        status: requestStatus.DONE,
        list: state.list.map(link => {
          return link.id === action.payload.id ? action.payload : link;
        }),
        error: ''
      };
    case types.UPDATE_LINK_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload
      };

    case types.DELETE_LINK:
      return {
        ...state,
        list: state.list.filter(link => link.id !== action.payload)
      };
    default:
      return state;
  }
};

export default links;