import types from '../types';
import { requestStatus } from '../request-status';

const initialState = {
  list: [],
  status: requestStatus.UNDONE,
  error: ''
};

const speakers = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_SPEAKERS_SUCCESS:
      return {
        ...state,
        status: requestStatus.DONE,
        list: state.list.concat([action.payload]),
        error: ''
      };

    case types.UPDATE_SPEAKERS_SUCCESS:
      return {
        ...state,
        list: state.list.map(speaker =>
          speaker.id === action.payload.id ? action.payload : speaker
        ),
        status: requestStatus.DONE,
        error: ''
      };

    case types.UPDATE_SPEAKERS_REQUEST:
    case types.CREATE_SPEAKERS_REQUEST:
    case types.FIND_ALL_SPEAKERS_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: ''
      };

    case types.FIND_ALL_SPEAKERS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        status: requestStatus.DONE,
        error: ''
      };

    case types.UPDATE_SPEAKERS_ERROR:
    case types.CREATE_SPEAKERS_ERROR:
    case types.FIND_ALL_SPEAKERS_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload
      };

    case types.DELETE_SPEAKER:
      return {
        ...state,
        list: state.list.filter(speaker => speaker.id !== action.payload)
      };

    default:
      return state;
  }
};

export default speakers;