import React from 'react';
import { Field, ErrorMessage } from '@atlaskit/form';
import { DatePicker } from '@atlaskit/datetime-picker';
import Select from '@atlaskit/select';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isDate } from '../utils/dates';
import { startOfDay } from 'date-fns/esm';
import { isEmpty } from 'lodash/fp';
import SizedTextfield from '../utils/sized-textfield';
import Tooltip from 'react-tooltip';
import i18n, { LOCALES_MAP } from '../../i18n/i18n';
import { format, getTimeZoneData, TIME_ZONES_DATA } from '../../services/DatesService';
import Tip from '../utils/tip';

const FieldsWrapper = styled.div`
  & > div {
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

// eslint-disable-next-line
export default () => {
  const { t } = useTranslation();
  const currentTimeZoneData = getTimeZoneData();
  const currentDate = startOfDay(new Date());
  const placeholder = t('common:form:date_placeholder', { date: format(currentDate, 'P') });
  const today = format(currentDate, 'yyyy-MM-dd');
  const defaultPickerDate = today;

  return (
    <FieldsWrapper>
      <Tooltip />
      <Field
        label={t('confs:create:name')}
        name="name"
        defaultValue=""
        isRequired
        validate={(val) => (isEmpty(val.trim()) ? 'common:form.empty' : null)}
      >
        {({ fieldProps, error }) => (
          <>
            <SizedTextfield {...fieldProps} maxLength={50} autoFocus />
            {error && <ErrorMessage>{t(error)}</ErrorMessage>}
          </>
        )}
      </Field>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '-8px', fontWeight: 500 }}>
        <div style={{ width: '100%' }}>
          <Field
            label={t('confs:create:from')}
            name="from"
            defaultValue={defaultPickerDate}
            validate={(val, form) => {
              if (!isDate(val)) return 'sessions:form:errors:date';
              const from = new Date(val);
              if (from < new Date(today)) return 'sessions:form:errors:past';
              if (form.to && from > new Date(form.to)) {
                if (form.to === defaultPickerDate) {
                  form.to = val;
                  return null;
                }
                return 'sessions:form:errors:start_after';
              }
              return null;
            }}
            isRequired
          >
            {({ fieldProps, error }) => (
              <>
                <DatePicker
                  {...fieldProps}
                  placeholder={placeholder}
                  locale={LOCALES_MAP[i18n.language]}
                />
                {error && <ErrorMessage>{t(error)}</ErrorMessage>}
              </>
            )}
          </Field>
        </div>
        <div style={{ width: '100%', marginLeft: 10 }}>
          <Field
            label={t('confs:create:to')}
            name="to"
            defaultValue={defaultPickerDate}
            validate={(val, form) => {
              if (!isDate(val)) return 'sessions:form:errors:date';
              if (new Date(val) < new Date(form.from)) return 'sessions:form:errors:end_before';
              return null;
            }}
            isRequired
          >
            {({ fieldProps, error }) => (
              <>
                <DatePicker
                  {...fieldProps}
                  placeholder={placeholder}
                  locale={LOCALES_MAP[i18n.language]}
                />
                {error && <ErrorMessage>{t(error)}</ErrorMessage>}
              </>
            )}
          </Field>
        </div>
      </div>
      <Field
        name="timezone"
        label={t('common:timezone')}
        isRequired
        defaultValue={currentTimeZoneData}
      >
        {({ fieldProps: { id, ...rest } }) => (
          <Select
            {...rest}
            inputId={id}
            options={TIME_ZONES_DATA}
            menuPortalTarget={document.body}
            styles={{
              container: base => ({ ...base, fontFamily: 'inherit' }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 })
            }}
          />
        )}
      </Field>
      <Tip>{t('confs:create:timezone_help')}</Tip>
    </FieldsWrapper>
  );
};
