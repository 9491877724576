import types from '../types';
import SubscribersService from '../../services/SubscribersService';
import handleError from '../utils/errors';

export const countAllSubscribers = (confId) => (dispatch) => {
  dispatch({ type: types.COUNT_ALL_SUBSCRIBERS_REQUEST });
  return SubscribersService.countAll(confId)
    .then((count) => {
      return dispatch({
        type: types.COUNT_ALL_SUBSCRIBERS_SUCCESS,
        payload: count,
      });
    })
    .catch((e) => handleError(types.COUNT_ALL_SUBSCRIBERS_ERROR, e, dispatch));
};
