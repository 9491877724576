import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMappedState, useDispatch } from 'redux-react-hook';
import Elements from '../utils/stripe/localized-elements';
import styled from 'styled-components';
import Page from '../layout/page';
import Button from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import BadgeSmall from '../layout/badge-small'
import {
  getAccount,
  removePaymentMethod,
  getInvoices,
  updateAccount
} from '../../store/actions/account';
import { addFlag } from '../../store/actions/flags';
import {
  isAccountLoading,
  isInvoicesLoading,
  getDeletingPaymentMethod
} from '../../store/selectors/account';
import PaymentMethodCreate from './payment-method-create';
import Invoices from './invoices';
import Card from './card';
import { getConfsMap, isConfLoading } from '../../store/selectors/confs';
import { findAllConfs } from '../../store/actions/confs';
import { emptyCart } from '../../store/actions/cart';

const Title = styled.h3`
  padding-bottom: 6px;
  border-bottom: 1px solid #e1e4e8;
`;

const NoPaymentMethodWrapper = styled.div`
  text-align: center;
`;

const PaymentMethodWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4f566a;
  margin-bottom: 8px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const Badge = styled(BadgeSmall)`
  margin-right: 12px;
`;

const PaymentMethod = ({ account, deletingPaymentMethodId, onDelete, onUpdate }) => {
  const { t } = useTranslation();
  const { sources } = account;

  if (sources.length === 0) {
    return (
      <NoPaymentMethodWrapper>
        <p>{t('billing:payment_method.no_method')}</p>
        <Elements>
          <PaymentMethodCreate
            address={account.address}
            vatNumber={account.vatNumber}
            name={account.name}
            onUpdate={onUpdate}
            action="create"
          />
        </Elements>
      </NoPaymentMethodWrapper>
    );
  }
  return (
    <>
      {sources.map(source => (
        <PaymentMethodWrapper key={source.id}>
          {/* TODO check default property when api is ready*/}
          {source.default && <Badge>{t('default')}</Badge>}
          <Card card={source.card} />
          <div style={{ marginLeft: 25 }}>
            <Elements>
              <PaymentMethodCreate
                address={account.address}
                vatNumber={account.vatNumber}
                name={account.name}
                onUpdate={onUpdate}
                action="edit"
              />
            </Elements>
            <Button
              spacing="compact"
              style={{ marginLeft: 5 }}
              onClick={() => onDelete(source.id)}
              isLoading={deletingPaymentMethodId === source.id}
            >
              {t('billing:payment_method.delete')}
            </Button>
          </div>
        </PaymentMethodWrapper>
      ))}
    </>
  );
};

const Billing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    account,
    deletingPaymentMethodId,
    isLoading,
    confsMap
  } = useMappedState(state => ({
    account: state.account,
    deletingPaymentMethodId: getDeletingPaymentMethod(state),
    isLoading: isAccountLoading(state) || isInvoicesLoading(state) || isConfLoading(state),
    confsMap: getConfsMap(state)
  }));

  useEffect(() => {
    dispatch(getAccount())
      .then(() => dispatch(findAllConfs()))
      .then(() => dispatch(getInvoices()));
  }, [dispatch]);

  const onPaymentMethodUpdated = data => {
    dispatch(updateAccount(data));
    dispatch(emptyCart); // if a country is changed than products may have new tax rates
    dispatch(
      addFlag({
        appearance: 'success',
        title: 'billing:actions.update.success',
        timeout: 5000
      })
    );
  };

  return (
    <Page title={t('billing:title')}>
      <Title>{t('billing:titles.payment_method')}</Title>
      {isLoading ? (
        <LoadingWrapper>
          <Spinner size="large" />
        </LoadingWrapper>
      ) : (
        <PaymentMethod
          account={account}
          deletingPaymentMethodId={deletingPaymentMethodId}
          onDelete={id => dispatch(removePaymentMethod(id))}
          onUpdate={onPaymentMethodUpdated}
        />
      )}
      <Title>{t('billing:titles.invoices')}</Title>
      {isLoading ? (
        <LoadingWrapper>
          <Spinner size="large" />
        </LoadingWrapper>
      ) : (
        <Invoices invoices={account.invoices} confsMap={confsMap}/>
      )}
    </Page>
  );
};

export default Billing;
