import HttpService from './HttpService';

class SubscribersService extends HttpService {
  countAll(confId) {
    return this.http
      .get('/conf_subscription', {
        params: {
          conf_id: `eq.${confId}`,
          select: 'id',
          limit: '1',
        },
        headers: {
          Range: '0-1',
          Prefer: 'count=exact',
        },
      })
      .then((res) => {
        if (!res) {
          return this.onResponse();
        }
        try {
          return parseInt(res.headers['content-range'].split('/')[1], 10);
        } catch (e) {
          return this.onResponse();
        }
      })
      .catch(this.onError);
  }
}

const subscribersService = new SubscribersService();

export default subscribersService;
