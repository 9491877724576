import types from '../types';

const width =
  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
const isTabletOrMobile = width < 1224;

const initialState = {
  // hide the sidebar by default on small screens
  isShown: !isTabletOrMobile
};

const sidebar = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SIDEBAR_VISIBILITY:
      return {
        ...state,
        isShown: action.payload.shouldShow
      };

    default:
      return state;
  }
};

export default sidebar;
