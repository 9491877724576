import React, { useEffect } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { useTranslation } from 'react-i18next';
import Spinner from '@atlaskit/spinner';
import Button from '@atlaskit/button';
import EmptyConf from '../components/layout/empty';
import { selectConf, findAllConfs } from '../store/actions/confs';
import { getSelectedConf } from '../store/selectors/confs';
import { requestStatus } from '../store/request-status';

// we use this HOC to fetch the selected conf and feed it to our wrapped child
// eslint-disable-next-line
export default WrappedComponent => {
  return props => {
    const { t } = useTranslation();
    const { id } = props.match.params;
    const dispatch = useDispatch();
    const { findAllConfsRequestStatus, selectedConfId, selectedConf } = useMappedState(state => ({
      findAllConfsRequestStatus: state.confs.status,
      selectedConfId: state.confs.selectedId,
      selectedConf: getSelectedConf(state)
    }));

    useEffect(() => {
      // don't reselect the conf if we already have it
      if (selectedConf && selectedConf.id === id) {
        return;
      }
      // if we arrived here without passing by the conf list page,
      // we didn't yet fetched our confs, so do it now
      (findAllConfsRequestStatus === requestStatus.UNDONE
        ? dispatch(findAllConfs())
        : Promise.resolve()
      ).then(() => {
        dispatch(selectConf(id));
      });
    }, []); //eslint-disable-line

    if (selectedConf) {
      return <WrappedComponent {...props} selectedConf={selectedConf} />;
    }
    if (findAllConfsRequestStatus !== requestStatus.DONE || !selectedConfId) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh'
          }}
        >
          <Spinner size="large" />
        </div>
      );
    }
    return (
      <EmptyConf
        entityProps={{
          description: t('confs:home:error:description'),
          primaryAction: (
            <Button onClick={() => window.location.reload()}>
              {t('confs:home:error.refresh')}
            </Button>
          )
        }}
        entityName="confs:home"
        showArrow={false}
        showLogo
        withMargin
      />
    );
  };
};
