import React from 'react';
import styled from 'styled-components';
import { format } from '../../services/DatesService';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';

const DateWrapper = styled.div`
  background-color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  ${props =>
    props.withShadow
      ? 'box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.04);'
      : ''}
  display: flex;
  align-items: center;
  color: #000;
  text-transform: capitalize;
  ${props => (props.onClick ? 'cursor: pointer;' : '')}
  &:hover {
    ${props =>
      props.withShadow
        ? 'box-shadow: 0 1px 3px 4px rgba(0, 0, 0, 0.08), 0 1px 2px 0px rgba(0, 0, 0, 0.04);'
        : ''}
  }
  transition: all 0.2s ease-in-out;
`;

const DateContent = styled.div`
  display: flex;
  flex-direction: ${props => (props.times ? 'row' : 'column')};
  align-items: center;
`;

// eslint-disable-next-line
export default ({ style, from, to, times, withShadow = true, onClick }) => {
  const dateFrom = new Date(from);
  const dateTo = new Date(to);
  return (
    <DateWrapper
      times={times}
      withShadow={withShadow}
      style={style || {}}
      onClick={onClick}
      className="onboarding-conf-date"
    >
      <DateContent times={times}>
        <span style={{ fontSize: times ? 15 : 20 }}>{format(dateFrom, times ? "HH':'" : 'dd')}</span>
        <span style={{ fontSize: times ? 15 : 12 }}>{format(dateFrom, times ? 'mm' : 'MMM')}</span>
      </DateContent>
      {dateFrom <= dateTo && (
        <>
          <ArrowRightIcon size={times ? 'small' : null} primaryColor="var(--blue-2)" />
          <DateContent times={times}>
            <span style={{ fontSize: times ? 15 : 20 }}>{format(dateTo, times ? "HH':'" : 'dd')}</span>
            <span style={{ fontSize: times ? 15 : 12 }}>{format(dateTo, times ? 'mm' : 'MMM')}</span>
          </DateContent>
        </>
      )}
    </DateWrapper>
  );
};
