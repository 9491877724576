import { getItem, setItem, removeItem } from './StorageService';

const ONBOARDING_KEY = 'ONBOARDING';

export const setDone = () => {
  setItem(ONBOARDING_KEY, 'done');
};

export const setUndone = () => {
  setItem(ONBOARDING_KEY, 'undone');
};

export const getStatus = () => {
  return getItem(ONBOARDING_KEY) || '';
};

export const remove = () => {
  removeItem(ONBOARDING_KEY);
};
