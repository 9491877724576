import types from '../types';
import { requestStatus } from '../request-status';

const initialState = {
  count: 0,
  status: requestStatus.UNDONE,
  error: '',
};

const subscribers = (state = initialState, action) => {
  switch (action.type) {
    case types.COUNT_ALL_SUBSCRIBERS_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: '',
      };

    case types.COUNT_ALL_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        count: action.payload,
        status: requestStatus.DONE,
        error: '',
      };

    case types.COUNT_ALL_SUBSCRIBERS_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default subscribers;