import types from '../types';
import AccountService from '../../services/AccountService';
import handleError from '../utils/errors';
import { IS_24_HOUR_TIME } from '../../constants';
import * as StorageService from '../../services/StorageService';
import { LOCALES_MAP } from '../../i18n/i18n';

export const sendEmailVerification = () => dispatch => {
  dispatch({ type: types.SEND_VERIFICATION_EMAIL_REQUEST });
  return AccountService.sendEmailVerification()
    .then(() => {
      dispatch({ type: types.SEND_VERIFICATION_EMAIL_SUCCESS });
    })
    .catch(e => handleError(types.SEND_VERIFICATION_EMAIL_ERROR, e, dispatch));
};

export const consent = () => dispatch => {
  dispatch({ type: types.CONSENT_REQUEST });
  return AccountService.updateUserConsent(true)
    .then(() => {
      dispatch({ type: types.CONSENT_SUCCESS });
    })
    .catch(e => handleError(types.CONSENT_ERROR, e, dispatch));
};

export const getAccount = () => dispatch => {
  dispatch({ type: types.GET_ACCOUNT_REQUEST });
  return AccountService.getAccount()
    .then(account => {
      dispatch({ type: types.GET_ACCOUNT_SUCCESS, payload: account });
    })
    .catch(e => handleError(types.GET_ACCOUNT_ERROR, e, dispatch));
};

export const updateAccount = data => dispatch => {
  dispatch({ type: types.UPDATE_ACCOUNT_SUCCESS, payload: data });
};

export const removePaymentMethod = id => dispatch => {
  dispatch({ type: types.REMOVE_PAYMENT_METHOD_REQUEST, payload: { id } });
  return AccountService.removePaymentMethod(id)
    .then(() => {
      dispatch({ type: types.REMOVE_PAYMENT_METHOD_SUCCESS, payload: { id } });
      dispatch(getAccount());
    })
    .catch(e => handleError(types.REMOVE_PAYMENT_METHOD_ERROR, e, dispatch));
};

export const getInvoices = () => dispatch => {
  dispatch({ type: types.GET_INVOICES_REQUEST });
  return AccountService.getInvoices()
    .then(invoices => {
      dispatch({ type: types.GET_INVOICES_SUCCESS, payload: invoices });
    })
    .catch(e => handleError(types.GET_INVOICES_ERROR, e, dispatch));
};

export const get24HourTime = () => dispatch => {
  let is24HourTime = StorageService.getItem(IS_24_HOUR_TIME);

  if (is24HourTime === undefined) {
    is24HourTime = window.navigator.language !== LOCALES_MAP.en;
    StorageService.setItem(IS_24_HOUR_TIME, is24HourTime);
  }

  dispatch({
    type: types.SET_TIME_FORMAT,
    payload: is24HourTime
  });
};

export const set24HourTime = payload => dispatch => {
  StorageService.setItem(IS_24_HOUR_TIME, payload);
  dispatch({ type: types.SET_TIME_FORMAT, payload });
};
