const wizard = {
  GET_WIZARD_REQUEST: 'GET_WIZARD_REQUEST',
  GET_WIZARD_SUCCESS: 'GET_WIZARD_SUCCESS',

  // save into localStorage
  SAVE_WIZARD_REQUEST: 'SAVE_WIZARD_REQUEST',
  SAVE_WIZARD_SUCCESS: 'SAVE_WIZARD_SUCCESS',

  // create it on the backend
  CREATE_WIZARD_REQUEST: 'CREATE_WIZARD_REQUEST',
  CREATE_WIZARD_SUCCESS: 'CREATE_WIZARD_SUCCESS',
  CREATE_WIZARD_ERROR: 'CREATE_WIZARD_ERROR'
};

export default wizard;
