import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { isEmpty } from 'lodash/fp';
import { FEATURES_LIMITS_MAP, UNLIMITED_VALUE } from '../../../constants';
import CheckIcon from '@atlaskit/icon/glyph/check';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { isBoolean } from 'lodash';
import getEmojiForPlan from '../../layout/emoji-plan';
import LimitsWarning from './limits-warning';
import MissingFeatures from './missing-features';

const LimitsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 890px;
  margin: 0 auto;
  
  @media (min-width: 960px) {
    flex-direction: row;
    align-items: stretch;
  }
`;

const LimitsTitle = styled.p`
  margin: 0 0 15px;
  font-size: 1.25em;
  font-weight: 500;
`;

const Limits = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 20px 40px 30px;
  margin-bottom: 30px;  
  border-radius: 10px;
  background-color: #ecffeb; 
  border: 2px solid #8ade85;
`;

const LimitsContent = styled.ul`
  display: grid;
  grid-gap: 8px 10px;
  margin: 0;
  padding: 0;
  
  @media (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
    
  @media (min-width: 600px) {
    max-width: 585px;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Limit = styled.li`
  list-style: none;
`;

const LimitName = styled.span`
  margin-left: 5px;
  font-size: 0.9em;
`;

// eslint-disable-next-line
export default ({
  total,
  plans,
  currentPlanId,
  suggestions,
  overused,
  isConfActivated,
  onPlanChoose,
  hasPaymentMethod,
  isConfUpgradable
}) => {
  const { t } = useTranslation();

  return (
    <LimitsWrapper>
      <Limits>
        <LimitsTitle>{t(`conf_plans:${currentPlanId}`)} {getEmojiForPlan(currentPlanId)}</LimitsTitle>
        <LimitsContent>
          {Object.values(FEATURES_LIMITS_MAP).map((name, key) => {
            const limitTotal = total[name] === UNLIMITED_VALUE
              ? t('common:unlimited')
              : total[name];

            return (
              <Limit key={key}>
                {isBoolean(limitTotal) && !limitTotal
                  ? <CrossIcon primaryColor="#df4841" size="small" />
                  : <CheckIcon primaryColor="#58be40" size="small" />
                }
                <LimitName>{t(`limits:${name}`, { count: limitTotal})}</LimitName>
              </Limit>
            );
          })}
        </LimitsContent>
      </Limits>
      {!isConfActivated && !isEmpty(overused)
        ? <MissingFeatures
          limits={overused}
          onChoose={onPlanChoose}
        />
        : !isEmpty(suggestions) && (
          <LimitsWarning
            limits={total}
            suggestions={suggestions}
            plans={plans}
            onPlanChoose={onPlanChoose}
            hasPaymentMethod={hasPaymentMethod}
            isConfUpgradable={isConfUpgradable}
          />
        )
      }

    </LimitsWrapper>
  );
}
