import * as store from 'store';

export const getItem = key => {
  return store.get(key);
};

export const setItem = (key, payload) => {
  return store.set(key, payload);
};

export const removeItem = key => {
  store.remove(key);
};
