import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import AvatarGroup from '@atlaskit/avatar-group';
import DateCmp from '../../utils/date';
import { DEFAULT_COLOR, DEFAULT_LOGO } from '../../../store/reducers/theme';
import Badge from '../../layout/badge'
import { useTranslation } from 'react-i18next';
import ConfButtons from '../conf-buttons';

const ConfList = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
  @media (min-width: 640px) {
    justify-content: flex-start;
  }
`;

const Card = styled.div`
  margin: 10px;
  border-radius: 15px;
  width: 250px;
  box-shadow: #dfe8f0 0 1px 4px;
  overflow: hidden;
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-2px);
  }
`;

const Heading = styled(Link)`
  color: #000000;
    
  &:hover {
    opacity: 0.7;
  }
`;

const ImageWrapper = styled(Link)`
  background-color: ${props => props.color};
  position: relative;
  min-height: 75px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatusBadge = styled(Badge)`
  position: absolute;
  top: 12px;
  left: 12px;
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  max-height: 125px;
`;

const CardContent = styled.div`
  padding: 0 10px;
  margin-top: 10px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 20px;
`;

const Desc = styled.div`
  color: var(--gray-3);
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// eslint-disable-next-line
export default ({ confs }) => {
  const { t } = useTranslation();

  return (
    <ConfList>
      {confs.map(conf => (
        <Card key={conf.id} color={conf.theme ? conf.theme.color : DEFAULT_COLOR}>
          <ImageWrapper
            to={`/conf/${conf.id}`}
            color={conf.theme ? conf.theme.color : DEFAULT_COLOR}
          >
            <StatusBadge>{t(`common:${conf.activated ? 'active' : 'inactive'}`)}</StatusBadge>
            {!isEmpty(conf.speakers) && (
              <div style={{ marginTop: 5, position: 'absolute', right: 10, bottom: 5 }}>
                <AvatarGroup
                  maxCount={4}
                  appearance="stack"
                  data={conf.speakers.map(speaker => ({
                    key: speaker.id,
                    name: speaker.name,
                    src: speaker.picture,
                    appearance: 'circle',
                    enableTooltip: true
                  }))}
                  size="small"
                />
              </div>
            )}
            <Image src={conf.theme ? conf.theme.logo : DEFAULT_LOGO} />
          </ImageWrapper>
          <CardContent>
            <DateCmp
              from={conf.from}
              to={conf.to}
              style={{ position: 'absolute', top: '-40px', left: '10px' }}
            />
            <div style={{ textAlign: 'right', width: '100%', marginBottom: '8px' }}>
              <Badge>{t(`conf_plans:${conf.conf_limits.current_plan}`)}</Badge>
            </div>
            <Heading to={`/conf/${conf.id}`}>
              <Title>{conf.name}</Title>
              <Desc title={conf.desc}>{conf.desc}</Desc>
            </Heading>
            <ConfButtons
              event={conf}
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                marginTop: '5px',
                marginBottom: '5px',
                justifyContent: 'center',
                flexShrink: 0
              }}
            />
          </CardContent>
        </Card>
      ))}
    </ConfList>
  );
};
