import React from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { useTranslation } from 'react-i18next';
import Button from '@atlaskit/button';
import Tip from '../../utils/tip';
import AdaptiveModalFooter from '../../utils/adaptive-modal-footer';

// eslint-disable-next-line
export default ({ open, subscribersCount, onConfirm, onClose }) => {
  const { t } = useTranslation();

  const onConfirmClick = () => {
    onConfirm();
    onClose();
  };

  return (
    <>
      <ModalTransition>
        {open && (
          <Modal
            shouldScrollInViewport
            shouldCloseOnOverlayClick={false}
            onClose={onClose}
          >
            <ModalHeader>
              <ModalTitle>{t('notifications:confirm:title')}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <div>{t('notifications:confirm:body', { subscribersCount })}</div>
              <Tip>{t('notifications:confirm:info')}</Tip>
            </ModalBody>
            <AdaptiveModalFooter>
              <Button appearance="primary" onClick={onConfirmClick} autoFocus>
                {t('notifications:confirm:yes')}
              </Button>
              <Button appearance="subtle" style={{ marginLeft: '8px' }} onClick={onClose}>
                {t('common:cancel')}
              </Button>
            </AdaptiveModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};
