// eslint-disable-next-line
import { PLAN_IDS } from '../../constants';

const getEmojiForPlan = plan => {
  return {
    Free: '🔆',
    Pro: '🔥',
    Enterprise: '🚀',
    Ultimate: '🪐️',
    'Non profit': '🌺',
    [PLAN_IDS.BASE]: '🔆',
    [PLAN_IDS.PRO]: '🔥',
    [PLAN_IDS.ENTERPRISE]: '🚀',
    [PLAN_IDS.ULTIMATE]: '🪐️',
    [PLAN_IDS.NON_PROFIT]: '🌺️',
  }[plan] || '';
};

export default getEmojiForPlan;
