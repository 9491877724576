import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tooltip from 'react-tooltip';
import { isBoolean } from 'lodash/fp';
import Button from '@atlaskit/button';
import CheckIcon from '@atlaskit/icon/glyph/check';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { PLAN_IDS, UNLIMITED_VALUE } from '../../../constants';
import getEmojiForPlan from '../../layout/emoji-plan';
import AdaptiveModalFooter from '../../utils/adaptive-modal-footer';

const PricingTableWrapper = styled.div`
  @media (min-width: 480px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -25px;
  }
`;

const PricingPlanContainer = styled.div`
  margin-bottom: 20px;
  min-width: 210px;
  
  @media (min-width: 480px) {
    width: 270px;
    margin-right: 20px; 
  }
`;

const PricingPlan = styled.div`
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid #e0e6ed;
  border-radius: 10px;
  ${props => (props.active ? 'background-color: var(--blue-1); border: 2px solid var(--blue-2);' : '')};
  text-align: center;
`;

const Title = styled.p`
  font-size: 1.25em;
  font-weight: 500;
`;

const Price = styled.div`
  font-size: 32px;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  padding: 15px 0;
`;

const FeaturesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FeaturesContainer = styled.div`
  color: var(--gray-2);
  margin: 5px 0 25px;
  list-style-type: none;
`;

const Feature = styled.div`
  font-size: 14px;
  line-height: 1.8;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const features = [
  { name: 'max_users_per_conf', showValue: true },
  { name: 'max_days_per_conf', showValue: false },
  { name: 'max_rooms_per_conf', showValue: true },
  { name: 'max_links_per_conf', showValue: true },
  { name: 'max_speakers_per_conf', showValue: true },
  { name: 'notifications_per_conf', showValue: true },
  { name: 'max_sessions_per_conf', isHardcoded: true },
  { name: 'conf_can_be_private', showValue: true },
];

const FeatureList = ({ plan }) => {
  const { t } = useTranslation();

  const Icon = ({ included }) => {
    return (
      <span style={{ marginRight: 5 }}>
        {included ? (
          <CheckIcon primaryColor="#58be40" size="small" />
        ) : (
          <CrossIcon primaryColor="#df4841" size="small" />
        )}
      </span>
    );
  };

  return (
    <FeaturesWrapper>
      <FeaturesContainer>
        {features.map(({ name, isHardcoded }) => {
          const value = plan[name];

          if (isHardcoded) {
            return (
              <Feature key={name}>
                <Icon included={true} />
                {' '}
                {t(`conf_plans:features:${name}`)}
              </Feature>
            );
          }

          if (!isBoolean(value) && !value) {
            return <></>;
          }

          const isUnlimited = value === UNLIMITED_VALUE;
          return (
            <Feature key={name}>
              {isBoolean(value) ? <Icon included={value} /> : <Icon included={true} />}
              {' '}
              {t(`conf_plans:features:${name}${isUnlimited ? '_unlimited' : ''}`, { count: value })}
            </Feature>
          );
        })}
      </FeaturesContainer>
    </FeaturesWrapper>
  );
};

function NonProfitButton({ onChoose, isDisabled, isNonProfit, planName }) {
  const { t } = useTranslation();
  const [isNonProfitModalOpen, setNonProfitModalOpen] = useState(false);

  return (
    <>
      <Button
        appearance='primary'
        onClick={ isNonProfit ? onChoose : () => setNonProfitModalOpen(true)}
        isDisabled={isDisabled}
      >
        {isNonProfit ? t('conf_plans:choose', { plan: planName }) : t('conf_plans:request')}
      </Button>
      <ModalTransition>
        {isNonProfitModalOpen && (
          <Modal onClose={() => setNonProfitModalOpen(false)}>
            <ModalHeader>
              <ModalTitle>{t('conf_plans:access_nonprofit', { plan: planName })}</ModalTitle>
            </ModalHeader>
            <ModalBody>{t('conf_plans:request_nonprofit')}</ModalBody>
            <AdaptiveModalFooter>
              <Button appearance="primary" onClick={() => setNonProfitModalOpen(false)}>
                {t('common:ok')}
              </Button>
            </AdaptiveModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}

function PricingTable ({ onChoose, hasPaymentMethod, plans, currentPlanId, isConfUpgradable, isNonProfit }) {
  const { t } = useTranslation();

  return (
    <PricingTableWrapper>
      {plans.map((plan, index) => {
        const planName = plan.name;
        const isCurrentPlan = index === 0;
        const isChooseBtnDisabled = !hasPaymentMethod || !isConfUpgradable || isCurrentPlan;

        return (
          <PricingPlanContainer key={index}>
            <Tooltip />
            <PricingPlan active={plan.id === currentPlanId}>
              <Title>{planName} {getEmojiForPlan(plan.id)}</Title>
              <FeatureList plan={plan} />
              <Price>{t(`upgrade:currency.${plan.currency}`, { content: plan.price })}</Price>
                <ButtonWrapper
                  {...(!hasPaymentMethod
                    ? { 'data-tip': t('billing:payment_method.please_add') }
                    : {})}
                >
                  {plan.id === PLAN_IDS.NON_PROFIT ? (
                    <NonProfitButton
                      isNonProfit={isNonProfit}
                      onChoose={() => onChoose(plan)}
                      isDisabled={isChooseBtnDisabled}
                      planName={planName}
                    />
                  ) : (
                    <Button
                      appearance="primary"
                      onClick={() => onChoose(plan)}
                      isDisabled={isChooseBtnDisabled}
                    >
                      {t('conf_plans:choose', { plan: planName })}
                    </Button>
                  )}
                </ButtonWrapper>
            </PricingPlan>
          </PricingPlanContainer>
        );
      })}
    </PricingTableWrapper>
  );
}

export default PricingTable;
