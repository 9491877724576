import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from '@atlaskit/button';
import FontAwesomeIcon from '../../utils/icons/font-awesome-icon';
import Counter from './counter';
import Tooltip from 'react-tooltip';
import { FEATURE_ID_MAP } from '../../../constants';

const FeaturesContainer = styled.div`
  width: 100%;
  max-width: 440px;
  margin-bottom: 30px;
`;

const Feature = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  min-width: 210px;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #e0e6ed;
  border-radius: 4px;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  @media (min-width: 480px) {
    max-width: 600px;
  }
`;

const FeaturePrice = styled.p`
  margin: 0;
  font-size: 0.9em;
  font-weight: 500;
    
  @media (min-width: 480px) {
    font-size: 1.25em;
  }
`;

const FeatureName = styled.p`
  width: 30%;
  overflow: hidden;
  margin: 0 8px 0;
  font-size: 0.9em;
  font-weight: 500;
  
  @media (min-width: 480px) {
    width: 40%;
    margin: 0 16px 0 8px;
    font-size: 1.25em;
  }
`;

const FeatureButtons = styled.div`
  margin-left: auto;
`;

// eslint-disable-next-line
export default ({ features, hasPaymentMethod, isConfUpgradable, onChoose }) => {
  const { t } = useTranslation();


  return (
    <FeaturesContainer>
      {!!features.length && (
        <div>
          <Tooltip />
          {features.map((feature) => {
            const featureQuantityField = FEATURE_ID_MAP[feature.id];
            return (
              <Feature key={feature.id}>
                <FeatureName>
                  {t(`upgrade:feature:${featureQuantityField}`, { count: feature[featureQuantityField] })}
                </FeatureName>
                <FeaturePrice>
                  {t(`upgrade:currency.${feature.currency}`, { content: feature.price })}
                </FeaturePrice>
                <FeatureButtons>
                  {feature.isInCart
                    ? <Counter item={feature} onChange={onChoose}/>
                    : <div
                      {...(!hasPaymentMethod
                        ? { 'data-tip': t('billing:payment_method.please_add') }
                        : {})}
                    >
                      <Button
                        style={{ padding: '0 6px' }}
                        appearance="primary"
                        onClick={() => onChoose(feature)}
                        isDisabled={!hasPaymentMethod || !isConfUpgradable}
                      >
                        <FontAwesomeIcon icon={"cart-plus"} size={'1x'} />
                      </Button>
                    </div>
                  }
                </FeatureButtons>
              </Feature>
            );
          })}
        </div>
      )}
    </FeaturesContainer>
  );
};
