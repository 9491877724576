import { combineReducers } from 'redux';
import auth from './auth';
import confs from './confs';
import rooms from './rooms';
import sessions from './sessions';
import speakers from './speakers';
import theme from './theme';
import links from './links';
import flags from './flags';
import wizard from './wizard';
import plans from './plans';
import account from './account';
import subscription from './subscription';
import sidebar from './sidebar';
import progress from './progress';
import notifications from './notifications';
import subscribers from './subscribers';
import products from './products';
import upgrade from './upgrade';
import dates from './dates';
import cart from './cart';
import settings from './settings';

export default combineReducers({
  auth,
  confs,
  rooms,
  sessions,
  speakers,
  theme,
  links,
  flags,
  wizard,
  plans,
  account,
  subscription,
  sidebar,
  progress,
  notifications,
  subscribers,
  products,
  upgrade,
  dates,
  cart,
  settings
});
