const subscription = {
  GET_SUBSCRIPTION_REQUEST: 'GET_SUBSCRIPTION_REQUEST',
  GET_SUBSCRIPTION_SUCCESS: 'GET_SUBSCRIPTION_SUCCESS',
  GET_SUBSCRIPTION_ERROR: 'GET_SUBSCRIPTION_ERROR',

  UPDATE_SUBSCRIPTION_REQUEST: 'UPDATE_SUBSCRIPTION_REQUEST',
  UPDATE_SUBSCRIPTION_SUCCESS: 'UPDATE_SUBSCRIPTION_SUCCESS',
  UPDATE_SUBSCRIPTION_NEED_VERIFICATION: 'UPDATE_SUBSCRIPTION_NEED_VERIFICATION',
  UPDATE_SUBSCRIPTION_ERROR: 'UPDATE_SUBSCRIPTION_ERROR',

  CANCEL_SUBSCRIPTION_REQUEST: 'CANCEL_SUBSCRIPTION_REQUEST',
  CANCEL_SUBSCRIPTION_SUCCESS: 'CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_ERROR: 'CANCEL_SUBSCRIPTION_ERROR'
};

export default subscription;