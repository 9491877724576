import types from '../types';
import RoomsService from '../../services/RoomsService';
import handleError from '../utils/errors';
import { updateEntityProgress } from './progress';

export const createRoom = room => dispatch => {
  dispatch({
    type: types.CREATE_ROOM_REQUEST
  });
  return RoomsService.create(room)
    .then(createdRoom => {
      dispatch(updateEntityProgress('rooms', true));
      return dispatch({
        type: types.CREATE_ROOM_SUCCESS,
        payload: createdRoom
      });
    })
    .catch(err => handleError(types.CREATE_ROOM_ERROR, err, dispatch));
};

export const updateRoom = room => dispatch => {
  return RoomsService.update(room).then(updatedRoom => {
    dispatch(updateEntityProgress('rooms', true));
    return dispatch({
      type: types.UPDATE_ROOM_SUCCESS,
      payload: updatedRoom
    });
  });
};

export const findRooms = confId => dispatch => {
  dispatch({
    type: types.FIND_ALL_ROOMS_REQUEST
  });
  return RoomsService.findAllOfConf(confId)
    .then(confs => {
      return dispatch({
        type: types.FIND_ALL_ROOMS_SUCCESS,
        payload: confs
      });
    })
    .catch(err => handleError(types.FIND_ALL_ROOMS_ERROR, err, dispatch));
};

export const deleteRoom = (room, remainingRooms) => dispatch => {
  return RoomsService.delete(room.id)
    .then(() => {
      return dispatch({
        type: types.DELETE_ROOM_SUCCESS,
        payload: room
      });
    })
    .then(() => {
      return dispatch(updateEntityProgress('rooms', remainingRooms - 1 > 0));
    });
};
