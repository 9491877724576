// Stripe supported countries
const countries = [
  "AE",
  "AR",
  "AT",
  "AU",
  "BD",
  "BE",
  "BG",
  "BR",
  "CA",
  "CH",
  "CL",
  "CO",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EC",
  "EE",
  "EG",
  "ES",
  "FI",
  "FR",
  "GB",
  "GH",
  "GI",
  "GR",
  "HK",
  "HR",
  "HU",
  "ID",
  "IE",
  "IL",
  "IN",
  "IT",
  "JP",
  "KE",
  "KR",
  "KW",
  "KZ",
  "LI",
  "LT",
  "LU",
  "LV",
  "MA",
  "MT",
  "MX",
  "MY",
  "NG",
  "NL",
  "NO",
  "NZ",
  "PE",
  "PH",
  "PK",
  "PL",
  "PT",
  "QA",
  "RO",
  "RU",
  "SA",
  "SE",
  "SG",
  "SI",
  "SK",
  "TH",
  "TR",
  "TW",
  "UA",
  "US",
  "VN",
  "ZA",
];

export const getCountries = t => {
  return countries
    .map(country => ({
      label: t(`countries:${country}`),
      value: country
    }))
    .sort((a, b) => a.label > b.label ? 1 : -1)
};
