import React from 'react';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

// eslint-disable-next-line
export default ({ children }) => {
  const { i18n } = useTranslation();
  return (
    <Elements locale={i18n.language} stripe={stripePromise}>
      {children}
    </Elements>
  );
};
