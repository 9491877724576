import React, { useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const createStateFromHtml = (html) => {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  return EditorState.createWithContent(contentState);
};

// eslint-disable-next-line
export default ({ onChange, onFocus, onBlur, value, locale = 'fr' }) => {
  const defaultState = value ? createStateFromHtml(value) : EditorState.createEmpty();
  const [editorState, setEditorState] = useState(defaultState);

  const onContentChange = () => {
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <div>
      <Editor
        editorState={editorState}
        editorStyle={{
          border: 1,
          borderColor: '#F1F1F1',
          borderStyle: 'solid',
          padding: 5,
          height: 150,
        }}
        onEditorStateChange={(state) => setEditorState(state)}
        localization={{ locale }}
        onChange={onContentChange}
        onFocus={onFocus}
        onBlur={onBlur}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'emoji',
            'link',
            'image',
            'list',
            'textAlign',
            'colorPicker',
            'remove',
            'history',
          ],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
        }}
      />
    </div>
  );
};
