import React, { useEffect, useState } from 'react';
import ConfirmProductModal from './confirm-product-modal';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Button from '@atlaskit/button';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@atlaskit/icon/glyph/check';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { COUNTABLE_FEATURE_ID_MAP } from '../../../constants';
import { addProductToCart, emptyCart, removeProductFromCart } from '../../../store/actions/cart';

const Cart = styled.section`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 210px;
  width: 100%;
  padding: 25px 25px;
  margin-bottom: 30px;
  border-radius: 10px;
  background-color: var(--blue-1); 
  border: 2px solid var(--blue-2);
  
  @media (min-width: 480px) {
    max-width: 280px;
  }  
    
  @media (min-width: 960px) {
    margin-left: 25px;  
  }
`;

const CartTitle = styled.p`
  margin: 0 0 15px;
  font-size: 1.17em;
  font-weight: 500;
`;

const CartContent = styled.div`
  overflow: auto;
  margin-bottom: 20px;
  font-size: 0.9em;
  line-height: 1;

  @media (min-width: 640px) {
    font-size: inherit;
  }
`;

const CartItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const CartItemName = styled.p`
  margin: 0 auto 0 5px;
  min-width: 90px;
  font-size: 0.9em;
  line-height: 1;
`;

const CartItemSum = styled.p`
  width: 40px;
  margin: 0 0 0 10px;
  text-align: right;
  font-size: 0.9em;
  line-height: 1;
`;

const CartItemRemove = styled.span`
  margin-left: 10px;
  cursor: pointer;
`;

const CartAmount = styled.div`
  margin: 20px 0 0;
  padding-top: 15px;
  border-top: 1px solid var(--blue-2);
`;

const CartTaxTotal = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  margin-bottom: 7px;
  color: var(--gray-2);
  font-size: 0.9em;
`;

const CartTotal = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
`;

const CartTotalName = styled(CartItemName)`
  font-size: 1.17em;
  font-weight: 500;
`;

const CartSum = styled.p`
  margin: 0;
  font-size: 1.17em;
  font-weight: 500;
`;

const CartButtonWrapper = styled.div`
  margin-top: auto;
  text-align: center;
`;

const getCartSum = (cart, tax) => {
  const cartSum = cart.reduce((sum, item) => sum + item.quantity * item.price, 0);

  return cartSum + tax;
};

const getTaxForCart = (cart) => {
  const res = cart.reduce((sum, item) => {
    const itemTax = item.tax_rate_percentage || 0;
    return sum + item.quantity * item.price * itemTax / 100;
  }, 0);

  return Math.round(res * 100) / 100;
};

// eslint-disable-next-line
export default ({ product, onChange, onSubmit, innerRef }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const { cart } = useMappedState((state) => ({
    cart: state.cart.products,
  }));

  useEffect(() => {
    if (!product) {
      return;
    }

    dispatch(addProductToCart(product));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, dispatch]);

  const removeItem = (item) => {
    dispatch(removeProductFromCart(item.id));
    onChange(item);
  };

  const onCartSubmit = () => {
    setConfirmModalOpen(true);
  };

  const onConfirm = () => {
    onSubmit(cart);
    dispatch(emptyCart);
  };

  const taxPercent = cart && cart.length ? cart[0].tax_rate_percentage : 0;
  const cartTax = getTaxForCart(cart);

  return (
    <>
      {confirmModalOpen &&
        <ConfirmProductModal
          products={cart}
          onConfirm={onConfirm}
          onClose={() => setConfirmModalOpen(false)}
        />
      }
      <Cart ref={innerRef}>
        <CartTitle>{t('upgrade:cart')}</CartTitle>
        {!cart.length
          ? <p>{t('upgrade:cart_empty')}</p>
          : <>
            <CartContent>
              {cart.map((item, i) => {
                const quantity = item.quantity * item[COUNTABLE_FEATURE_ID_MAP[item.id]];

                return <CartItem key={i}>
                  <CheckIcon primaryColor="#58be40" size="small" />
                  <CartItemName>
                    {t(`upgrade:product:${item.id}`, { quantity })}
                  </CartItemName>
                  <CartItemSum>
                    {t(`upgrade:currency.${item.currency}`, { content: item.price * item.quantity })}
                  </CartItemSum>
                  <CartItemRemove onClick={() => removeItem(item)}>
                    <CrossIcon size='small' />
                  </CartItemRemove>
                </CartItem>
              })}
              <CartAmount>
                {taxPercent && (
                  <CartTaxTotal>
                    <span>{t('upgrade:tax', { percent: `${taxPercent}` })}</span>
                    <span>
                      {t(`upgrade:currency.${cart[0].currency}`, { content: cartTax })}
                    </span>
                  </CartTaxTotal>
                )}
                <CartTotal>
                  <CartTotalName>{t('upgrade:total')}</CartTotalName>
                  <CartSum>
                    {t(`upgrade:currency.${cart[0].currency}`, { content: getCartSum(cart, cartTax) })}
                  </CartSum>
                </CartTotal>
              </CartAmount>
            </CartContent>
            <CartButtonWrapper>
              <Button appearance="primary" onClick={() => onCartSubmit()}>
                {t('upgrade:pay')}
              </Button>
            </CartButtonWrapper>
          </>
        }
      </Cart>
    </>
  );
};
