import React from 'react';
import styled from 'styled-components';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import { format } from '../../services/DatesService';

const TimeWrapper = styled.div`
  background-color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  transition: all 0.2s ease-in-out;
`;

const TimeContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
`;

export const TimeCmp = ({ from, to, timeFormat }) => {
  const dateFrom = new Date(from);
  const dateTo = new Date(to);

  return (
    <TimeWrapper>
      <TimeContent>{format(dateFrom, timeFormat)}</TimeContent>
      {dateFrom <= dateTo && (
        <>
          <ArrowRightIcon size="small" primaryColor="var(--blue-2)" />
          <TimeContent>{format(dateTo, timeFormat)}</TimeContent>
        </>
      )}
    </TimeWrapper>
  );
};
