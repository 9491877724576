const upgrade = {
  CHECK_UPGRADABLE_REQUEST: 'CHECK_UPGRADABLE_REQUEST',
  CHECK_UPGRADABLE_SUCCESS: 'CHECK_UPGRADABLE_SUCCESS',
  CHECK_UPGRADABLE_ERROR: 'CHECK_UPGRADABLE_ERROR',

  UPGRADE_CONF_REQUEST: 'UPGRADE_CONF_REQUEST',
  UPGRADE_CONF_SUCCESS: 'UPGRADE_CONF_SUCCESS',
  UPGRADE_CONF_ERROR: 'UPGRADE_CONF_ERROR',

  UPGRADE_CONF_NEED_VERIFICATION: 'UPGRADE_CONF_NEED_VERIFICATION',
  UPGRADE_CONF_CANCEL_VERIFICATION: 'UPGRADE_CONF_CANCEL_VERIFICATION',

  CONF_LIMITS_REQUEST: 'CONF_LIMITS_REQUEST',
  CONF_LIMITS_SUCCESS: 'CONF_LIMITS_SUCCESS',
  CONF_LIMITS_ERROR: 'CONF_LIMITS_ERROR',
};

export default upgrade;
