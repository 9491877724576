import React, { useState } from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import styled from 'styled-components';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism.css';

const StyledEditor = styled(Editor)`
  font-size: 14px;
  border-color: #dfe1e6;
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  transition: background-color 0.2s, border-color 0.2s;
  min-height: 120px;
  &:hover {
    background-color: #ebecf0;
  }
  &:focus-within {
    border-color: #4c9aff;
    background-color: transparent;
  }
`;

// eslint-disable-next-line
export default ({ onChange, onFocus, onBlur, value, locale = 'fr' }) => {
  const [code, setCode] = useState(value);

  const onCodeChange = (data) => {
    setCode(data);
    onChange(data);
  };

  return (
    <StyledEditor
      value={code}
      padding={20}
      className="container__editor"
      onValueChange={data => onCodeChange(data)}
      highlight={code => {
        return highlight(code, languages.html)
          .split('\n')
          .map(line => `<span class="container_editor_line_number">${line}</span>`)
          .join('\n');
      }}
    />
  );
};
