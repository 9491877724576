import styled from 'styled-components';

export default styled.section`
  display: inline-block;
  padding: 17px 15px 12px;
  font-size: 0.9em;
  line-height: 1.7;
  background-color: var(--blue-3);

  @media (min-width: 640px) {
    padding: 21px 15px 19px;
  }
`;


