import HttpService from './HttpService';
import { setHours } from 'date-fns/esm';

class SessionsService extends HttpService {
  entity = 'sessions';

  create(payload) {
    return this.http
      .post('/session', payload, {
        headers: this.defaultHeaders
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  createFake(t, confId, roomId, createdAt, date) {
    const currentHour = (new Date()).getHours();
    const confDate = new Date(date);

    const from = setHours(confDate, currentHour);
    const to = setHours(confDate, currentHour + 1);

    return this.create({
      name: t('confs:mock:name'),
      desc: t('confs:mock:desc'),
      from,
      to,
      tags: [t('confs:mock:tag'), 'conf.app'],
      room_id: roomId,
      conf_id: confId,
      updated_at: createdAt
    });
  }

  update(payload) {
    return this.http
      .patch('/session', payload, {
        headers: this.defaultHeaders,
        params: {
          id: `eq.${payload.id}`
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  findAllOfConf(confId) {
    return this.http
      .get(
        '/session',
        {
          params: {
            conf_id: `eq.${confId}`,
            order: 'from.asc,to.asc,name',
            select: '*,room(id,name),speakers:session_speaker(speaker(id,picture,name)),apps:session_link(*),conf(timezone),session_registration(mobile_user_id)'
          }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  delete(sessionId) {
    return this.http
      .delete(`/session`, {
        headers: this.defaultHeaders,
        params: {
          id: `eq.${sessionId}`
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }
}

const sessionsService = new SessionsService();

export default sessionsService;
