import HttpService from './HttpService';

class ProgressService extends HttpService {
  findAll(confId) {
    return this.http
      .get('/rpc/created_entities', {
        params: {
          current_conf_id: confId
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }
}

const progressService = new ProgressService();

export default progressService;
