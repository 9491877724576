import React from 'react';
import styled from 'styled-components';
import InlineEdit from '@atlaskit/inline-edit';
import Textfield from '@atlaskit/textfield';
import { ErrorMessage } from '@atlaskit/form';
import { useTranslation } from 'react-i18next';

const Code = styled.div`
  text-align: center;
  
  & input {
    text-align: center;
  }
  
  @media (min-width: 640px) {
    display: flex;
    min-height: 48px;
    text-align: left;
    
    & input {
      text-align: left;
    }
  }
`;

const CodeValue = styled.div`
  padding: 11px 5px 10px;
  color: var(--blue-2);
  font-size: 14px;
`;

const CodeCaption = styled.div`
  margin-top: 18px;
  
  @media (min-width: 640px) {
    padding-left: 24px;
    padding-right: 3px;
  }
`;

// eslint-disable-next-line
export default ({ code = 'CONFAPP', onCodeConfirm }) => {
  const { t } = useTranslation();

  return (
    <Code>
      <CodeCaption>{t('confs:home.private.token')}:</CodeCaption>
      <InlineEdit
        readViewFitContainerWidth={true}
        defaultValue={code}
        onConfirm={onCodeConfirm}
        validate={code => (code.length < 3 ? 'error' : null)}
        editView={fieldProps => (
          <>
            <Textfield {...fieldProps} />
            {fieldProps.isInvalid && (
              <ErrorMessage>{t('confs:home.private.validation_error')}</ErrorMessage>
            )}
          </>
        )}
        readView={() => (
          <CodeValue>{code}</CodeValue>
        )}
      />
    </Code>
  );
}
