import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../layout/card';
import Button from '@atlaskit/button';
import Tour, { Controls, Dot, Arrow, Navigation } from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { home } from './steps';

export const HomeTour = ({ finishOnboarding }) => {
  const { t } = useTranslation();

  const disableBody = target => disableBodyScroll(target)
  const enableBody = target => enableBodyScroll(target)

  const CustomHelper = ({ current, totalSteps, gotoStep, content, close }) => {
    return (
      <Card style={{ padding: 15, maxWidth: 450, borderRadius: 5 }}>
        <div style={{ padding: '15px 15px 0' }}>{content}</div>
        <Controls data-tour-elem="controls">
          <Arrow onClick={() => gotoStep(current - 1)} disabled={current === 0} />
          <span
            onClick={() => gotoStep(current - 1)}
            disabled={current === 0}
            style={{ marginRight: 15, cursor: 'pointer' }}
          >
            {t('common:prev')}
          </span>
          <Navigation data-tour-elem="navigation">
            {Array.from(Array(totalSteps).keys()).map((li, i) => (
              <Dot
                key={li}
                onClick={() => current !== i && gotoStep(i)}
                current={current}
                index={i}
                disabled={current === i}
                showNumber={true}
                data-tour-elem="dot"
              />
            ))}
          </Navigation>
          {current === totalSteps - 1 ? (
            <div style={{ marginLeft: 15 }}>
              <Button appearance="primary" onClick={() => finishOnboarding(true)}>
                {t('onboarding:finish_button')}
              </Button>
            </div>
          ) : (
            <>
              <span
                onClick={() => gotoStep(current + 1)}
                style={{ marginLeft: 15, cursor: 'pointer' }}
              >
                {t('common:next')}
              </span>
              <Arrow onClick={() => gotoStep(current + 1)} inverted />
            </>
          )}
        </Controls>
      </Card>
    );
  };

  return (
    <Tour
      onAfterOpen={disableBody}
      onBeforeClose={enableBody}
      rounded={5}
      steps={home(t)}
      isOpen={true}
      disableInteraction={true}
      CustomHelper={CustomHelper}
      onRequestClose={() => finishOnboarding()}
    />
  );
};
