import { createSelector } from 'reselect';
import { requestStatus } from '../request-status';

const fetchStatus = state => state.wizard.fetchStatus;
const saveStatus = state => state.wizard.saveStatus;
const createStatus = state => state.wizard.createStatus;

const isDone = status => status === requestStatus.DONE;

export const isWizardFetched = createSelector(
  fetchStatus,
  isDone
);

export const isWizardSaved = createSelector(
  saveStatus,
  isDone
);

export const isWizardCreating = createSelector(
  createStatus,
  status => status === requestStatus.WAITING
);

export const isWizardCreated = createSelector(
  createStatus,
  isDone
);
