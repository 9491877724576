import { jsx } from 'slate-hyperscript';

export const deserialize = (html) => {
  const document = new DOMParser().parseFromString(html, 'text/html');
  return _deserialize(document.body);
};

const _deserialize = (el, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  // define attributes for text nodes
  switch (el.nodeName) {
    case 'STRONG':
      nodeAttributes.bold = true;
      break;
    case 'CODE':
      nodeAttributes.code = true;
      break;
    case 'EM':
      nodeAttributes.italic = true;
      break;
    case 'U':
      nodeAttributes.underline = true;
      break;
    default:
  }

  const children = Array.from(el.childNodes)
    .map((node) => _deserialize(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  const attr = {};
  const textAlign = el.style?.getPropertyValue('text-align');
  if (textAlign) {
    attr.align = textAlign;
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'BR':
      return '\n';
    case 'H1':
      return jsx('element', { ...attr, type: 'title' }, children);
    case 'H2':
      return jsx('element', { ...attr, type: 'heading-one' }, children);
    case 'H3':
      return jsx('element', { ...attr, type: 'heading-two' }, children);
    case 'BLOCKQUOTE':
      return jsx('element', { ...attr, type: 'block-quote' }, children);
    case 'UL':
      return jsx('element', { ...attr, type: 'bulleted-list' }, children);
    case 'LI':
      return jsx('element', { ...attr, type: 'list-item' }, children);
    case 'OL':
      return jsx('element', { ...attr, type: 'numbered-list' }, children);
    case 'P':
      return jsx('element', { ...attr, type: 'paragraph' }, children);
    case 'A':
      return jsx('element', { ...attr, type: 'link', url: el.getAttribute('href') }, children);
    default:
      return children;
  }
};
