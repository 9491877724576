import React from 'react';
import Button from '@atlaskit/button';
import FontAwesomeIcon from '../icons/font-awesome-icon';
import styled from 'styled-components';

const CloseModalButton = styled(Button)`
  position: absolute;
  right: 18px;
  top: 18px;
  z-index: 2;
`;

// eslint-disable-next-line
export default props => {
  return (
    <CloseModalButton {...props} appearance="subtle">
      <FontAwesomeIcon icon="times" />
    </CloseModalButton>
  );
};
