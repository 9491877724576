import React, { useState, useMemo } from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { useTranslation } from 'react-i18next';
import Button from '@atlaskit/button';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import TextField from '@atlaskit/textfield';
import Form, { Field, ErrorMessage } from '@atlaskit/form';
import Select from '@atlaskit/select';
import { get, has, isNil, mapValues, isEmpty } from 'lodash/fp';
import { getCountries } from './countries';
import AccountService from '../../services/AccountService';
import CloseModalButton from '../utils/modals/close-modal-button';

const CardWrapper = styled.div`
  margin: 5px 0 40px;
`;

const cleanFields = data => {
  return mapValues(value => {
    switch (typeof value) {
      case 'string':
        return value.trim();
      case 'object':
        return cleanFields(value);
      default:
        return value;
    }
  })(data);
};

const PaymentMethodCreate = ({ address = {}, vatNumber = '', name = '', action, onUpdate }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = data => {
    if (isNil(data.country)) {
      // Second fix for Atlaskit's select
      return {
        country: t(`billing:form.errors.empty_country`)
      };
    }

    const payload = cleanFields({
      name: data.name,
      address: {
        line1: data.line1,
        line2: data.line2,
        city: data.city,
        postal_code: data.postal_code,
        country: get('country.value')(data),
        state: data.state,
      },
      vatNumber: data.vatNumber
    });

    const cardElement = elements.getElement(CardElement);
    return stripe
      .createSource(cardElement)
      .then(result => {
        if (result.error) {
          return Promise.reject(result.error);
        }
        // We can't use the store, we need to do informations validation using the backend
        return AccountService.updateAccount({ ...payload, source: result.source.id, lang: i18n.language });
      })
      .then(data => {
        onUpdate(data);
        onClose();
        return undefined; // no error
      })
      .catch(e => {
        if (has('code', e)) {
          // Stripe element errors
          return {
            card: t(`billing:form.errors.${e.code}`)
          };
        }
        if (e.field) {
          return {
            [e.field]: t(`billing:form.errors.${e.hint}`)
          };
        }

        const errorData = e.data;

        const message = errorData && errorData.message
          ? errorData.message
          : t(`billing:form.errors.unexpected`);

        return {
          'card': message,
        };
      });
  };

  const countries = useMemo(() => getCountries(t), [t]);
  const validateString = val => (isEmpty((val || '').trim()) ? t('common:form.empty') : null);

  return (
    <>
      <ModalTransition>
        {open && (
          <Modal
            shouldScrollInViewport
            shouldCloseOnOverlayClick={false}
            onClose={onClose}>
            <ModalHeader>
              <CloseModalButton onClick={onClose} />
              <ModalTitle>{t(`billing:form.title.${action}`)}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={onSubmit}>
                {({ formProps, submitting }) => (
                  <form style={{ paddingBottom: 24 }} {...formProps}>
                    <Field
                      name="name"
                      label={t('billing:form.name')}
                      isRequired
                      defaultValue={name || ''}
                      validate={validateString}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <TextField autoComplete="off" {...fieldProps} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      name="line1"
                      label={t('billing:form.address_line1')}
                      isRequired
                      defaultValue={address.line1 || ''}
                      validate={validateString}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <TextField autoComplete="off" {...fieldProps} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      name="line2"
                      label={t('billing:form.address_line2')}
                      defaultValue={address.line2 || ''}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <TextField autoComplete="off" {...fieldProps} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      name="city"
                      label={t('billing:form.address_city')}
                      isRequired
                      defaultValue={address.city || ''}
                      validate={validateString}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <TextField autoComplete="off" {...fieldProps} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      name="postal_code"
                      label={t('billing:form.address_postal_code')}
                      isRequired
                      defaultValue={address.postal_code || ''}
                      validate={validateString}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <TextField autoComplete="off" {...fieldProps} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      name="country"
                      label={t('billing:form.address_country')}
                      isRequired
                      defaultValue={
                        countries.find(country => country.value === address.country) || undefined
                      }
                    >
                      {({ fieldProps: { id, ...rest }, error }) => (
                        <>
                          <Select
                            {...rest}
                            validationState={error ? 'error' : 'none'}
                            inputId={id}
                            options={countries}
                            isClearable
                            menuPortalTarget={document.body}
                            styles={{
                              container: base => ({ ...base, fontFamily: 'inherit' }),
                              menuPortal: base => ({ ...base, zIndex: 9999 })
                            }}
                            placeholder={t('common:select')}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      name="state"
                      label={t('billing:form.address_state')}
                      defaultValue={address.state || ''}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <TextField autoComplete="off" {...fieldProps} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      name="vatNumber"
                      label={t('billing:form.vatNumber')}
                      defaultValue={vatNumber || ''}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <TextField autoComplete="off" {...fieldProps} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field name="card" label={t('billing:form.card')} isRequired defaultValue="">
                      {({ fieldProps, error }) => (
                        <>
                          <CardWrapper {...fieldProps}>
                            <CardElement options={{ hidePostalCode: true }}/>
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </CardWrapper>
                        </>
                      )}
                    </Field>
                    <Button
                      type="submit"
                      appearance="primary"
                      isLoading={submitting}
                      shouldFitContainer={true}
                    >
                      {t('common:save')}
                    </Button>
                  </form>
                )}
              </Form>
            </ModalBody>
          </Modal>
        )}
      </ModalTransition>
      <Button spacing="compact" onClick={() => setOpen(true)}>{t(`billing:payment_method.${action}`)}</Button>
    </>
  );
};

export default PaymentMethodCreate;
