import { WebAuth } from 'auth0-js';
import { SUPERADMIN_KEY } from '../constants';
import { setItem, removeItem } from './StorageService';
import { setIntercomUser, shutdownIntercom } from './IntercomService';
import HttpService from './HttpService';
import AccountService from './AccountService';

const clientID = process.env.REACT_APP_AUTH0_ID;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const redirectUri = process.env.REACT_APP_AUTH0_REDIRECT;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const auth0Client = new WebAuth({
  domain,
  audience,
  clientID,
  redirectUri,
  responseType: 'id_token',
  scope: 'openid profile email'
});

export const getFullUser = () => {
  const httpService = new HttpService(process.env.REACT_APP_SERVICES_URL);
  return httpService.http
    .get('/account/user')
    .then(httpService.onResponse)
    .catch(httpService.onError);
};

export const handleAuthentication = (lang) => {
  return new Promise((resolve, reject) => {
    auth0Client.parseHash((err, authResult) => {
      if (err) return reject(err);
      if (!authResult || !authResult.idToken) {
        return reject(err);
      }

      const idToken = authResult.idToken;
      setItem('token', idToken);
      const profile = authResult.idTokenPayload;
      // set the time that the id token will expire at
      const expiresAt = authResult.idTokenPayload.exp * 1000;

      getFullUser()
        .then((data) => {
          if (!data.user_metadata?.lang) {
            AccountService.setUserLang(lang);
          }

          return data;
        })
        .then(setIntercomUser)
        .catch(err => {
          // we don't want to break the authentication workflow
          // if we didn't manage to set the Intercom user
          console.error(err);
        });

      resolve({
        authenticated: true,
        idToken,
        profile,
        expiresAt
      });
    });
  });
};

export const exitSuperAdmin = () => {
  removeItem(SUPERADMIN_KEY);
  window.location = '/';
};

export const signIn = (action = 'login', language = 'en') => {
  auth0Client.authorize({ action, language });
};

export const signOut = () => {
  shutdownIntercom();
  auth0Client.logout({
    returnTo: process.env.REACT_APP_URL,
    clientID
  });
};
