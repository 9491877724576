import React from 'react';
import { SlateEditor } from './slate-editor/slate-editor';
import styles from './content-tpl-editor.module.css';

const stylesBaseUrl = process.env.REACT_APP_STYLES_URL || process.env.REACT_APP_URL;

export const ContentTplEditor = ({ onChange, onFocus, onBlur, value, locale = 'fr' }) => {
  const { content, editorClassName } = destructContentValue(value);
  const onEditorChange = (content) => {
    onChange(
      JSON.stringify({
        styles: [`${stylesBaseUrl}/assets/styles/${editorClassName}.css`],
        editorClassName: editorClassName,
        content: content,
      })
    );
  };

  return (
    <div className={styles.container}>
      <SlateEditor
        onChange={onEditorChange}
        value={content}
        editorClassName={editorClassName}
      ></SlateEditor>
    </div>
  );
};

const destructContentValue = (value) => {
  try {
    const { editorClassName, content } = JSON.parse(value);
    return {
      editorClassName: editorClassName,
      content: content,
    };
  } catch {
    return {
      // TODO at the moment only one style is supported
      editorClassName: 'tplEditor',
      content: value,
    };
  }
};
