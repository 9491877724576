import { isEmpty, last } from 'lodash/fp';
import types from '../types';
import { ProductsTypes } from '../../constants';

const initialState = {
  list: []
};
const pushToList = (state, action) => {
  // do not display the same flags in the same time, as it is useless and might confuse the user
  const exist = state.list.find(flag => flag.title === action.payload.title);

  if (exist) {
    return state;
  }

  return {
    ...state,
    list: [
      {
        ...action.payload,
        id: (isEmpty(state.list) ? 0 : last(state.list)) + 1
      },
      ...state.list
    ]
  };
};

const flags = (state = initialState, action) => {
  /* listen for every error action */
  const regex = /.*_ERROR/;

  if (regex.test(action.type) && action.payload.type !== ProductsTypes.PLAN) {
    const payload = {
      title: action.payload.message || 'common:errors.unknown_error',
      timeout: 10000,
      appearance: 'error'
    };

    return pushToList(state, { ...action, payload });
  }

  switch (action.type) {
    case types.ADD_FLAG:
      return pushToList(state, action);

    case types.REMOVE_FLAG:
      return {
        ...state,
        list: state.list.filter(flag => flag.id !== action.payload)
      };

    default:
      return state;
  }
};

export default flags;
