import React, { useContext, useEffect, useRef, useState } from 'react';
import { compose, isBoolean, isEmpty } from 'lodash/fp';
import { __RouterContext, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { useTranslation } from 'react-i18next';
import Spinner from '@atlaskit/spinner';
import Button from '@atlaskit/button';
import CreditcardFilledIcon from '@atlaskit/icon/glyph/creditcard-filled'
import { useStripe } from '@stripe/react-stripe-js';

import ConfSelected from '../../../containers/conf-selected';
import Page from '../../layout/page';
import Info from '../../layout/info';
import { hasPaymentMethod, isAccountLoading } from '../../../store/selectors/account';
import PricingTable from './pricing-table';
import Features from './features';
import Limits from './limits';
import Cart from './cart';
import { findProducts } from '../../../store/actions/products';
import { areProductsLoading, getConfFeatures } from '../../../store/selectors/products';
import { getAccount } from '../../../store/actions/account';
import { getActualConfPlans, isUpgradeLoading } from '../../../store/selectors/upgrade';
import {
  cancelVerification,
  checkUpgradable,
  getConfLimits,
  setUpgradeError,
  upgradeConf
} from '../../../store/actions/upgrade';
import { addFlag } from '../../../store/actions/flags';
import { FEATURES_LIMITS_MAP, ProductsTypes, UNCOUNTABLE_FEATURES, UNLIMITED_VALUE } from '../../../constants';
import { delay } from '../../utils';
import { findAllConfs, selectConf, unSelectConf } from '../../../store/actions/confs';
import { getSelectedConf } from '../../../store/selectors/confs';
import ActivateButton from '../activate-button';
import { getUserNonProfit } from '../../../store/selectors/auth';

const Title = styled.h3`
  margin: 50px 0 2px;
`;

const LimitsTitle = styled(Title)`
  margin: 0 0 35px 0;
  padding-bottom: 6px;
  border-bottom: 1px solid #e1e4e8;
`;

const Subtitle = styled.p`
  padding-bottom: 6px;
  margin: 0 0 40px 0;
  font-size: 0.9em;
  color: var(--gray-2);
  border-bottom: 1px solid #e1e4e8;
`;

const SpinnerWrapper = styled.div`
  text-align: center;
`;

const FeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 890px;
  margin: 0 auto;
  
  @media (min-width: 960px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }
`;

const paymentBanner = t => {
  return {
    message: (
      <p>
        {t('billing:payment_method.no_method')}{' '}
        <Link to="/settings/billing">{t('billing:go_to_billing')}</Link>
      </p>
    ),
    type: 'warning'
  };
};

const finishedConfBanner = (t, selectedConfId) => {
  return {
    message: (
      <p>
        {t('upgrade:restricted')}{' '}
        <Link to={`/conf/${selectedConfId}`}>{t('upgrade:go_to_dashboard')}</Link>
      </p>
    ),
    type: 'warning'
  };
};

const getInitialFeatures = (features, limits) => {
  return features
    .filter(feature => {
      const limitName = FEATURES_LIMITS_MAP[feature.id];
      const limitValue = limits[limitName];
      const isValueBooleanAndTrue = isBoolean(limitValue) && limitValue;

      return !isValueBooleanAndTrue && limitValue !== UNLIMITED_VALUE;
    })
    .map(feature => ({
      ...feature,
      isUncountable: UNCOUNTABLE_FEATURES.includes(feature.id),
    }));
};

const updateFeaturesByCart = (features, cart) => {
  return features.map(feature => {
    const featureFromCart = cart.find(item => item.id === feature.id);

    if (featureFromCart) {
      return {
        ...feature,
        isInCart: true,
        quantity: featureFromCart.quantity
      }
    }

    return feature;
  });
};

const Upgrade = () => {
  const stripe = useStripe();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cartEl = useRef();
  const router = useContext(__RouterContext);
  const [featuresList, setFeaturesList] = useState([]);
  const [productToCart, setProductToCart] = useState(null);

  const {
    shouldAddPaymentMethod,
    isPaymentMethodLoading,
    isLoading,
    selectedConfId,
    selectedConf,
    currentConfPlanId,
    plans,
    features,
    isConfUpgradeLoading,
    isConfUpgradable,
    needVerification,
    paymentIntent,
    confLimits,
    upgradeSuggestions,
    limitsOverused,
    cart,
    isNonProfit
  } = useMappedState(state => ({
    shouldAddPaymentMethod: !hasPaymentMethod(state),
    isPaymentMethodLoading: isAccountLoading(state),
    isLoading: areProductsLoading(state),
    selectedConfId: state.confs.selectedId,
    selectedConf: getSelectedConf(state),
    currentConfPlanId: state.upgrade.confPlanId,
    plans: getActualConfPlans(state),
    features: getConfFeatures(state),
    isConfUpgradeLoading: isUpgradeLoading(state),
    isConfUpgradable: state.upgrade.isUpgradable,
    needVerification: state.upgrade.needVerification,
    paymentIntent: state.upgrade.paymentIntent,
    confLimits: state.upgrade.confLimits,
    upgradeSuggestions: state.upgrade.upgradeSuggestions,
    limitsOverused: state.upgrade.confLimitsOveruse,
    cart: state.cart.products,
    isNonProfit: getUserNonProfit(state)
  }));

  useEffect(() => {
    if (selectedConfId) {
      dispatch(findProducts(selectedConfId));
    }
  }, [selectedConfId, currentConfPlanId, dispatch]);

  useEffect(() => {
    dispatch(getAccount());
  }, [dispatch]);

  useEffect(() => {
    if (selectedConfId) {
      dispatch(checkUpgradable(selectedConfId));
      dispatch(getConfLimits(selectedConfId));
    }
  }, [selectedConfId, dispatch]);

  useEffect(() => {
    if (needVerification) {
      stripe.handleCardPayment(paymentIntent.client_secret)
        .then(({ paymentIntent, error }) => {
          if (!error && paymentIntent.status === 'succeeded') {
            return delay(5000)
              .then(() => dispatch(unSelectConf()))
              .then(() => dispatch(findAllConfs()))
              .then(() => dispatch(selectConf(selectedConfId)))
              .then(() => {
                dispatch(
                  addFlag({
                    appearance: 'success',
                    title: 'upgrade:actions.update.success',
                    timeout: 5000
                  })
                );
              });
          }

          dispatch(setUpgradeError(error ? error.message : 'Unknown error'));
        });

      dispatch(cancelVerification());
    }
  }, [needVerification, paymentIntent, stripe, dispatch, selectedConfId]);

  useEffect(() => {
    if (!confLimits) {
      return;
    }

    const initialFeatures = getInitialFeatures(features, confLimits);
    const updatedByCartFeatures = updateFeaturesByCart(initialFeatures, cart);

    setFeaturesList(updatedByCartFeatures);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [features, confLimits]);

  const onUpgradeSubmit = (items) => {
    const data = items.map((item) => ({
      quantity: item.quantity,
      stripe_price_id: item.stripe_price_id,
    }));

    dispatch(upgradeConf(selectedConfId, data));
    setProductToCart(null);
    setFeaturesList(getInitialFeatures(features, confLimits));
  };

  const onProductChoose = (product) => {
    if (product) {
      const updatedProduct = {
        ...product,
        isInCart: true,
        quantity: product.quantity || 1
      };

      if (product.type === ProductsTypes.FEATURE) {
        const chosenFeatureIndex = featuresList.findIndex(f => f.id === product.id);

        featuresList[chosenFeatureIndex] = updatedProduct;
        setFeaturesList([...featuresList]);
      }

      setProductToCart(updatedProduct);
    }

    cartEl.current.scrollIntoView({ behavior: 'smooth' });
  }

  const onCartChange = (product) => {
    if (product.type === ProductsTypes.FEATURE) {
      const removedFeature = {
        ...product,
        isInCart: false,
        quantity: 0
      };

      const chosenFeatureIndex = featuresList.findIndex(f => f.id === product.id);

      featuresList[chosenFeatureIndex] = removedFeature;
      setFeaturesList([...featuresList]);
    }
  };

  const banners = [
    ...(shouldAddPaymentMethod && !isPaymentMethodLoading ? [paymentBanner(t)] : []),
    ...(!isConfUpgradable && !isConfUpgradeLoading ? [finishedConfBanner(t, selectedConfId)] : [])
  ];

  return (
    <Page
      title={t('upgrade:title')}
      addChildren={
        <Button
          appearance="primary"
          iconBefore={<CreditcardFilledIcon size="small" label="card icon" />}
          onClick={() => router.history.push('/settings/billing')}
        >
            {t('billing:widget_title')}
        </Button>
      }
      banners={banners}
    >
      {isLoading || isConfUpgradeLoading
        ? <SpinnerWrapper><Spinner size="large" /></SpinnerWrapper>
        : <>
          <LimitsTitle>{t('upgrade:current_limits')}</LimitsTitle>
          <Limits
            total={confLimits}
            plans={plans}
            currentPlanId={currentConfPlanId}
            suggestions={upgradeSuggestions}
            overused={limitsOverused}
            isConfActivated={selectedConf.activated}
            onPlanChoose={onProductChoose}
            hasPaymentMethod={!shouldAddPaymentMethod}
            isConfUpgradable={isConfUpgradable}
          />
          <div style={{textAlign: 'center'}}>
            {selectedConf.activated
              ? <Info>{t('upgrade:conf_is_active')}</Info>
              : (
                <ActivateButton
                  isUpgradable={isConfUpgradable}
                  isActivated={selectedConf.activated}
                  isOverused={!isEmpty(limitsOverused)}
                  conf={selectedConf}
                />
              )
            }
          </div>
          <Title>{t('upgrade:plans')}</Title>
          <Subtitle>{t('upgrade:choose_plan')}</Subtitle>
          <PricingTable
            onChoose={onProductChoose}
            hasPaymentMethod={!shouldAddPaymentMethod}
            plans={plans}
            currentPlanId={currentConfPlanId}
            isConfUpgradable={isConfUpgradable}
            isNonProfit={isNonProfit}
          />
          <Title>{t('upgrade:add_features')}</Title>
          <Subtitle>{t('upgrade:improvements')}</Subtitle>
          <FeaturesWrapper>
            <Features
              features={featuresList}
              hasPaymentMethod={!shouldAddPaymentMethod}
              isConfUpgradable={isConfUpgradable}
              onChoose={onProductChoose}
            />
            <Cart
              product={productToCart}
              onChange={onCartChange}
              onSubmit={onUpgradeSubmit}
              innerRef={cartEl}
            />
          </FeaturesWrapper>
        </>
      }
    </Page>
  );
};

export default compose(
  withRouter,
  ConfSelected
)(Upgrade);
