import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';

const CookbookWrapper = styled.div`
  background-color: #d7e8fc;
  border: 2px solid #c4dfff;
  margin-top: 25px;
`;

const CookbookContainer = styled.div`
  padding: 20px;
  font-size: 14px;
`;

const CookbookContent = styled.div`
  white-space: pre-wrap;
  line-height: 22px;
`;

const linkDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer" style={{ color: '#0000ee' }}>
    {text}
  </a>
);

// eslint-disable-next-line
export default ({ link }) => {
  const { t } = useTranslation();
  const cookbook = t(`links:choices:${link.app}:cookbook`);
  if (isEmpty(cookbook)) {
    return <></>;
  }
  return (
    <CookbookWrapper>
      <CookbookContainer>
        <CookbookContent>
          <Linkify componentDecorator={linkDecorator}>{cookbook}</Linkify>
        </CookbookContent>
      </CookbookContainer>
    </CookbookWrapper>
  );
};
