import types from '../types';
import { requestStatus } from '../request-status';
import { getStatus } from '../../services/OnboardingService';
import ConfsService from '../../services/ConfsService';

const initialState = {
  list: [],
  deleteStatus: requestStatus.UNDONE,
  duplicateStatus: requestStatus.UNDONE,
  status: requestStatus.UNDONE,
  error: '',
  selectedId: 0,
  showOnboarding: getStatus() !== 'done'
};

const confs = (state = initialState, action) => {
  switch (action.type) {
    case types.DELETE_CONF_REQUEST:
      return {
        ...state,
        deleteStatus: requestStatus.WAITING,
        error: ''
      };

    case types.DUPLICATE_CONF_REQUEST:
      return {
        ...state,
        duplicateStatus: requestStatus.WAITING,
        error: ''
      };

    case types.SET_SHOW_ONBOARDING:
      return {
        ...state,
        showOnboarding: action.payload
      };

    case types.FIND_ALL_CONFS_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: ''
      };

    case types.FIND_ALL_CONFS_SUCCESS:
      const data = action.payload;
      const list = Array.isArray(data)
        ? data.map(ConfsService.getZonedConfData)
        : [];

      return {
        ...state,
        list,
        status: requestStatus.DONE
      };

    case types.DELETE_CONF_ERROR:
      return {
        ...state,
        deleteStatus: requestStatus.DONE,
        error: action.payload
      };

    case types.DUPLICATE_CONF_ERROR:
      return {
        ...state,
        duplicateStatus: requestStatus.DONE,
        error: action.payload
      };

    case types.FIND_ALL_CONFS_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload
      };

    case types.DUPLICATE_CONF_SUCCESS:
      return {
        ...state,
        duplicateStatus: requestStatus.DONE,
      };

    case types.CREATE_CONF_SUCCESS:
      return {
        ...state,
        list: [...state.list, ConfsService.getZonedConfData(action.payload)],
        error: ''
      };

    case types.DELETE_CONF_SUCCESS:
      return {
        ...state,
        deleteStatus: requestStatus.DONE,
        list: state.list.filter(conf => conf.id !== action.payload.id),
        error: ''
      };

    case types.SELECT_CONF:
      return {
        ...state,
        selectedId: action.payload
      };

    case types.UNSELECT_CONF:
      return {
        ...state,
        selectedId: 0
      };

    case types.UPDATE_CONF_SUCCESS:
      return {
        ...state,
        list: state.list.map(conf => {
          return conf.id === action.payload.id ? { ...conf, ...ConfsService.getZonedConfData(action.payload) } : conf;
        })
      };

    case types.UPDATE_CONF_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: ''
      };

    case types.UPDATE_CONF_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload
      };

    case types.SET_CONF_TOKEN:
    case types.UPDATE_CONF_TOKEN:
      const { conf_id, token } = action.payload;
      return {
        ...state,
        list: state.list.map(conf => {
          return conf.id === conf_id ? { ...conf, token } : conf;
        })
      };

    default:
      return state;
  }
};

export default confs;
