import HttpService from './HttpService';
import ImageBlobReduce from 'image-blob-reduce';
import Pica from 'pica';

class ImagesService extends HttpService {
  async uploadFile(file, max = 512) {
    try {
      // resize
      /**
       * TODO
       * Disable web worker because
       * - I didn't find a way how to solve https://github.com/nodeca/image-blob-reduce#known-issues.
       *   I mean how to disable terser plugin evaluate option
       * - disabling web worker was mentioned here https://github.com/nodeca/image-blob-reduce/issues/17
       *   In general web worker plugin disabling is not a nice solution in terms of performance
       */
      const pica = Pica({ features: ['js', 'wasm'] });
      const reducer = new ImageBlobReduce({ pica });
      const blob = await reducer.toBlob(file, {
        max: max,
        unsharpAmount: 100,
        unsharpRadius: 0.6,
        unsharpThreshold: 2,
      });
      // form data
      const data = new FormData();
      data.append(
        'file',
        new File([blob], file.name || 'file', {
          lastModified: file.lastModified || Date.now(),
          type: file.type,
        })
      );
      // upload request
      const response = await this.http.post('/upload', data, {
        // image API is only used here:
        baseURL: process.env.REACT_APP_IMAGE_API_URL,
      });

      return this.onResponse(response);
    } catch (e) {
      return this.onError(e);
    }
  }

  fileToBase64Image(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  // http://bit.ly/2MOFW5i
  // + https://stackoverflow.com/a/16245768/4134756 (modified)
  base64ImageToFile(imageStr) {
    const block = imageStr.split(';'); // Split the base64 string in data and contentType
    const contentType = block[0].split(':')[1]; // Get the content type of the image
    const b64Data = block[1].split(',')[1]; // get the real base64 content of the file
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    const sliceSize = 512;
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return Promise.resolve(new Blob(byteArrays, { type: contentType }));
  }
}

const imagesService = new ImagesService();

export default imagesService;
