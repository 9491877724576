const speakers = {
  UPDATE_SPEAKERS_REQUEST: 'UPDATE_SPEAKERS_REQUEST',
  UPDATE_SPEAKERS_SUCCESS: 'UPDATE_SPEAKERS_SUCCESS',
  UPDATE_SPEAKERS_ERROR: 'UPDATE_SPEAKERS_ERROR',
  CREATE_SPEAKERS_REQUEST: 'CREATE_SPEAKERS_REQUEST',
  CREATE_SPEAKERS_SUCCESS: 'CREATE_SPEAKERS_SUCCESS',
  CREATE_SPEAKERS_ERROR: 'CREATE_SPEAKERS_ERROR',
  FIND_ALL_SPEAKERS_REQUEST: 'FIND_ALL_SPEAKERS_REQUEST',
  FIND_ALL_SPEAKERS_SUCCESS: 'FIND_ALL_SPEAKERS_SUCCESS',
  FIND_ALL_SPEAKERS_ERROR: 'FIND_ALL_SPEAKERS_ERROR',
  DELETE_SPEAKER: 'DELETE_SPEAKER'
};

export default speakers;
