import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { isNil } from 'lodash/fp';

const backgroundColor = appearance => {
  return {
    warning: colors.Y300,
    error: colors.R400,
    announcement: colors.N500
  }[appearance];
};

const textColor = appearance => {
  return {
    warning: colors.N700,
    error: colors.N0,
    announcement: colors.N0
  }[appearance];
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${props => backgroundColor(props.appearance)};
  fill: ${props => backgroundColor(props.appearance)};
  color: ${props => textColor(props.appearance)};
  padding: 12px;
  max-height: 52px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 4px;

  a,
  a:visited,
  a:hover,
  a:active,
  a:focus {
    color: ${textColor};
    text-decoration: underline;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ChildrenWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

// eslint-disable-next-line
export default ({ icon, appearance, isOpen, children }) => {
  if (!isOpen) {
    return null;
  }
  const showIcon = !isNil(icon);
  return (
    <Wrapper appearance={appearance}>
      {showIcon && <IconWrapper>{icon}</IconWrapper>}
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Wrapper>
  );
};
