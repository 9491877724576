import { createSelector } from 'reselect';
import { requestStatus } from '../request-status';
import { getConfPlans } from './products';
import { PLAN_IDS } from '../../constants';

const upgradeStatus = state => state.upgrade.status;
const confLimits = state => state.upgrade.confLimits;
const confPlanId = state => state.upgrade.confPlanId;

export const isUpgradeLoading = createSelector(
  upgradeStatus,
  status => status === requestStatus.WAITING
);

export const getNotificationsAllowed = createSelector(
  confLimits,
  (limits) => limits.notifications
);

export const getActualConfPlans = createSelector(
  getConfPlans,
  confPlanId,
  (plans, id) => {
    if (id === PLAN_IDS.NON_PROFIT) {
      return [plans.find(plan => plan.id === id)];
    }

    const currentPlanIndex = plans.findIndex(plan => plan.id === id);

    return plans.slice(currentPlanIndex);
  }
);
