import React, { useState } from 'react';
import Popover from 'react-popover';
import styled from 'styled-components';
import ReactList from 'react-list';
import TextField from '@atlaskit/textfield';
import i18n from '../../../../../i18n/i18n';
import FontAwesomeIcon from '../../../../utils/icons/font-awesome-icon';
import { getChunkedIconListFiltered } from './icon-list';
import EditLabel from '../../../../utils/edit-label';

const PickerContainer = styled.div`
  background-color: #ffffff;
  box-shadow: rgba(9, 30, 66, 0.25) 0 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
  padding: 10px;
  border-radius: 5px;
  max-width: 300px;
  max-height: 30vh;
  overflow-y: auto;
`;

const Picker = styled.div`
  overflow: auto;
  max-height: 28vh;
`;

const SearchInput = styled(TextField)`
  margin-bottom: 5px;
`;

const IconListContainer = styled.div`
  margin-top: 8px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-column-gap: 3px;
  margin-bottom: 3px;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(9, 30, 66, 0.04);
  width: 42px;
  height: 24px;
  cursor: pointer;
  border-radius: 3px;
  color: #4f5f79;

  &:hover {
    background-color: rgba(9, 30, 66, 0.08);
  }
`;

const renderRow = (icons, onChange) => (index, key) => {
  return (
    <Grid key={key}>
      {icons[index].map((icon, i) => (
        <Icon key={i} onClick={() => onChange(icon)}>
          <FontAwesomeIcon icon={icon} size={'1x'} />
        </Icon>
      ))}
    </Grid>
  );
};

const IconPicker = ({ onChange }) => {
  const [searchInput, setSearchInput] = useState('');
  const icons = getChunkedIconListFiltered(6, searchInput, i18n.language);
  return (
    <PickerContainer>
      <Picker>
        <SearchInput autoFocus value={searchInput} onChange={e => setSearchInput(e.target.value)} />
        <IconListContainer>
          <ReactList
            itemRenderer={renderRow(icons, onChange)}
            length={icons.length}
            type="uniform"
          />
        </IconListContainer>
      </Picker>
    </PickerContainer>
  );
};

// eslint-disable-next-line
export default ({ value, onChange, defaultValue }) => {
  const [dialogOpen, setDialogOpen] = useState();
  const onIconChange = icon => {
    onChange(icon);
    setDialogOpen(false);
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Popover
        isOpen={dialogOpen}
        preferPlace={'below'}
        body={<IconPicker onChange={onIconChange} />}
        onOuterAction={() => setDialogOpen(false)}
        style={{ zIndex: 999 }}
      >
        <EditLabel onLabelClick={() => setDialogOpen(!dialogOpen)} labelStyle={{right: '-30px'}}>
          <Icon
            onClick={() => setDialogOpen(!dialogOpen)}
            style={{ padding: '10px', borderRadius: '5px' }}
          >
            <FontAwesomeIcon icon={value || defaultValue} size={'2x'} />
          </Icon>
        </EditLabel>
      </Popover>
    </div>
  );
};
