import { useCallback, useContext, useEffect } from 'react';
import { useMappedState } from 'redux-react-hook';
import { MaskContext } from '../contexts/mask-context';

// eslint-disable-next-line
export default () => {
  const { setMaskData } = useContext(MaskContext);
  const confs = useMappedState(state => state.confs.list);

  useEffect(() => {
    return () => setMaskData(null);
  }, [setMaskData]);

  const resetMask = useCallback(() => setMaskData(null), [setMaskData]);

  const showMask = useCallback((data) => {
    if (confs.length > 1) {
      return;
    }
    // TODO remove to have CONF 567 react-tour feature
    if (process.env.REACT_APP_FEATURE_REACT_TOUR) {
      setMaskData(data);
    }
  }, [confs, setMaskData]);


  return { showMask, resetMask };
};
