import { format as fmt, formatDistance as fmtDist } from 'date-fns/esm';
import { enUS, fr } from 'date-fns/locale';
import i18n from '../i18n/i18n';
import { rawTimeZones } from '@vvo/tzdb';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { DEFAULT_TIMEZONE } from '../constants';

const dateFnsLocales = { en: enUS, fr };

export const format = (date, format, options) => {
  return fmt(date, format, {
    ...options,
    locale: dateFnsLocales[i18n.language]
  });
};

export const formatDistance = (from, to) => {
  return fmtDist(from, to, { addSuffix: true, locale: dateFnsLocales[i18n.language] });
};

export const getDateRange = (min, max) => {
  return {
    minDate: fmt(new Date(min), 'yyyy-MM-dd'),
    maxDate: fmt(new Date(max), 'yyyy-MM-dd')
  };
};

export const TIME_ZONES_DATA = rawTimeZones.map(zone => ({
  label: zone.rawFormat,
  value: zone.name
}));

export const getCurrentTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const getTimeZoneData = (timezone) => {
  const timeZoneChecked = timezone || getCurrentTimeZone();
  const data = TIME_ZONES_DATA.find(zone => zone.value === timeZoneChecked);

  if (!data) {
    const defaultTimeZoneData = TIME_ZONES_DATA.find(zone => zone.value === DEFAULT_TIMEZONE);

    if (!defaultTimeZoneData) {
      throw new Error('Could not find default time zone');
    }

    return defaultTimeZoneData;
  }

  return data;
}

export const getZonedISOString = (date, timezone) => {
  const timeZoneChecked = timezone || getCurrentTimeZone();
  return utcToZonedTime(date, timeZoneChecked).toISOString();
}

export const getUtcFromZonedTime = (date, timezone) => {
  const timeZoneChecked = timezone || getCurrentTimeZone();
  return zonedTimeToUtc(date, timeZoneChecked).toISOString();
}
