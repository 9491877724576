// eslint-disable-next-line
export default [
  { en: 'ad', fr: 'pub' },
  { en: 'address-book', fr: 'annuaire' },
  { en: 'address-card', fr: 'carte contact' },
  { en: 'adjust', fr: 'ajuster' },
  { en: 'air-freshener', fr: 'diffuseur-odeur' },
  { en: 'ambulance', fr: 'ambulance' },
  { en: 'american-sign-language-interpreting', fr: 'language-signes' },
  { en: 'anchor', fr: 'ancre' },
  { en: 'angry', fr: 'mecontent' },
  { en: 'ankh', fr: 'ankh' },
  { en: 'apple-alt', fr: 'pomme-alt' },
  { en: 'archive', fr: 'archive' },
  { en: 'archway', fr: 'arc' },
  { en: 'assistive-listening-systems', fr: 'aide-audio' },
  { en: 'asterisk', fr: 'asterisque' },
  { en: 'at', fr: 'arobase' },
  { en: 'atlas', fr: 'atlas' },
  { en: 'atom', fr: 'atome' },
  { en: 'audio-description', fr: 'audio-description' },
  { en: 'award', fr: 'recompense' },
  { en: 'baby', fr: 'bebe' },
  { en: 'baby-carriage', fr: 'poussette' },
  { en: 'backspace', fr: 'retour' },
  { en: 'backward', fr: 'en-arriere' },
  { en: 'bacon', fr: 'bacon' },
  { en: 'balance-scale', fr: 'balance' },
  { en: 'balance-scale-left', fr: 'balance-gauche' },
  { en: 'balance-scale-right', fr: 'balance-droite' },
  { en: 'ban', fr: 'interdit' },
  { en: 'band-aid', fr: 'pansement' },
  { en: 'barcode', fr: 'code-bar' },
  { en: 'bars', fr: 'burger-menu' },
  { en: 'baseball-ball', fr: 'balle-baseball' },
  { en: 'basketball-ball', fr: 'balle-basketball' },
  { en: 'bath', fr: 'chauve-souris' },
  { en: 'battery-empty', fr: 'batterie-vide' },
  { en: 'battery-full', fr: 'batterie-pleine' },
  { en: 'battery-half', fr: 'batterie-moitie' },
  { en: 'battery-quarter', fr: 'batterie-quart' },
  { en: 'battery-three-quarters', fr: 'batterie-trois-quarts' },
  { en: 'bed', fr: 'lit' },
  { en: 'beer', fr: 'biere' },
  { en: 'bell', fr: 'cloche' },
  { en: 'bell-slash', fr: 'bell-slash' },
  { en: 'bezier-curve', fr: 'courbe-bezier' },
  { en: 'bible', fr: 'bible' },
  { en: 'bicycle', fr: 'velo' },
  { en: 'biking', fr: 'moto' },
  { en: 'binoculars', fr: 'jumelles' },
  { en: 'biohazard', fr: 'radioactif' },
  { en: 'birthday-cake', fr: 'gateau-anniversaire' },
  { en: 'blender', fr: 'mixeur' },
  { en: 'blender-phone', fr: 'mixeur-telephone' },
  { en: 'blind', fr: 'aveugle' },
  { en: 'blog', fr: 'blog' },
  { en: 'bold', fr: 'gras' },
  { en: 'bolt', fr: 'eclair' },
  { en: 'bomb', fr: 'bombe' },
  { en: 'bone', fr: 'os' },
  { en: 'bong', fr: 'bang' },
  { en: 'book', fr: 'livre' },
  { en: 'book-dead', fr: 'livre-mort' },
  { en: 'book-medical', fr: 'livre-medical' },
  { en: 'book-open', fr: 'livre-ouvert' },
  { en: 'book-reader', fr: 'livre-lecteur' },
  { en: 'bookmark', fr: 'favoris' },
  { en: 'bowling-ball', fr: 'boule-bowling' },
  { en: 'box', fr: 'boite' },
  { en: 'box-open', fr: 'boite-ouverte' },
  { en: 'boxes', fr: 'boites' },
  { en: 'braille', fr: 'braille' },
  { en: 'brain', fr: 'cerveau' },
  { en: 'bread-slice', fr: 'tranche-pain' },
  { en: 'briefcase', fr: 'malette' },
  { en: 'briefcase-medical', fr: 'malette-medicale' },
  { en: 'broadcast-tower', fr: 'antenne-relai' },
  { en: 'broom', fr: 'balai' },
  { en: 'brush', fr: 'brosse' },
  { en: 'bug', fr: 'insecte' },
  { en: 'building', fr: 'building' },
  { en: 'bullhorn', fr: 'megaphone' },
  { en: 'bullseye', fr: 'cible' },
  { en: 'burn', fr: 'flamme' },
  { en: 'bus', fr: 'bus' },
  { en: 'bus-alt', fr: 'bus-alt' },
  { en: 'business-time', fr: 'malette-horloge' },
  { en: 'calculator', fr: 'calculatrice' },
  { en: 'calendar', fr: 'calendrier' },
  { en: 'calendar-alt', fr: 'calendrier-alt' },
  { en: 'calendar-check', fr: 'calendrier-check' },
  { en: 'calendar-day', fr: 'calendrier-jour' },
  { en: 'calendar-minus', fr: 'calendrier-moins' },
  { en: 'calendar-plus', fr: 'calendrier-plus' },
  { en: 'calendar-times', fr: 'calendrier-croix' },
  { en: 'calendar-week', fr: 'calendrier-semaine' },
  { en: 'camera', fr: 'camera' },
  { en: 'camera-retro', fr: 'vieille-camera' },
  { en: 'campground', fr: 'tente' },
  { en: 'candy-cane', fr: 'sucre-orge' },
  { en: 'cannabis', fr: 'cannabis' },
  { en: 'capsules', fr: 'gelules' },
  { en: 'car', fr: 'voituree' },
  { en: 'car-alt', fr: 'voiture-alt' },
  { en: 'car-battery', fr: 'voiture-batterie' },
  { en: 'car-crash', fr: 'voiture-crash' },
  { en: 'car-side', fr: 'voiture-cote' },
  { en: 'carrot', fr: 'carrote' },
  { en: 'cart-arrow-down', fr: 'carrote-fleche-bas' },
  { en: 'cart-plus', fr: 'carrote-plus' },
  { en: 'cash-register', fr: 'casse-enregistreuse' },
  { en: 'cat', fr: 'chat' },
  { en: 'certificate', fr: 'certificat' },
  { en: 'chair', fr: 'chaise' },
  { en: 'chalkboard', fr: 'tableau-craie' },
  { en: 'chalkboard-teacher', fr: 'tableau-craie-professeur' },
  { en: 'charging-station', fr: 'station-recharge' },
  { en: 'chart-area', fr: 'graphique-aire' },
  { en: 'chart-bar', fr: 'graphique-histogramme' },
  { en: 'chart-line', fr: 'graphique-ligne' },
  { en: 'chart-pie', fr: 'graphique-camembert' },
  { en: 'check', fr: 'check' },
  { en: 'check-circle', fr: 'check-cercle' },
  { en: 'check-double', fr: 'check-double' },
  { en: 'check-square', fr: 'check-carre' },
  { en: 'cheese', fr: 'fromage' },
  { en: 'chess', fr: 'echec' },
  { en: 'chess-bishop', fr: 'echecs-fou' },
  { en: 'chess-board', fr: 'echecs-plateau' },
  { en: 'chess-king', fr: 'echecs-roi' },
  { en: 'chess-knight', fr: 'echecs-chevalier' },
  { en: 'chess-pawn', fr: 'echecs-pion' },
  { en: 'chess-queen', fr: 'echecs-reine' },
  { en: 'chess-rook', fr: 'echecs-tour' },
  { en: 'child', fr: 'enfant' },
  { en: 'church', fr: 'eglise' },
  { en: 'circle', fr: 'cercle' },
  { en: 'circle-notch', fr: 'cercle-encoche' },
  { en: 'city', fr: 'ville' },
  { en: 'clinic-medical', fr: 'clinique-medicale' },
  { en: 'clipboard', fr: 'presse-papier' },
  { en: 'clipboard-check', fr: 'presse-papier-check' },
  { en: 'clipboard-list', fr: 'presse-papier-list' },
  { en: 'clock', fr: 'horlogne' },
  { en: 'clone', fr: 'clonage' },
  { en: 'closed-captioning', fr: 'sous-titrage' },
  { en: 'cloud', fr: 'nuage' },
  { en: 'cloud-download-alt', fr: 'nuage-telechargement' },
  { en: 'cloud-meatball', fr: 'nuage-boule-viande' },
  { en: 'cloud-moon', fr: 'nuage-lune' },
  { en: 'cloud-moon-rain', fr: 'nuage-lune-pluie' },
  { en: 'cloud-rain', fr: 'nuage-pluie' },
  { en: 'cloud-showers-heavy', fr: 'nuage-pluie-forte' },
  { en: 'cloud-sun', fr: 'nuage-soleil' },
  { en: 'cloud-sun-rain', fr: 'nuage-soleil-pluie' },
  { en: 'cloud-upload-alt', fr: 'nuage-upload-alt' },
  { en: 'cocktail', fr: 'cocktail' },
  { en: 'code', fr: 'code' },
  { en: 'code-branch', fr: 'code-branche' },
  { en: 'coffee', fr: 'cafe' },
  { en: 'cog', fr: 'boulon' },
  { en: 'cogs', fr: 'boulons' },
  { en: 'coins', fr: 'pieces' },
  { en: 'columns', fr: 'colonnes' },
  { en: 'comment', fr: 'commentaire' },
  { en: 'comment-alt', fr: 'commentaire-alt' },
  { en: 'comment-dollar', fr: 'commentaire-dollar' },
  { en: 'comment-dots', fr: 'commentaire-points' },
  { en: 'comment-medical', fr: 'commentaire-medical' },
  { en: 'comment-slash', fr: 'commentaire-raye' },
  { en: 'comments', fr: 'commentaires' },
  { en: 'comments-dollar', fr: 'commentaires-dollar' },
  { en: 'compact-disc', fr: 'cd-rom' },
  { en: 'compass', fr: 'compas' },
  { en: 'compress', fr: 'compresser' },
  { en: 'compress-arrows-alt', fr: 'compresser-fleche-alt' },
  { en: 'concierge-bell', fr: 'sonette' },
  { en: 'cookie', fr: 'cookie' },
  { en: 'cookie-bite', fr: 'cookie-morsure' },
  { en: 'copy', fr: 'copie' },
  { en: 'copyright', fr: 'droits-auteur' },
  { en: 'couch', fr: 'canape' },
  { en: 'credit-card', fr: 'carte-credit' },
  { en: 'crop', fr: 'rogner' },
  { en: 'crop-alt', fr: 'rogner-alt' },
  { en: 'cross', fr: 'croix' },
  { en: 'crosshairs', fr: 'viseur' },
  { en: 'crow', fr: 'corbeau' },
  { en: 'crown', fr: 'couronne' },
  { en: 'crutch', fr: 'bequilles' },
  { en: 'cube', fr: 'cube' },
  { en: 'cubes', fr: 'cubes' },
  { en: 'cut', fr: 'couper' },
  { en: 'database', fr: 'base-donnees' },
  { en: 'deaf', fr: 'sourd' },
  { en: 'democrat', fr: 'democrate' },
  { en: 'desktop', fr: 'bureau' },
  { en: 'dharmachakra', fr: 'dharmachakra' },
  { en: 'diagnoses', fr: 'diagnostiques' },
  { en: 'dice', fr: 'de' },
  { en: 'dice-d20', fr: 'de-20' },
  { en: 'dice-d6', fr: 'de-6' },
  { en: 'dice-five', fr: 'des-cinq' },
  { en: 'dice-four', fr: 'des-quatre' },
  { en: 'dice-one', fr: 'des-un' },
  { en: 'dice-six', fr: 'des-six' },
  { en: 'dice-three', fr: 'des-trois' },
  { en: 'dice-two', fr: 'des-deux' },
  { en: 'digital-tachograph', fr: 'tachygraphe-digital' },
  { en: 'directions', fr: 'panneau' },
  { en: 'divide', fr: 'diviser' },
  { en: 'dizzy', fr: 'vertige' },
  { en: 'dna', fr: 'adn' },
  { en: 'dog', fr: 'chien' },
  { en: 'dollar-sign', fr: 'signe-dollar' },
  { en: 'dolly', fr: 'chariot' },
  { en: 'dolly-flatbed', fr: 'chariot-plat' },
  { en: 'donate', fr: 'donner' },
  { en: 'door-closed', fr: 'porte-fermee' },
  { en: 'door-open', fr: 'porte-ouverte' },
  { en: 'dot-circle', fr: 'cercle-point' },
  { en: 'dove', fr: 'plonger' },
  { en: 'download', fr: 'telecharger' },
  { en: 'drafting-compass', fr: 'compas-dessin' },
  { en: 'dragon', fr: 'dragon' },
  { en: 'draw-polygon', fr: 'dessin-polygone' },
  { en: 'drum', fr: 'tambour' },
  { en: 'drum-steelpan', fr: 'tambour-acier' },
  { en: 'drumstick-bite', fr: 'pilon-morsure' },
  { en: 'dumbbell', fr: 'haltere' },
  { en: 'dumpster', fr: 'decharge' },
  { en: 'dumpster-fire', fr: 'decharge-feu' },
  { en: 'dungeon', fr: 'dongeon' },
  { en: 'edit', fr: 'editer' },
  { en: 'egg', fr: 'oeuf' },
  { en: 'eject', fr: 'ejecter' },
  { en: 'ellipsis-h', fr: 'ellipse-horizontale' },
  { en: 'ellipsis-v', fr: 'ellipse-verticale' },
  { en: 'envelope', fr: 'enveloppe' },
  { en: 'envelope-open', fr: 'enveloppe-ouverte' },
  { en: 'envelope-open-text', fr: 'enveloppe-ouverte-texte' },
  { en: 'envelope-square', fr: 'enveloppe-carree' },
  { en: 'equals', fr: 'egaux' },
  { en: 'eraser', fr: 'effaceur' },
  { en: 'ethernet', fr: 'ethernet' },
  { en: 'euro-sign', fr: 'signe-euro' },
  { en: 'exchange-alt', fr: 'echangeur-alt' },
  { en: 'exclamation', fr: 'exclamation' },
  { en: 'exclamation-circle', fr: 'exclamation-cercle' },
  { en: 'exclamation-triangle', fr: 'exclamation-triangle' },
  { en: 'expand', fr: 'etendre' },
  { en: 'expand-arrows-alt', fr: 'etendre-fleche-alt' },
  { en: 'external-link-alt', fr: 'etendre-lien-alt' },
  { en: 'external-link-square-alt', fr: 'lien-externe-carre-alt' },
  { en: 'eye', fr: 'oeil' },
  { en: 'eye-dropper', fr: 'pipette' },
  { en: 'eye-slash', fr: 'oeil-slash' },
  { en: 'fan', fr: 'ventilateur' },
  { en: 'fast-backward', fr: 'rapide-arriere' },
  { en: 'fast-forward', fr: 'rapide-avant' },
  { en: 'fax', fr: 'fax' },
  { en: 'feather', fr: 'plume' },
  { en: 'feather-alt', fr: 'plume-alt' },
  { en: 'female', fr: 'femme' },
  { en: 'fighter-jet', fr: 'avion-chasse' },
  { en: 'file', fr: 'fichier' },
  { en: 'file-alt', fr: 'fichier-alt' },
  { en: 'file-archive', fr: 'fichier-archive' },
  { en: 'file-audio', fr: 'fichier-audio' },
  { en: 'file-code', fr: 'fichier-code' },
  { en: 'file-contract', fr: 'fichier-contrat' },
  { en: 'file-csv', fr: 'fichier-csv' },
  { en: 'file-download', fr: 'fichier-telechargement' },
  { en: 'file-excel', fr: 'fichier-excel' },
  { en: 'file-export', fr: 'fichier-exporter' },
  { en: 'file-image', fr: 'fichier-image' },
  { en: 'file-import', fr: 'fichier-importer' },
  { en: 'file-invoice', fr: 'fichier-facture' },
  { en: 'file-invoice-dollar', fr: 'fichier-facture-dollar' },
  { en: 'file-medical', fr: 'fichier-medical' },
  { en: 'file-medical-alt', fr: 'fichier-medical-alt' },
  { en: 'file-pdf', fr: 'fichier-pdf' },
  { en: 'file-powerpoint', fr: 'fichier-powerpoint' },
  { en: 'file-prescription', fr: 'fichier-prescription' },
  { en: 'file-signature', fr: 'fichier-signature' },
  { en: 'file-upload', fr: 'fichier-upload' },
  { en: 'file-video', fr: 'fichier-video' },
  { en: 'file-word', fr: 'fichier-word' },
  { en: 'fill', fr: 'remplir' },
  { en: 'fill-drip', fr: 'remplir-goutte' },
  { en: 'film', fr: 'film' },
  { en: 'filter', fr: 'filtre' },
  { en: 'fingerprint', fr: 'empreinte-digitale' },
  { en: 'fire', fr: 'feu' },
  { en: 'fire-alt', fr: 'feu-alt' },
  { en: 'fire-extinguisher', fr: 'extincteur' },
  { en: 'first-aid', fr: 'premiers-soins' },
  { en: 'fish', fr: 'poisson' },
  { en: 'fist-raised', fr: 'poings-air' },
  { en: 'flag', fr: 'drapeau' },
  { en: 'flag-checkered', fr: 'drapeau-carreaux' },
  { en: 'flag-usa', fr: 'drapeau-usa' },
  { en: 'flask', fr: 'flacon' },
  { en: 'flushed', fr: 'rougir' },
  { en: 'folder', fr: 'dossier' },
  { en: 'folder-minus', fr: 'dossier-moins' },
  { en: 'folder-open', fr: 'dossier-ouvert' },
  { en: 'folder-plus', fr: 'dossier-plus' },
  { en: 'font', fr: 'police' },
  { en: 'football-ball', fr: 'ballon-football' },
  { en: 'forward', fr: 'avant' },
  { en: 'frog', fr: 'grenouille' },
  { en: 'frown', fr: 'froncer' },
  { en: 'frown-open', fr: 'froncer-ouvert' },
  { en: 'funnel-dollar', fr: 'entonnoir-dollar' },
  { en: 'futbol', fr: 'ballon-football' },
  { en: 'gamepad', fr: 'mannette-jeu' },
  { en: 'gas-pump', fr: 'pompe-essence' },
  { en: 'gavel', fr: 'marteau-justice' },
  { en: 'gem', fr: 'pierre-precieuse' },
  { en: 'genderless', fr: 'sans-genre' },
  { en: 'ghost', fr: 'fantome' },
  { en: 'gift', fr: 'cadeau' },
  { en: 'gifts', fr: 'cadeaux' },
  { en: 'glass-cheers', fr: 'verres-trinquer' },
  { en: 'glass-martini', fr: 'verre-cocktail' },
  { en: 'glass-martini-alt', fr: 'verre-cocktail-alt' },
  { en: 'glass-whiskey', fr: 'verre-whiskey' },
  { en: 'glasses', fr: 'verres' },
  { en: 'globe', fr: 'globe' },
  { en: 'globe-africa', fr: 'globe-afrique' },
  { en: 'globe-americas', fr: 'globe-ameriques' },
  { en: 'globe-asia', fr: 'globe-asie' },
  { en: 'globe-europe', fr: 'globe-europe' },
  { en: 'golf-ball', fr: 'balle-golf' },
  { en: 'gopuram', fr: 'gopuram' },
  { en: 'graduation-cap', fr: 'chapeau-graduation' },
  { en: 'greater-than', fr: 'plus-grand' },
  { en: 'greater-than-equal', fr: 'plus-grand-egal' },
  { en: 'grimace', fr: 'grimace' },
  { en: 'grin', fr: 'sourire' },
  { en: 'grin-alt', fr: 'sourire-alt' },
  { en: 'grin-beam', fr: 'sourire-radieux' },
  { en: 'grin-beam-sweat', fr: 'sourire-radieux-suer' },
  { en: 'grin-hearts', fr: 'sourire-radieux-coeurs' },
  { en: 'grin-squint', fr: 'sourire-radieux-rire' },
  { en: 'grin-squint-tears', fr: 'sourire-radieux-larmes' },
  { en: 'grin-stars', fr: 'sourire-etoiles' },
  { en: 'grin-tears', fr: 'sourire-larmes' },
  { en: 'grin-tongue', fr: 'sourire-langue' },
  { en: 'grin-tongue-squint', fr: 'sourire-langue-rire' },
  { en: 'grin-tongue-wink', fr: 'sourire-langue-clin-oeil' },
  { en: 'grin-wink', fr: 'sourire-clin-oeil' },
  { en: 'grip-horizontal', fr: 'grille-horizontale' },
  { en: 'grip-lines', fr: 'grille-lignes' },
  { en: 'grip-lines-vertical', fr: 'grille-lignes-verticale' },
  { en: 'grip-vertical', fr: 'grille-verticale' },
  { en: 'guitar', fr: 'guitare' },
  { en: 'h-square', fr: 'h-carre' },
  { en: 'hamburger', fr: 'hamburger' },
  { en: 'hammer', fr: 'marteau' },
  { en: 'hamsa', fr: 'hamsa' },
  { en: 'hand-holding', fr: 'hamsa-tenir' },
  { en: 'hand-holding-heart', fr: 'hamsa-tenir-coeur' },
  { en: 'hand-holding-usd', fr: 'hamsa-tenir-argent' },
  { en: 'hand-lizard', fr: 'main-lesard' },
  { en: 'hand-middle-finger', fr: 'main-doigt-honneur' },
  { en: 'hand-paper', fr: 'main-papier' },
  { en: 'hand-peace', fr: 'main-peace' },
  { en: 'hand-point-down', fr: 'main-pointe-bas' },
  { en: 'hand-point-left', fr: 'main-pointe-gauche' },
  { en: 'hand-point-right', fr: 'main-pointe-droite' },
  { en: 'hand-point-up', fr: 'main-pointe-haut' },
  { en: 'hand-pointer', fr: 'main-pointeur' },
  { en: 'hand-rock', fr: 'main-rock' },
  { en: 'hand-scissors', fr: 'main-ciseaux' },
  { en: 'hand-spock', fr: 'main-spock' },
  { en: 'hands', fr: 'mains' },
  { en: 'hands-helping', fr: 'mains-aident' },
  { en: 'handshake', fr: 'poignee-mains' },
  { en: 'hanukiah', fr: 'hanukiah' },
  { en: 'hard-hat', fr: 'casque-protection' },
  { en: 'hashtag', fr: 'hashtag' },
  { en: 'hat-wizard', fr: 'chapeau-sorcier' },
  { en: 'haykal', fr: 'haykal' },
  { en: 'hdd', fr: 'disque-dur' },
  { en: 'heading', fr: 'titre' },
  { en: 'headphones', fr: 'casque' },
  { en: 'headphones-alt', fr: 'casque-alt' },
  { en: 'headset', fr: 'casque-micro' },
  { en: 'heart', fr: 'coeur' },
  { en: 'heart-broken', fr: 'coeur-brise' },
  { en: 'heartbeat', fr: 'battement-coeur' },
  { en: 'helicopter', fr: 'helicoptere' },
  { en: 'highlighter', fr: 'surligneur' },
  { en: 'hiking', fr: 'randonnee' },
  { en: 'hippo', fr: 'hippopotame' },
  { en: 'history', fr: 'histoire' },
  { en: 'hockey-puck', fr: 'palet-hockey' },
  { en: 'holly-berry', fr: 'houx' },
  { en: 'home', fr: 'accueil' },
  { en: 'horse', fr: 'cheval' },
  { en: 'horse-head', fr: 'tete-cheval' },
  { en: 'hospital', fr: 'hopital' },
  { en: 'hospital-alt', fr: 'hopital-alt' },
  { en: 'hospital-symbol', fr: 'hopital-symbole' },
  { en: 'hot-tub', fr: 'jacuzzi' },
  { en: 'hotdog', fr: 'hotdog' },
  { en: 'hotel', fr: 'hotel' },
  { en: 'hourglass', fr: 'sablier' },
  { en: 'hourglass-end', fr: 'sablier-fin' },
  { en: 'hourglass-half', fr: 'sablier-milieu' },
  { en: 'hourglass-start', fr: 'sablier-debut' },
  { en: 'house-damage', fr: 'maison-degats' },
  { en: 'hryvnia', fr: 'hryvnia' },
  { en: 'i-cursor', fr: 'i-curseur' },
  { en: 'ice-cream', fr: 'glace' },
  { en: 'icicles', fr: 'stalactite' },
  { en: 'icons', fr: 'icones' },
  { en: 'id-badge', fr: 'carte-badge' },
  { en: 'id-card', fr: 'carte-identification' },
  { en: 'id-card-alt', fr: 'carte-identification-alt' },
  { en: 'igloo', fr: 'igloo' },
  { en: 'image', fr: 'image' },
  { en: 'images', fr: 'images' },
  { en: 'inbox', fr: 'boite-mail' },
  { en: 'indent', fr: 'indentation' },
  { en: 'industry', fr: 'industrie' },
  { en: 'infinity', fr: 'infinie' },
  { en: 'info', fr: 'information' },
  { en: 'info-circle', fr: 'information-cercle' },
  { en: 'italic', fr: 'italique' },
  { en: 'jedi', fr: 'jedi' },
  { en: 'joint', fr: 'joint' },
  { en: 'journal-whills', fr: 'journal-whills' },
  { en: 'kaaba', fr: 'kaaba' },
  { en: 'key', fr: 'cle' },
  { en: 'keyboard', fr: 'clavier' },
  { en: 'khanda', fr: 'khanda' },
  { en: 'kiss', fr: 'bise' },
  { en: 'kiss-beam', fr: 'bise-radieux' },
  { en: 'kiss-wink-heart', fr: 'bise-clin-oeil-coeur' },
  { en: 'kiwi-bird', fr: 'kiwi-austral' },
  { en: 'landmark', fr: 'monument' },
  { en: 'language', fr: 'langage' },
  { en: 'laptop', fr: 'pc-portable' },
  { en: 'laptop-code', fr: 'pc-portable-code' },
  { en: 'laptop-medical', fr: 'pc-portable-medical' },
  { en: 'laugh', fr: 'rire' },
  { en: 'laugh-beam', fr: 'rire-radieux' },
  { en: 'laugh-squint', fr: 'rire-fort' },
  { en: 'laugh-wink', fr: 'rire-clin-oeil' },
  { en: 'layer-group', fr: 'groupe-calques' },
  { en: 'leaf', fr: 'feuille' },
  { en: 'lemon', fr: 'citron' },
  { en: 'less-than', fr: 'moins-que' },
  { en: 'less-than-equal', fr: 'moins-egal' },
  { en: 'level-down-alt', fr: 'niveau-inferieur-alt' },
  { en: 'level-up-alt', fr: 'niveau-superieur-alt' },
  { en: 'life-ring', fr: 'bouee-sauvetage' },
  { en: 'lightbulb', fr: 'ampoule' },
  { en: 'link', fr: 'lien' },
  { en: 'lira-sign', fr: 'signe-lira' },
  { en: 'list', fr: 'liste' },
  { en: 'list-alt', fr: 'liste-alt' },
  { en: 'list-ol', fr: 'liste-ol' },
  { en: 'list-ul', fr: 'liste-ul' },
  { en: 'location-arrow', fr: 'position-fleche' },
  { en: 'lock', fr: 'cadenas' },
  { en: 'lock-open', fr: 'cadenas-ouvert' },
  { en: 'low-vision', fr: 'mauvaise-vue' },
  { en: 'luggage-cart', fr: 'chariot-bagages' },
  { en: 'magic', fr: 'magie' },
  { en: 'magnet', fr: 'aimant' },
  { en: 'mail-bulk', fr: 'enveloppes' },
  { en: 'male', fr: 'homme' },
  { en: 'map', fr: 'carte' },
  { en: 'map-marked', fr: 'carte-position' },
  { en: 'map-marked-alt', fr: 'carte-position-alt' },
  { en: 'map-marker', fr: 'position-signe' },
  { en: 'map-marker-alt', fr: 'position-signe-alt' },
  { en: 'map-pin', fr: 'carte-pin' },
  { en: 'map-signs', fr: 'carte-panneau' },
  { en: 'marker', fr: 'marqueur' },
  { en: 'mars', fr: 'mars' },
  { en: 'mars-double', fr: 'mars-double' },
  { en: 'mars-stroke', fr: 'mars-trait' },
  { en: 'mars-stroke-h', fr: 'mars-trait-h' },
  { en: 'mars-stroke-v', fr: 'mars-trait-v' },
  { en: 'mask', fr: 'masque' },
  { en: 'medal', fr: 'medaille' },
  { en: 'medkit', fr: 'kit-medical' },
  { en: 'meh', fr: 'meh' },
  { en: 'meh-blank', fr: 'meh-vide' },
  { en: 'meh-rolling-eyes', fr: 'meh-yeux-rouler' },
  { en: 'memory', fr: 'memoire' },
  { en: 'menorah', fr: 'menorah' },
  { en: 'mercury', fr: 'mercure' },
  { en: 'meteor', fr: 'meteorite' },
  { en: 'microchip', fr: 'puce-electronique' },
  { en: 'microphone', fr: 'microphone' },
  { en: 'microphone-alt', fr: 'microphone-alt' },
  { en: 'microphone-alt-slash', fr: 'microphone-alt-slash' },
  { en: 'microphone-slash', fr: 'microphone-slash' },
  { en: 'microscope', fr: 'microscope' },
  { en: 'minus', fr: 'moins' },
  { en: 'minus-circle', fr: 'moins-cercle' },
  { en: 'minus-square', fr: 'moins-carre' },
  { en: 'mitten', fr: 'mouffles' },
  { en: 'mobile', fr: 'mobile' },
  { en: 'mobile-alt', fr: 'mobile-alt' },
  { en: 'money-bill', fr: 'billet-banque' },
  { en: 'money-bill-alt', fr: 'billet-banque-alt' },
  { en: 'money-bill-wave', fr: 'billet-banque-brandir' },
  { en: 'money-bill-wave-alt', fr: 'billet-banque-brandir-alt' },
  { en: 'money-check', fr: 'argent-cheque' },
  { en: 'money-check-alt', fr: 'argent-cheque-alt' },
  { en: 'monument', fr: 'monument' },
  { en: 'moon', fr: 'lune' },
  { en: 'mortar-pestle', fr: 'mortier-pilon' },
  { en: 'mosque', fr: 'mosque' },
  { en: 'motorcycle', fr: 'moto' },
  { en: 'mountain', fr: 'montaigne' },
  { en: 'mouse-pointer', fr: 'pointeur-souris' },
  { en: 'mug-hot', fr: 'tasse-chaude' },
  { en: 'music', fr: 'musique' },
  { en: 'network-wired', fr: 'reseau-cable' },
  { en: 'neuter', fr: 'neutre' },
  { en: 'newspaper', fr: 'journal' },
  { en: 'not-equal', fr: 'non-egal' },
  { en: 'notes-medical', fr: 'notes-medicales' },
  { en: 'object-group', fr: 'objets-groupes' },
  { en: 'object-ungroup', fr: 'objets-degroupes' },
  { en: 'oil-can', fr: 'burette-huile' },
  { en: 'om', fr: 'om' },
  { en: 'otter', fr: 'loutre' },
  { en: 'outdent', fr: 'desindenter' },
  { en: 'pager', fr: 'bippeur' },
  { en: 'paint-brush', fr: 'brosse-peinture' },
  { en: 'paint-roller', fr: 'rouleau-peinture' },
  { en: 'palette', fr: 'palette-couleurs' },
  { en: 'pallet', fr: 'palette' },
  { en: 'paper-plane', fr: 'avion-papier' },
  { en: 'paperclip', fr: 'trombone' },
  { en: 'parachute-box', fr: 'boite-parachute' },
  { en: 'paragraph', fr: 'paragraphe' },
  { en: 'parking', fr: 'parking' },
  { en: 'passport', fr: 'passport' },
  { en: 'pastafarianism', fr: 'pastafarianisme' },
  { en: 'paste', fr: 'coller' },
  { en: 'pause', fr: 'pause' },
  { en: 'pause-circle', fr: 'pause-cercle' },
  { en: 'paw', fr: 'patte' },
  { en: 'peace', fr: 'paix' },
  { en: 'pen', fr: 'crayon' },
  { en: 'pen-alt', fr: 'crayon-alt' },
  { en: 'pen-fancy', fr: 'crayon-fantaisie' },
  { en: 'pen-nib', fr: 'crayon-plume' },
  { en: 'pen-square', fr: 'crayon-carre' },
  { en: 'pencil-alt', fr: 'crayon-alt' },
  { en: 'pencil-ruler', fr: 'crayon-regle' },
  { en: 'people-carry', fr: 'personnes-porter' },
  { en: 'pepper-hot', fr: 'piment-piquant' },
  { en: 'percent', fr: 'pourcentage' },
  { en: 'percentage', fr: 'pourcentage' },
  { en: 'person-booth', fr: 'cabine-personne' },
  { en: 'phone', fr: 'telephone' },
  { en: 'phone-alt', fr: 'telephone-alt' },
  { en: 'phone-slash', fr: 'telephone-slash' },
  { en: 'phone-square', fr: 'telephone-carre' },
  { en: 'phone-square-alt', fr: 'telephone-carre-alt' },
  { en: 'phone-volume', fr: 'telephone-volume' },
  { en: 'photo-video', fr: 'photo-video' },
  { en: 'piggy-bank', fr: 'cochon-tirelire' },
  { en: 'pills', fr: 'pilules' },
  { en: 'pizza-slice', fr: 'part-pizza' },
  { en: 'place-of-worship', fr: 'lieu-priere' },
  { en: 'plane', fr: 'avion' },
  { en: 'plane-arrival', fr: 'avion-arrivee' },
  { en: 'plane-departure', fr: 'avion-depart' },
  { en: 'play', fr: 'lecture' },
  { en: 'play-circle', fr: 'lecture-cercle' },
  { en: 'plug', fr: 'prise' },
  { en: 'plus', fr: 'plus' },
  { en: 'plus-circle', fr: 'plus-cercle' },
  { en: 'plus-square', fr: 'plus-carre' },
  { en: 'podcast', fr: 'podcast' },
  { en: 'poll', fr: 'sondage' },
  { en: 'poll-h', fr: 'sondage-h' },
  { en: 'poo', fr: 'crotte' },
  { en: 'poo-storm', fr: 'crotte-eclair' },
  { en: 'poop', fr: 'caca' },
  { en: 'portrait', fr: 'portrait' },
  { en: 'pound-sign', fr: 'signe-diese' },
  { en: 'power-off', fr: 'eteindre' },
  { en: 'pray', fr: 'prier' },
  { en: 'praying-hands', fr: 'mains-priere' },
  { en: 'prescription', fr: 'ordonnance' },
  { en: 'prescription-bottle', fr: 'ordonnance-medicament' },
  { en: 'prescription-bottle-alt', fr: 'ordonnance-medicament-alt' },
  { en: 'print', fr: 'imprimer' },
  { en: 'procedures', fr: 'procedures' },
  { en: 'project-diagram', fr: 'schema-projet' },
  { en: 'puzzle-piece', fr: 'piece-puzzle' },
  { en: 'qrcode', fr: 'qrcode' },
  { en: 'question', fr: 'question' },
  { en: 'question-circle', fr: 'question-cercle' },
  { en: 'quidditch', fr: 'quidditch' },
  { en: 'quote-left', fr: 'guillements-gauche' },
  { en: 'quote-right', fr: 'guillements-droite' },
  { en: 'quran', fr: 'coran' },
  { en: 'radiation', fr: 'radiation' },
  { en: 'radiation-alt', fr: 'radiation-alt' },
  { en: 'rainbow', fr: 'arc-en-ciel' },
  { en: 'random', fr: 'aleatoire' },
  { en: 'receipt', fr: 'recu' },
  { en: 'recycle', fr: 'recicler' },
  { en: 'redo', fr: 'refaire' },
  { en: 'redo-alt', fr: 'refaire-alt' },
  { en: 'registered', fr: 'enregistre' },
  { en: 'remove-format', fr: 'supprimer-format' },
  { en: 'reply', fr: 'repondre' },
  { en: 'reply-all', fr: 'repondre-tous' },
  { en: 'republican', fr: 'republicain' },
  { en: 'restroom', fr: 'salle-repos' },
  { en: 'retweet', fr: 'retweet' },
  { en: 'ribbon', fr: 'ruban' },
  { en: 'ring', fr: 'anneau' },
  { en: 'road', fr: 'route' },
  { en: 'robot', fr: 'robot' },
  { en: 'rocket', fr: 'fusee' },
  { en: 'route', fr: 'route' },
  { en: 'rss', fr: 'rss' },
  { en: 'rss-square', fr: 'rss-carre' },
  { en: 'ruble-sign', fr: 'rouble-signe' },
  { en: 'ruler', fr: 'regle' },
  { en: 'ruler-combined', fr: 'equerre' },
  { en: 'ruler-horizontal', fr: 'regle-horizontal' },
  { en: 'ruler-vertical', fr: 'regle-vertical' },
  { en: 'running', fr: 'courir' },
  { en: 'rupee-sign', fr: 'roupie-signe' },
  { en: 'sad-cry', fr: 'triste-pleurer' },
  { en: 'sad-tear', fr: 'triste-larme' },
  { en: 'satellite', fr: 'satellite' },
  { en: 'satellite-dish', fr: 'satellite-emetteur' },
  { en: 'save', fr: 'sauvegarder' },
  { en: 'school', fr: 'ecole' },
  { en: 'screwdriver', fr: 'tournevis' },
  { en: 'scroll', fr: 'defilement' },
  { en: 'sd-card', fr: 'sd-carte' },
  { en: 'search', fr: 'loupe' },
  { en: 'search-dollar', fr: 'loupe-dollar' },
  { en: 'search-location', fr: 'loupe-endroit' },
  { en: 'search-minus', fr: 'loupe-moins' },
  { en: 'search-plus', fr: 'loupe-plus' },
  { en: 'seedling', fr: 'plante' },
  { en: 'server', fr: 'serveur' },
  { en: 'shapes', fr: 'formes' },
  { en: 'share', fr: 'partager' },
  { en: 'share-alt', fr: 'partager-alt' },
  { en: 'share-alt-square', fr: 'partager-alt-carre' },
  { en: 'share-square', fr: 'partager-carre' },
  { en: 'shekel-sign', fr: 'signe-shekel' },
  { en: 'shield-alt', fr: 'signe-shekel-alt' },
  { en: 'ship', fr: 'bateau' },
  { en: 'shipping-fast', fr: 'bateau-rapide' },
  { en: 'shoe-prints', fr: 'traces-pas' },
  { en: 'shopping-bag', fr: 'sac-courses' },
  { en: 'shopping-basket', fr: 'panier-courses' },
  { en: 'shopping-cart', fr: 'caddie-courses' },
  { en: 'shower', fr: 'douche' },
  { en: 'shuttle-van', fr: 'camionette' },
  { en: 'sign', fr: 'signe' },
  { en: 'sign-in-alt', fr: 'connexion' },
  { en: 'sign-language', fr: 'langue-signes' },
  { en: 'sign-out-alt', fr: 'deconnexion-alt' },
  { en: 'signal', fr: 'signal' },
  { en: 'signature', fr: 'signature' },
  { en: 'sim-card', fr: 'carte-sim' },
  { en: 'sitemap', fr: 'plan-site' },
  { en: 'skating', fr: 'skateboarder' },
  { en: 'skiing', fr: 'skier' },
  { en: 'skiing-nordic', fr: 'skier-nordique' },
  { en: 'skull', fr: 'crane' },
  { en: 'skull-crossbones', fr: 'crane-os-croix' },
  { en: 'slash', fr: 'slash' },
  { en: 'sleigh', fr: 'traîneau' },
  { en: 'sliders-h', fr: 'curseurs-h' },
  { en: 'smile', fr: 'sourire' },
  { en: 'smile-beam', fr: 'sourire-radieux' },
  { en: 'smile-wink', fr: 'souris-clin-oeil' },
  { en: 'smog', fr: 'fumee' },
  { en: 'smoking', fr: 'fumer' },
  { en: 'smoking-ban', fr: 'interdit-fumer' },
  { en: 'sms', fr: 'sms' },
  { en: 'snowboarding', fr: 'snowboarder' },
  { en: 'snowflake', fr: 'flocon-neige' },
  { en: 'snowman', fr: 'bonhomme-neige' },
  { en: 'snowplow', fr: 'chasse-neige' },
  { en: 'socks', fr: 'chaussettes' },
  { en: 'solar-panel', fr: 'panneau-solaire' },
  { en: 'sort', fr: 'trier' },
  { en: 'sort-alpha-down', fr: 'trier-lettre-decroissant' },
  { en: 'sort-alpha-down-alt', fr: 'trier-lettre-decroissant-alt' },
  { en: 'sort-alpha-up', fr: 'trier-lettre-croissant' },
  { en: 'sort-alpha-up-alt', fr: 'trier-lettre-croissant-alt' },
  { en: 'sort-amount-down', fr: ' trier-decroissant' },
  { en: 'sort-amount-down-alt', fr: 'trier-decroissant' },
  { en: 'sort-amount-up', fr: 'trier-croissant' },
  { en: 'sort-amount-up-alt', fr: 'trier-croissant' },
  { en: 'sort-down', fr: 'trier-bas' },
  { en: 'sort-numeric-down', fr: 'trier-nombre-decroissant' },
  { en: 'sort-numeric-down-alt', fr: 'trier-nombre-decroissant' },
  { en: 'sort-numeric-up', fr: 'trier-nombre-croissant' },
  { en: 'sort-numeric-up-alt', fr: 'trier-nombre-croissant' },
  { en: 'sort-up', fr: 'trier-haut' },
  { en: 'spa', fr: 'spa' },
  { en: 'space-shuttle', fr: 'navette-spatiale' },
  { en: 'spell-check', fr: 'correcteur-orthographique' },
  { en: 'spider', fr: 'araignee' },
  { en: 'spinner', fr: 'chargement' },
  { en: 'splotch', fr: 'tache' },
  { en: 'spray-can', fr: 'aerosol' },
  { en: 'square', fr: 'carre' },
  { en: 'square-full', fr: 'carre-plein' },
  { en: 'square-root-alt', fr: 'racine-carre-alt' },
  { en: 'stamp', fr: 'timbre' },
  { en: 'star', fr: 'etoile' },
  { en: 'star-and-crescent', fr: 'etoile-croissant' },
  { en: 'star-half', fr: 'etoile-moitie' },
  { en: 'star-half-alt', fr: 'etoile-moitie-alt' },
  { en: 'star-of-david', fr: 'etoile-david' },
  { en: 'star-of-life', fr: 'etoile-vie' },
  { en: 'step-backward', fr: 'etape-precedente' },
  { en: 'step-forward', fr: 'etape-suivante' },
  { en: 'stethoscope', fr: 'stethoscope' },
  { en: 'sticky-note', fr: 'post-it' },
  { en: 'stop', fr: 'stop' },
  { en: 'stop-circle', fr: 'stop-cercle' },
  { en: 'stopwatch', fr: 'chronometre' },
  { en: 'store', fr: 'magasin' },
  { en: 'store-alt', fr: 'magasin-alt' },
  { en: 'stream', fr: 'flux' },
  { en: 'street-view', fr: 'vue-rue' },
  { en: 'strikethrough', fr: 'barre' },
  { en: 'stroopwafel', fr: 'stroopwafel' },
  { en: 'subscript', fr: 'indice' },
  { en: 'subway', fr: 'metro' },
  { en: 'suitcase', fr: 'valise' },
  { en: 'suitcase-rolling', fr: 'valise-roulante' },
  { en: 'sun', fr: 'soleil' },
  { en: 'superscript', fr: 'exposant' },
  { en: 'surprise', fr: 'surprise' },
  { en: 'swatchbook', fr: 'carnet-echantillons' },
  { en: 'swimmer', fr: 'nageur' },
  { en: 'swimming-pool', fr: 'piscine' },
  { en: 'synagogue', fr: 'synagogue' },
  { en: 'sync', fr: 'synchronisation' },
  { en: 'sync-alt', fr: 'synchronisation-alt' },
  { en: 'syringe', fr: 'seringue' },
  { en: 'table', fr: 'table' },
  { en: 'table-tennis', fr: 'tennis-table' },
  { en: 'tablet', fr: 'tablette' },
  { en: 'tablet-alt', fr: 'tablette-alt' },
  { en: 'tablets', fr: 'tablettes' },
  { en: 'tachometer-alt', fr: 'tachymetre-alt' },
  { en: 'tag', fr: 'tag' },
  { en: 'tags', fr: 'tags' },
  { en: 'tape', fr: 'ruban' },
  { en: 'tasks', fr: 'taches' },
  { en: 'taxi', fr: 'taxi' },
  { en: 'teeth', fr: 'dent' },
  { en: 'teeth-open', fr: 'dent-ouverte' },
  { en: 'temperature-high', fr: 'temperature-elevee' },
  { en: 'temperature-low', fr: 'temperature-basse' },
  { en: 'tenge', fr: 'tenge' },
  { en: 'terminal', fr: 'terminal' },
  { en: 'text-height', fr: 'texte-hauteur' },
  { en: 'text-width', fr: 'texte-largeur' },
  { en: 'th', fr: 'th' },
  { en: 'th-large', fr: 'th-large' },
  { en: 'th-list', fr: 'th-liste' },
  { en: 'theater-masks', fr: 'masques-theatre' },
  { en: 'thermometer', fr: 'thermometre' },
  { en: 'thermometer-empty', fr: 'thermometre-vide' },
  { en: 'thermometer-full', fr: 'thermometre-plein' },
  { en: 'thermometer-half', fr: 'thermometre-moitie' },
  { en: 'thermometer-quarter', fr: 'thermometre-quart' },
  { en: 'thermometer-three-quarters', fr: 'thermometre-trois-quarts' },
  { en: 'thumbs-down', fr: 'pouces-bas' },
  { en: 'thumbs-up', fr: 'pouces-haut' },
  { en: 'thumbtack', fr: 'punaise' },
  { en: 'ticket-alt', fr: 'billet' },
  { en: 'times', fr: 'croix' },
  { en: 'times-circle', fr: 'croix-cercle' },
  { en: 'tint', fr: 'goutte' },
  { en: 'tint-slash', fr: 'goutte-slash' },
  { en: 'tired', fr: 'fatigue' },
  { en: 'toggle-off', fr: 'eteindre' },
  { en: 'toggle-on', fr: 'allumer' },
  { en: 'toilet', fr: 'toilettes' },
  { en: 'toilet-paper', fr: 'papier-toilettes' },
  { en: 'toolbox', fr: 'boite-outils' },
  { en: 'tools', fr: 'outils' },
  { en: 'tooth', fr: 'dent' },
  { en: 'torah', fr: 'torah' },
  { en: 'torii-gate', fr: 'pont-torii' },
  { en: 'tractor', fr: 'tracteur' },
  { en: 'trademark', fr: 'marque deposee' },
  { en: 'traffic-light', fr: 'feu-circulation' },
  { en: 'train', fr: 'train' },
  { en: 'tram', fr: 'tram' },
  { en: 'transgender', fr: 'transgenre' },
  { en: 'transgender-alt', fr: 'transgenre-alt' },
  { en: 'trash', fr: 'corbeille' },
  { en: 'trash-alt', fr: 'corbeille-alt' },
  { en: 'trash-restore', fr: 'corbeille-restaurer' },
  { en: 'trash-restore-alt', fr: 'corbeille-restaurer-alt' },
  { en: 'tree', fr: 'arbre' },
  { en: 'trophy', fr: 'trophe' },
  { en: 'truck', fr: 'camion' },
  { en: 'truck-loading', fr: 'camion-chargement' },
  { en: 'truck-monster', fr: 'monster-truck' },
  { en: 'truck-moving', fr: 'camion-roule' },
  { en: 'truck-pickup', fr: 'camion-pickup' },
  { en: 'tshirt', fr: 'tshirt' },
  { en: 'tty', fr: 'tty' },
  { en: 'tv', fr: 'tv' },
  { en: 'umbrella', fr: 'parapluie' },
  { en: 'umbrella-beach', fr: 'parasol' },
  { en: 'underline', fr: 'souligner' },
  { en: 'undo', fr: 'annuler' },
  { en: 'undo-alt', fr: 'annuler-alt' },
  { en: 'universal-access', fr: 'acces-universel' },
  { en: 'university', fr: 'universite' },
  { en: 'unlink', fr: 'separer' },
  { en: 'unlock', fr: 'deverouiller' },
  { en: 'unlock-alt', fr: 'deverouiller-alt' },
  { en: 'upload', fr: 'upload' },
  { en: 'user', fr: 'utilisateur' },
  { en: 'user-alt', fr: 'utilisateur-alt' },
  { en: 'user-alt-slash', fr: 'utilisateur-alt-slash' },
  { en: 'user-astronaut', fr: 'utilisateur-astronaute' },
  { en: 'user-check', fr: 'utilisateur-check' },
  { en: 'user-circle', fr: 'utilisateur-cercle' },
  { en: 'user-clock', fr: 'utilisateur-horloge' },
  { en: 'user-cog', fr: 'utilisateur-boulon' },
  { en: 'user-edit', fr: 'utilisateur-editer' },
  { en: 'user-friends', fr: 'utilisateur-amis' },
  { en: 'user-graduate', fr: 'utilisateur-diplome' },
  { en: 'user-injured', fr: 'utilisateur-blesse' },
  { en: 'user-lock', fr: 'utilisateur-verouille' },
  { en: 'user-md', fr: 'utilisateur-docteur' },
  { en: 'user-minus', fr: 'utilisateur-moins' },
  { en: 'user-ninja', fr: 'utilisateur-ninja' },
  { en: 'user-nurse', fr: 'utilisateur-infirmier' },
  { en: 'user-plus', fr: 'utilisateur-plus' },
  { en: 'user-secret', fr: 'utilisateur-secret' },
  { en: 'user-shield', fr: 'utilisateur-bouclier' },
  { en: 'user-slash', fr: 'utilisateur-slash' },
  { en: 'user-tag', fr: 'utilisateur-tag' },
  { en: 'user-tie', fr: 'utilisateur-cravate' },
  { en: 'user-times', fr: 'utilisateur-croix' },
  { en: 'users', fr: 'utilisateurs' },
  { en: 'users-cog', fr: 'utilisateurs-boulon' },
  { en: 'utensil-spoon', fr: 'utensile-cuillere' },
  { en: 'utensils', fr: 'utensiles' },
  { en: 'vector-square', fr: 'vecteur-carre' },
  { en: 'venus', fr: 'venus' },
  { en: 'venus-double', fr: 'venus-double' },
  { en: 'venus-mars', fr: 'venus-mars' },
  { en: 'vial', fr: 'eprouvette' },
  { en: 'vials', fr: 'eprouvettes' },
  { en: 'video', fr: 'video' },
  { en: 'video-slash', fr: 'video-slash' },
  { en: 'vihara', fr: 'vihara' },
  { en: 'voicemail', fr: 'repondeur' },
  { en: 'volleyball-ball', fr: 'ballon-volleyball' },
  { en: 'volume-down', fr: 'volume-baisser' },
  { en: 'volume-mute', fr: 'volume-muter' },
  { en: 'volume-off', fr: 'volume-eteindre' },
  { en: 'volume-up', fr: 'volume-monter' },
  { en: 'vote-yea', fr: 'voter-pour' },
  { en: 'vr-cardboard', fr: 'vr-papier-carton' },
  { en: 'walking', fr: 'marcher' },
  { en: 'wallet', fr: 'portefeuille' },
  { en: 'warehouse', fr: 'entrepot' },
  { en: 'water', fr: 'eau' },
  { en: 'wave-square', fr: 'ondes-carres' },
  { en: 'weight', fr: 'poids' },
  { en: 'weight-hanging', fr: 'poids-suspendu' },
  { en: 'wheelchair', fr: 'chaise-roulante' },
  { en: 'wifi', fr: 'wifi' },
  { en: 'wind', fr: 'vent' },
  { en: 'wine-bottle', fr: 'bouteille-vin' },
  { en: 'wine-glass', fr: 'verre-vin' },
  { en: 'wine-glass-alt', fr: 'verre-vin-alt' },
  { en: 'won-sign', fr: 'signe-victoire' },
  { en: 'wrench', fr: 'cle' },
  { en: 'x-ray', fr: 'rayons-x' },
  { en: 'yen-sign', fr: 'signe-yen' },
  { en: 'yin-yang', fr: 'yin-yang' }
];
