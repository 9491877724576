import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import EmptyState from '@atlaskit/empty-state';
import Lottie from 'react-lottie';
import EmptyStateImage from '../../assets/undraw_empty.png';
import NoDataImage from '../../assets/undraw_no_data.png';
import NoLinkAnimation from '../../assets/links/miniapp-empty.json';
import NoTeacherImage from '../../assets/undraw_no_teacher.png';
import NoRoomImage from '../../assets/undraw_no_room.png';
import { ReactComponent as EmptyArrow } from '../../assets/arrow.svg';
import AppLogo from './logo';

const ArrowWrapper = styled.div`
  transform: rotate(-45deg) scale(1.5);
  right: 90px;
  top: 60px;
  position: absolute;
  z-index: 0;
`;

const EmptyStateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: ${props => (props.withMargin ? '10%' : '0')};
  position: relative;
`;

const entityToImage = entityName => {
  return (
    {
      sessions: NoDataImage,
      speakers: NoTeacherImage,
      rooms: NoRoomImage
    }[entityName] || EmptyStateImage
  );
};

// eslint-disable-next-line
export default ({
  entityProps,
  entityName,
  withMargin = false,
  showArrow = true,
  showLogo = false,
  isAnimation = false
}) => {
  const { t } = useTranslation();

  const Img = () => (
    <div>
      {showLogo && <AppLogo width="40%" textalign="center" />}
      <EmptyState
        header={t(`${entityName}:empty:header`)}
        description={t(`${entityName}:empty:description`)}
        imageUrl={entityToImage(entityName)}
        {...entityProps}
      />
    </div>
  );

  const AnimationTextsContainer = styled.div`
    margin-top: 20px;
    text-align: center;
    color: #172b4d;
  `;
  const AnimationHeader = styled.div`
    font-size: 1.4em;
    font-weight: 500;
    margin-bottom: 16px;
  `;
  const AnimationDescription = styled.p`
    margin-bottom: 24px;
  `;
  const Animation = () => (
    <>
      <Lottie
        style={{ maxWidth: 600 }}
        options={{
          loop: true,
          autoplay: true,
          animationData: NoLinkAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        isStopped={false}
        isPaused={false}
      />
      <AnimationTextsContainer>
        <AnimationHeader>{t(`${entityName}:empty:header`)}</AnimationHeader>
        <AnimationDescription>{t(`${entityName}:empty:description`)}</AnimationDescription>
      </AnimationTextsContainer>
    </>
  );

  return (
    <>
      {showArrow && (
        <ArrowWrapper>
          <EmptyArrow />
        </ArrowWrapper>
      )}
      <EmptyStateWrapper withMargin={withMargin}>
        {isAnimation ? <Animation /> : <Img />}
      </EmptyStateWrapper>
    </>
  );
};
