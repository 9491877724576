import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, ErrorMessage, FormFooter as FormFooterWrapper } from '@atlaskit/form';
import Button from '@atlaskit/button';
import TextField from '@atlaskit/textfield';
import Toggle from '@atlaskit/toggle';
import { Checkbox } from '@atlaskit/checkbox';
import styled from 'styled-components';
import SizedTextfield from '../../../utils/sized-textfield';
import IconPicker from './icon-picker/icon-picker';
import ContentEditor from './link-content-editors/content-editor';
import EmbeddedEditor from './link-content-editors/embedded-editor';
import LinkCookBook from './link-cookbok';
import { pick } from 'lodash/fp';
import { ContentTplEditor } from './link-content-editors/content-tpl-editor';
import { DEFAULT_LINK_MODE } from '../../../../constants';
import { getEnv } from '../../../../envOverrides';

const FormFooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.isEditing ? 'flex-end' : 'space-between')};
`;

const Settings = styled.div`
  margin-top: 25px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 0.9em;

  & label[data-checked],
  label[data-checked]:hover {
    background-color: var(--blue-4);
  }
`;

const ToggleText = styled.p`
  margin: 0 4px 0 0;
`;

const DefaultLinkCheckboxText = styled.p`
  margin: 0 2px 0 15px;
`;

const LinkCreatorInput = ({ fieldProps, linkType, linkApp, value, onChange }) => {
  const { i18n } = useTranslation();
  switch (linkType) {
    case 'url':
      return (
        <TextField
          autoComplete="off"
          {...fieldProps}
          onChange={(e) => onChange(e.target.value)}
          value={value}
        />
      );
    case 'embedded':
      return (
        <EmbeddedEditor {...fieldProps} locale={i18n.language} onChange={onChange} value={value} />
      );
    default:
      return linkApp === 'tpl-editor' ? (
        <ContentTplEditor
          {...fieldProps}
          locale={i18n.language}
          onChange={onChange}
          value={value}
        />
      ) : (
        <ContentEditor {...fieldProps} locale={i18n.language} onChange={onChange} value={value} />
      );
  }
};

const LinkCreator = ({ error, ...rest }) => {
  const { t } = useTranslation();
  return (
    <>
      <LinkCreatorInput {...rest} />
      {error && <ErrorMessage>{t(error)}</ErrorMessage>}
    </>
  );
};

// eslint-disable-next-line
export default ({
  link,
  isEditing,
  isLoading,
  onSubmit,
  onBack,
  errors = {},
  showDefaultAppToggle,
  showHideAppToggle
}) => {
  const { t } = useTranslation();
  const [app, setApp] = useState(link);

  const onLinkSubmit = () => {
    return onSubmit(pick(['icon', 'name', 'content', 'is_default', 'is_hidden'])(app));
  };

  return (
    <div style={{ marginBottom: 20 }}>
      <Field name="icon" isRequired>
        {({ fieldProps }) => (
          <IconPicker
            {...fieldProps}
            value={app.icon || 'plane'}
            onChange={(icon) => setApp({ ...app, icon })}
          />
        )}
      </Field>
      <Field label={t('links:form.name')} name="title" isRequired defaultValue={app.name || ''}>
        {({ fieldProps }) => (
          <>
            <SizedTextfield
              autoComplete="off"
              {...fieldProps}
              maxLength={25}
              value={app.name}
              onChange={(e) => setApp({ ...app, name: e.target.value })}
            />
            {errors.name && <ErrorMessage>{t(errors.name)}</ErrorMessage>}
          </>
        )}
      </Field>
      <Field label={t(`links:form.${app.type}`)} name="content" isRequired>
        {({ fieldProps }) => (
          <LinkCreator
            error={errors.content}
            fieldProps={fieldProps}
            linkType={app.type}
            linkApp={app.app}
            value={app.content || ''}
            onChange={(data) => setApp({ ...app, content: data })}
          />
        )}
      </Field>

      <LinkCookBook link={app} />
      <Settings>
        {/*TODO remove to make https://liveedev.atlassian.net/browse/CONF-560 work*/}
        {getEnv('REACT_APP_DEFAULT_MINI_APP') && showDefaultAppToggle && (
          <ToggleWrapper>
            <ToggleText>{t('links:is_default')}</ToggleText>
            <Toggle
              size="large"
              isChecked={!!app.is_default}
              onChange={() =>
                setApp({
                  ...app,
                  is_default: app.is_default ? null : DEFAULT_LINK_MODE.ALWAYS,
                })
              }
            />
            {!!app.is_default && (
              <>
                <DefaultLinkCheckboxText>{t('links:show_once')}</DefaultLinkCheckboxText>
                <Checkbox
                  size="large"
                  isChecked={app.is_default === DEFAULT_LINK_MODE.ONCE}
                  onChange={() =>
                    setApp({
                      ...app,
                      is_default:
                        app.is_default === DEFAULT_LINK_MODE.ONCE
                          ? DEFAULT_LINK_MODE.ALWAYS
                          : DEFAULT_LINK_MODE.ONCE,
                    })
                  }
                />
              </>
            )}
          </ToggleWrapper>
        )}
        {showHideAppToggle && (
          <ToggleWrapper>
            <ToggleText>{t('links:is_hidden')}</ToggleText>
            <Toggle
              size="large"
              isChecked={app.is_hidden}
              onChange={() =>
                setApp({
                  ...app,
                  is_hidden: !app.is_hidden,
                })
              }
            />
          </ToggleWrapper>
        )}
      </Settings>

      <FormFooterWrapper>
        <FormFooterContainer isEditing={isEditing}>
          {!isEditing && (
            <Button
              appearance="default"
              isLoading={isLoading}
              onClick={onBack}
              style={{ order: -2 }}
            >
              {t(`links:form.go_back`)}
            </Button>
          )}
          <Button onClick={onLinkSubmit} appearance="primary" isLoading={isLoading}>
            {t('common:save')}
          </Button>
        </FormFooterContainer>
      </FormFooterWrapper>
    </div>
  );
};
