import React, { useEffect, useRef } from 'react';
import { ErrorMessage, Field } from '@atlaskit/form';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@atlaskit/datetime-picker';
import { addHours, setHours, startOfHour } from 'date-fns/esm';
import styled from 'styled-components';
import { isEmpty } from 'lodash/fp';
import { SizedTextarea, SizedTextfield } from '../../utils/sized-textfield';
import i18n, { LOCALES_MAP } from '../../../i18n/i18n';
import Tip from '../../utils/tip';
import { isDate } from '../../utils/dates';
import { format as fmt } from 'date-fns/esm';
import DEFAULT_TIMES_10_MIN from '../../utils/default-times-10-min';

const FieldsWrapper = styled.div`
  font-weight: 500;
  
  & > div {
    margin-bottom: 15px;
  }
`;

const getDatesRangeError = (date, from, to, t) => {
  // check if the date is after the start date of the conference
  // const eventFromDate = new Date(from);
  const sendAtDate = new Date(date);
  // we decided not to limit a user in sending dates, so it is commented
  // if (sendAtDate < startOfDay(eventFromDate)) {
  //   return t('notifications:errors:past_event', { date: format(eventFromDate, 'P') });
  // }
  //
  // // check if the date is before the end date of the conference
  // const eventToDate = new Date(to);
  //
  // if (startOfDay(sendAtDate) > startOfDay(eventToDate)) {
  //   return t('notifications:errors:after_event', { date: format(eventToDate, 'P') });
  // }

  // check if the date is before today
  if (sendAtDate < new Date()) {
    return t('notifications:errors:past');
  }

  return null;
}

const getDefaultFrom = (from) => {
  const today = new Date();
  const fromDate = new Date(from);

  const res = fromDate > today
    ? startOfHour(setHours(fromDate, today.getHours())) // event start date and current hour
    : startOfHour(addHours(today, 1)); // today date and next hour

  return res.toISOString();
};

const NotificationsForm = ({ notification, isEditingMode, timeFormat, from, to, onChange }) => {
  const { t } = useTranslation();
  const content = useRef(null);
  const validateString = val => (isEmpty((val || '').trim()) ? t('common:form.empty') : null);

  useEffect(() => {
    let observer;
    const contentEl = content.current;

    if (contentEl && isEditingMode) {
      const handleChange = () => {
        onChange(!!contentEl.querySelector("[id$='-error']"));
      };

      observer = new MutationObserver(handleChange);

      observer.observe(contentEl, {
        characterData: true,
        characterDataOldValue: true,
        childList: true,
        subtree: true
      });
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <FieldsWrapper ref={content}>
      <Field
        name="title"
        label={t('notifications:create:title')}
        isRequired
        isDisabled={!isEditingMode}
        defaultValue={notification.title || ''}
        validate={validateString}
      >
        {({ fieldProps, error }) => (
          <>
            <SizedTextfield
              {...fieldProps}
              maxLength={50}
              autoFocus
            />
            {error && <ErrorMessage>{t(error)}</ErrorMessage>}
          </>
        )}
      </Field>
      <Field
        label={t('notifications:send_date')}
        isDisabled={!isEditingMode}
        validate={(val) => {
          // check if the date is invalid
          if (!isDate(val)) {
            return t('notifications:errors:date');
          }

          const datesRangeError = getDatesRangeError(val, from, to, t);

          if (datesRangeError) {
            return datesRangeError;
          }

          return null;
        }}
        name="date"
        defaultValue={notification.date || getDefaultFrom(from)}
        isRequired
      >
        {({ fieldProps, error, meta: { touched } }) => {
          const dateRangeError = isEditingMode && !touched && getDatesRangeError(new Date(fieldProps.value), from, to, t);

          return (
            <>
              <DateTimePicker
                {...fieldProps}
                times={DEFAULT_TIMES_10_MIN}
                timeFormat={timeFormat}
                locale={LOCALES_MAP[i18n.language]}
                datePickerProps={{ minDate: fmt(new Date(), 'yyyy-MM-dd')}}
              />
              {dateRangeError
                ? <ErrorMessage>{dateRangeError}</ErrorMessage>
                : error
                  ? <ErrorMessage>{t(error)}</ErrorMessage>
                  : <Tip>{t('sessions:form:tips:hours')}</Tip>
              }
            </>
          )
        }}
      </Field>
      <Field
        name="body"
        label={t('notifications:create:body')}
        isRequired
        isDisabled={!isEditingMode}
        defaultValue={notification.body || ''}
        validate={validateString}
      >
        {({ fieldProps, error }) => (
          <>
            <SizedTextarea
              {...fieldProps}
              maxLength={250}
            />
            {error && <ErrorMessage>{t(error)}</ErrorMessage>}
          </>
        )}
      </Field>
    </FieldsWrapper>
  );
};

export default NotificationsForm;
