import React, { useCallback, useMemo } from 'react';
import { Slate, Editable, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { createEditor } from 'slate';
import { serialize } from './serialize';
import { withLayout } from './with-layout';
import { deserialize } from './deserialize';
import { Element } from './element';
import isHotkey from 'is-hotkey';
import { Toolbar } from './component';
import { Leaf } from './leaf';
import { BlockButton } from './block-button';
import { HOTKEYS } from './constants';
import { MarkButton, toggleMark } from './mark-button';
import { AddLinkButton, RemoveLinkButton, withInlines } from './with-inlines';

export const SlateEditor = ({ onChange, value, editorClassName }) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withInlines(withLayout(withHistory(withReact(createEditor())))), []);

  const change = (value) => {
    const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type);
    if (!isAstChange) return;

    const html = serialize({ children: value });
    onChange(html);
  };

  return (
    <Slate editor={editor} value={buildValue(value)} onChange={change}>
      <Toolbar>
        <MarkButton format="bold" icon="bold" />
        <MarkButton format="italic" icon="italic" />
        <MarkButton format="underline" icon="underline" />
        <MarkButton format="code" icon="code" />
        <BlockButton format="heading-one" icon="1" />
        <BlockButton format="heading-two" icon="2" />
        <BlockButton format="block-quote" icon="quote-left" />
        <BlockButton format="numbered-list" icon="list-ol" />
        <BlockButton format="bulleted-list" icon="list-ul" />
        <BlockButton format="left" icon="align-left" />
        <BlockButton format="center" icon="align-center" />
        <BlockButton format="right" icon="align-right" />
        <BlockButton format="justify" icon="align-justify" />
        <AddLinkButton />
        <RemoveLinkButton />
      </Toolbar>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck
        autoFocus
        className={editorClassName}
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
      />
    </Slate>
  );
};

const buildValue = (value) => {
  if (!value?.length) return initialValue;
  const v = deserialize(value);
  if (!v?.length) return initialValue;
  return v;
};

const initialValue = [
  {
    type: 'title',
    children: [{ text: '' }],
  },
];
