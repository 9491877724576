import HttpService from './HttpService';

class LinksService extends HttpService {
  entity = 'links';

  create(payload) {
    return this.http
      .post('/link', payload, {
        headers: this.defaultHeaders
      })
      .then(({ data }) => data)
      .catch(this.onError);
  }

  createFake(t, confId, createdAt) {
    return this.create({
      app: 'editor',
      conf_id: confId,
      content: '',
      icon: 'book-open',
      name: t('links:mock:name'),
      type: 'content',
      updated_at: createdAt
    });
  }

  findAllOfConf(confId) {
    return this.http
      .get('/link', {
        params: {
          conf_id: `eq.${confId}`,
          order: 'name.asc'
        }
      })
      .then(({ data }) => data)
      .catch(this.onError);
  }

  update(payload) {
    return this.http
      .patch('/link', payload, {
        headers: this.defaultHeaders,
        params: {
          id: `eq.${payload.id}`
        }
      })
      .then(({ data }) => data)
      .catch(this.onError);
  }

  delete(linkId) {
    return this.http
      .delete(`/link`, {
        headers: this.defaultHeaders,
        params: {
          id: `eq.${linkId}`
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  createSessionLinks(payload) {
    return this.http
      .post('/session_link', payload)
      .then(this.onResponse)
      .catch(this.onError);
  }

  deleteSessionLinks(sessionId) {
    return this.http
      .delete('/session_link', {
        params: {
          session_id: `eq.${sessionId}`
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }
}

const linksService = new LinksService();

export default linksService;
