import React from 'react';
import Sidebar from './sidebar';
import styled from 'styled-components';

const ViewContainer = styled.div`
  display: flex;
`;

const ChildrenContainer = styled.div`
  flex: 1;
`;

const ViewWithSidebar = ({ children }) => (
  <ViewContainer>
    <Sidebar />
    <ChildrenContainer>{children}</ChildrenContainer>
  </ViewContainer>
);

export default ViewWithSidebar;
