const getProfile = state => {
  return (state.auth.user || {}).profile || {};
};

export const isConnectedUserVerified = state => {
  return state.auth.user.profile.email_verified;
};

export const getUserConsent = state => {
  const profile = getProfile(state);

  return profile['https://conf.app/consentGiven'] || false;
};

export const getUserNonProfit = state => {
  const profile = getProfile(state);

  return profile['https://conf.app/nonProfit'] || false;
};
