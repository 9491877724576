const theme = {
  GET_THEME_REQUEST: 'GET_THEME_REQUEST',
  GET_THEME_SUCCESS: 'GET_THEME_SUCCESS',
  GET_THEME_ERROR: 'GET_THEME_ERROR',

  UPDATE_THEME_REQUEST: 'UPDATE_THEME_REQUEST',
  UPDATE_THEME_SUCCESS: 'UPDATE_THEME_SUCCESS',
  UPDATE_THEME_ERROR: 'UPDATE_THEME_ERROR',

  SET_THEME_LOGO: 'SET_THEME_LOGO',
  SET_THEME_BACKGROUND: 'SET_THEME_BACKGROUND',
  SET_THEME_COLOR: 'SET_THEME_COLOR',
  SET_THEME_FONT: 'SET_THEME_FONT',
};

export default theme;