import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import fr from './locales/fr.json';
import en from './locales/en.json';
import de from './locales/de.json';
import es from './locales/es.json';
import pt from './locales/pt.json';
import it from './locales/it.json';
import zh from './locales/zh.json';

export const LOCALES_MAP = {
  en: 'en-US',
  fr: 'fr-FR',
  de: 'de-DE',
  es: 'es-ES',
  pt: 'pt-BR',
  it: 'it-IT',
  zh: 'zh-CN',
};

export const languages = [
  { icon: '🇺🇸', name: 'English', code: 'en' },
  { icon: '🇫🇷', name: 'Français', code: 'fr' },
  { icon: '🇪🇸', name: 'Español', code: 'es' },
  { icon: '🇧🇷', name: 'Português', code: 'pt' },
  { icon: '🇩🇪', name: 'Deutsch', code: 'de' },
  { icon: '🇮🇹', name: 'Italiano', code: 'it' },
  { icon: '🇨🇳', name: '中文', code: 'zh' },
];

const languageCodes = languages.map(lang => lang.code);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: languageCodes,
    resources: {
      fr,
      en,
      es,
      pt,
      de,
      it,
      zh
    },
    ns: ['common'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false
    }
});

export default i18n;
