import React, { useEffect } from 'react';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router';
import { setItem } from '../../services/StorageService';
import { SUPERADMIN_KEY } from '../../constants';

const SuperAdminLogin = ({ match }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setItem(SUPERADMIN_KEY, {
      userId: match.params.userId,
      name: params.get('name')
    });
    window.location = '/';
  }, [match.params.userId]);
  return <div />;
};

export default compose(withRouter)(SuperAdminLogin);
