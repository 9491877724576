import React, { useState } from 'react';
import Button from '@atlaskit/button';
import { useDispatch } from 'redux-react-hook';
import { useTranslation } from 'react-i18next';
import { updateConf } from '../../store/actions/confs';
import { addFlag } from '../../store/actions/flags';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import AdaptiveModalFooter from '../utils/adaptive-modal-footer';

const getActivateBtnTip = (isUpgradable, isActivated, isOverused, t) => {
  const getTip = (text) => ({ 'data-tip': t(text) });

  if(isOverused) {
    return {};
  }

  if(!isActivated && isUpgradable) {
    return getTip('confs:home.test_mode');
  }

  return {};
}

const ActivateButton = ({
  isUpgradable,
  isActivated,
  isOverused = false,
  conf
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isUpgradableModalOpen, setUpgradableModalOpen] = useState(false);

  const activateConf = () => {
    if (!isUpgradable) {
      setUpgradableModalOpen(true);
      return;
    }

    dispatch(updateConf({id: conf.id, activated: true}))
      .then(() => {
        dispatch(
          addFlag({
            appearance: 'success',
            title: 'upgrade:actions.activate.success',
            timeout: 5000
          })
        );
      })
      .catch(() => {
        // do nothing since we've already displayed the flag
        // or the upgrade modal
      });
  };

  return (
    <div
      style={{display: 'inline-block'}}
      data-multiline={true}
      data-effect='solid'
      data-place='left'
      {...getActivateBtnTip(isUpgradable, isActivated, isOverused, t)}
    >
      <Button
        appearance="primary"
        onClick={activateConf}
        isDisabled={isActivated || isOverused}
      >
        {t('common:activate')}
      </Button>
      <ModalTransition>
        {isUpgradableModalOpen && (
          <Modal onClose={() => setUpgradableModalOpen(false)}>
            <ModalHeader>
              <ModalTitle>{t('confs:activate_modal.header')}</ModalTitle>
            </ModalHeader>
            <ModalBody>
             <p style={{marginTop: 0}}>{t('confs:home.is_past')}</p>
             <p style={{marginBottom: 0}}>{t('confs:activate_modal.message')}</p>
            </ModalBody>
            <AdaptiveModalFooter>
              <Button appearance="primary" onClick={() => setUpgradableModalOpen(false)}>
                {t('common:ok')}
              </Button>
            </AdaptiveModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  )
};

export default ActivateButton;
