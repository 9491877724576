import { createSelector } from 'reselect';
import { requestStatus } from '../request-status';
import { isNil } from 'lodash/fp';

const verificationStatus = state => state.account.emailVerification.status;
const accountStatus = state => state.account.status;
const invoicesStatus = state => state.account.invoices.status;
const deletingId = state => state.account.deletion.id;
const sourcesList = state => state.account.sources;
const consentStatus = state => state.account.consent.status;
const is24HourTime = state => state.account.is24HourTime;

export const isSendingEmail = createSelector(
  verificationStatus,
  status => status === requestStatus.WAITING
);

export const isEmailSent = createSelector(
  verificationStatus,
  status => status === requestStatus.DONE
);

export const isAccountLoading = createSelector(
  accountStatus,
  status => status === requestStatus.WAITING
);

export const isInvoicesLoading = createSelector(
  invoicesStatus,
  status => status === requestStatus.WAITING
);

export const getDeletingPaymentMethod = createSelector(
  deletingId,
  id => id
);

export const hasPaymentMethod = createSelector(
  sourcesList,
  sources => !isNil(sources) && sources.length > 0
);

export const isConsentLoading = createSelector(
  consentStatus,
  status => status === requestStatus.WAITING
);

export const getTimeFormat = createSelector(
  is24HourTime,
  is24HourTime => is24HourTime ? 'HH:mm' : 'h:mm a'
)
